import React from 'react';
import homepage from './homepage.jsx';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import MainImage from '../../assets/images/background.jpg';

const style = theme =>({
    cmp_logo:{
      width:125,
      [theme.breakpoints.down('md')]: {
        width: 100
      },
      [theme.breakpoints.down('sm')]:{
        width:80
      }
    },
    login_ul:{
      color:'#000',
      float:'right',
      margin:0,
      cursor:'pointer',
      listStyleType:'none',
      [theme.breakpoints.down('md')]:{
        padding:0
      }
    },
    main_content:{
      [theme.breakpoints.down('md')]:{
        fontSize:25,
        textAlign:'center'
      }
    },
    background_div:{
      backgroundImage: `url(${MainImage})`,
      height:'430px',
      backgroundSize:'cover',
      paddingTop:80,
      [theme.breakpoints.down('md')]:{
        paddingTop:10,
        paddingBottom:40,
        height:'100%'
      }
    },
    bold_text:{
      fontWeight:500
    },
    main_text:{
        textAlign:'left',
        width:'100%',
        marginTop:10,
        fontSize:15
    },
    loginModal:{
      width:'310px',
      padding:'20px',
      [theme.breakpoints.down('md')]:{
        width:'250px',
        padding:20
      },
      
    }
})

class Homepage extends React.Component {
    state={open:false,redirect: false}
    handleClickOpen = () => {
        this.setState({
          open:true
        })
      };      
    handleClose = () => {
        this.setState({
          open:false
        })
      };
      componentDidMount(){
        let seller = localStorage.getItem('seller')
        if(seller !== null){
          this.setState({
            redirect: true
          })
        }
      }
  render() {
    if(this.state.redirect){
      return <Redirect to="/dashboard" />
    }
    return homepage.call(this);
  }
}

export default withStyles(style)(Homepage);
import React from 'react';
import Alert from '@material-ui/lab/Alert';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { Grid,TextField,FormLabel,Button,FormGroup,FormControl,FormControlLabel,Checkbox,Chip,Dialog,Tooltip,Fab,DialogTitle,DialogContent,DialogActions,Snackbar,Popover,Typography,Hidden } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from 'react-select';
import {MuiPickersUtilsProvider,KeyboardTimePicker} from '@material-ui/pickers';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';

function warehouseDetails(){
    const {classes} = this.props;
    return (
        <React.Fragment>
            {
                this.props.isLoadingAddress
                        ?
                <React.Fragment>
                    <Skeleton 
                        variant="rect" 
                        height={90} 
                        className={classes.mb_10}
                        />
                    <Skeleton
                        variant="rect" 
                        height={90} 
                        className={classes.mb_10}
                        />
                    <Skeleton 
                        variant="rect" 
                        height={90} 
                        className={classes.mb_10}
                        />
                    <div className={classes.text_center}>
                        <Skeleton 
                            variant="rect" 
                            height={50} 
                            width={100} 
                            className={classes.mar_auto} 
                        />
                    </div>
                </React.Fragment>
                        :
                <React.Fragment>
                    <ul className={classes.addressUl}>
                        {
                            this.props.address.map((item,index)=>{
                                let main_address = item.address;
                                let city = item.city;
                                let state = item.state;
                                let pincode = item.pincode;
                                let gstin = item.gstin;
                                let s_time = "";
                                let e_time = "";
                                // console.log(item.address,item.working_hours )
                                if(item.working_hours && item.working_hours !== null){
                                    // console.log(item.working_hours)
                                    let start_time = item.working_hours.split(" ");
                                    // console.log(item.working_hours);
                                    s_time = new Date('2020-01-18T'+start_time[0]).toLocaleTimeString('en-IT',{hour:'2-digit',minute:'2-digit'});
                                    e_time = new Date('2020-01-18T'+start_time[2]).toLocaleTimeString('en-IT',{hour:'2-digit',minute:'2-digit'});
                                }else{
                                    s_time = "";
                                    e_time = "";
                                }
                                let address = main_address + ", " + city + ", " + state + ", " + pincode
                                return  (
                                        <li 
                                            key={item.address_id} 
                                            className={classes.addressList}
                                        >
                                            <ul 
                                                className={classes.address_ul}
                                            >
                                                <li 
                                                    className={classes.address_li}
                                                >
                                                    <span style={{verticalAlign:'top'}}>
                                                        Address : 
                                                    </span>
                                                    <span 
                                                        className={classes.full_address}
                                                    >
                                                        {address}
                                                    </span>
                                                    {/* {
                                                        address.length > 40
                                                                ?
                                                            <Hidden only={['sm','xs']}>
                                                            <InfoIcon 
                                                                onMouseEnter={this.openPopover}
                                                                onMouseLeave={this.closePopover}
                                                                aria-owns={this.state.popoveropen ? 'mouse-over-popover' : undefined}
                                                                aria-haspopup="true"
                                                                className={classes.address_icon}
                                                                address_id={item.address_id}
                                                                index={index}
                                                            />
                                                            <Popover
                                                                id="mouse-over-popover"
                                                                className={classes.popover}
                                                                classes={{
                                                                    paper: classes.paper,
                                                                }}
                                                                open={this.state.popoveropen}
                                                                anchorEl={this.state.Popover}
                                                                anchorOrigin={{
                                                                    vertical: 'top',
                                                                    horizontal: 'center',
                                                                }}
                                                                transformOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'center',
                                                                }}
                                                                onClose={this.closePopover}
                                                                disableRestoreFocus
                                                                >
                                                                <Typography>
                                                                    {
                                                                        this.state.fullAddress
                                                                    }
                                                                </Typography>
                                                                </Popover>
                                                            </Hidden>
                                                                :
                                                            null
                                                    } */}
                                                </li>
                                                <li 
                                                    className={classes.address_li}
                                                >
                                                    <span>
                                                        GSTIN :
                                                    </span>
                                                    <span
                                                        className={classes.full_time}
                                                    >
                                                        {item.gstin}
                                                    </span>
                                                </li>
                                                <li 
                                                    className={classes.address_li}
                                                >
                                                    <span>
                                                        Working Hours :
                                                    </span>
                                                    <span
                                                        className={classes.full_time}
                                                    >
                                                        {s_time} - {e_time}
                                                    </span>
                                                </li>
                                                <li 
                                                    className={classes.address_li}
                                                >
                                                    <span>
                                                        Weekly Off :
                                                    </span>
                                                    <span 
                                                        className={classes.full_address_weekly}
                                                    >
                                                        {
                                                            item.weekly_on_off
                                                        }
                                                    </span>
                                                </li>
                                                <li className={classes.address_li}>
                                                <span>
                                                        Address Status :
                                                    </span>
                                                    <span className={item.address_verified === 1 ? classes.verified : classes.not_verified}>
                                                        {
                                                            item.address_verified === 1
                                                                    ?
                                                            "Verified"
                                                                    :
                                                            "Not Verified"
                                                        }
                                                    </span>
                                                </li>
                                                <li>
                                                    <Hidden only={"lg"}>
                                                    <div className={classes.mar_top_10}>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            id={index} 
                                                            onClick={this.AddEditAddress} 
                                                            address_id={item.address_id}
                                                            className={classes.mar_5}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            address_id={item.address_id} 
                                                            onClick={this.openDeleteDialog}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </div>
                                                    </Hidden>
                                                </li>
                                            </ul>
                                            <Hidden only={['xs','sm']}>
                                            <div 
                                                className={classes.f_right}
                                            >
                                                {
                                                    item.address_verified === 1 ?
                                                    ""
                                                    :
                                                    <Tooltip 
                                                    title="Edit" 
                                                    placement="top"
                                                >
                                                    <Fab 
                                                        color="primary" 
                                                        size="small" 
                                                        id={index} 
                                                        onClick={this.AddEditAddress} 
                                                        address_id={item.address_id}
                                                    >
                                                        <Edit />
                                                    </Fab>
                                                </Tooltip>
                                                }
                                                
                                                <Tooltip 
                                                    title="Delete" 
                                                    placement="top"
                                                >
                                                    <Fab 
                                                        color="secondary" 
                                                        size="small" 
                                                        className={classes.ml_5} 
                                                        address_id={item.address_id} 
                                                        onClick={this.openDeleteDialog}
                                                    >
                                                        <Delete />
                                                    </Fab>
                                                </Tooltip>
                                            </div>      
                                            </Hidden>
                                        </li>
                                    )
                            })
                        }                  
                    </ul>
                    <div 
                        style={{padding:10,textAlign:'center'}} 
                        className={classes.addressList}
                    >
                        <Button 
                            variant="contained" 
                            color="primary" 
                            id={this.props.address.length} 
                            onClick={this.AddEditAddress} 
                            address_id="0"
                            >
                            <AddIcon />
                            Add New Address
                        </Button> 
                    </div>
                </React.Fragment>
            }
            <Dialog 
                open={this.state.openDialog} 
                onClose={null}
                >
            <DialogTitle>
                Add/Edit address
            </DialogTitle>
            <DialogContent>
            <form 
                onSubmit={this.submitWareHouseDetails} 
                className={classes.AddressForm}
            >
                {
                    this.props.showSame
                        ?
                    <FormGroup style={{marginBottom:10}}>
                        <FormControl>
                            <FormControlLabel
                                value="end"
                                control = {
                                            <Checkbox 
                                                color="primary" 
                                                onChange={this.props.same} 
                                                checked={this.props.checked}
                                            />
                                        }
                                label="Same as Business Address"
                                labelPlacement="end"
                                />
                        </FormControl>
                    </FormGroup>
                        :
                    null
                }
                <TextField 
                    variant="outlined" 
                    fullWidth 
                    label="Registered Warehouse Address"
                    name="address"
                    value={this.state.address} 
                    style={{marginBottom:20}}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.address}
                    helperText={this.state.submitted && !this.state.address ? "Enter valid address" : ""}
                    inputRef={this.addressRef}
                    margin="dense"
                    autoFocus
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth 
                    label="Pincode"
                    name="pincode"
                    value={this.state.pincode} 
                    style={{marginBottom:20}}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.pincode}
                    helperText={this.state.submitted && !this.state.pincode ? "Enter valid pincode" : ""}
                    inputRef={this.pincodeRef}
                    margin="dense"
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth 
                    label="City"
                    name="city"
                    value={this.state.city} 
                    style={{marginBottom:20}}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.city}
                    helperText={this.state.submitted && !this.state.city ? "Enter valid city" : ""}
                    inputRef={this.cityRef}
                    margin="dense"
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth 
                    label="State"
                    name="State"
                    value={this.state.State} 
                    style={{marginBottom:20}}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.State}
                    helperText={this.state.submitted && !this.state.State ? "Enter valid State" : ""}
                    inputRef={this.StateRef}
                    margin="dense"
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth 
                    label="GSTIN"
                    name="gstin"
                    value={this.state.gstin} 
                    style={{marginBottom:20}}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.gstin}
                    helperText={this.state.submitted && !this.state.gstin ? "Enter valid GSTIN" : ""}
                    inputRef={this.gstinRef}
                    margin="dense"
                    >
                </TextField>
                <FormLabel 
                    style={{float:'left'}}
                    >
                    Working Hours
                </FormLabel>
                <Grid container spacing={3} style={{marginBottom:20}}>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                margin="normal"
                                id={"start-time"}
                                label="From"
                                onChange={this.handleStartChange}
                                value={this.state.start_time}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                                margin="normal"
                                id={"end-time"}
                                label="To"
                                value={this.state.end_time}
                                onChange={this.handleEndChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                </Grid>
                <FormLabel>
                    Weekly Off
                </FormLabel>
                <Select
                    isMulti={true}
                    options={this.options}
                    onChange={this.selectChange}
                    value={this.state.selectedOptions}
                    ref={this.WeeklyOffRef}
                >
                </Select>
                    {
                        this.state.submitted && !this.state.selectedOptions.length>0
                                    ?
                        <p 
                            style={{
                                color:'red',
                                textAlign:'left',
                                fontSize:14}}
                        >
                            Please Select weekly off 
                        </p>
                            :
                            null
                    }
                    <div style={{padding:20,textAlign:'center'}}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            className={classes.mar_5} 
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            className={classes.mar_5} 
                            onClick={this.closeDialog}
                        >
                            Cancel
                        </Button>
                    {
                        this.props.isDeletable
                            ?
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={this.deleteAddress}
                        >
                            Delete
                        </Button>
                            :
                            null
                    }
                </div>  
            </form>
            </DialogContent>
            </Dialog>
            <Dialog open={this.state.deleteDialog}>
                <DialogTitle>
                    Delete Address
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this Address.
                </DialogContent>
                <DialogActions>
                    <Button 
                        color="primary" 
                        onClick={this.deleteAddress} 
                        variant="contained"
                    >
                        Delete
                    </Button>
                    <Button 
                        color="primary" 
                        onClick={this.cancelDeletion} 
                        variant="contained"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar 
                open={this.state.alertMessage} 
                anchorOrigin={{
                        horizontal:"center",
                        vertical:"top"
                        }} 
            >
                <Alert 
                    severity={
                            this.state.isError 
                                ? 
                            "error" 
                                :
                            "success"
                            } 
                    >
                    {
                        this.state.message
                    }
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
export default warehouseDetails;
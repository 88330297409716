import React, { Component } from 'react';
import { AppBar,Toolbar,IconButton,Paper,Badge,Menu,MenuItem,SwipeableDrawer,List,ListItem,ListItemIcon,ListItemText,Collapse,TextField,CircularProgress,Tooltip } from '@material-ui/core';
import {Link } from 'react-router-dom';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccountCircle  from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { userActions } from '../../../actions';
import { connect } from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Logo from '../../../assets/images/Mccoy-mart-logo.png';
import axios from 'axios';
import siteConfig from '../../../config';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ContactsRoundedIcon from '@material-ui/icons/ContactsRounded';

let apiUrl = siteConfig.apiUrl;


const StyledBadge = withStyles((theme) => ({
    badge: {
      right: -5,
      top: 0,
      background:'transparent',
      color :'red',
      padding: '0 3px',
      fontSize:9,
      fontWeight:'bold',
      borderRadius:'6px'
    },
  }))(Badge);

  const StyledInnerBadge = withStyles((theme) => ({
    badge: {
      right: -60,
      top: 3,
      background : 'transparent',
      color : 'red',
      padding: '0 3px',
      fontSize:9,
      fontWeight:'bold',
      borderRadius:'6px'
    },
  }))(Badge);

const styles = theme => ({
    heading : {
        display:'none',
        [theme.breakpoints.up('md')]:{
            display:'block',
        }
    },
    menuButton:{
        display:'block',
        color:"#000",
        [theme.breakpoints.up('md')]:{
            display:'none'
        }
    },
    desktopMenu:{
        display:'none',
        marginLeft:50,
        [theme.breakpoints.up('md')]:{
            display:'block'
        }
    },
    root:{
        width: '200px',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    link:{
        color:"#666",
        textDecoration:'none',
        padding:'5px 16px',
        width:'100%'
    },
    list_l:{
        padding:0
    },
    Maintext:{
        display:"inline-block",
        fontSize:15,
    },
    exp_icon:{
        verticalAlign:'middle',
        float:'right'
    },
    submenu:{
        textDecoration:'none',
        color:"#666"
    },
    submenuText:{
        padding:0,
        margin:0,
        marginLeft:10
    },
    mainLink:{
        display:"inline-block",
        fontWeight:500,
        color: '#333',
        padding:15,
        paddingRight:0,
        position:'relative',
        textDecoration:'none'
    },
    linkMainDiv:{
        display:'inline-block',
        paddingRight:20
    },
    ContactsRoundedIcon:{
        color : '#666',
        marginRight:10
    }
})



class DashboardNavbar extends Component {
    state ={
        navId : 0,
        isMenuOpen:false,
        isUserMenuOpen:false,
        isNotificationMenuOpen : false,
        menutarget:null,
        notificationTarget:null,
        opendrawer:false,
        isMobileMenuOpen :false,
        itemId : 0,
        vendors : [],
        search : '',
        loading : false,
        selectedBrandName : '',
        selectedBrandId : '',
        search : '',
        superUser : false
    }


    componentDidMount(){
        // console.log(localStorage.getItem('is_superUser'));
        
        // setTimeout(() => {
            let superUser = localStorage.getItem('is_superUser');
            let selectedVendor = localStorage.getItem('selectedVendor');

        // console.log('superUser>>>>',superUser)
        if(superUser > 0){
            let userlist = this.getAllUser().then(data=> {
                let index = this.state.vendors.findIndex(x=>x.vendor_id == selectedVendor);
                let selectedBrand = this.state.vendors[index].vendor_name;
                this.setState({
                    selectedBrandName : selectedBrand
                })
            }).catch(error=>error);
            }
        // },500);
    }


    getAllUser = () => {
        return new Promise((resolve,reject)=>{
            let superUser = JSON.parse(localStorage.getItem('superUser'));
        if(superUser){
            this.setState({
                superUser : true
            },()=>{
                let token = superUser.access_token;
        // console.log(token);
        const requestOptions = {
            method : "Get",
            headers : {
                'Authorization': 'Bearer ' + token
            }
        }
        return axios(apiUrl+'/getAllUsersList',requestOptions)
        .then(data=>{
            let vendorData = data.data.data;
            let filteredData = vendorData.filter(item=>{
                return item.vendor_name != ""
            })
            let sortedData = filteredData.sort((a,b)=> (a.vendor_name > b.vendor_name) ? 1 : -1)
            // console.log(sortedData);
                this.setState({
                vendors : sortedData
            })
            resolve(true)
        })
        .catch(error=>reject(error))
            })
        }
        })
        // console.log('called')    
    }

    setValue=(e)=>{
        if(e.target.value !== ''){
            this.setState({
                selectedBrandName : e.target.value
                },()=>{
                    let index = this.state.vendors.findIndex(x=>x.vendor_name === this.state.selectedBrandName)
                    let brand = this.state.vendors[index];
                    // console.log(brand)
                    this.setState({
                        selectedBrandId : brand
                    },()=>{
                        this.props.getSelectedBrand(this.state.selectedBrandId)
                    })
               })
        }
     }

    menuOpenHandler = (e) =>{
        let id = e.currentTarget.getAttribute('id');
        this.setState({
            isMenuOpen : true,
            navId:id
        })
    }
    closeMenu =()=>{
        this.setState({
            isMenuOpen:false
        })
    }
    closeUserMenu = () => {
        this.setState({
            menutarget:null,
            isUserMenuOpen : false
        })
    }
    closeNotificationMenu = () => {
        this.setState({
            notificationTarget : null,
            isNotificationMenuOpen : false
        })
    }
    opendrawer =()=>{
        this.setState({
            opendrawer : !this.state.opendrawer,
            itemId : 0
        })
    }
    toggleUserMenu = (e) => {
        this.setState({
            menutarget:e.currentTarget,
            isUserMenuOpen : true
        })
    }
    toggleNotificationMenu = (e) => {
        this.setState({
            notificationTarget : e.currentTarget,
            isNotificationMenuOpen : true
        })
    }

    setSubmenuOpen=(e)=>{
        let id = e.currentTarget.id;
        this.setState({
            isMobileMenuOpen : !this.state.isMobileMenuOpen,
            itemId : id
        })
    }
    setSearch = (e) => {
        this.setState({
            search : e.target.value
        })
    }
    logout=()=>{
        this.props.logout()
    }
    changeInput=(e)=>{
        console.log(e)
    }
    render() {
      const {classes}=this.props;
    return (
            <AppBar style={{backgroundColor:"#fff"}} >
            <Toolbar>
                <Link 
                    to="/dashboard" 
                    className={classes.heading}
                >
                    <img 
                        src = {
                            Logo
                                } 
                            alt = {
                                this.props.logo.alt
                            } 
                            style={{
                                    width:150
                                }}
                    />
                </Link>
                <IconButton 
                    edge="start" 
                    className={classes.menuButton} 
                    color="inherit" 
                    aria-label="menu" 
                    onClick={this.opendrawer}
                >
                    <MenuIcon />
                </IconButton>
                <div 
                    className="header_item" 
                    className={classes.desktopMenu}
                >
                    {
                        this.props.menu.map((item,index)=>{
                            return  (
                                        <div 
                                            key={item.title} 
                                            id={index} 
                                            className={classes.linkMainDiv} 
                                            onMouseOver={this.menuOpenHandler} 
                                            onMouseLeave={this.closeMenu}
                                        >
                                            {
                                                <Link 
                                                id={index} 
                                                className={classes.mainLink} 
                                                to={
                                                        item.submenu 
                                                            ? 
                                                        "#" 
                                                            : 
                                                        item.action
                                                    } 
                                            >
                                                    {
                                                        item.title
                                                    }
                                            </Link>}
                                            {
                                                item.title == 'Payments' || item.title == 'Reports'
                                                        ?
                                            <StyledBadge
                                                badgeContent={'New'}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                style={{
                                                    top:'-14px'
                                                }}
                                                >
                                            </StyledBadge>
                                            :
                                            null
                                            }
                                        {
                                            item.submenu
                                                ?
                                                this.state.isMenuOpen && index == this.state.navId
                                                        ?
                                                <ExpandLessIcon 
                                                    style={{verticalAlign:"middle"}} 
                                                    fontSize="small" 
                                                    color="action" 
                                                />                
                                                        :
                                                <ExpandMoreIcon 
                                                    style={{verticalAlign:"middle"}} 
                                                    fontSize="small" 
                                                    color="action"
                                                /> 
                                                :
                                                null
                                        }
                                        {
                                            item.submenu
                                                ?
                                            <Paper 
                                                style={
                                                    this.state.isMenuOpen && index == this.state.navId
                                                                ?
                                                        {
                                                            position:'absolute',
                                                            visibility:"visible"
                                                        }
                                                                :
                                                        {
                                                            position:'absolute',
                                                            visibility:"hidden"
                                                        }} 
                                                    id={index} 
                                                    onMouseOver={this.menuOpenHandler} 
                                                    onMouseLeave={this.closeMenu}
                                            >
                                                <ul 
                                                    style={
                                                            {
                                                                margin:0,
                                                                padding:"5px 0",
                                                                listStyleType:"none",
                                                                borderRadius:5
                                                            }
                                                        }
                                                >
                                                    {
                                                        item.submenu.map((subItem,index)=>{
                                                            return  (
                                                                    <li 
                                                                        className="nav_sub_item" 
                                                                        key={
                                                                                subItem.title
                                                                            }
                                                                        >
                                                                        <Link 
                                                                            id={index} 
                                                                            to={subItem.action} className="nav_sub_menu_link" onMouseOver={this.menuOpenHandler}
                                                                            onClick={this.closeMenu}
                                                                        >
                                                                            {
                                                                                subItem.title
                                                                            }
                                                                        </Link>
                                                                        {
                                                                            subItem.title == 'Invoices'
                                                                                    ?
                                                                        <StyledInnerBadge
                                                                            badgeContent={'New'}
                                                                            anchorOrigin={{
                                                                                vertical: 'top',
                                                                                horizontal: 'right',
                                                                            }}
                                                                            style={{
                                                                                position:'absolute',
                                                                                top:22,
                                                                                right:90
                                                                            }}
                                                                            >
                                                                        </StyledInnerBadge>
                                                                        :
                                                                        null
                                                                        }
                                                                    </li>
                                                                )
                                                                    
                                                        })    
                                                    }
                                                </ul>
                                            </Paper>
                                                :
                                            null
                                        }
                                    </div>
                                )
                        })
                    }
                </div>
                <div 
                    className="header_menu_right"
                >
                    {
                        this.state.superUser    
                                ?
                                <Autocomplete
                                id="brands"
                                style={{float:'left'}}
                                options={this.state.vendors}
                                getOptionLabel={(option) => option.vendor_name}
                                renderInput={(params) => <TextField 
                                    style={{width:'100%'}} 
                                    {...params} 
                                    label={this.state.selectedBrandName} 
                                    variant="outlined" 
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <React.Fragment>
                                            {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                          </React.Fragment>
    
                                        ),
                                      }}
                                    onChange={this.setSearch}
                                    // value={this.state.selectedBrandId}
                                    value={this.state.selectedBrandName}
                                    // onInputChange={this.changeInput}
                                    onFocus={this.state.search.length > 0 ? this.getAllUser:null}
                                    style={{marginTop:5,width:300,marginLeft:50,display:'inline-block'}}
                                    // loading={this.state.loading}
                                    // onChange={this.setValue}
                                    onSelect={this.setValue}
                                    // onChange={this.cancelEvent}
                                    margin="dense"
                                    />}  
                            />
                            :
                            null

                    }
                    <div 
                        className="header_menu_r_items"
                    >
                        <Link to="/support" style={{verticalAlign:'middle'}}>
                            <Tooltip title="Contact us">
                                <IconButton 
                                    aria-label="show 17 new notifications" 
                                    color="inherit"
                                    >
                                    <Badge 
                                        badgeContent={
                                                    this.props.notificationCount
                                                    } 
                                        color="secondary"
                                    >
                                        <ContactsRoundedIcon 
                                            color="action"
                                        />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Tooltip title="notifications">
                            <IconButton 
                                aria-label="show 17 new notifications" 
                                color="inherit"
                                onClick={this.toggleNotificationMenu}
                            >
                                <Badge 
                                    badgeContent={
                                                this.props.notificationCount
                                                } 
                                    color="secondary"
                                >
                                    <NotificationsIcon 
                                        color="action"
                                    />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Click to open user menu">
                            <IconButton 
                                onClick={this.toggleUserMenu}
                            >
                                <AccountCircle />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>
            <Menu 
                style={{marginTop:20}}
                open={this.state.isNotificationMenuOpen} 
                anchorEl={this.state.notificationTarget} 
                anchorOrigin={
                                {
                                    vertical:"top",
                                    horizontal: "right"
                                }
                            }
                transformOrigin={
                                    {
                                        vertical:"top",
                                        horizontal: "right"
                                    }
                                } 
                keepMounted 
                onClose={this.closeNotificationMenu} 
                transitionDuration={
                                        {
                                            enter:300,
                                            exit:200
                                        }
                                    }
            >
                <div style={{padding:20}}>
                    No Notification yet
                </div>
            </Menu>
            <Menu 
                style={{marginTop:20}}
                open={this.state.isUserMenuOpen} 
                anchorEl={this.state.menutarget} 
                anchorOrigin={
                                {
                                    vertical:"top",
                                    horizontal: "right"
                                }
                            }
                transformOrigin={
                                    {
                                        vertical:"top",
                                        horizontal: "right"
                                    }
                                } 
                keepMounted 
                onClose={this.closeUserMenu} 
                transitionDuration={
                                        {
                                            enter:300,
                                            exit:200
                                        }
                                    }
            >
                {
                    this.props.userMenu.map(item=>{
                        return <Link 
                                    to={item.title==='Logout'?'#':item.action}
                                    onClick={
                                                item.title==='Logout'
                                                    ? 
                                                this.logout 
                                                    :
                                                this.closeUserMenu
                                            }
                                    key={item.title} 
                                    className="r_user_menu"
                                > 
                                    <MenuItem>
                                        {
                                            item.title
                                        }
                                    </MenuItem>
                                </Link>
                            })
                }
            </Menu>
            <SwipeableDrawer 
                onOpen={this.opendrawer} 
                open={this.state.opendrawer} 
                onClose={this.opendrawer}
            >
            <div style={{padding:'10px 20px'}}>
                <img 
                    src={Logo} 
                    alt={this.props.logo.alt} 
                    style={{width:100}}
                />   
            </div>
            <List
                component="nav"
                classes={{root :classes.root}}
            >
                {
                    this.props.menu.map((item,index)=>(
                        <React.Fragment key={item.action}>
                        <ListItem 
                            button 
                            key={item.action} 
                            classes={
                                        {
                                            gutters:classes.list_l
                                        }
                                    }
                        >
                            <Link 
                                to={
                                        item.submenu 
                                            ?
                                        "#"
                                            : 
                                        item.action
                                    } 
                                onClick={
                                            item.submenu 
                                                ? 
                                            this.setSubmenuOpen 
                                                :  
                                            this.opendrawer
                                        } 
                                id={index} 
                                className={classes.link}
                            >
                                <ListItemText 
                                    primary={item.title} 
                                    className={classes.Maintext}
                                />
                                    {
                                        item.submenu    
                                            ?
                                        this.state.isMobileMenuOpen && this.state.itemId == index   
                                                    ?
                                        <ExpandLess className={classes.exp_icon}/>
                                                    :
                                        <ExpandMore className={classes.exp_icon}/>
                                                    :
                                                null
                                    }
                            </Link>
                        </ListItem>
                        {
                            item.submenu  
                                ?
                            <Collapse 
                                in = {
                                        this.state.isMobileMenuOpen && this.state.itemId == index
                                    }
                                timeout="auto" 
                                unmountOnExit
                            >
                                <List 
                                    component="div" 
                                    disablePadding
                                >
                                    {
                                        item.submenu.map(val=>(
                                            <Link 
                                                to={val.action} 
                                                key={val.title} 
                                                onClick={this.opendrawer} 
                                                className={classes.submenu}
                                            >
                                                <ListItem 
                                                    button 
                                                    key={val.title}
                                                >
                                                    <ListItemText 
                                                        className={classes.submenuText}
                                                    >
                                                        {val.title}
                                                    </ListItemText>
                                                </ListItem>
                                            </Link>
                                        ))
                                    }
                                </List>
                            </Collapse>
                            :
                            null  
                        }
                        </React.Fragment>
                    ))
                }
                </List>
            </SwipeableDrawer>
            </AppBar>
    );
  }
}

const actionCreator = {
    logout : userActions.logout
}
export default  connect(null,actionCreator)(withStyles(styles)(DashboardNavbar));
import React, { Component } from 'react';
import bankDetails from './bankDetails.jsx';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import siteConfig from '../../../../config';
import { authHeader } from '../../../../helper/auth-header.js';


const apiUrl = siteConfig.apiUrl;
const maxImageSize = 8e+6
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map(item=>{return item.trim()})
const image_url = siteConfig.image_url;

const styles = theme => ({
    editAccount:{
        position:'absolute',
        right:10,
        top:10
    },
    addressList:{
        border:'1px solid #eaeaea',
        boxShadow:' 0 2px 4px 0 rgba(0,0,0,.15)',
        borderRadius:5,
        marginBottom:10,
        padding:10
    },
    accountUl:{
        listStyleType:'none',
        padding:0,
        paddingTop: 10,
    },
    AccountForm:{
        paddingTop:30
    },
    contentmessage:{
        fontSize:14,
        width:'100%',
        marginBottom:20
    },
    actionsblock:{
        padding:'8px 24px'
    },
    image_div:{
        border:'1px solid #eaeaea',
        boxShadow:' 0 2px 4px 0 rgba(0,0,0,.15)',
        borderRadius:5,
        marginBottom:10,
        padding:10,
        position:'relative'
    },
    actionButton:{
        position:'absolute',
        top:20,
        right:20
    },
    textCenter:{
        textAlign:'center'
    },
    w_100:{
        width:100
    },
    text:{
        fontSize:14,
        fontWeight:'normal',
        width:'100%',
        marginTop:10,
        color:"#999"
    },
    acc_paper:{
        marginBottom:10,
        padding:20,
        position:'relative'
    },
    acc_ul:{
        padding:0,
        margin:0,
        listStyleType:'none'
    },
    acc_li:{
        lineHeight:'25px'
    },
    acc_label:{
        fontSize:14,
        color:'#666',
    },
    formContent:{
        fontSize:15,
        color:"#333",
        marginLeft:5
    },
    defaultMark:{
        position:'absolute',
        top:20,
        right:20
    },
    default_tag:{
        width:'100%',
        background: '#fb6a6a',
        fontSize: 14,
        color: '#fff',
        padding: 5,
        borderRadius: 5
    },
    add_n_heading:{
        fontSize:18,
        margin:0,
        fontWeight:500,
        display:'inline-block'
    },
    addButton:{
        float:'right'
    },
    cheque:{
        border:'1px solid #ccc',
        borderRadius:'5px',
        padding:20
    },
    cheque_heading:{
        fontSize:16,
        margin:0,
        fontWeight:500,
        display:'inline-block',
        color: '#333'
    },
    errorChequeHeading:{
        fontSize:16,
        margin:0,
        fontWeight:500,
        display:'inline-block',
        color : 'red'
    },
    chequeImagediv:{
        width:'100%',
        height:100
    },
    cheque:{
        width:'100%',
        height:'100%'
    },
    buttonDiv:{
        textAlign:'center',
        margin : '20px 0'
    },
    imageError:{
        color:'red',
        fontSize: '12px',
        display : "inline-block",
        width:'auto',
        marginLeft:'10px'
    },
    pdfDiv:{
        textAlign : 'center'
    },
    helperPara:{
        textAlign:'center',
        width:'100%',
        fontSize : 14,
        color: "#666"
    },
    uploadPdfDiv:{
        marginTop : 20,
        textAlign : 'center'
    }
})

class BankDetails extends Component{
    constructor(props){
        super(props);
        this.state={
            isLoading : false,
            submitted:false,
            name : '',
            account_no:'',
            confirmAccount:'',
            ifsc:'',
            branch:'',
            bank_address:'',
            bank_name:'',
            bank_city:'',
            bank_state:'',
            openDialog:false,
            otp:'',
            mobile:'',
            snackOpen:false,
            isSendingOTP : false,
            submitOTP:false,
            cheque_image : null,
            cheque_image_file : null,
            isError:false,
            message:'',
            AccountList:[],
            open:false,
            bankItem : ''
        }
        this.nameRef = React.createRef('name');
        this.account_no = React.createRef('account_no');
        this.confirmAccountRef = React.createRef('confirmAccountNumber');
        this.ifsc = React.createRef('ifsc');
        this.bank_name = React.createRef('bank_name');
        this.branch = React.createRef('branch');
        this.bank_address = React.createRef('bank_address');
        this.bank_city = React.createRef('bank_city');
        this.bank_state = React.createRef('bank_state');
        this.otpRef = React.createRef('otp');
    }
    componentDidMount(){
        this.getAccountDetails()
    }
    getAccountDetails =()=>{
        this.setState({
            isLoading : true
        },()=>{
            const requestOptions={
                method : "GET",
                headers : authHeader(),
            }
            return axios(apiUrl+'/bank_details',requestOptions)
            .then(data=>{
                this.setState({
                    AccountList : data.data.data,
                    isLoading:false
                })
            })
            .catch(err=>{
                this.setState({
                    isError :true,
                    open:true,
                    message:"Error in fetching details",
                    isLoading:false
                  },()=>{
                    setTimeout(() => {
                      this.setState({
                        open:false,
                        isError:false,
                        message:""
                      })
                    }, 3000);
                  })
            })
        })
    }
    handleChange=(e)=>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    cancelAccountEdit=()=>{
        this.setState({
            IsAccountEditing:false
        })
    }
    openDialog = () =>{
        this.setState({
            openDialog : true
        })
    }
    closeDialog = () => {
        this.setState({
            openDialog : false,
            cheque_image_file : null,
            cheque_image : null,
            submitted : false
        })
    }

    verifyFile =(files)=> {
        if(files){
            const currentFile = files
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > maxImageSize){
              this.setState({
                isError :true,
                open:true,
                message:"This file size should be less than 8MB"
              },()=>{
                setTimeout(() => {
                  this.setState({
                    open:false,
                    isError:false,
                    message:""
                  })
                }, 3000);
              })
                return false
            }
            if(!acceptedFileTypesArray.includes(currentFileType)){
              this.setState({
                isError :true,
                open:true,
                message:'This file is not allowed.Only Images are allowed.'
              },()=>{
                setTimeout(() => {
                  this.setState({
                    open:false,
                    isError:false,
                    message:""
                  })
                }, 3000);
              })
                return false
            }
            return true
        }
    }

    getImage = (e) => {
        let file = e.target.files[0];
        const isVerified = this.verifyFile(file);
        if(isVerified){
            let reader = new FileReader();

            reader.onloadend = () => {
                this.setState({
                    cheque_image : reader.result
                })
            }
            if(file){
                reader.readAsDataURL(file)
                this.setState({
                    cheque_image : reader.result,
                    cheque_image_file : file
                })
            }
        }else{
            this.setState({
                isError :true,
                open:true,
                message:'This file is not allowed.Only Images are allowed.'
              },()=>{
                setTimeout(() => {
                  this.setState({
                    open:false,
                    isError:false,
                    message:""
                  })
                }, 3000);
              })
        }
    }

   
    AddAccountDetails = (e) => {
        e.preventDefault();
        this.setState({
            isLoading : true,
            submitted : true
        },()=>{
            if(this.state.submitted && this.state.name && this.state.account_no && this.state.account_no === this.state.confirmAccount && this.state.ifsc && this.state.bank_name && this.state.branch && this.state.bank_state && this.state.bank_city && this.state.cheque_image && this.state.bank_address){

            let seller = JSON.parse(localStorage.getItem('seller'));
            let seller_id = seller.user_id;
            let formdata = new FormData();

            formdata.append("image_type","cheque");
            formdata.append('user_id',seller_id);
            formdata.append("proof",this.state.cheque_image_file);

            const requestOptions = {
                method:"post",
                headers : authHeader(),
                data : formdata
            }
            return axios(apiUrl+'/upload_cheque',requestOptions)
            .then(data=>{
                const requestOptions = {
                    method:"POST",
                    headers : authHeader(),
                    data:{
                        account_holder_name : this.state.name,
                        account_number : this.state.account_no,
                        ifsc : this.state.ifsc,
                        bank_name : this.state.bank_name,
                        bank_address : this.state.bank_address,
                        branch : this.state.branch,
                        city : this.state.bank_city,
                        state : this.state.bank_state,
                        cancelled_cheque : data.data.data,
                        is_default : '0'
                    }
                }
                return axios(apiUrl+'/update-bank-details',requestOptions)
                .then(data=>{
                    this.setState({
                        openDialog:false,
                        snackOpen : true,
                        isError :false,
                        message : 'Account added successfully',
                        account_holder_name : '',
                        account_number : '',
                        ifsc : '',
                        bank_name : '',
                        bank_address : '',
                        branch : '',
                        city : '',
                        state : '',
                        cancelled_cheque : null,
                    },()=>{
                        this.getAccountDetails()
                        setTimeout(() => {
                            this.setState({
                                snackOpen : false,
                                isError : false,
                                message:''
                            })
                        }, 3000);
                    })
                })
                .catch(error=>{
                    this.setState({
                        snackOpen :true,
                        isError : true,
                        message : error.response ? error.response.data.message : 'network error'
                    },()=>{
                        setTimeout(() => {
                            this.setState({
                                snackOpen : false,
                                isError : false,
                                message :''
                            })
                        }, 3000);
                    })
                })
            })
            .catch(error=>{
                this.setState({
                    snackOpen :true,
                    isError : true,
                    message : error.response ? error.response.data.message : 'network error'
                },()=>{
                    setTimeout(() => {
                        this.setState({
                            snackOpen : false,
                            isError : false,
                            message :''
                        })
                    }, 3000);
                })
            })
            }else{
                this.setState({
                    isLoading : false
                },()=>{
                    if(this.state.submitted && !this.state.name){
                        this.nameRef.current.focus();
                    }else if(this.state.submitted && !this.state.account_no){
                        this.account_no.current.focus();
                    }else if(this.state.submitted && !this.state.confirmAccount){
                        this.confirmAccountRef.current.focus();
                    }else if(this.state.submitted && !this.state.bank_name){
                        this.bank_name.current.focus();
                    }else if(this.state.submitted && !this.state.bank_address){
                        this.bank_address.current.focus();
                    }else if(this.state.submitted && !this.state.ifsc){
                        this.ifsc.current.focus();
                    }else if(this.state.submitted && !this.state.branch){
                        this.branch.current.focus();
                    }else if(this.state.submitted && !this.state.bank_city){
                        this.bank_city.current.focus();
                    }else if(this.state.submitted && !this.state.bank_state){
                        this.bank_state.current.focus();
                    }
                })
            }
        })
    }
    markDefault = (e) => {
        let account_id = e.currentTarget.id;
        let requestOptions = {
            method: 'Post',
            headers : authHeader(),
            data : {
                account_id : account_id
            }
        }
        return axios(apiUrl+'/set_default',requestOptions)
        .then(data=>{
            this.getAccountDetails()
        })
        .catch(error=>{
            this.setState({
                snackOpen :true,
                isError : true,
                message :error.response ? error.response.data.message : 'network error'
            },()=>{
                setTimeout(() => {
                    this.setState({
                        snackOpen : false,
                        message : ''
                    })
                }, 2000);
            })
        })
    }
    render(){
        return bankDetails.call(this,this.nameRef,this.account_no,this.confirmAccountRef,this.ifsc,this.bank_name,this.branch,this.bank_city,this.bank_state,this.otpRef,this.bank_address);
    }
}

export default withStyles(styles)(BankDetails);
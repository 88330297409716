import React, { Component } from 'react';
import TimerIcon from '@material-ui/icons/Timer';
import { withStyles } from '@material-ui/core/styles';
import maindashboard from './mainDashboard.jsx';
import { authHeader } from '../../../../helper/auth-header.js';
import axios from 'axios';
import SiteConfig from '../../../../config';

const apiUrl = SiteConfig.apiUrl;

const style = (theme) => ({
  coming_soon:{
    fontSize:"20rem",
    color:"rgba(0,0,0,0.3)"
  },
  text_center:{
    textAlign:'center',
    marginTop:65,
    paddingBottom:40
  },
  c_text:{
    fontSize:"3rem",
    marginTop:0,
    fontWeight:500,
    color:"#999"
  },
  heading:{
    margin:0,
    fontSize:15,
    padding:10,
    fontWeight:500,
    color:'#333'
  },
  br_5:{
    borderRadius : 5
  },
  mt_20:{
    marginTop:20
  },
  orderCount:{
    borderRadius:5,
    height:80,
    padding:20,
    boxShadow:" rgba(0, 0, 0, 0.15) 0px 2px 6px",
    border : '1px solid #eaeaea',
    transition : 'box-shadow 0.2s ease 0s',
    '&:hover' : {
      boxShadow : 'rgba(0, 0, 0, 0.1) 0px 6px 12px'
    }
  },
  count:{
    fontSize:40,
    margin:0,
    fontWeight:400
  },
  count_text:{
    textAlign:'left',
    marginTop:10,
    width:'100%'
  },
  latest_order_div:{
    borderRadius:5,
    height:window.innerHeight - 350,
    padding:0,
    boxShadow:" rgba(0, 0, 0, 0.15) 0px 2px 6px",
    overflow:"hidden",
    border : '1px solid #eaeaea',
    transition : 'box-shadow 0.2s ease 0s',
    '&:hover' : {
      boxShadow : 'rgba(0, 0, 0, 0.1) 0px 6px 12px'
    }
  },
  notification_div:{
    borderRadius:5,
    height:window.innerHeight - 350,
    padding:0,
    boxShadow:" rgba(0, 0, 0, 0.15) 0px 2px 6px",
    border : '1px solid #eaeaea',
    overflow:"hidden",
    transition : 'box-shadow 0.2s ease 0s',
    '&:hover' : {
      boxShadow : 'rgba(0, 0, 0, 0.1) 0px 6px 12px'
    }
  },
})


 class MainDashboard extends Component {
   state = {
     isLoading:false,
     Countdata : [],
     orderList : [],
     lowStockProduct : []
   }
   componentDidMount(){
    this.setState({
      isLoading:true
    },()=>{
      this.getDashboard()
    })
   }
   getDashboard = () =>{
     const requestOptions = {
       method:'get',
       headers : authHeader()
     }
     return axios(apiUrl+'/dashboard',requestOptions)
     .then(data=>{
       this.setState({
         isLoading : false,
         Countdata : data.data.data.result,
         orderList : data.data.data.orderList,
         lowStockProduct : data.data.data.lowStockProduct
       })
     })
     .catch(error=>{
       this.setState({
         isLoading:false
       })
     })
   }
  render() {
    const {classes}=this.props;
    return maindashboard.call(this);
  }
}
export default withStyles(style)(MainDashboard);
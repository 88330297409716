import React, { Component } from 'react';
import editProduct from './EditProduct.jsx';
import { withStyles } from '@material-ui/core/styles';
import siteConfig from '../../../../config';
import {authHeader} from '../../../../helper/auth-header';
import axios from 'axios';
import {history} from '../../../../helper/history';

const apiUrl = siteConfig.apiUrl;

const styles = (theme) => ({
    heading : {
        fontSize : 22,
        marginTop : 20,
        fontWeight: '400',
        marginBottom:10
    },
    root: {
        flexGrow: 1,
        display: 'flex',
        height: 'auto',
      },
      tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
      cont:{
        width:'65%'
    },
    leftTab:{
        backgroundColor:"#FFF",
        width:300,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,.15)',
        borderRadius:4,
        padding:'10px 0',
        height:200,
        [theme.breakpoints.down('md')]:{
            width:'100%',
            marginBottom:20
        },
    },
    rightPanel:{
        backgroundColor:"#FFF",
        marginLeft:30,
        width:'100%',
        boxShadow:' 0 2px 4px 0 rgba(0,0,0,.15)',
        borderRadius:5,
        position:'relative',
        marginBottom:20,
        [theme.breakpoints.down('md')]:{
            marginLeft:0
        },
    },
    indicator:{
        left:0
    },
    scrollButtons:{
        textTransform :'lowercase'
    },
    wrap:{
        alignItems: 'baseline',
    },
    rootButton:{
        paddingLeft: 25,
        textTransform:'capitalize',
        fontSize:16,
        fontWeight:400
    },
    selectedTab:{
        color:'#3069f0',
        backgroundColor:"#eaeaea"
    },
    mb_20:{
        marginBottom:20
    },
    fullWidth:{
        width:'100%'
    },
    m_name:{
        paddingBottom:10,
    },
    m_b_t_20:{
        margin:'20px 0'
    },
    desc:{
        display:'inline-block',
        marginBottom:20
    },
    desc_div:{
        '& p':{
            width:'100%',
            textAlign:'left',
            fontSize:16
        },
        '& iframe':{
            width:'100%'
        }
    }
})

class EditProduct extends Component{
    constructor(props){
        super(props);
        this.state ={
            product_id : 0,
            value : 0,
            product_name :'',
            product_description : '',
            product_model :'',
            product_sku : '',
            product_price : '',
            selectedTax : '',
            product_length : '',
            product_width:'',
            product_height:'',
            product_length_class : '',
            product_weight : '',
            product_weight_class : '',
            product_status:'',
            status : '',
            pack_quantity:'',
            stock_status_id : '',
            transfer_price : '',
            quantity :'',
            maximum_quantity:'',
            minimum_quantity:'',
            stock_list : [],
            isSaving : false,
            submitted : false,
            price:0,
            open: false,
            error:false,
            message : '',
            manufacturer_name : '',
            first_tp:false,
            previousStatus : 0
        }
        this.inputLabel = React.createRef('inputLabel');
        this.transfer_price = React.createRef('transfer_price');
        this.max_quantity = React.createRef('max_quantity');
        this.min_quantity = React.createRef('min_quantity');
        this.quantity = React.createRef('quantity');
        this.StockLabel = React.createRef('stock_label')
    }
    componentDidMount(){
        // console.log(this.props)
        let p_id = this.props.locationState.product_id;
        this.setState({
            product_id : p_id
        },()=>this.getProductDescription(),this.getStockList())
    }

    getStockList = async () =>{
        const requestOptions = {
            headers : authHeader(),
            method : 'get'
        }
        return axios(apiUrl+'/product_stock',requestOptions)
        .then(data=>{
            this.setState({
                stock_list : data.data.data
            })
        })
    }

    getProductDescription =()=>{
        let product_id = this.state.product_id;
        const requestOptions = {
            method : 'post',
            headers : authHeader(),
            data : {
                product_id : product_id
            }
        }
        return axios(apiUrl + "/product_description",requestOptions)
        .then(data=>{
            // console.log(data)
            this.setState({
                product_name : data.data.data[0].product_name,
                product_description : data.data.data[0].product_description,
                product_model : data.data.data[0].model,
                product_sku : data.data.data[0].product_sku,
                selectedTax : data.data.data[0].tax_class_id,
                product_length : data.data.data[0].length,
                product_width:data.data.data[0].width,
                product_height:data.data.data[0].height,
                product_length_class : data.data.data[0].length_class_id,
                product_weight : data.data.data[0].weight,
                product_weight_class : data.data.data[0].weight_class_id,
                status : data.data.data[0].product_status,
                previousStatus : data.data.data[0].product_status,
                product_status : data.data.data[0].status,
                pack_quantity : data.data.data[0].pack_quantity,
                stock_status_id : data.data.data[0].product_stock_status,
                transfer_price : data.data.data[0].transfer_price,
                quantity : data.data.data[0].qty,
                maximum_quantity: data.data.data[0].maximum_qty,
                minimum_quantity: data.data.data[0].minimum_qty,
                product_price : data.data.data[0].price,
                manufacturer_name:data.data.data[0].manufacturer_name
            },()=>{
                // console.log(this.state.product_name);
                if(this.state.transfer_price > 0){
                    this.setState({
                        first_tp : true
                    })
                }
            })
        })
        .catch(error=>console.log(error))
    }

    handleChange=(e,value)=>{
        this.setState({
            value
        })
    }
    handleValueChange=(e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    setLabelWidth =()=>{
        this.setState({
            labelWidth : this.inputLabel.current.offsetWidth
        })
    }
    setStatusLabelWidth =()=>{
        this.setState({
            labelstatusWidth : this.StockLabel.current.offsetWidth
        })
    }
    submitHandler=(e)=>{
        e.preventDefault();
        this.setState({
            isSaving : true,
            submitted : true
        },()=>{
            if(this.state.submitted && this.state.quantity && this.state.minimum_quantity){
                const requestOptions={
                    headers : authHeader(),
                    method : "post",
                    data : {
                        transfer_price : this.state.transfer_price,
                        quantity : this.state.quantity,
                        maximum_quantity : this.state.maximum_quantity,
                        minimum_quantity : this.state.minimum_quantity,
                        stock_status_id : this.state.stock_status_id,
                        status : this.state.status,
                        product_id : this.state.product_id,
                    }
                }
                return axios(apiUrl+'/update_product',requestOptions)
                .then(data=>{
                    this.setState({
                        isSaving : false
                    },()=>history.push('/manage-products'),this.sendNotification(this.state.product_id+"-"+this.state.product_name + " ,quantity updated : "+this.state.quantity))
                })
                .catch(error=>{
                    this.setState({
                        isSaving : false,
                        
                    })
                })
            }else{
                this.setState({
                    isSaving : false
                },()=>{
                    if(!this.state.quantity){
                        this.quantity.current.focus();
                        // this.setState({

                        // })
                    }else if(isNaN(this.state.quantity)){
                        this.quantity.current.focus();
                    }else if(!this.state.minimum_quantity){
                        this.min_quantity.current.focus();
                    } 
                })
            }
        })
    }

    sendNotification = (product_id) => {
        const requestOptions={
          method : 'get',
          headers : authHeader()
        }
        return axios(apiUrl+'/get_vendor_name',requestOptions).then(data=>{
         const requestOptions = {
           method : 'POST',
           headers : authHeader(),
           data : {
               template : "PRODUCTSUPDATED",
               name : data.data.data[0].vendor_name,
               product_id : product_id
           }
       }
       return axios(apiUrl+'/send-notification',requestOptions)
       .then(data=>data)
       .catch(error=>error)
          }).catch(error=>{
            console.log(error)
          })
   }
    render(){
        // console.log(this.state.quantity);
        return editProduct.call(this,this.inputLabel,this.transfer_price,this.max_quantity,this.min_quantity,this.quantity,this.StockLabel);
    }
}

export default withStyles(styles)(EditProduct);
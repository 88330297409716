import React from 'react';
import {Grid,Divider,Button,TextField,Select,MenuItem,InputLabel,FormControl,FormLabel,CircularProgress,Snackbar,IconButton,Tooltip} from '@material-ui/core';
import { Alert} from '@material-ui/lab';
import {Add,Edit} from '@material-ui/icons/';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import pdf from '../../../../assets/images/pdf.png';
// import {Autocomplete} from '@material-ui/lab';

function step1(){
    const { classes } = this.props;
    return (
            <form onSubmit={this.submitBusinessData}>
            <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
            <FormControl variant="outlined" fullWidth style={{marginBottom:20}}>
                <InputLabel id="demo-simple-select-outlined-label">Business Type</InputLabel>
                    <Select 
                        value={this.state.selectedBusiness} 
                        name="selectedBusiness" 
                        onChange={this.handleChange} 
                        inputRef={this.business_select}
                        error={this.state.Submitted && !this.state.selectedBusiness}
                        labelWidth={110}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                    >
                        {
                            this.state.business_types.map(element=>{
                                return <MenuItem 
                                            value={element.id} 
                                            key={element.id}
                                        >
                                            {element.business_type}
                                        </MenuItem>
                            })
                        }
                    </Select>
                    {
                        this.state.Submitted && !this.state.selectedBusiness
                            &&
                            <p className="select-helper">
                            Select valid business type
                        </p>
                    }
                    
                </FormControl>
                
                <TextField 
                    variant="outlined"
                    label="Brand Name / Display Name"
                    name="brand_name"
                    value={this.state.brand_name}
                    onChange={this.handleChange}
                    fullWidth
                    style={{marginBottom:20}}
                    inputRef={this.brandName}
                    error={this.state.Submitted && !this.state.brand_name}
                    helperText={this.state.Submitted && !this.state.brand_name ? "Enter valid Brand Name":""}
                >
                </TextField>
                <TextField 
                    variant="outlined"
                    label="Registered Business Address"
                    fullWidth
                    name="business_address"
                    value={this.state.business_address}
                    onChange={this.handleChange}
                    style={{marginBottom:20}}
                    inputRef={this.Address}
                    error={this.state.Submitted && !this.state.business_address}
                    helperText={this.state.Submitted && !this.state.business_address ? "Enter Valid Address": ""}
                >
                </TextField>
                <TextField 
                    variant="outlined"
                    label="Pincode"
                    fullWidth
                    name="pincode"
                    value={this.state.pincode}
                    onChange={this.getCityByPincode}
                    style={{marginBottom:20}}
                    inputRef={this.pinCode}
                    error={(this.state.Submitted && this.state.pincode.length != 6) || this.state.pincodeError}
                    helperText={this.state.Submitted && this.state.pincode.length != 6 ? "Enter valid pincode":""}
                    >
                    </TextField>
                <TextField 
                    variant="outlined"
                    label="City"
                    fullWidth
                    name="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                    style={{marginBottom:20}}
                    inputRef={this.City}
                    // disabled={true}
                    >
                    </TextField>
                    <TextField 
                    variant="outlined"
                    label="State"
                    fullWidth
                    name="state"
                    value={this.state.state}
                    onChange={this.handleChange}
                    style={{marginBottom:20}}
                    inputRef={this.State}
                    // disabled={true}
                    >
                    </TextField>
                    
            </Grid>
            <Grid item sm={12} md={6}>
                <TextField 
                        variant="outlined"
                        label="Registered Business Name"
                        fullWidth
                        name="business_name"
                        value={this.state.business_name}
                        onChange={this.handleChange}
                        style={{marginBottom:20}}
                        inputRef={this.businessName}
                        error={this.state.Submitted && !this.state.business_name}
                        helperText={this.state.Submitted && !this.state.business_name ? "Enter valid Business Name":""}
                    >
                    </TextField>
                    <TextField 
                    variant="outlined"
                    label="Business Description"
                    fullWidth
                    name="business_description"
                    value={this.state.business_description}
                    onChange={this.handleChange}
                    style={{marginBottom:20}}
                    inputRef={this.Description}
                    rows="5"
                    rowsMax="6"
                    multiline
                    >
                    </TextField>
                    <TextField 
                    variant="outlined"
                    label="PAN No."
                    fullWidth
                    name="pan_no"
                    value={this.state.pan_no}
                    onChange={this.handleChange}
                    style={{marginBottom:20}}
                    inputRef={this.PanNo}
                    error={this.state.Submitted && this.state.pan_no.length != 10}
                    helperText={this.state.Submitted && this.state.pan_no.length != 10 ? "Enter valid PAN no":""}
                    >
                    </TextField>
                    <TextField 
                    variant="outlined"
                    label="GST No."
                    fullWidth
                    name="gstin_no"
                    value={this.state.gstin_no}
                    onChange={this.handleChange}
                    style={{marginBottom:20}}
                    inputRef={this.GSTNo}
                    error={this.state.Submitted && this.state.gstin_no.length != 15}
                    helperText={this.state.Submitted && this.state.gstin_no.length != 15 ? "Enter valid GSTIN no":""}
                    >
                    </TextField>
            </Grid>
            </Grid>
           
            <Divider />
            <FormLabel style={{display:'block',margin:'20px 0',fontWeight:'bold',color:"#000"}}>Upload Documents <span className={classes.image_message}>(only .jpg, .png, .jpeg, .pdf files are allowed)</span></FormLabel>
            <Grid container spacing={3}>
            <Grid item xs>
            <FormLabel>Address Proof</FormLabel>
                    <div className={this.state.Submitted && !this.state.addressProof ? "image_upload_true":"image_upload_false"}>
                    <div 
                        className={
                            this.state.addressProof == null || this.state.addressProof == ""
                                ?
                                null
                                :
                            this.state.addressProof.includes('.pdf')
                                            ?
                                        classes.pdfdocument
                                        :
                                        classes.imagedocument
                                        
                        }>
                        {
                            this.state.isLoadingImage && this.state.image_type == 'address'
                                        ?
                            <CircularProgress />
                                        :
                            this.state.addressProof == null || this.state.addressProof == ""
                                        ?
                                        
                                null
                                :
                            this.state.addressProof.includes('.pdf')
                                        ?
                                <>
                                <a href={this.state.addressProof} target="blank">
                                <img src={pdf} alt="pdf" style={{height:80,width:80}} />
                                </a>
                                <p className={classes.text}>Click the icon to preview the document</p>
                                </>

                                        :
                            <img 
                                src={this.state.addressProof} 
                                className={classes.image}
                                onClick={()=>window.open(this.state.addressProof)} 
                                alt="address_proof"
                            />
                                
                        }
                    </div>
                    
                   
                            <React.Fragment>
                        <input accept="image/*" id="icon-button-file" type="file" className="image_i" onChange={this.uploadProofHandler} image_type="address" style={{position:"absolute",right:120,bottom:80}}/>
                        <label htmlFor="icon-button-file">
                        <Tooltip title={this.state.addressProof !== null ? "Edit Address Proof":"Upload Address Proof"}>
                          <IconButton aria-label="upload picture" component="span" size="medium" style={{backgroundColor:"#3f51b5",color:"#fff",position:"absolute",left:this.state.addressProof !== null ?"44%":120,bottom:this.state.addressProof !== null ? 10: 80}}>
                          {
                              this.state.addressProof != null
                                        ?
                                  <Edit/>  
                                  :
                                  <Add />
                          }
                          </IconButton>
                          </Tooltip>
                        </label>
                        </React.Fragment>
                    
                    </div>
                    {
                        this.state.Submitted && !this.state.addressProof
                                    ?
                        <p style={{fontSize:13,color:'red'}}>Address proof is required</p>
                        :
                        null
                    }
                </Grid>
                <Grid item xs>
                <FormLabel>PAN Card</FormLabel>
                    <div className={this.state.Submitted && !this.state.panProof ? "image_upload_true":"image_upload_false"}>
                    <div 
                        className={
                            this.state.panProof == null || this.state.panProof == ""
                                ?
                                null
                                :
                            this.state.panProof.includes('.pdf') 
                                            ?
                                        classes.pdfdocument
                                        :
                                        classes.imagedocument
                        }>
                        {
                            this.state.isLoadingImage && this.state.image_type == 'pan'
                            ?
                <CircularProgress />

                            :
                            this.state.panProof == null || this.state.panProof == ""
                                        ?
                                        null
                                        :
                            this.state.panProof.includes('.pdf') 
                                        ?
                                <>
                                <a href={this.state.panProof} target="blank">
                                <img src={pdf} alt="pdf" style={{height:80,width:80}} />
                                </a>
                                <p className={classes.text}>Click the icon to preview the document</p>
                                </>

                                        :
                            <img 
                                src={this.state.panProof} 
                                className={classes.image}
                                onClick={()=>window.open(this.state.panProof)} 
                                alt="pan_proof"
                            />
                        }
                    </div>
                    <React.Fragment>
                        <input accept="image/*" id="icon-button-file-pan" type="file" className="image_i" onChange={this.uploadProofHandler} image_type="pan" name="proof" style={{position:"absolute",right:120,bottom:80}}/>
                            <label htmlFor="icon-button-file-pan">
                            <Tooltip title={this.state.panProof != null ? "Edit PAN Proof":"Upload PAN Proof"}>
                                <IconButton aria-label="upload picture" component="span" size="medium" style={{backgroundColor:"#3f51b5",color:"#fff",position:"absolute",right:this.state.panProof !== null ?"44%":120,bottom:this.state.panProof !== null ? 10: 80}}>
                                {
                                    this.state.panProof != null
                                                ?
                                        <Edit />  
                                        :
                                        <Add />
                                }
                                </IconButton>
                                </Tooltip>
                            </label>
                        </React.Fragment> 
                    </div>
                    {
                        this.state.Submitted && !this.state.panProof
                                    ?
                        <p style={{fontSize:13,color:'red'}}>pan proof is required</p>
                        :
                        null
                    }
                </Grid>
                <Grid item xs>
                    <FormLabel>GSTIN</FormLabel>
                    <div 
                        className={
                                this.state.Submitted && !this.state.gstinProof 
                                    ? 
                                "image_upload_true"
                                    :
                                "image_upload_false"
                                }
                        >
                    <div 
                        className={
                            this.state.gstinProof == null || this.state.gstinProof == ""
                                ?
                                null
                                :
                            this.state.gstinProof.includes('.pdf')
                                            ?
                                        classes.pdfdocument
                                        :
                                        classes.imagedocument
                                        
                                }
                        >
                        {
                            this.state.isLoadingImage && this.state.image_type == 'gstin'
                                            ?
                                <CircularProgress />

                            :
                            this.state.gstinProof == null || this.state.gstinProof == ""
                                        ?
                                        null
                                        :
                            this.state.gstinProof.includes('.pdf') 
                                        ?
                                <>
                                <a href={this.state.gstinProof} target="blank">
                                <img src={pdf} alt="pdf" style={{height:80,width:80}} />
                                </a>
                                <p className={classes.text}>Click the icon to preview the document</p>
                                </>

                                        :
                            <img 
                                src={this.state.gstinProof} 
                                className={classes.image}
                                onClick={()=>window.open(this.state.gstinProof)} 
                                alt="gst_proof"
                            />
                        }
                        </div>
                        <React.Fragment>
                            <input 
                                accept="image/*" 
                                id="icon-button-file-gstin" 
                                type="file" 
                                className="image_i" 
                                onChange={this.uploadProofHandler} 
                                image_type="gstin" 
                                style={{position:"absolute",right:120,bottom:80}}
                                />
                            <label 
                                htmlFor="icon-button-file-gstin"
                            >
                            <Tooltip 
                                title={this.state.gstinProof != null ? "Edit GST Proof":"Upload GST Proof"}
                                >
                                <IconButton 
                                    aria-label="upload picture" 
                                    component="span" 
                                    size="medium" 
                                    style={{
                                        backgroundColor:"#3f51b5",
                                        color:"#fff",
                                        position:"absolute",
                                        left:this.state.gstinProof != null ?"44%":120,
                                        bottom:this.state.gstinProof != null ? 10: 80
                                    }} 
                                    image_type="gstin"
                                >
                                {
                                    this.state.gstinProof !== null
                                                ?
                                        <Edit />  
                                        :
                                        <Add />
                                } 
                                </IconButton>
                            </Tooltip>
                            </label>
                        </React.Fragment> 
                    </div>
                    {
                        this.state.Submitted && !this.state.gstinProof
                                    ?
                        <p style={{fontSize:13,color:'red'}}>gstin proof is required</p>
                        :
                        null
                    }
                </Grid>
            </Grid>
            <div style={{padding:'40px 0',textAlign:'center'}}>
                <Button type="submit" variant="contained" color="primary" style={{padding:"8px 16px"}} disabled={this.state.isLoading}>
                    {
                        this.state.isLoading
                            ?
                        <CircularProgress color="inherit" size={24} />
                            :
                            "Next"
                    }
                </Button>
            </div>
            <Snackbar open={this.state.open}  TransitionComponent={this.state.Slide}>
                    <Alert severity={this.state.hasError?"error":"success"} >
                        {
                            this.state.message
                        }
                    </Alert>
            </Snackbar>
        </form>
    )
}
export default step1;
import React from "react";
import {
  Grid,
  Container,
  Paper,
  Button,
  Typography,
  CircularProgress,
  TableContainer,
  TableHead,
  TableCell,
  Tooltip,
  TableRow,
  TablePagination,
  TableBody,
  Checkbox,
  Table,
  Toolbar,
  Dialog,
  Snackbar,
  Fab,
  Divider,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
  Backdrop,
  TableSortLabel,
  OutlinedInput,
  FormControlLabel,
  InputAdornment,
  FormGroup,
  ListItem,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";
import {default as MuiSnackbar} from '@mui/material/Snackbar';
import {default as MuiAlert} from '@mui/material/Alert';
//import{ Alert as alert }from '@mui/material/Alert';
// import {Alert} as 'alert' from '@mui/material/Alert';
import { Link } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import InfoIcon from "@material-ui/icons/Info";
import "./ManageOrder.css";
import Draggable from "react-draggable";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ErrorIcon from "@material-ui/icons/Error";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DimensionDialog from "../../Dialog/DimensionDialog/DimensionDialog";
// import {Alert} from '@mui/material';

//
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const headCells = [
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order Id",
    active: true,
  },
  {
    id: "product_info",
    numeric: false,
    disablePadding: false,
    label: "Product Information",
    active: false,
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    active: true,
  },
  {
    id: "date_added",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
    active: true,
  },
];
const pendingAcceptance = [
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order Id",
    active: true,
  },
  {
    id: "product_info",
    numeric: false,
    disablePadding: false,
    label: "Product Information",
    active: false,
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    active: true,
  },
  {
    id: "date_added",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
    active: true,
  },
  {
    id: "dimension",
    numeric: true,
    disablePadding: false,
    label: "Dimensions(L*B*H*W)",
    active: false,
  },
  {
    id: "order_age",
    numeric: true,
    disablePadding: false,
    label: "Order Age",
    active: false,
  },
];
const OrderIdBook = [
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order Id",
    active: true,
  },
  {
    id: "product_info",
    numeric: false,
    disablePadding: false,
    label: "Product Information",
    active: false,
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    active: true,
  },
  {
    id: "date_added",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
    active: true,
  },
  {
    id: "dimension",
    numeric: true,
    disablePadding: false,
    label: "Dimensions(L*B*H*W)",
    active: false,
  },
  {
    id: "Purchase Invoice",
    numeric: true,
    disablePadding: false,
    label: "Purchase Invoice",
    active: false,
  },
  {
    id: "order_age",
    numeric: true,
    disablePadding: false,
    label: "Order Age",
    active: false,
  },
];
// const OrderWithoutAction = [
//     { id: 'order_id', numeric: false, disablePadding: false, label: 'Order Id',active : true },
//     { id: 'product_info', numeric: false, disablePadding: false, label: 'Product Information',active:false },
//     { id: 'total', numeric: true, disablePadding: false, label: 'Amount',active:true },
//     { id: 'date_added', numeric: true, disablePadding: false, label: 'Order Date',active:true }
// ]
const pending_print_pickup = [
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order Id",
    active: true,
  },
  {
    id: "product_info",
    numeric: false,
    disablePadding: false,
    label: "Product Information",
    active: false,
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    active: true,
  },
  {
    id: "date_added",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
    active: true,
  },
  {
    id: "shipment_date",
    numeric: false,
    disablePadding: false,
    label: "Shipment Book Date",
    active: false,
  },
];
const inTransit = [
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order Id",
    active: true,
  },
  {
    id: "product_info",
    numeric: false,
    disablePadding: false,
    label: "Product Information",
    active: false,
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    active: true,
  },
  {
    id: "date_added",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
    active: true,
  },
  {
    id: "tracking_id",
    numeric: false,
    disablePadding: false,
    label: "Tracking ID",
    active: false,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
    active: false,
  },
];
const delivered = [
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order Id",
    active: true,
  },
  {
    id: "product_info",
    numeric: false,
    disablePadding: false,
    label: "Product Information",
    active: false,
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    active: true,
  },
  {
    id: "date_added",
    numeric: true,
    disablePadding: false,
    label: "Order Date",
    active: true,
  },
  {
    id: "tracking_id",
    numeric: false,
    disablePadding: false,
    label: "Tracking ID",
    active: false,
  },
  {
    id: "delivery_time",
    numeric: true,
    disablePadding: false,
    label: "Delivery Time",
    active: false,
  },
];

const cancelReasonArray = ["Out of Stock", "Transfer Price Issue", "Others"];

const ReturnDelivered = [
  {
    id: "date_added_delivered_req_id",
    numeric: true,
    disablePadding: false,
    label: "Return Request ID & Created On, Deliverd On",
    active: true,
  },

  {
    id: "vendor_order_id",
    numeric: true,
    disablePadding: false,
    label: "against Vendor Order Id",
    active: true,
  },
  {
    id: "Product Information",
    numeric: false,
    disablePadding: false,
    label: "Product Name, SKU, Model",
    active: true,
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Amount",
    active: true,
  },

  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Return Type",
    active: true,
  },
  {
    id: "reason",
    numeric: true,
    disablePadding: false,
    label: "Return Reason",
    active: true,
  },
  {
    id: "Tracking",
    numeric: true,
    disablePadding: false,
    label: "Return Status & Tracking ID",
    active: true,
  },
];

const EnhancedToolbar = (props) => {
  console.log(props)
  const {
    getRelatedOrders,
    order_status_id,
    classes,
    selected,
    bookbulkorders,
    selectedAddress,
    handleAddressChange,
    addressList,
    changeOrderStatus,
    BulkShippingLabel,
    buttonTitle,
    generateBulkManifest,
    isLoadingBulkManifest,
    BulkInvoice,
    isGeneratingInvoice,
    BulkShippingLabelAndInvoice,
    readyToShip,
    handleCancelOrders,
    dimensionsCheck
  } = props;
  
  return (
    <Toolbar style={{ height: 80, boxShadow: "2px 0px 5px #ccc" }}>
      {selected.length > 0 ? (
        <div style={{ display: "inline-block", width: "50%" }}>
          {selected.length + " "} Selected
        </div>
      ) : (
        <div style={{ display: "inline-block", width: "50%" }}>
          <FormatListBulletedIcon />
          <span style={{ verticalAlign: "super" }}>Order List</span>
        </div>
      )}
      <div
        style={{ width: "50%", display: "inline-block" }}
        className="selectListWarehouse"
      >
        <div style={{ float: "right" }}>
          {(() => {
            switch (order_status_id) {
              case "15":
                return (
                  <>
                    {/* <FormControl variant="outlined" className={classes.address}>
                      <InputLabel id="warehouse-simple-select-outlined-label">
                        Select Warehouse
                      </InputLabel>
                      <Select
                        labelId="warehouse-simple-select-outlined-label"
                        id="warehouse-simple-select-outlined"
                        value={selectedAddress}
                        onChange={handleAddressChange}
                        label="Select Warehouse"
                        name="selectedAddress"
                        fullWidth
                        disabled={!selected.length > 0}
                        margin="dense"
                        // disabled={this.state.AddressList.length<2}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {addressList.map((address) => (
                          <MenuItem
                            key={address.address_id}
                            value={address.address_id}
                          >
                            {address.warehouse_address +
                              "," +
                              address.waddress_city +
                              "," +
                              address.waddress_state +
                              "," +
                              address.waddress_pincode}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={bookbulkorders}
                      disabled={!selected.length > 1}
                      style={{ marginLeft: 10 }}
                      title="bulk"
                    >
                      <DoneIcon className={classes.mr_5} />
                      Ready to Ship
                    </Button>
                  </>
                );
              case "17":
                return (
                  <>
                    {buttonTitle == "pending_manifest" ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={BulkShippingLabelAndInvoice}
                        style={{textTransform: "capitalize"}}
                        disabled={!selected.length > 0}
                      >
                        Generate Shipping Label and Invoice
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={BulkShippingLabel}
                          disabled={isGeneratingInvoice}
                          style={{
                            textTransform: "capitalize",
                            marginRight: 5,
                          }}
                          disabled={!selected.length > 0}
                        >
                          Reprint Shipping Label
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={BulkInvoice}
                          disabled={isGeneratingInvoice}
                          style={{
                            textTransform: "capitalize",
                            marginRight: 5,
                          }}
                          disabled={!selected.length > 0}
                        >
                          Reprint Invoice
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={generateBulkManifest}
                          disabled={isLoadingBulkManifest}
                          style={{
                            textTransform: "capitalize",
                            marginRight: 5,
                          }}
                          disabled={!selected.length > 0}
                        >
                          Generate Manifest
                        </Button>
                      </>
                    )}
                  </>
                );
              case "1":
                return (
                  <>
                   <FormControl variant="outlined" className={classes.address}>
                      <InputLabel id="warehouse-simple-select-outlined-label">
                        Select Warehouse 
                      </InputLabel>
                      <Select
                        labelId="warehouse-simple-select-outlined-label"
                        id="warehouse-simple-select-outlined"
                        value={selectedAddress}
                        onChange={handleAddressChange}
                        label="Select Warehouse"
                        name="selectedAddress"
                        fullWidth
                        disabled={!selected.length > 0}
                        margin="dense"
                        // disabled={this.state.AddressList.length<2}
                      >
                        <MenuItem value="">Select</MenuItem>
                        {addressList.map((address) => (
                          <MenuItem
                            key={address.address_id}
                            value={address.address_id}
                          >
                            {address.warehouse_address +
                              "," +
                              address.waddress_city +
                              "," +
                              address.waddress_state +
                              "," +
                              address.waddress_pincode}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br/>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.accept}
                      id="15"
                      status="confirm"
                      onClick={changeOrderStatus}
                      // disabled={!selected.length > 0}
                      disabled={!selected.length > 0 || !readyToShip} // || selected.length>1

                    >
                      <DoneIcon className={classes.mr_5} />
                      {selected.length < 2 ? "Confirm Order" : "Confirm Orders"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.reject}
                      id="8"
                      status="denied"
                      onClick={handleCancelOrders}
                      disabled={!selected.length > 0}
                    >
                      <CloseIcon className={classes.mr_5} />
                      {selected.length < 2 ? "Cancel Order" : "Cancel Orders"}
                    </Button>
                  </>
                );
              default:
                return null;
            }
          })()}
        </div>
      </div>
    </Toolbar>
  );
};
function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    order_status_id,
    selected,
    onSelectAllClick,
    setOrder,
    orderData,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* {console.log(order_status_id, "ordeRStatusID")} */}
        {order_status_id == 3 ||
        order_status_id == 18 ||
        order_status_id == 8 ||
        order_status_id==5||
        order_status_id == 7 ||
        order_status_id == 9 ? null : (
          <TableCell className={classes.tablecell}>
            <Checkbox
              indeterminate={numSelected > 0}
              checked={numSelected > 0}
              onChange={onSelectAllClick}
              disabled={!orderData.length > 0}
            />
          </TableCell>
        )}
        {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell> */}
        {order_status_id == 1
          ? pendingAcceptance.map((headCell) => (
              <TableCell
                key={headCell.id}
                //   align={headCell.numeric ? 'right' : 'left'}
                align="center"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.active ? "" : classes.checkStep}
                style={{ border: "1px solid #ccc" }}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={
                    orderData.length > 0
                      ? headCell.active
                        ? createSortHandler(headCell.id)
                        : null
                      : null
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : order_status_id == 15
          ? OrderIdBook.map((headCell) => (
              <TableCell
                key={headCell.id}
                //   align={headCell.numeric ? 'right' : 'left'}
                align="center"
                style={{ border: "1px solid #ccc" }}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.active ? "" : classes.checkStep}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={
                    orderData.length > 0
                      ? headCell.active
                        ? createSortHandler(headCell.id)
                        : null
                      : null
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : order_status_id == 17
          ? pending_print_pickup.map((headCell) => (
              <TableCell
                key={headCell.id}
                //   align={headCell.numeric ? 'right' : 'left'}
                align="center"
                style={{ border: "1px solid #ccc" }}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.active ? "" : classes.checkStep}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={
                    orderData.length > 0
                      ? headCell.active
                        ? createSortHandler(headCell.id)
                        : null
                      : null
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : order_status_id == 3
          ? inTransit.map((headCell) => (
              <TableCell
                key={headCell.id}
                //   align={headCell.numeric ? 'right' : 'left'}
                align="center"
                style={{ border: "1px solid #ccc" }}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.active ? "" : classes.checkStep}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={
                    orderData.length > 0
                      ? headCell.active
                        ? createSortHandler(headCell.id)
                        : null
                      : null
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : order_status_id == 18
          ? delivered.map((headCell) => (
              <TableCell
                key={headCell.id}
                style={{ border: "1px solid #ccc" }}
                //   align={headCell.numeric ? 'right' : 'left'}
                align="center"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.active ? "" : classes.checkStep}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={
                    orderData.length > 0
                      ? headCell.active
                        ? createSortHandler(headCell.id)
                        : null
                      : null
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : order_status_id == 7 || order_status_id == 8 || order_status_id == 9
          ? headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                //   align={headCell.numeric ? 'right' : 'left'}
                align="center"
                padding={headCell.disablePadding ? "none" : "default"}
                style={{ border: "1px solid #ccc" }}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.active ? "" : classes.checkStep}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={
                    orderData.length > 0
                      ? headCell.active
                        ? createSortHandler(headCell.id)
                        : null
                      : null
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : order_status_id == 5
          ? ReturnDelivered.map((headCell) => (
              <TableCell
                key={headCell.id}
                style={{ border: "1px solid #ccc" }}
                //   align={headCell.numeric ? 'right' : 'left'}
                align="center"
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}
                className={headCell.active ? "" : classes.checkStep}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={
                    orderData.length > 0
                      ? headCell.active
                        ? createSortHandler(headCell.id)
                        : null
                      : null
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          : null}
      </TableRow>
    </TableHead>
  );
}

function manageOrders() {
  const { classes } = this.props;
  return this.state.blankResponse ? (
    <div style={{ textAlign: "center", paddingTop: 100 }}>
      <ErrorIcon style={{ fontSize: 150, color: "#999" }} />
      <h1 style={{ color: "#666" }}>Oops !</h1>
      <h2 style={{ color: "#666" }}>Something Went wrong</h2>
      <Button variant="contained" color="primary" onClick={this.refreshPage}>
        Refresh
      </Button>
    </div>
  ) : (
    <React.Fragment>
      <div style={{ margin: "0px 20px" }} onClick={this.handledclick}>
        <h2 style={{ fontWeight: 400, fontSize: 20, display: "inline-block" }}>
          Manage Orders..
        </h2>
        <div
          style={{
            float: "right",
            background: "#fff",
            margin: "10px 0px",
            position: "relative",
          }}
          name="Y32d3"
        >
          {/* <p style={{margin:0,fontSize:12,textAlign:'left'}}>Search Order</p> */}
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">
              Search Order
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={this.state.search}
              name="Y32d3"
              ref={this.searchOrder}
              onChange={this.handleSearchOrder}
              startAdornment={
                <InputAdornment position="start">
                  <FormControl className={classes.formControl}>
                    <Select
                      value={this.state.search_by}
                      onChange={this.handleSearchChange}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {/* <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem> */}
                      <MenuItem value="order_id">Order Id</MenuItem>
                      <MenuItem value="name">Name</MenuItem>
                      <MenuItem value="mobile">Mobile</MenuItem>
                    </Select>
                  </FormControl>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.searchOrderBy}
                  >
                    Search
                  </Button>
                </InputAdornment>
              }
              labelWidth={95}
            />
          </FormControl>
          {this.state.searchOrderVisible ? (
            <Paper
              style={{
                position: "absolute",
                background: "#fff",
                width: "90%",
                padding: 15,
                zIndex: "99",
                top: 60,
                borderRadius: 0,
                maxHeight: 300,
                overflowY: "scroll",
              }}
              onBlur={this.setOrderVisibility}
              name="Y32d3"
            >
              {this.state.searchData.length > 0 ? (
                this.state.searchData.map((item) => {
                  if (this.state.search_by === "order_id") {
                    return (
                      <Link
                        to={{
                          pathname: "/order-detail/" + item.vendor_order_id,
                        }}
                        key={item.vendor_order_id}
                        target="_blank"
                        name="Y32d3"
                        style={{ textDecoration: "none", color: "#333" }}
                      >
                        <div>
                          <div
                            style={{
                              width: "48%",
                              display: "inline-block",
                              verticalAlign: "top",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: 15,
                                fontWeight: "bold",
                                margin: 0,
                              }}
                            >
                              Order detail
                            </h4>
                            <p
                              style={{
                                width: "100%",
                                fontSize: 13,
                                textAlign: "left",
                                margin: 0,
                                marginTop: 10,
                              }}
                            >
                              Order Id :{" "}
                            </p>
                            <p
                              style={{
                                width: "100%",
                                fontSize: 13,
                                textAlign: "left",
                                marginBottom: 5,
                                fontWeight: "bold",
                              }}
                            >
                              {item.vendor_order_id}
                            </p>
                            <div>
                              <p
                                style={{
                                  width: "100%",
                                  fontSize: 13,
                                  textAlign: "left",
                                  margin: "5px 0px",
                                }}
                              >
                                Product Details :{" "}
                              </p>
                              <div>
                                {item.productData.map((p_data, index) => {
                                  return (
                                    <div key={p_data.image + index}>
                                      <img
                                        src={p_data.image}
                                        style={{
                                          height: 40,
                                          width: 40,
                                          border: "1px solid #ccc",
                                        }}
                                      />
                                      <div
                                        style={{
                                          fontSize: 10,
                                          display: "inline-block",
                                          width: "65%",
                                          marginLeft: 10,
                                          verticalAlign: "top",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {p_data.name}
                                        <p
                                          style={{
                                            fontSize: 12,
                                            textAlign: "left",
                                            marginTop: 5,
                                            width: "100%",
                                          }}
                                        >
                                          Quantity : {p_data.quantity}
                                        </p>
                                      </div>
                                      {/* <Divider orientation="horizontal" style={{marginBottom:10}}/> */}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <Divider
                            orientation="vertical"
                            style={{
                              display: "inline-block",
                              height: 150,
                              marginTop: 10,
                              marginRight: 5,
                            }}
                          />
                          <div
                            style={{
                              width: "48%",
                              display: "inline-block",
                              verticalAlign: "top",
                              paddingLeft: 5,
                            }}
                          >
                            <h4
                              style={{
                                fontSize: 15,
                                fontWeight: "bold",
                                margin: 0,
                                textTransform: "capitalize",
                              }}
                            >
                              customer details
                            </h4>
                            <p
                              style={{
                                width: "100%",
                                fontSize: 13,
                                textAlign: "left",
                                margin: 0,
                                marginTop: 10,
                              }}
                            >
                              Customer Name :{" "}
                            </p>
                            <p
                              style={{
                                width: "100%",
                                fontSize: 13,
                                textAlign: "left",
                                marginBottom: 5,
                                fontWeight: "bold",
                              }}
                            >
                              {item.firstname === item.lastname
                                ? item.firstname
                                : item.firstname + " " + item.lastname}
                            </p>
                            <p
                              style={{
                                width: "100%",
                                fontSize: 13,
                                textAlign: "left",
                                margin: 0,
                              }}
                            >
                              Cutomer Address :{" "}
                            </p>
                            <p
                              style={{
                                width: "100%",
                                fontSize: 13,
                                textAlign: "left",
                                margin: 0,
                                fontWeight: "bold",
                              }}
                            >
                              {item.shipping_firstname +
                                " " +
                                item.shipping_lastname}
                            </p>
                            <p
                              style={{
                                width: "100%",
                                fontSize: 13,
                                textAlign: "left",
                                margin: 0,
                                fontWeight: "bold",
                              }}
                            >
                              {item.shipping_address_1 +
                                " " +
                                item.shipping_address_2 +
                                "," +
                                item.shipping_city}
                            </p>
                            <p
                              style={{
                                width: "100%",
                                fontSize: 13,
                                textAlign: "left",
                                margin: 0,
                                fontWeight: "bold",
                              }}
                            >
                              {item.shipping_zone +
                                "-" +
                                item.shipping_postcode}
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  } else {
                    return (
                      <Link
                        to={{
                          pathname: "/order-detail/" + item.vendor_order_id,
                        }}
                        key={item.vendor_order_id}
                        target="_blank"
                        style={{
                          color: "#333",
                          textDecoration: "none",
                          marginTop: 10,
                        }}
                      >
                        <div style={{ margin: "10px 0" }}>
                          <div
                            style={{ width: "50%", display: "inline-block" }}
                          >
                            <p
                              style={{
                                fontSize: 12,
                                textAlign: "left",
                                width: "100%",
                                margin: 0,
                                color: "#666",
                              }}
                            >
                              Order Id:{" "}
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                textAlign: "left",
                                width: "100%",
                                margin: 0,
                                fontWeight: "bold",
                              }}
                            >
                              {item.vendor_order_id}
                            </p>
                          </div>
                          <div
                            style={{ width: "50%", display: "inline-block" }}
                          >
                            <p
                              style={{
                                fontSize: 12,
                                textAlign: "left",
                                width: "100%",
                                margin: 0,
                                color: "#666",
                              }}
                            >
                              Customer Name
                            </p>
                            <p
                              style={{
                                fontSize: 12,
                                textAlign: "left",
                                width: "100%",
                                margin: 0,
                                fontWeight: "bold",
                              }}
                            >
                              {item.firstname}
                            </p>
                          </div>
                        </div>

                        <Divider orientation="horizontal" />
                      </Link>
                    );
                  }
                })
              ) : (
                <div name="Y32d3">No order Found</div>
              )}
            </Paper>
          ) : null}
        </div>

        <Grid container className={classes.stickyDiv}>
          {this.state.isLoading ? (
            <>
              <Skeleton
                variant="rect"
                width={250}
                height={50}
                style={{ marginRight: 30 }}
              />
              <Skeleton
                variant="rect"
                width={250}
                height={50}
                style={{ marginRight: 30 }}
              />
              <Skeleton
                variant="rect"
                width={250}
                height={50}
                style={{ marginRight: 30 }}
              />
              <Skeleton
                variant="rect"
                width={250}
                height={50}
                style={{ marginRight: 30 }}
              />
            </>
          ) : (
            this.state.orderCount.map((item, index) => (
              <div key={item.title} style={{ marginRight: 30 }}>
                <Typography
                  style={{ textAlign: "left", width: "100%", fontSize: 14 }}
                >
                  {item.title}({item.Count})
                </Typography>

                {/* use cases for upper grey tabs of */}
                {(() => {
                  switch (item.title) {
                    case "New Orders":
                      return (
                        <Button
                          variant="contained"
                          color={
                            this.state.order_status_id == "1" &&
                            this.state.buttonTitle == "new"
                              ? "primary"
                              : "default"
                          }
                          id="1"
                          onClick={this.getRelatedOrders}
                          title="new"
                          style={{ textTransform: "capitalize", fontSize: 11 }}
                        >
                          Acceptance Pending({this.state.newCount})
                        </Button>
                      );
                      break;
                    case "Processing Orders":
                      return (
                        <ButtonGroup
                          color="primary"
                          variant="contained"
                          aria-label="contained primary button group"
                        >
                          <Button
                            color={
                              this.state.order_status_id == "15" &&
                              this.state.buttonTitle == "accepted"
                                ? "primary"
                                : "default"
                            }
                            id="15"
                            title="accepted"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                            // order_type = ""
                          >
                            Book({this.state.acceptCount})
                          </Button>
                          <Button
                            color={
                              this.state.order_status_id == "17" &&
                              this.state.buttonTitle == "pending_manifest"
                                ? "primary"
                                : "default"
                            }
                            id="17"
                            title="pending_manifest"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                            order_type="pending_manifest"
                          >
                            Print Pending ({this.state.pendingManifestCount})
                          </Button>
                          <Button
                            color={
                              this.state.order_status_id == "17" &&
                              this.state.buttonTitle == "pending_pickup"
                                ? "primary"
                                : "default"
                            }
                            id="17"
                            title="pending_pickup"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                            order_type="pending_pickup"
                          >
                            {" "}
                            Pickup Pending({this.state.pendingPickupCount})
                          </Button>
                        </ButtonGroup>
                      );
                    case "Shipped Orders":
                      return (
                        <ButtonGroup
                          color="primary"
                          variant="contained"
                          aria-label="contained primary button group"
                        >
                          <Button
                            variant="contained"
                            color={
                              this.state.order_status_id == "3" &&
                              this.state.buttonTitle == "transit"
                                ? "primary"
                                : "default"
                            }
                            id="3"
                            title="transit"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                          >
                            In Transit({this.state.shipCount})
                          </Button>
                          <Button
                            variant="contained"
                            color={
                              this.state.order_status_id == "18" &&
                              this.state.buttonTitle == "delivered"
                                ? "primary"
                                : "default"
                            }
                            id="18"
                            title="delivered"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                          >
                            Delivered({this.state.deliveredCount})
                          </Button>
                        </ButtonGroup>
                      );
                    case "Cancelled Orders":
                      return (
                        <ButtonGroup
                          color="primary"
                          variant="contained"
                          aria-label="contained primary button group"
                        >
                          <Button
                            variant="contained"
                            color={
                              this.state.order_status_id == "7" &&
                              this.state.buttonTitle == "cancelled"
                                ? "primary"
                                : "default"
                            }
                            id="7"
                            title="cancelled"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                          >
                            Cancelled({this.state.cancelCount})
                          </Button>
                          <Button
                            variant="contained"
                            color={
                              this.state.order_status_id == "8" &&
                              this.state.buttonTitle == "denied"
                                ? "primary"
                                : "default"
                            }
                            id="8"
                            title="denied"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                          >
                            Denied({this.state.deniedCount})
                          </Button>
                          <Button
                            variant="contained"
                            color={
                              this.state.order_status_id == "30" &&
                              this.state.buttonTitle == "RTO"
                                ? "primary"
                                : "default"
                            }
                            id="30"
                            title="RTO"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                          >
                            RTO({this.state.RTOCount})
                          </Button>
                        </ButtonGroup>
                      );
                    case "Return Delivered":
                      return (
                        <ButtonGroup
                          color="primary"
                          variant="contained"
                          aria-label="contained primary button group"
                        >
                          <Button
                            variant="contained"
                            color={
                              this.state.order_status_id == "5" &&
                              this.state.buttonTitle == "Return Delivered"
                                ? "primary"
                                : "default"
                            }
                            id="5"
                            title="Return Delivered"
                            onClick={this.getRelatedOrders}
                            style={{
                              textTransform: "capitalize",
                              fontSize: 11,
                            }}
                          >
                            Return Delivered({this.state.RTODlvdCount})
                          </Button>
                        </ButtonGroup>
                      );
                    default:
                      break;
                  }
                })()}
              </div>
            ))
          )}
        </Grid>
        {this.state.isLoadingTable ? (
          <Skeleton
            variant="rect"
            style={{ marginTop: 30, borderRadius: 5 }}
            height={360}
          >
            <Container>
              <Skeleton variant="text" height={70} />
            </Container>
            <Skeleton variant="text" height={70} />
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                  <Skeleton variant="rect" height={50} />
                </Grid>
                <Grid item xs={11}>
                  <Skeleton variant="text" height={50} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                  <Skeleton variant="rect" height={50} />
                </Grid>
                <Grid item xs={11}>
                  <Skeleton variant="text" height={50} />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                  <Skeleton variant="rect" height={50} />
                </Grid>
                <Grid item xs={11}>
                  <Skeleton variant="text" height={50} />
                </Grid>
              </Grid>
            </Container>
          </Skeleton>
        ) : !this.state.orderData.length > 0 ? (
          <Paper
            style={{
              height: window.innerHeight - 310,
              marginTop: 20,
            }}
            elevation={3}
          >
            <div
              style={{
                textAlign: "center",
                height: window.innerHeight - 310,
              }}
            >
              <ShoppingCartIcon
                style={{
                  marginTop: window.innerHeight - 600,
                  fontSize: 150,
                  color: "#ccc",
                }}
              />
              <h1 style={{ color: "#ccc", fontWeight: 500 }}>
                No orders Found
              </h1>
            </div>
          </Paper>
        ) : (
          <Paper style={{ width: "100%", marginTop: 30, boxShadow: 0 }}>
            {this.state.order_status_id == 3 ||
            this.state.order_status_id == 18 ||
            this.state.order_status_id == 7 ||
            this.state.order_status_id == 8 ||
            this.state.order_status_id == 15 ||
            this.state.order_status_id == 17 ||
            this.state.order_status_id == 1 ||
            this.state.order_status_id == 21 ||
            this.state.order_status_id == 9 ? (
              <EnhancedToolbar
              dimensionsCheck={this.state}
                shipCount={this.state.shipCount}
                deliveredCount={this.state.deliveredCount}
                getRelatedOrders={this.getRelatedOrders}
                order_status_id={this.state.order_status_id}
                classes={classes}
                isLoadingStatus={this.state.isLoadingStatus}
                deniedCount={this.state.deniedCount}
                cancelCount={this.state.cancelCount}
                acceptCount={this.state.acceptCount}
                pendingPickupCount={this.state.pendingPickupCount}
                selected={this.state.selected}
                bookbulkorders={this.bookbulkorders}
                addressList={this.state.addressList}
                selectedAddress={this.state.selectedAddress}
                handleAddressChange={this.handleAddressChange}
                handleCancelOrders={this.handleCancelOrders}
                // addressList={this.state.addressList}
                changeOrderStatus={this.changeOrderStatus}
                BulkShippingLabel={this.BulkShippingLabel}
                buttonTitle={this.state.buttonTitle}
                generateBulkManifest={this.generateBulkManifest}
                isLoadingBulkManifest={this.state.isLoadingBulkManifest}
                BulkInvoice={this.BulkInvoice}
                isGeneratingInvoice={this.state.isGeneratingInvoice}
                BulkShippingLabelAndInvoice={this.BulkShippingLabelAndInvoice}
                readyToShip={this.state.readyToShip}
              />
            ) : null}
            {/* {
                            this.state.order_status_id == 15 && this.state.dimensionCheck
                                    ?
                        <div style={{position:'absolute',right:'23%'}}>
                            <ArrowDownwardIcon fontSize="large"/>
                        </div>
                                :
                                null
                        } */}
            <TableContainer
              component={Paper}
              style={{ minHeight: 230, borderBottomLeftRadius: 0 }}
            >
              <Table style={{ position: "relative" }}>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={this.state.selected.length}
                  order={this.state.orderInc}
                  orderBy={this.state.orderBy}
                  // onSelectAllClick={this.handleSelectAllClick}
                  order_status_id={this.state.order_status_id}
                  selected={this.state.selected}
                  onSelectAllClick={this.onSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  setOrder={this.setOrder}
                  orderData={this.state.orderData}
                  // rowCount={rows.length}
                />

                <TableBody>
                  {this.state.orderData.length > 0 ? (
                    this.state.orderData.map((item, index) => {
                      // console.log(item);
                      const isItemSelected = this.isSelected(
                        item.vendor_order_id
                      );
                      let shippedDate;
                      let shippedMonth;
                      let pickupDate;
                      let shipmentpickupDate;
                      let orderDate;
                      let deliveredDate;
                      let pickDate;
                      const oneDay = 1000 * 60 * 60 * 24;

                      if (item.pickupDate != "N/A") {
                        shipmentpickupDate = new Date(
                          item.pickupDate
                        ).toLocaleDateString("en-IN");
                        pickDate = new Date(item.pickupDate);
                      } else {
                        shipmentpickupDate = "0";
                      }
                      //    console.log(shipmentpickupDate)
                      if (shipmentpickupDate != "0") {
                        pickupDate = pickDate.toLocaleDateString("en-IN");
                      } else {
                        pickupDate = "N/A";
                      }
                
                      let order_date = new Date(item.date_added);
                      // console.log(order_date, "ORDER-DATE");
                      let isoDate = new Date(order_date)
                        .toISOString()
                        .substring(0,10);
                        // .substr(0, 10);
                      // console.log(isoDate, "ISO DATE");
                      let orderDay = new Date(isoDate).getDate();
                      // console.log(orderDay, "OD");
                      let orderMonth =
                        Number(new Date(isoDate).getMonth()) + Number(1);
                      // console.log(orderMonth, "OM");
                      let orderYear = new Date(isoDate).getFullYear();
                      // console.log(orderYear, "OY");
                      if (orderDay < 10) {
                        orderDay = "0" + orderDay;
                      } else {
                        orderDay = orderDay;
                      }

                      if (orderMonth < 10) {
                        orderMonth = "0" + orderMonth;
                      } else {
                        orderMonth = orderMonth;
                      }
                      orderDate = orderDay + "/" + orderMonth + "/" + orderYear;

              
                      let deliver_date = new Date(item.deliverDate);
                      let isoDateDeliver = new Date(deliver_date)
                        .toISOString()
                        .substring(0,10);
                        // .substr(0, 10);
                      let orderDayDeliver = new Date(isoDateDeliver).getDate();
                      let orderMonthDeliver =
                        Number(new Date(isoDateDeliver).getMonth()) + Number(1);
                      let orderYearDeliver = new Date(
                        isoDateDeliver
                      ).getFullYear();
                      if (orderDayDeliver < 10) {
                        orderDayDeliver = "0" + orderDayDeliver;
                      } else {
                        orderDayDeliver = orderDayDeliver;
                      }

                      if (orderMonthDeliver < 10) {
                        orderMonthDeliver = "0" + orderMonthDeliver;
                      } else {
                        orderMonthDeliver = orderMonthDeliver;
                      }
                      deliveredDate =
                        orderDayDeliver +
                        "/" +
                        orderMonthDeliver +
                        "/" +
                        orderYearDeliver;

                      const reasonsString =
                        this?.state?.order_status_id == 5
                          ? item?.reasonsAndComments?.map((obj, index) => (
                            <React.Fragment key={index} >
                               <span
                                style={{
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >{`${obj.reason}`}</span>  
                              {obj.comment && <React.Fragment><br/><br/> {String.fromCharCode(index + 97)}) {obj.comment}</React.Fragment>}
                              <br/>
                              <br/>
                              <br/>
                              <br/>
                            </React.Fragment>
                          ))
                          : "";
                          // let formattedStringReason=reasonsString.join("\n")
                      // const commentsString =
                      //   this?.state?.order_status_id == 5
                      //     ? item?.returnReason?.comment?.map((obj, index) => (
                      //         <React.Fragment key={index}>
                      //           {`${String.fromCharCode(index + 97)}) ${
                      //             Object.values(obj)[0]
                      //           }`}
                      //           <br />
                      //           <br />
                      //         </React.Fragment>
                      //       ))
                      //     : "";

                      // console.log(commentsString, "COMMENT STRING");

                      let total = Number(item.total).toFixed(2);
                      let fullBookingDate;
                      if (item.bookingDate != "N/A") {
                        let bookingDate = new Date(item.bookingDate);
                        fullBookingDate =
                          bookingDate.toLocaleDateString("en-IN");
                      } else {
                        fullBookingDate = "N/A";
                      }
                      let orderAge;

                      if (
                        this.state.order_status_id == 1 ||
                        this.state.order_status_id == 15
                      ) {
                        let today_date = new Date();

                        let order_start = Date.UTC(
                          orderYear,
                          Number(orderMonth) - Number(1),
                          orderDay
                        );
                        let today_dt = Date.UTC(
                          today_date.getFullYear(),
                          today_date.getMonth(),
                          today_date.getDate()
                        );
                        orderAge = (today_dt - order_start) / oneDay;
                      }
                      // console.log(orderAge);
                      let deliveredDayTillToday;
                      if (item.deliverDate.length > 1) {
                        let d_date = new Date(item.deliverDate);
                        // let del_date = d_date.toISOString().substr(0, 10);
                        let ship_pick_Date = new Date(item.pickupDate);
                        let deliverDate = new Date(item.deliverDate);
                        // console.log(ship_pick_Date,deliverDate);
                        let start = Date.UTC(
                          ship_pick_Date.getFullYear(),
                          ship_pick_Date.getMonth(),
                          ship_pick_Date.getDate()
                        );

                        let end = Date.UTC(
                          deliverDate.getFullYear(),
                          deliverDate.getMonth(),
                          deliverDate.getDate()
                        );
                        // console.log(start,end);
                        deliveredDayTillToday = (end - start) / oneDay;
                        // console.log('day',(isNaN(deliveredDayTillToday)))
                        if (!isNaN(deliveredDayTillToday)) {
                          deliveredDayTillToday = deliveredDayTillToday;
                        } else {
                          deliveredDayTillToday = "N/A";
                        }
                      } else {
                        deliveredDayTillToday = "N/A";
                      }

                      // console.log(deliveredDayTillToday);
                      return (
                        <TableRow
                          key={item.vendor_order_id}
                          hover
                          role={Checkbox}
                          selected={item.isPresent ? null : isItemSelected}
                          tabIndex={-1}
                          // onClick={event => this.handleClick(event, item.product_id)}
                        >
                          {this.state.order_status_id == 9 ||
                          this.state.order_status_id == 18 ||
                          this.state.order_status_id == 8 ||
                                                    this.state.order_status_id == 5 ||

                          this.state.order_status_id == 3 ||
                          this.state.order_status_id == 7 ? null : (
                            <TableCell className={classes.tablecell}>
                              <Checkbox
                                checked={isItemSelected}
                                onClick={(event) =>
                                  this.handleClick(
                                    event,
                                    item.vendor_order_id,
                                    item.length,
                                    item.purchaseInvoice_no
                                  )
                                }
                              />
                            </TableCell>
                          )}
                          {this.state.order_status_id == "5" && (
                            <TableCell
                              className={classes.tablecell}
                              align="center"
                              style={{ fontSize: 13 }}
                            >
                              {/* <span
                                style={{
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >
                                Return Request ID :
                              </span> */}

                              <span style={{ fontSize: 13 }}>
                                {item.returnID}
                              </span>
                              <br />
                              <br />
                              {/* <span
                                style={{
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >
                                Created on :
                              </span> */}
                              <span style={{ fontSize: 13 }}>{orderDate}</span>
                              <br />
                              <br />
                              {/* <span
                                style={{
                                  fontSize: 13,
                                  fontWeight: "bold",
                                }}
                              >
                                Delivered on :
                              </span> */}
                              <span style={{ fontSize: 13 }}>
                                {deliveredDate}
                              </span>
                              <br />
                            </TableCell>
                          )}
                          <TableCell
                            align="center"
                            style={{ position: "relative", fontSize: 13 }}
                            className={classes.tablecell}
                          >
                            <Link
                              to={{
                                pathname:
                                  "order-detail/" + item.vendor_order_id,
                                state: {
                                  vendor_order_id: item.vendor_order_id,
                                  order_status_id: this.state.order_status_id,
                                  page: this.state.page,
                                },
                              }}
                              target="_blank"
                            >
                              {item.vendor_order_id}
                            </Link>
                            {this.state.order_status_id != 5 && (
                              <InfoIcon
                                color="primary"
                                style={{
                                  verticalAlign: "middle",
                                  fontSize: 18,
                                  marginLeft: 4,
                                  cursor: "pointer",
                                }}
                                id={index}
                                onMouseEnter={this.openOrderInfo}
                                onMouseLeave={this.closeOrderInfo}
                              />
                            )}
                            {this.state.orderDetailVisible &&
                            this.state.detailId == index ? (
                              <Paper
                                elevation={3}
                                style={{
                                  position: "absolute",
                                  left: 130,
                                  top: "-50%",
                                  padding: 10,
                                }}
                                id={index}
                                onMouseEnter={this.openOrderInfo}
                                onMouseLeave={this.closeOrderInfo}
                              >
                                {this.state.isLoadingDetail ? (
                                  <CircularProgress />
                                ) : (
                                  <div style={{ width: 550 }}>
                                    {item.orderDetail.productData.map(
                                      (p_data, index) => {
                                        // console.log(p_data, "PDATA");
                                        return (
                                          <div
                                            key={p_data.product_sku}
                                            style={{
                                              textAlign: "left",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "inline-block",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              <img
                                                src={p_data.product_image}
                                                alt="product_image"
                                                style={{
                                                  height: 100,
                                                  width: 100,
                                                  border: "1px solid #ccc",
                                                  verticalAlign: "top",
                                                }}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                width: "80%",
                                                display: "inline-block",
                                              }}
                                            >
                                              <Typography
                                                style={{
                                                  display: "inline-block",
                                                  verticalAlign: "top",
                                                  textAlign: "left",
                                                  width: "100%",
                                                  marginLeft: 5,
                                                  fontSize: 14,
                                                }}
                                              >
                                                {p_data.product_name}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  width: "100%",
                                                  textAlign: "left",
                                                  marginLeft: 5,
                                                  fontSize: 14,
                                                }}
                                              >
                                                <strong>SKU :</strong>{" "}
                                                {p_data.product_sku}
                                              </Typography>
                                              <Typography
                                                style={{
                                                  width: "100%",
                                                  textAlign: "left",
                                                  marginLeft: 5,
                                                  fontSize: 14,
                                                }}
                                              >
                                                <strong>Quantity :</strong>{" "}
                                                {p_data.quantity}
                                              </Typography>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </Paper>
                            ) : null}
                            <a
                              style={{ marginTop: "20px" }}
                              className={classes.setDialog}
                              onClick={this.OpenShippingAddressModal}
                              id={index}
                            >
                              Shipping Address
                            </a>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tablecell}
                          >
                            <div>
                              {item.orderDetail.productData.map(
                                (p_data, index) => {
                                  return (
                                    <div key={p_data.product_sku + index}>
                                      <div
                                        style={{
                                          textAlign: "left",
                                          marginBottom: 15,
                                          width: "70%",
                                          float: "left",
                                          marginRight: "5%",
                                        }}
                                      >
                                      {this.state.order_status_id!=5&&  <div
                                          style={{
                                            display: "inline-block",
                                            verticalAlign: "top",
                                            height: 50,
                                            width: 50,
                                            border: "1px solid #ccc",
                                          }}
                                        >
                                          <img
                                            src={p_data.product_image}
                                            alt="product"
                                            style={{
                                              width: "100%",
                                              verticalAlign: "top",
                                            }}
                                          />
                                        </div>}
                                        <div
                                          style={{
                                            width: "80%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              display: "inline-block",
                                              verticalAlign: "top",
                                              textAlign: "left",
                                              width: "100%",
                                              marginLeft: 5,
                                              fontSize: 13,
                                            }}
                                          >
                                            <strong>Product Name: </strong>
                                            {p_data.product_name}
                                          </Typography>
                                          <Typography
                                            style={{
                                              width: "100%",
                                              textAlign: "left",
                                              marginLeft: 5,
                                              fontSize: 13,
                                            }}
                                          >
                                            <strong>SKU: </strong>{" "}
                                            {p_data.product_sku}
                                          </Typography>
                                          <Typography
                                            style={{
                                              width: "100%",
                                              textAlign: "left",
                                              marginLeft: 5,
                                              fontSize: 13,
                                            }}
                                          >
                                            <strong>Model: </strong>{" "}
                                            {p_data.model}
                                          </Typography>
                                        </div>
                                      </div>
                                      <Divider
                                        orientation="vertical"
                                        flexItem
                                        style={{
                                          height: 70,
                                          display: "inline-block",
                                          float: "left",
                                        }}
                                      />
                                      <div
                                        style={{ float: "left", marginLeft: 0 }}
                                      >
                                        <Typography
                                          style={{
                                            width: "20%",
                                            textAlign: "left",
                                            marginLeft: 5,
                                            fontSize: 13,
                                            display: "inline-block",
                                            padding: "25px 5px",
                                            borderRadius: 2,
                                            width: "100%",
                                            display: "inline-block",
                                          }}
                                        >
                                          <strong>Qty: </strong>{" "}
                                          {p_data.quantity}
                                        </Typography>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tablecell}
                            style={{ fontSize: 13 }}
                          >
                            Rs.{total}
                          </TableCell>
                          {this.state.order_status_id != 5 && (
                            <TableCell
                              className={classes.tablecell}
                              align="center"
                              style={{ fontSize: 13 }}
                            >
                              {orderDate}
                            </TableCell>
                          )}

                          {this.state.order_status_id == 5 && (
                            <TableCell
                              className={classes.tablecell}
                              align="center"
                              style={{ fontSize: 13 }}
                            >
                              Return Type not given in api
                            </TableCell>
                          )}
                          {this.state.order_status_id == 5 && (
                            <TableCell
                              className={classes.tablecell}
                              align="center"
                              style={{ fontSize: 13,textAlign:'left' }}

                            >
                              {reasonsString}
                              <br />
                              <br />
                              {/* {commentsString &&<Typography
                                style={{
                                  width: "20%",
                                  textAlign: "left",
                                  marginLeft: 5,
                                  fontSize: 13,
                                  display: "inline-block",
                                  padding: "25px 5px",
                                  borderRadius: 2,
                                  width: "100%",
                                  display: "inline-block",
                                }}
                              >
                                {commentsString}
                              </Typography>} */}
                            </TableCell>
                          )}
                          {this.state.order_status_id == 5 && (
                            <TableCell
                              className={classes.tablecell}
                              align="center"
                              style={{ fontSize: 13 }}
                            >
                               <span style={{ fontSize: 12,textAlign:'left' }}>
                                  <strong>AWB No. :</strong>{" "}
                                </span>{" "}
                                <span style={{ fontSize: 12 }}>
                                  {`${item.awbNumber ? item.awbNumber : "NA"}`}
                                </span>
<br/>
<span style={{ fontSize: 13 }}>
                                  <strong>Carrier: </strong>{" "}
                                </span>{" "}
                                <span style={{ fontSize: 13 }}>
                                  {`${item.carrierName}`}
                                </span>
<br/>                              <br />
                            </TableCell>
                          )}
{this.state.order_status_id != "1" ? null : (
                            <>
                              <TableCell
                                className={classes.tablecell}
                                style={{ padding: 0, position: "relative" }}
                                align="center"
                              >
                                {item.length > 0 &&
                                item.width > 0 &&
                                item.height > 0 &&
                                item.weight > 0 ? (
                                  <>
                                    {this.state.mpsBoxCount == 0 && (
                                      <>
                                        {Number(item.length).toFixed(2)}
                                        <span className={classes.mul_span}>
                                          *
                                        </span>
                                        {Number(item.width).toFixed(2)}
                                        <span className={classes.mul_span}>
                                          *
                                        </span>
                                        {Number(item.height).toFixed(2)}
                                        <span className={classes.mul_span}>
                                          *
                                        </span>
                                        {Number(item.weight).toFixed(2)}
                                      </>
                                    )}
                                    {/* {console.log(this.state,"Handle change after state 0",this.props)} */}
                                    {this.state.mpsBoxCount != 0 &&
                                      this.state.mpsBoxIndex == index && (
                                        <Typography
                                          key={item.product_id}
                                          display="block"
                                          variant="overline"
                                        >
                                          MPS Box Count:{this.state.allidentical?this.state.box:this.state.mpsBoxCount}
                                        </Typography>
                                      )}
                                    <p
                                      className={classes.editDimension}
                                      quantity={item.quantity}
                                      product_id={item.product_id}
                                      index={index}
                                      product_index={item.product_image.length}
                                      onClick={this.OpenDimensionDialog}
                                    >
                                      Edit
                                    </p>
                                  </>
                                ) : (
                                  <Button
                                    variant="text"
                                    color="primary"
                                    quantity={item.quantity}
                                    className={classes.setDialog}
                                    product_id={item.product_id}
                                    index={index}
                                    product_index={item.product_image.length}
                                    onClick={this.OpenDimensionDialog}
                                  >
                                    Set package details
                                  </Button>
                                )}
                              </TableCell>
                           
                            
                            </>
                          )}
                          {this.state.order_status_id != "15" ? null : (
                            <>
                              <TableCell
                                className={classes.tablecell}
                                style={{ padding: 0, position: "relative" }}
                                align="center"
                              >
                                {item.length > 0 &&
                                item.width > 0 &&
                                item.height > 0 &&
                                item.weight > 0 ? (
                                  <>
                                    {this.state.mpsBoxCount == 0 && (
                                      <>
                                        {Number(item.length).toFixed(2)}
                                        <span className={classes.mul_span}>
                                          *
                                        </span>
                                        {Number(item.width).toFixed(2)}
                                        <span className={classes.mul_span}>
                                          *
                                        </span>
                                        {Number(item.height).toFixed(2)}
                                        <span className={classes.mul_span}>
                                          *
                                        </span>
                                        {Number(item.weight).toFixed(2)}
                                      </>
                                    )}
                                    {/* {console.log(this.state,"Handle change after state 0",this.props)} */}
                                    {this.state.mpsBoxCount != 0 &&
                                      this.state.mpsBoxIndex == index && (
                                        <Typography
                                          key={item.product_id}
                                          display="block"
                                          variant="overline"
                                        >
                                          MPS Box Count:{this.state.allidentical?this.state.box:this.state.mpsBoxCount}
                                        </Typography>
                                      )}
                                    <p
                                      className={classes.editDimension}
                                      quantity={item.quantity}
                                      product_id={item.product_id}
                                      index={index}
                                      product_index={item.product_image.length}
                                      onClick={this.OpenDimensionDialog}
                                    >
                                      Edit
                                    </p>
                                  </>
                                ) : (
                                  <Button
                                    variant="text"
                                    color="primary"
                                    quantity={item.quantity}
                                    className={classes.setDialog}
                                    product_id={item.product_id}
                                    index={index}
                                    product_index={item.product_image.length}
                                    onClick={this.OpenDimensionDialog}
                                  >
                                    Set package details
                                  </Button>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tablecell}
                                padding="none"
                              >
                                {item.purchaseInvoice_no.length > 0 ? (
                                  <div
                                    style={{
                                      textAlign: "left",
                                      paddingLeft: 5,
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontSize: 11,
                                      }}
                                    >
                                      Invoice No. :
                                    </label>
                                    <br />
                                    <span
                                      style={{
                                        fontSize: 13,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item.purchaseInvoice_no}
                                    </span>
                                    <br />
                                    <label
                                      style={{
                                        fontSize: 11,
                                      }}
                                    >
                                      Invoice Date :
                                    </label>
                                    {item.purchase_invoice_date ? (
                                      <>
                                        <br />
                                        <span
                                          style={{
                                            fontSize: 13,
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {new Date(
                                            item.purchase_invoice_date
                                          ).toLocaleDateString("en-IN")}
                                        </span>
                                      </>
                                    ) : null}
                                    {item.purchase_invoice_url ? (
                                      <>
                                        <br />
                                        <label
                                          style={{
                                            fontSize: 11,
                                          }}
                                        >
                                          Invoice file :
                                        </label>
                                        {item.purchase_invoice_url ? (
                                          <>
                                            <br />
                                            <a
                                              href={item.purchase_invoice_url}
                                              style={{
                                                fontSize: 13,
                                                fontWeight: "bold",
                                              }}
                                              target="_blank"
                                            >
                                              View file
                                            </a>
                                          </>
                                        ) : null}
                                      </>
                                    ) : null}

                                    <br />
                                    <Button
                                      variant="text"
                                      color="primary"
                                      onClick={this.EditPurchaseInvoice}
                                      id={index}
                                      title="edit"
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                ) : (
                                  <>
                                    <Button
                                      variant="text"
                                      color="primary"
                                      className={classes.setDialog}
                                      id={index}
                                      onClick={this.OpenPurchaseInvoiceUpload}
                                      title="add"
                                    >
                                      Set Purchase Invoice
                                    </Button>
                                    <Tooltip title="Enter the invoice number you have raised to McCoy Digital Pvt. Ltd for this order">
                                      <InfoIcon />
                                    </Tooltip>
                                  </>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tablecell}
                                style={
                                  orderAge > 2
                                    ? { background: "red", color: "#fff" }
                                    : {}
                                }
                              >
                                {orderAge} days
                              </TableCell>
                            </>
                          )}
                          {this.state.order_status_id == "17" ? (
                            <TableCell
                              align="center"
                              className={classes.tablecell}
                            >
                              {fullBookingDate}
                            </TableCell>
                          ) : null}
                          {this.state.order_status_id == "18" ? (
                            <>
                              <TableCell
                                align="center"
                                className={classes.tablecell}
                              >
                                <span style={{ fontSize: 13 }}>
                                  <strong>AWB No. :</strong>{" "}
                                </span>{" "}
                                <span style={{ fontSize: 13 }}>
                                  {item.awbData.length > 0
                                    ? item.awbData[0].awb_no
                                    : "N/A"}
                                </span>
                                <br />
                                <span style={{ fontSize: 13 }}>
                                  <strong>Carrier :</strong>{" "}
                                </span>{" "}
                                <span style={{ fontSize: 13 }}>
                                  {item.awbData.length > 0
                                    ? item.awbData[0].carrier_name
                                    : "N/A"}
                                </span>
                                <br />
                                <span style={{ fontSize: 13 }}>
                                  <strong>Shipped Date :</strong>{" "}
                                </span>
                                <span style={{ fontSize: 13 }}>
                                  {pickupDate != "N/A" ? pickupDate : "N/A"}
                                </span>
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tablecell}
                              >
                                {deliveredDayTillToday != "N/A"
                                  ? deliveredDayTillToday < 2
                                    ? deliveredDayTillToday + " day"
                                    : deliveredDayTillToday + " days"
                                  : "N/A"}
                              </TableCell>
                            </>
                          ) : null}
                          {this.state.order_status_id == "3" ? (
                            <>
                              <TableCell className={classes.tablecell}>
                                <span style={{ fontSize: 13 }}>
                                  <strong>AWB No. :</strong>{" "}
                                </span>{" "}
                                <span style={{ fontSize: 13 }}>
                                  {item.awbData.length > 0
                                    ? item.awbData[0].awb_no
                                    : "N/A"}
                                </span>
                                <br />
                                <span style={{ fontSize: 13 }}>
                                  <strong>Carrier :</strong>{" "}
                                </span>{" "}
                                <span style={{ fontSize: 13 }}>
                                  {item.awbData.length > 0
                                    ? item.awbData[0].carrier_name
                                    : "N/A"}
                                </span>
                                <br />
                                <span style={{ fontSize: 13 }}>
                                  <strong>Shipped Date :</strong>{" "}
                                </span>
                                <span style={{ fontSize: 13 }}>
                                  {pickupDate != "N/A" ? pickupDate : "N/A"}
                                </span>
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  id={item.vendor_order_id}
                                  style={{ fontSize: 13 }}
                                  onClick={this.trackOrder}
                                >
                                  Track
                                </Button>
                              </TableCell>
                            </>
                          ) : null}
                          {this.state.order_status_id == 1 ? (
                            <TableCell
                              align="center"
                              className={classes.tablecell}
                              style={
                                orderAge > 2
                                  ? { background: "red", color: "#fff" }
                                  : {}
                              }
                            >
                              {orderAge} days
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      {this.state.order_status_id == 1 ? (
                        <>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>No orders</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </>
                      ) : this.state.order_status_id == 17 ||
                        this.state.order_status_id == 3 ||
                        this.state.order_status_id == 18 ? (
                        <>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>No orders</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </>
                      ) : (
                        (this.state.order_status_id = 15 ? (
                          <>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>No orders</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </>
                        ) : null)
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {this.state.order_status_id != 5 && (
              <TablePagination
                component="div"
                rowsPerPage={this.state.rowsPerPage}
                labelRowsPerPage="Total Orders"
                rowsPerPageOptions={[10, 25, 50]}
                count={this.state.totalOrders}
                page={this.state.page - 1}
                onChangePage={this.pageChangeHandler}
                classes={{
                  caption: classes.page_root,
                }}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            )}
          </Paper>
        )}
      </div>
      <DimensionDialog
        open={this.state.isdimensionDialogOpen}
        closeDimensionDialog={this.CloseDimensionDialog}
        payment_method={this.state.payment_method}
        length={this.state.length}
        width={this.state.width}
        height={this.state.height}
        weight={this.state.weight}
        setDimension={this.setDimension}
        handleDimensionChange={this.handleDimensionChange}
        orderData={this.state.orderData}
        orderIndex={this.state.index}
      />
      <Dialog
        open={this.state.modalopen}
        onClose={this.handleModalClose}
        fullWidth
        classes={{ paperFullWidth: classes.track_dialog }}
        className={classes.p_10}
      >
        <Toolbar className={classes.toolbar}>
          <h2 className={classes.trackHeading}>Track Order</h2>
          <Tooltip title="Close">
            <Fab
              className={classes.closeIcon}
              size="small"
              onClick={this.handleModalClose}
            >
              <CloseIcon />
            </Fab>
          </Tooltip>
        </Toolbar>
        {this.state.isLoadingTracking ? (
          <div className={classes.t_center}>
            <CircularProgress />
          </div>
        ) : (
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="center">Location</TableCell>
                  <TableCell align="center">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.trackingData.map((item, index) => {
                  // let Tracktime;
                  let newTime;
                  let totalTime;
                  let isoDate = new Date(item.Time).toISOString().substr(0, 10);
                  let date = new Date(isoDate).getDate();
                  let month = new Date(isoDate).getMonth();
                  let Year = new Date(isoDate).getFullYear();
                  let hour = new Date(isoDate).getHours();
                  let newMonth = Number(month) + 1;
                  let Tracktime = new Date(item.Time);
                  // console.log('track',moment(item.Time).format("MMMM Do YYYY, h:mm:ss a [GMT]ZZ"))

                  newTime = Tracktime.toLocaleString("en-IN", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  });
                  // let newTime = new Intl.DateTimeFormat('en-AU', {
                  //     hour: 'numeric', minute: 'numeric', second: 'numeric',
                  //     timeZone: 'Asia/Kolkata',
                  //     timeZoneName: 'short'
                  //   }).format(date)
                  if (item.Track_Time) {
                    totalTime = item.Time + " " + item.Track_Time;
                  } else {
                    totalTime =
                      date + "/" + newMonth + "/" + Year + " " + newTime;
                  }
                  return (
                    <TableRow key={item.Remarks + index}>
                      <TableCell className={classes.tableCell}>
                        {index === 0 ? null : (
                          <Divider
                            orientation="vertical"
                            className={classes.divider}
                          />
                        )}
                        <Fab className={classes.trackIndex}>
                          {this.state.trackingData.length - index}
                        </Fab>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        <span>{item.Remarks}</span>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <span>{item.Location}</span>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {totalTime}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Dialog>
      <Dialog open={this.state.AddressModal} onClose={this.CloseAddressDialog}>
        <DialogTitle id="form-dialog-title">
          Select Address for pickup
        </DialogTitle>
        <DialogContent>
          <form>
            <FormControl variant="outlined" className={classes.address}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Warehouse
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.selectedAddress}
                onChange={this.handleChange}
                label="Select Warehouse"
                name="selectedAddress"
                fullWidth
                margin="dense"
                // disabled={this.state.AddressList.length<2}
              >
                {this.state.addressList.map((address) => (
                  <MenuItem key={address.address_id} value={address.address_id}>
                    {address.warehouse_address +
                      "," +
                      address.waddress_city +
                      "," +
                      address.waddress_state +
                      "," +
                      address.waddress_pincode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.bookbulkorders}
              title="single"
            >
              Ready to Ship
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.CloseAddressDialog}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog open={this.state.shippingAddressModalopen} fullWidth="sm">
        <DialogTitle
          id="customized-dialog-title"
          onClose={this.CloseShippingAddressDialog}
        >
          Shipping Address
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <b>Name</b>: {this.state.shipping_firstname}{" "}
            {this.state.shipping_firstname}{" "}
          </div>
          <div>
            <b>Address</b> : {this.state.shipping_address_1}
          </div>
          <div>
            {this.state.shipping_city}, {this.state.shipping_postcode},{" "}
            {this.state.shipping_zone}, {this.state.shipping_country}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.CloseShippingAddressDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={this.state.purchaseInvoiceModalopen}
        onClose={this.ClosePurchaseInvoiceDialog}
      >
        <DialogTitle id="form-dialog-title">
          Purchase Invoice Details
        </DialogTitle>
        <DialogContent style={{ width: 420 }}>
          <form>
            <TextField
              variant="outlined"
              label="Purchase Invoice Number"
              autoFocus
              value={this.state.purchaseInvoiceNumber}
              onChange={this.handlePurchaseInvoiceChange}
              name="purchaseInvoiceNumber"
              fullWidth
              style={{ marginBottom: 20 }}
              inputRef={this.purchaseInvoiceNumber}
              error={
                this.state.p_invoiceSubmitted &&
                !this.state.purchaseInvoiceNumber
                  ? true
                  : false
              }
              helperText={
                this.state.p_invoiceSubmitted &&
                !this.state.purchaseInvoiceNumber
                  ? "Purchase Invoice Number is required"
                  : ""
              }
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Purchase Invoice Date"
                // format="MM/dd/yyyy"
                format="dd/MM/yyyy"
                value={this.state.selectedDate}
                onChange={this.handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <div>
              <label
                style={{
                  fontSize: 12,
                  letterSpacing: "0.02px",
                  color: "#777",
                  margin: "20px 0 10px 0",
                  display: "block",
                }}
              >
                Upload Purchase Invoice
              </label>
            </div>
            <div>
              <input
                accept="pdf/*"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={this.handleInvoiceUpload}
                // error={this.state.p_invoiceSubmitted && !this.state.purchaseInvoicePdf ? true : false}
                // helperText = {this.state.p_invoiceSubmitted && !this.state.purchaseInvoicePdf ? "Purchase Invoice is required":""}
                // purchaseInvoicePdf
              />
              {/* {(this.state.p_invoiceSubmitted && !this.state.purchaseInvoicePdf) && <Alert variant="filled" severity="error">
                                Please upload invoice file first</Alert>} */}
              <label htmlFor="contained-button-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  disabled={this.state.invoiceLoading}
                >
                  Upload
                </Button>
              </label>
              {this.state.purchaseInvoicePdf !== null
                ? this.state.purchaseInvoicePdf.name
                : null}
            </div>
          </form>
          <DialogActions>
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <Button
                variant="contained"
                color="primary"
                title="single"
                style={{ marginRight: 10 }}
                onClick={this.handlePurchaseInvoiceUpload}
                disabled={this.state.invoiceLoading}
              >
                {this.state.invoiceLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Save"
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.ClosePurchaseInvoiceDialog}
                disabled={this.state.invoiceLoading}
              >
                Cancel
              </Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={this.state.cancelReasonDialogOpen}
        onClose={this.handleCancelOrders}
      >
        <DialogTitle id="form-dialog-title">Cancel Order Reasons</DialogTitle>
        <DialogContent style={{ width: 420 }} className="ccl_reason">
          <div ref={this.modalHeading}></div>
          {this.state.cancel_error ? (
            <Alert severity="error" style={{ marginBottom: 20 }}>
              {this.state.cancel_error_message}
            </Alert>
          ) : null}
          {this.state.show_success_message ? (
            <Alert severity="success" style={{ marginBottom: 20 }}>
              {this.state.show_success_message}
            </Alert>
          ) : null}
          <FormControl variant="outlined" className={classes.address}>
            <InputLabel id="cancel_reasons">
              Select Order Cancel Reason
            </InputLabel>
            <Select
              labelId="cancel_reasons"
              id="cancel_reasons-select-outlined"
              value={this.state.cancel_reason}
              onChange={this.handleCancelReasonChange}
              label="Select Order Cancel Reason"
              name="cancel_reason"
              margin="dense"
              // disabled={this.state.AddressList.length<2}
            >
              <MenuItem value="">Select</MenuItem>
              {cancelReasonArray.map((item, index) => (
                <MenuItem key={item + index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            {this.state.cancel_reason === "Others" ? (
              <TextField
                name="cancel_other"
                value={this.state.cancel_other}
                variant="outlined"
                label="Other Reason"
                style={{ margin: "20px 0px" }}
                margin="dense"
                ref={this.other_reason}
                onChange={this.handleChange}
              />
            ) : null}
            {this.state.cancel_reason === "Out of Stock" ? (
              <>
                <p
                  style={{
                    width: "100%",
                    fontSize: 14,
                    textAlign: "left",
                    marginTop: 20,
                  }}
                >
                  Select Products which are Out of Stock
                </p>
                {this.state.orderProductData.map((item) =>
                  item.orderDetail.productData.map((item1, index) => {
                    return (
                      <FormGroup
                        key={"product" + index + "" + item1.prouduct_id}
                      >
                        <FormControlLabel
                          control={
                            <>
                              <Checkbox
                                checked={this.isproductSelected(
                                  item1.product_id
                                )}
                                onChange={(e) =>
                                  this.handleProductSelect(e, item1.product_id)
                                }
                                name="product"
                                color="primary"
                              />
                              <img
                                src={item1.product_image}
                                alt="product_image"
                                style={{
                                  width: 50,
                                  height: 50,
                                  border: "1px solid #ccc",
                                }}
                              />
                              <div>
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Product Name :
                                </span>
                                <span style={{ fontSize: 13 }}>
                                  {item1.product_name}
                                </span>
                                <br />
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Product SKU :
                                </span>
                                <span
                                  style={{
                                    fontSize: 13,
                                  }}
                                >
                                  {item1.product_sku}
                                </span>
                                <br />
                                <span
                                  style={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Prouduct Model :
                                </span>
                                <span
                                  style={{
                                    fontSize: 13,
                                  }}
                                >
                                  {item1.model}
                                </span>
                              </div>
                            </>
                          }
                          style={{
                            margin: "10px 0px",
                          }}
                        />
                      </FormGroup>
                    );
                  })
                )}
              </>
            ) : null}
          </FormControl>
          <DialogActions
            style={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              width: 400,
            }}
          >
            <div
              style={{ textAlign: "center", display: "block", width: "100%" }}
            >
              <Button
                variant="contained"
                color="primary"
                title="single"
                style={{ marginRight: 10, width: 200, padding: "8px 0px" }}
                onClick={this.SubmitCancelOrder}
                disabled={this.state.cancel_order_loading}
              >
                {this.state.cancel_order_loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : this.state.selected.length < 2 ? (
                  "Cancel Order"
                ) : (
                  "Cancel Orders"
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleCancelOrders}
                disabled={this.state.cancel_order_loading}
              >
                Close
              </Button>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <div style={{position:"fixed", bottom:"24px", left:"24px"}}>
          {this.state.success_order ? (
            <MuiSnackbar 
                 open={this.state.success_order} 
                 onClose={this.handleCloseSuccessOrder} 
                 style={{position:"static",marginBottom:"4px"}}>
              <MuiAlert 
                severity='success'
                variant="filled"
                onClose={this.handleCloseSuccessOrder}
                >
                  {this.state.success_order_message}
              </MuiAlert>
            </MuiSnackbar>
            ) : null}
          {this.state.cancel_order_error ? (
            <MuiSnackbar 
                 open={this.state.cancel_order_error} 
                 onClose={this.handleCloseCancelOrder} 
                 style={{position:"static"}}
            >
              <MuiAlert 
                severity='error'
                variant="filled"
                onClose={this.handleCloseCancelOrder}
                >
                  {this.state.cancel_order_error_message}
              </MuiAlert>
            </MuiSnackbar>
          ) : null}
      </div>
      <Snackbar open={this.state.open}>
          <Alert severity={this.state.isError ? 'error':'success'}>
              {this.state.message}
          </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={this.state.backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.regenerateShipping}
          style={{ marginBottom: 60 }}
          message={
            <div>
              <CircularProgress color="inherit" size={20} />
              {this.state.buttonTitle == "pending_manifest" ? (
                <span style={{ verticalAlign: "text-bottom", marginLeft: 10 }}>
                  Generating Shipping Label
                </span>
              ) : (
                <span style={{ verticalAlign: "text-bottom", marginLeft: 10 }}>
                  Regenerating Shipping Label
                </span>
              )}
            </div>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.regenerateInvoice}
          message={
            <div>
              <CircularProgress color="inherit" size={20} />
              {this.state.buttonTitle == "pending_manifest" ? (
                <span style={{ verticalAlign: "text-bottom", marginLeft: 10 }}>
                  Generating Invoice
                </span>
              ) : (
                <span style={{ verticalAlign: "text-bottom", marginLeft: 10 }}>
                  Regenerating Invoice
                </span>
              )}
            </div>
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.downloadmerged}
          message={
            <div>
              <CircularProgress color="inherit" size={20} />
              <span style={{ verticalAlign: "text-bottom", marginLeft: 10 }}>
                Generating Invoice and Shipping Label
              </span>
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
}
export default manageOrders;


import React, { Fragment } from 'react';
import {Button,CircularProgress,TextField,Tooltip} from '@material-ui/core';

function login_otp(){
    return (
        <Fragment>
            <Tooltip title="Click to go back" placement="left">
              <i className="material-icons back" onClick={()=>this.props.changeStep('password')}>
              keyboard_backspace
              </i>
              </Tooltip>
            <p style={{fontSize:16}}>Otp sent to {this.props.username}</p>
            <TextField 
                variant="outlined" 
                style={{marginBottom:20}} 
                fullWidth label="Enter OTP" 
                value={this.state.otp} 
                onChange={this.handleChange}
                inputRef={this.otpRef}
                autoFocus
                error={(this.state.submitted && !this.state.otp) || this.state.otpError }
                helperText={this.state.submitted && !this.state.otp ? "Enter valid OTP":""}
                />
            <Button variant="text" disabled={this.state.isLoading} onClick={this.resendOtp}>
                Resend Otp
            </Button>

            <Button 
                variant="contained" 
                color="primary" 
                fullWidth
                style={{padding:12}}
                onClick={this.handleSubmit}
                disabled={this.state.isLoading}
                >
                {
                    this.state.isLoading
                        ?
                    <CircularProgress color="inherit" size="22px" />
                        :
                        "Login"
                }
            </Button>
        </Fragment>
    )
}

export default login_otp;
import React from 'react';
import { Button,Tooltip,Snackbar,Paper,Dialog,DialogTitle,DialogContent, TextField,Grid,CircularProgress} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton';
import {Add} from '@material-ui/icons'
import {Edit} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import pdf from '../../../../assets/images/pdf.png';
import FileCopyIcon from '@material-ui/icons/FileCopy';


function bankDetails(){
    const {classes}=this.props;
    return (
        <React.Fragment>
            {
                this.state.isLoading
                        ?
                <div>
                <Skeleton variant="text" height={70} />
                <Skeleton variant="text" height={70} />
                <Skeleton variant="text" height={70} />
                <Skeleton variant="text" height={70} />
                </div>     
                    :
                <>
                {
                    this.state.AccountList.map((item,index)=>{
                        return  <Paper 
                                    key={item.id} 
                                    className={classes.acc_paper}
                                    >
                                        <ul className={classes.acc_ul}>
                                            <li className={classes.acc_li}>
                                                <span className={classes.acc_label}>
                                                    Account Holder Name : 
                                                </span>
                                                <span className={classes.formContent}>
                                                    {item.account_holder_name}
                                                </span>
                                            </li>
                                            <li className={classes.acc_li}>
                                                <span className={classes.acc_label}>
                                                    Account Number : 
                                                </span>
                                                <span className={classes.formContent}>
                                                    {item.account_number}
                                                </span>
                                            </li>
                                            <li className={classes.acc_li}>
                                                <span className={classes.acc_label}>
                                                    Bank Name : 
                                                </span>
                                                <span className={classes.formContent}>
                                                    {item.bank_name}
                                                </span>
                                            </li>
                                            <li className={classes.acc_li}>
                                                <span className={classes.acc_label}>
                                                    IFSC Code : 
                                                </span>
                                                <span className={classes.formContent}>
                                                    {item.ifsc}
                                                </span>
                                            </li>
                                            <li className={classes.acc_li}>
                                                <span className={classes.acc_label}>
                                                    Branch : 
                                                </span>
                                                <span className={classes.formContent}>
                                                    {item.branch}
                                                </span>
                                            </li>
                                            <li className={classes.acc_li}>
                                                <span className={classes.acc_label}>
                                                    Bank Address : 
                                                </span>
                                                <span className={classes.formContent}>
                                                    {item.bank_address},
                                                    {item.city},
                                                    {item.state}
                                                </span>
                                            </li>
                                            <li>
                                            <span className={classes.acc_label}>
                                                    Cheque : 
                                                </span>
                                                <span>
                                                    <a href={item.cancelled_cheque} alt="document" target="blank">Click to check the document</a>
                                                </span>
                                            </li>
                                        </ul>
                                        <div className={classes.defaultMark}>
                                            {
                                                item.is_default
                                                    ?
                                                <p
                                                className={classes.default_tag}
                                                >
                                                    Default
                                                </p>
                                                    :
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    id={item.id}
                                                    onClick={this.markDefault}
                                                >
                                                    Set default
                                                </Button>
                                            }
                                        </div>
                                </Paper>
                                
                    })
                }  
                <Paper
                    className={classes.acc_paper}
                >
                    <h4 className={classes.add_n_heading}>Add New Bank Account</h4>
                    <Button 
                        color="primary" 
                        variant="contained"
                        className={classes.addButton}
                        onClick={this.openDialog}
                        >
                            Add
                    </Button>
                </Paper>
                </>
            }
            <Snackbar open={this.state.snackOpen} anchorOrigin={this.state.isError?{horizontal:"center",vertical:"bottom"}:{horizontal:"center",vertical:"top"}}>
                <Alert severity={this.state.isError? "error" : "success"}>
                    {this.state.message}
                </Alert>
            </Snackbar> 
            <Dialog open={this.state.openDialog}>
                <DialogTitle>
                    Add New Bank Account
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={this.AddAccountDetails}>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6}>
                                <TextField 
                                variant="outlined"
                                name="name"
                                label="Account Holder Name"
                                value={this.state.name}
                                onChange={this.handleChange}
                                fullWidth
                                style={{marginBottom:15}}
                                inputRef={this.nameRef}
                                error={this.state.submitted && !this.state.name}
                                helperText={this.state.submitted && !this.state.name ? 'Enter valid Name':""}
                                />
                                <TextField 
                                    variant="outlined"
                                    name="account_no"
                                    label="Account Number"
                                    value={this.state.account_no}
                                    onChange={this.handleChange}
                                    fullWidth
                                    style={{marginBottom:15}}
                                    inputRef={this.account_no}
                                    error={this.state.submitted && !this.state.account_no}
                                    helperText={this.state.submitted && !this.state.account_no ? 'Enter valid Name':""}
                                />
                                <TextField 
                                    variant="outlined"
                                    name="confirmAccount"
                                    label="Confirm Account Number"
                                    value={this.state.confirmAccount}
                                    onChange={this.handleChange}
                                    fullWidth
                                    style={{marginBottom:15}}
                                    inputRef={this.confirmAccountRef}
                                    error={this.state.submitted && this.state.account_no !== this.state.confirmAccount}
                                    helperText={this.state.submitted && this.state.account_no !== this.state.confirmAccount ? 'Account Not Match':""}
                                />
                                <TextField 
                                    variant="outlined"
                                    name="bank_name"
                                    label="Bank Name"
                                    value={this.state.bank_name}
                                    onChange={this.handleChange}
                                    fullWidth
                                    style={{marginBottom:15}}
                                    inputRef={this.bank_name}
                                    error={this.state.submitted && !this.state.bank_name}
                                    helperText={this.state.submitted && !this.state.bank_name ? 'Enter valid Bank Name':""}
                                />
                                <TextField 
                                    variant="outlined"
                                    name="bank_address"
                                    label="Bank Address"
                                    value={this.state.bank_address}
                                    onChange={this.handleChange}
                                    fullWidth
                                    multiline
                                    style={{marginBottom:15}}
                                    inputRef={this.bank_address}
                                    error={this.state.submitted && !this.state.bank_address}
                                    helperText={this.state.submitted && !this.state.bank_address ? 'Enter valid Bank Address':""}
                                    rowsMax="2"
                                    rows="2"
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField 
                                variant="outlined"
                                name="ifsc"
                                label="IFSC Code"
                                value={this.state.ifsc}
                                onChange={this.handleChange}
                                fullWidth
                                style={{marginBottom:15}}
                                inputRef={this.ifsc}
                                error={this.state.submitted && !this.state.ifsc}
                                helperText={this.state.submitted && !this.state.ifsc ? 'Enter valid IFSC Code':""}
                                />
                                <TextField 
                                    variant="outlined"
                                    name="branch"
                                    label="Branch"
                                    value={this.state.branch}
                                    onChange={this.handleChange}
                                    fullWidth
                                    style={{marginBottom:15}}
                                    inputRef={this.branch}
                                    error={this.state.submitted && !this.state.branch}
                                    helperText={this.state.submitted && !this.state.branch ? 'Enter valid Branch':""}
                                />
                                <TextField 
                                    variant="outlined"
                                    name="bank_city"
                                    label="City"
                                    value={this.state.bank_city}
                                    onChange={this.handleChange}
                                    fullWidth
                                    style={{marginBottom:15}}
                                    inputRef={this.bank_city}
                                    error={this.state.submitted && !this.state.bank_city}
                                    helperText={this.state.submitted && !this.state.bank_city ? 'Enter valid City':""}
                                />
                                <TextField 
                                    variant="outlined"
                                    name="bank_state"
                                    label="State"
                                    value={this.state.bank_state}
                                    onChange={this.handleChange}
                                    fullWidth
                                    style={{marginBottom:15}}
                                    inputRef={this.bank_state}
                                    error={this.state.submitted && !this.state.bank_state}
                                    helperText={this.state.submitted && !this.state.bank_state ? 'Enter valid State':""}
                                />
                            </Grid>
                        </Grid>
                        <div className={classes.cheque}>
                            <h4 className={this.state.submitted && this.state.cheque_image === null ? classes.errorChequeHeading : classes.cheque_heading}>Upload Cancelled Cheque</h4>
                            <React.Fragment>
                            <input 
                                accept="image/*" 
                                id="cheque_image" 
                                type="file" 
                                className="image_i" 
                                onChange={this.getImage} 
                                image_type="gstin" 
                                style={{position:"absolute",right:120,bottom:80}}
                                />
                            <label 
                                htmlFor="cheque_image"
                            >
                                <Button
                                variant="contained"
                                color="primary"
                                component="span"
                            >   
                                {
                                    this.state.cheque_image
                                            ?
                                        "Change"
                                            :
                                        "Select Image"
                                }
                            </Button>
                            </label>
                            {
                                this.state.submitted && this.state.cheque_image === null
                                            ?
                                    <p className={classes.imageError}>Document required</p>
                                            :
                                            null
                            }
                        </React.Fragment>
                            {
                                this.state.cheque_image
                                        ?
                                <div className={classes.chequeImagediv}>
                                    {
                                        this.state.cheque_image_file.name.includes('pdf')
                                                        ?
                                            <div 
                                            className={classes.uploadPdfDiv}
                                            >
                                                <img src={pdf} alt="pdf" style={{height:80,width:80}} />
                                                <p className={classes.helperPara}>{this.state.cheque_image_file.name}</p>
                                            </div>
                                                :                    
                                <img 
                                    src={this.state.cheque_image} 
                                    alt="cheque" 
                                    className={classes.cheque}
                                    />
                                    }
                                </div>
                                        :
                                        null
                            }  
                        </div>
                        <div className={classes.buttonDiv}>
                            <Button 
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{marginRight:10}}
                                disabled={this.state.isLoading}
                            >
                                {
                                    this.state.isLoading
                                            ?
                                    <CircularProgress 
                                        size={25}
                                        color="inherit"
                                    />
                                        :
                                    "Submit"
                                }
                            </Button>
                            <Button 
                                variant="contained"
                                color="primary"
                                onClick={this.closeDialog}
                                disabled={this.state.isLoading}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
export default bankDetails;
import React, { Component } from 'react';
import TimerIcon from '@material-ui/icons/Timer';
import { withStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  coming_soon:{
    fontSize:"20rem",
    color:"rgba(0,0,0,0.3)"
  },
  text_center:{
    textAlign:'center',
    marginTop:65,
    paddingBottom:40
  },
  c_text:{
    fontSize:"3rem",
    marginTop:0,
    fontWeight:500,
    color:"#999"
  }
})


 class ManageCancellation extends Component {
  render() {
    const {classes}=this.props;
    return (
      <div className={classes.text_center}>
        <TimerIcon
          classes={{root:classes.coming_soon}}
        />
        <h2 className={classes.c_text}>Coming Soon</h2>
      </div>
    );
  }
}
export default withStyles(style)(ManageCancellation);
import React from 'react';
import {TextField,Button,CircularProgress,InputAdornment} from '@material-ui/core';


function signupstep1(){
    return (
        <React.Fragment>
          
            <p style={{fontSize:12,color:'red'}}>{this.state.error}</p>
          
            <form onSubmit={this.signupstep1}>
              <TextField 
                id="name"
                name="name" 
                value={this.props.name} 
                onChange={this.handleNameChange} 
                label="Full Name" 
                variant="outlined" 
                error={this.state.isSubmitted && this.props.name.length < 1} 
                fullWidth 
                style={{marginBottom:20}}
                helperText={this.state.isSubmitted && this.props.name.length < 1 ?"Enter Valid Name":""}
                autoFocus
                inputRef={this.ref1}
                
                />
              <TextField 
                id="mobile" 
                type="text" 
                name="mobile"
                value={this.props.mobile} 
                onChange={this.handleMobileChange} 
                label="Mobile No" 
                variant="outlined" 
                error={this.state.isSubmitted && (this.props.mobile.length < 1 || this.props.mobile.length > 10 || this.state.MobileError) ? true : false} 
                helperText={this.state.isSubmitted && (this.props.mobile.length < 1 || this.props.mobile.length > 10) ? "Enter valid 10 digit mobile number":""}
                fullWidth 
                style={{marginBottom:20}}
                inputRef={this.ref2}
                InputProps={{
                  startAdornment : <InputAdornment position="start" >
                  +91  
                  </InputAdornment>
                }}
                />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{padding:12}}
                type="submit"
                disabled={this.state.isLoading}
              >
              {
                  this.state.isLoading
                        ?
                    <CircularProgress size={24} color="inherit"/>
                        :
                        "Next"
              }
              </Button>  
            </form>
          </React.Fragment>
    )
}
export default signupstep1
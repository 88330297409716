import React from 'react';
import { Grid,TextField,FormLabel,Button,FormGroup,FormControl,FormControlLabel,Checkbox,Chip } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete  from '@material-ui/lab/Autocomplete';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
  } from '@material-ui/pickers';
  import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
  import CheckBoxIcon from '@material-ui/icons/CheckBox';
  import Add from '@material-ui/icons/Add';
import Select from 'react-select';

let icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    let checkedIcon= <Add />;

export default function AddressForm(props){
    
    return (
        <form onSubmit={props.submitWareHouseDetails}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                    {
                        props.showSame
                            ?
                        <FormGroup style={{marginBottom:10}}>
                        <FormControl>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox color="primary" onChange={props.same} checked={props.checked}/>}
                                label="Same as Business Address"
                                labelPlacement="end"
                                />
                        </FormControl>
                    </FormGroup>
                        :
                        null
                    }
                    
    
                    <TextField 
                        variant="outlined" 
                        fullWidth 
                        label="Registered Warehouse Address"
                        name="address"
                        value={props.address} 
                        style={{marginBottom:20}}
                        onChange={props.handleChange}
                        error={props.submitted && !props.address}
                        helperText={props.submitted && !props.address ? "Enter valid address" : ""}
                        inputRef={props.addressRef}
                        >
                    </TextField>
                    <TextField 
                        variant="outlined" 
                        fullWidth 
                        label="Pincode"
                        name="pincode"
                        value={props.pincode} 
                        style={{marginBottom:20}}
                        onChange={props.handleChange}
                        error={props.submitted && !props.pincode}
                        helperText={props.submitted && !props.pincode ? "Enter valid pincode" : ""}
                        inputRef={props.pincodeRef}
                        >
                    </TextField>
                    <TextField 
                        variant="outlined" 
                        fullWidth 
                        label="City"
                        name="city"
                        value={props.city} 
                        style={{marginBottom:20}}
                        onChange={props.handleChange}
                        error={props.submitted && !props.city}
                        helperText={props.submitted && !props.city ? "Enter valid city" : ""}
                        inputRef={props.cityRef}
                        >
                    </TextField>
                    <TextField 
                        variant="outlined" 
                        fullWidth 
                        label="State"
                        name="State"
                        value={props.State} 
                        style={{marginBottom:20}}
                        onChange={props.handleChange}
                        error={props.submitted && !props.State}
                        helperText={props.submitted && !props.State ? "Enter valid State" : ""}
                        inputRef={props.StateRef}
                        >
                    </TextField>
                    <FormLabel>Working Hours</FormLabel>
                    <Grid container spacing={3} style={{marginBottom:20}}>
                        
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                            margin="normal"
                            id={"start-time"+props.index}
                            label="From"
                            onChange={props.handleStartChange}
                            value={props.start_time}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardTimePicker
                            margin="normal"
                            id={"end-time"+props.index}
                            label="To"
                            value={props.end_time}
                            onChange={props.handleEndChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <FormLabel>Weekly Off</FormLabel>
                    <Select
                        isMulti={true}
                        options={props.options}
                        onChange={props.selectChange}
                        value={props.selectedOptions}
                        ref={props.weeklyRef}
                        
                    >
                    </Select>
                    {/* <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={props.options}
                            getOptionLabel={option => option.label}
                            filterSelectedOptions={true}
                            value={props.selectedOptions}
                            onChange={props.selectChange}
                            renderOption={(option,{selected})=>(
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}

                                    />
                                    {option.label}
                                </React.Fragment>
                            )}
                            renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Weekly off"
                                placeholder=""
                                fullWidth
                                error={props.submitted && !props.selectedOptions.length>0}
                                onChange={props.handleWeeklyOff}
                                inputRef={props.weeklyRef}
                                value={props.weeklyOff}
                                
                            />
                            )}
                        /> */}
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
                <div style={{padding:'40px 0',textAlign:'center'}}>
                    {
                    props.addressList.length>0?

                    <Button 
                        variant="contained"
                        color="primary"
                        style={{marginRight:'10px',padding:'8px 30px'}}
                        onClick={props.closecollapse}
                    >
                        Cancel
                    </Button>
                    :
                    <Button
                        variant="contained"
                        color="primary"
                        style={{marginRight:'10px',padding:'8px 30px'}}
                        onClick={props.previous}
                    >
                        Back
                    </Button>    
                }
                    <Button 
                        variant="contained" 
                        color="primary" 
                        type="submit"
                        style={{marginRight:'10px',padding:'8px 30px'}}
                        onClick={props.submitWareHouseDetails}
                        >
                        Save
                    </Button>
                    {props.delete===true ? <Button 
                        variant="contained" 
                        color="primary" 
                        style={{marginRight:'10px',padding:'8px 30px'}}
                        onClick={props.openDialog}
                        >
                        Delete
                    </Button>
                    :
                    null   
                }
                </div>   
            </form>
    )
}
import React, { Component } from 'react';
import signupstep2 from './SignupStep2.jsx';
import axios from 'axios';
import { history } from '../../../../helper/history.js';
import {authHeader} from '../../../../helper/auth-header';
import siteConfig from '../../../../config';

const apiUrl = siteConfig.apiUrl;
const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export default class SignupStep2 extends Component {
    constructor(props){
      super(props);
      this.state={
        otp:'',
        email:'',
        password:'',
        isSubmitted: false,
        isLoading:false,
        message:'',
        hasError:false,
        open:false,
        otpError:false,
        emailError:false,
        ErrorMessage:''
      }
      this.otpRef = React.createRef('otp');
      this.emailRef = React.createRef('email');
      this.passwordRef = React.createRef('password');
    }
  
  handleChange=(e)=>{
    this.setState({
      [e.target.name]:e.target.value,
      otpError:false
    })
  }
  submitForm = (e) => {
    e.preventDefault();
    this.setState({
      isSubmitted: true,
      isLoading:true
    }, ()=>{
      if(this.state.isSubmitted && this.state.otp.length=== 4 && this.state.password.length > 5 && regex.test(this.state.email)){
        const requestOptions ={
          method:'post',
          data: {
            email: this.state.email,
            password: this.state.password,
            otp: this.state.otp,
            key:'signup',
            username : this.props.mobile,
            user_id : 0,
            device_type: "1"
          }
        }
        return axios(apiUrl+'/verify-otp',requestOptions)
        .then(data=>{
            localStorage.setItem('seller',JSON.stringify(data.data.data));
            
            const notificationOptions = {
              method : 'POST',
              data : {
                name : this.props.name,
                email : this.state.email,
                mobile : this.props.mobile,
                template : 'SELLERREGISTRATION'
              }
            }
            return axios(apiUrl+'/send-notification', notificationOptions)
            .then(data=>history.push('/seller-signup'))
            .catch(err=>history.push('/seller-signup'))
        })
        .catch(error=>{
          this.setState({
            message:error.response ? error.response.data.message : 'network error',
            ErrorMessage:error.response ? error.response.data.message : 'network error',
            isLoading:false,
            hasError:true,
            open:true,
          },()=>{
            setTimeout(() => {
              this.setState({
                open:false,
              })
            }, 3000);
            if(this.state.ErrorMessage.includes('otp')){
              this.setState({
                otpError:true
              },()=>this.otpRef.current.focus())
            }
          })
        })
      }else{
        this.setState({
          isLoading: false
        },()=>{
          if(this.state.isSubmitted && this.state.otp.length !== 4){
            this.otpRef.current.focus()
          }
          else if(this.state.isSubmitted && (!this.state.email || !regex.test(this.state.email))){
            this.emailRef.current.focus()
          }else if(this.state.isSubmitted && this.state.password.length < 6){
            this.passwordRef.current.focus()
          }
        })
      }
    })
  }
  resendOTP=()=>{
    this.setState({
      isLoading:false
    },()=>{
      let mobile = this.props.mobile;
      const requestOptions = {
        method:'post',
        headers : authHeader(),
        data:{
          username : mobile,
          key:'otp_mobile',
          user_id:"0"
        }
      }
      return axios(apiUrl+'/resend-otp',requestOptions)
      .then(data=>{
        this.setState({
          hasError:false,
          message:data.data.message,
          open:true,
          isLoading:false
        },()=>{
          this.otpRef.current.focus();
          setTimeout(() => {
            this.setState({
              open:false,
              message:''
            })
          }, 3000);
        })
      })
      .catch(error=>{
        this.setState({
          open:true,
          hasError:true,
          message:error.response ? error.response.data.message : 'network error',
          isLoading:false
        },()=>{
          setTimeout(() => {
            this.setState({
              open:false,
              hasError:false,
              message:""
            })
          }, 3000);
        })
      })
    })
  }
  render() {
    return signupstep2.call(this,this.otpRef,this.emailRef,this.passwordRef);
  }
}

import React,{ Component } from 'react';
import { Dialog,DialogTitle,DialogContent,TextField,DialogActions,Button,Tabs,Tab,Paper,Box,Typography,FormControlLabel,Checkbox,FormControl,InputLabel,MenuItem,Select } from '@material-ui/core';
import Dimension from './Dimension/Dimension';
import Draggable from 'react-draggable';

let boxes = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103];

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} padding={0}>
            {children}
          </Box>
        )}
      </div>
    );
  }

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function dimension(){
    const {classes} = this.props;

    return (
        <Dialog 
                    open={this.state.isdimensionDialogOpen} 
                    onClose={this.CloseDimensionDialog} 
                    aria-labelledby="draggable-dialog-title"
                    PaperComponent={PaperComponent}
                    >
                    <DialogTitle 
                        id="form-dialog-title" 
                        style={{ cursor: 'move' }} 
                        id="draggable-dialog-title"
                        >
                            Set Product Dimension
                    </DialogTitle>
                    <DialogContent style={{width:400}}>
                    <Paper className={classes.root} >
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                        >
                            <Tab label="Single Packet" {...a11yProps(0)}/>
                            <Tab label="Multiple Packet" {...a11yProps(1)} disabled={this.props.payment_method == 'code'}/>
                        </Tabs>
                    </Paper>
                    <TabPanel value={this.state.value} index={0}>
                    <form >
                            <TextField
                                variant="outlined" 
                                label="Length(in cm)"
                                autoFocus 
                                value={this.state.length} 
                                onChange={this.handleDimensionChange} 
                                name="length" 
                                fullWidth 
                                style={{marginBottom:20,marginTop:20}}
                                inputRef={this.length}
                                error={this.state.submitted && (this.state.length < 0.01 || isNaN(this.state.length))}
                                helperText={this.state.submitted && (this.state.length < 0.01 || isNaN(this.state.length)) ? 'length is required in number only':""}
                                />
                            <TextField
                                variant="outlined" 
                                label="Width(in cm)"
                                value={this.state.width} 
                                onChange={this.handleDimensionChange} 
                                name="width" 
                                fullWidth 
                                style={{marginBottom:20}}
                                inputRef={this.width}
                                error={this.state.submitted && (this.state.width < 0.01 || isNaN(this.state.width))}
                                helperText={this.state.submitted && (this.state.width < 0.01 || isNaN(this.state.width)) ? 'width is required in number only':""}
                                />
                            <TextField 
                                variant="outlined" 
                                label="Height(in cm)" 
                                value={this.state.height} 
                                onChange={this.handleDimensionChange} 
                                name="height" 
                                fullWidth 
                                style={{marginBottom:20}}
                                inputRef={this.height}
                                error={this.state.submitted && (this.state.height < 0.01 || isNaN(this.state.height))}
                                helperText={this.state.submitted && (this.state.height < 0.01 || isNaN(this.state.height)) ? 'height is required in number only':""}
                                />
                            <TextField 
                                variant="outlined" 
                                label="Weight(in Kg)" 
                                value={this.state.weight} 
                                onChange={this.handleDimensionChange} 
                                name="weight" 
                                fullWidth 
                                style={{marginBottom:20}}
                                inputRef={this.weight}
                                error={this.state.submitted && (this.state.weight < 0.01 || isNaN(this.state.weight))}
                                helperText={this.state.submitted && (this.state.weight < 0.01 || isNaN(this.state.weight)) ? 'weight is required in number only':""}
                                />
                        </form>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <div>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="box">Box Count</InputLabel>
                            <Select
                            labelId="box"
                            id="box"
                            value={this.state.box}
                            onChange={this.handleBoxChange}
                            label="Box Count"
                            margin="dense"
                            >
                            {
                                boxes.map(item=>{
                                    return (
                                        <MenuItem key={"boxno"+item} value={item}>{item}</MenuItem>
                                    )
                                })
                            }
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            style={{marginTop:8}}
                            control={
                            <Checkbox
                                checked={this.state.allidentical}
                                onChange={this.handleChange}
                                name="box"
                                color="primary"
                            />
                            }
                            label="All boxes are identical"
                        />
                        </div>
                        <div>
                            <Typography style={{textAlign:'left'}}>Package Details</Typography>
                            <Typography variant="caption" color="error">
                            combined weight should not exceed 200kgs</Typography>


                            <form>    
                                {
                                    this.state.boxArray.map((item,index)=>{
                                        return <Dimension 
                                                    key={'dimension'+index}
                                                    isSubmitted={this.state.submitted}
                                                    index={index}
                                                    item={item}
                                                    getDimension = {this.getDimension}
                                                    orderData={this.props.orderData[this.props.orderIndex]}
                                                    boxArray={this.state.boxArray}
                                                    getMpsDimension={this.getMpsDimension}
                                                    />
                                    })
                                }
                            </form>
                        </div>
                    </TabPanel>
                        
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={(e)=>this.setDimension(e,this.state)}>Save</Button>
                        <Button variant="contained" color="primary" onClick={this.CloseDimensionDialog}>Cancel</Button>
                    </DialogActions>
                </Dialog>
    )
}

export default dimension;
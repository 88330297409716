import React from 'react';
import {TextField,Grid,Select,FormControl,InputLabel,MenuItem} from '@material-ui/core';

function product_description(){
    const { classes } = this.props;
    return (
        <React.Fragment>
            <TextField
                label="Model"
                variant="outlined"
                fullWidth
                value={this.props.product_model}
                disabled
                className={classes.mb_20}
            />
            <TextField
                label="SKU"
                variant="outlined"
                fullWidth
                value={this.props.product_sku}
                disabled
                className={classes.mb_20}
            />
            <TextField
                label="Price"
                variant="outlined"
                fullWidth
                value={this.props.product_price}
                disabled
                className={classes.mb_20}
            />
            {/* <TextField
                label="Tax Class"
                variant="outlined"
                fullWidth
                value={this.props.selectedTax}
                disabled
                className={classes.mb_20}
            /> */}
            <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel id="tax_class">
                            Tax Class
                        </InputLabel>
                        <Select
                        labelId="tax"
                        id="tax_class"
                        value={0}
                        disabled
                        onChange={this.handleValueChange}
                        fullWidth
                        className={classes.mb_20}
                        name="tax_class"
                        labelWidth={70}
                        >
                        {/* <MenuItem value="1" >Enable</MenuItem> */}
                        <MenuItem value="0" >None</MenuItem>
                        </Select>
                    </FormControl>
            <Grid container spacing={3} className={classes.mb_20}>
                <Grid item xs={4}>
                    <TextField
                    label="Product Length"
                    variant="outlined"
                    fullWidth
                    value={this.props.product_length}
                    disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                    label="Product Width"
                    variant="outlined"
                    fullWidth
                    value={this.props.product_width}
                    disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                    label="Product Height"
                    variant="outlined"
                    fullWidth
                    value={this.props.product_height}
                    disabled
                    />
                </Grid>
            </Grid>
            {/* <TextField
                    label="Length Class"
                    variant="outlined"
                    fullWidth
                    value={this.props.product_length_class}
                    disabled
                    className={classes.mb_20}
                    /> */}
                    <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel id="length_class">
                            Length Class
                        </InputLabel>
                        <Select
                        labelId="length_class"
                        id="length_class"
                        value={this.props.product_length_class}
                        disabled
                        onChange={this.handleValueChange}
                        fullWidth
                        className={classes.mb_20}
                        name="length_class"
                        labelWidth={95}
                        >
                        <MenuItem value="0" >Centimeter</MenuItem>
                        <MenuItem value="2" >Millimeter</MenuItem>
                        <MenuItem value="3" >Inch</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel id="weight_class">
                            Weight Class
                        </InputLabel>
                        <Select
                        labelId="weight_class"
                        id="weight_class"
                        value={this.props.product_weight_class}
                        disabled
                        onChange={this.handleValueChange}
                        fullWidth
                        className={classes.mb_20}
                        name="weight_class"
                        labelWidth={95}
                        >
                        <MenuItem value="0" >Kilogram</MenuItem>
                        <MenuItem value="1" >Kilogram</MenuItem>
                        <MenuItem value="2" >Gram</MenuItem>
                        <MenuItem value="5" >Pound </MenuItem>
                        <MenuItem value="6" >Ounce </MenuItem>
                        <MenuItem value="7" >Millilitre </MenuItem>
                        <MenuItem value="8" >Litre </MenuItem>
                        <MenuItem value="9" >Metre </MenuItem>
                        <MenuItem value="10" >Millimetre </MenuItem>
                        </Select>
                    </FormControl>
                
                    <FormControl variant="outlined" className={classes.fullWidth}>
                        <InputLabel id="select_status" ref={this.StockLabel}>
                            Status
                        </InputLabel>
                        <Select
                        labelId="status"
                        id="select_status"
                        value={this.props.status}
                        disabled
                        onChange={this.handleValueChange}
                        fullWidth
                        className={classes.mb_20}
                        name="status"
                        labelWidth={50}
                        >
                        <MenuItem value="1" >Enable</MenuItem>
                        <MenuItem value="0" >Disable</MenuItem>
                        </Select>
                    </FormControl>
        </React.Fragment>
    )
}

export default product_description;
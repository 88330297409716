import { LOGIN_REQUEST,LOGIN_SUCCESS, LOGIN_FAILURE, LOG_OUT } from '../constant/index';

let user = JSON.parse(localStorage.getItem('seller'));

const loginState = user ? {
    loggedIn: true, user
} :
{}

 export function authentication(state=loginState, action){
    switch(action.type){
        case LOGIN_REQUEST:
        return {
            loggingIn : true,
            user : action.user
        };
        case LOGIN_SUCCESS:
        return {
            loggedIn : true,
            user : action.user
        };
        case LOGIN_FAILURE:
        return {};
        case LOG_OUT:
        return {};
        default : 
        return state;
    }
}
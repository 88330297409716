import React from 'react';
import {TextField,Button,CircularProgress,Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
function signupstep2(){
    return (
        <React.Fragment>
          <form onSubmit={this.submitForm}>
           <TextField 
              id="otp" 
              name="otp"
              label="OTP" 
              variant="outlined"
              value={this.state.otp} 
              onChange={this.handleChange}
              fullWidth 
              autoFocus
              error={this.state.isSubmitted && (this.state.otp.length < 4 || this.state.otpError )}
              style={{marginBottom:20}}
              helperText={this.state.isSubmitted && (this.state.otp.length < 4 || this.state.otpError ) ? "Enter valid OTP" : ""}
              inputRef={this.otpRef}
              />
            <TextField 
              id="email" 
              name="email" 
              type="text" 
              label="Email" 
              variant="outlined" 
              fullWidth 
              error={this.state.isSubmitted && (this.state.email.length < 1 || !regex.test(this.state.email))}
              value={this.state.email} 
              onChange={this.handleChange} 
              style={{marginBottom:20}}
              helperText={this.state.isSubmitted && (this.state.email.length < 1 || !regex.test(this.state.email)) ? "Enter valid Email" : ""}
              inputRef={this.emailRef}
              />
            <TextField 
              id="password" 
              type="password" 
              name="password"
              label="Set Password" 
              variant="outlined" 
              fullWidth 
              error={this.state.isSubmitted && this.state.password.length < 6}
              value={this.state.password} 
              onChange={this.handleChange} 
              style={{marginBottom:20}}
              helperText={this.state.isSubmitted && this.state.password.length < 6 ?"Password must be 6 digits long":""}
              inputRef={this.passwordRef}
              />
              <Button 
                variant="text" 
                style={{marginBottom:10}}
                disabled={this.state.isLoading}
                onClick={this.resendOTP}
              >
                Resend OTP
              </Button>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              style={{padding:'12px 0'}}
              type="submit"
              disabled={this.state.isLoading}
            >
            {
                this.state.isLoading 
                    ?
                <CircularProgress color="inherit" size={25} />
                :
                "Signup"
            }
              
            </Button>
            </form>
            <Snackbar open={this.state.open} anchorOrigin={{vertical:"top",horizontal:"center"}} >
              <Alert severity={this.state.hasError ? 'error':'success'} >
                  {
                    this.state.message
                  }
              </Alert>
            </Snackbar>
      </React.Fragment>
    )
}
export default signupstep2;
import React, { Component } from 'react';
import manageProducts from './ManageProducts.jsx';
import {authHeader} from '../../../../helper/auth-header';
import axios from 'axios';
import siteConfig from '../../../../config';
import {withStyles} from '@material-ui/core/styles';
import { saveSync } from 'save-file';
import json2xls from 'json2xls';

const apiUrl = siteConfig.apiUrl;

const styles = theme => ({
  heading:{
    display:"inline-block",
    fontWeight:400,
    fontSize:20,
    [theme.breakpoints.down('md')]: {
     fontSize:20,
    },
  },
  add_button:{
    float:'right',
    marginTop:8
  },
  b_heading:{
    fontSize:30,
    width:'100%',
    fontWeight:500,
    textAlign:'left'
  },
  sub_heading:{
    fontSize:12,
    width:'100%',
    textAlign:'left'
  },
  t_container:{
    borderBottomLeftRadius:0,
    borderTop:'1px solid #ccc'
  },
  search: {
    float:'right',
    position: 'relative',
    marginTop:10,
    borderRadius: 3,
    backgroundColor: '#eee',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'#eee',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    }
  },
  input: {
    display: 'none',
  },
  searchSpan:{
    background:'#1976d2',
    color:'#fff',
    padding:"5px 2px",
    borderRadius:5
  },
  pr_status :{
    padding:20,
    width:'100%',
    backgroundColor:"#FFF",
  },
  pr_status_active:{
    padding:20,
    width:'100%',
    backgroundColor:"#3f51b5",
    color:"#FFF"
  },
  progress:{
    position:"absolute",
    top:30,
    right:30
  },
  page_root:{
    flexShrink : '2'
  },
  spacer:{
    flex:0
  },
  searchloader:{
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'#eee',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    right:0,
    top:0,
    
  },
  mp_heading:{
    width:'100%',
    display:'inline-block',
    textAlign:'left',
    fontSize:16,
    marginLeft:10,
    marginRight:20,
  },
  mp_mob_heading:{
    margin: 0,
    display: "inline-block",
    fontSize: 22,
    fontWeight: 400,
    marginLeft: 5,
    marginBottom: 10,
    verticalAlign:'super'
  },
  backdrop:{
    zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
  },
  stickyDiv:{
    position: '-webkit-sticky',
  position: 'sticky',
  top: 60,
  background : '#fff',
  zIndex : '9',
  padding:20,
  boxShadow : '2px 2px 5px #ccc'
  },
  Deactive:{
    marginRight:5,
  },
  selectformat:{
    padding:0
  },
  editQuantity:{
    cursor:'pointer',
    color : 'blue'
  }
});


class ManageProducts extends Component {
  constructor(props){
    super(props);
    this.state={
      isLoading:false,
      isLoadingTable : false,
      productData:[],
      page:1,
      productCountData:[],
      item:0,
      status:"Active",
      search:"",
      selected:[],
      totalProduct:0,
      isSearching:false,
      isTotalRequired:0,
      download_as:"",
      allProductData:[],
      file : null,
      vendor_name : '',
      backdropOpen : false,
      rowsPerPage : 10,
      active_low: 0,
      active_good : 0,
      active_out : 0,
      inactive : 0,
      fast_good : 0,
      fast_low : 0,
      fast_out : 0,
      productCount : [],
      statusId : "2",
      ProductType : "Active",
      selectAll : false,
      priceUpdate : false,
      productUpdateId : 0,
      p_quantity : '',
      productIdtobeUpdated : 0,
    }
    
  }
    CancelToken = axios.CancelToken;
    source = this.CancelToken.source();
    abortController = new AbortController();

  getProductCountByStatus= async ()=>{
      const requestOptions = {
        method:"Get",
        headers : authHeader()
      }
      return await axios(apiUrl+'/product_count',requestOptions,{cancelToken:this.source.token})
      .then(data=>{
        this.setState({
          active_low: data.data.data[1].Count,
          active_good : data.data.data[0].Count,
          active_out : data.data.data[2].Count,
          fast_good : data.data.data[4].Count,
          fast_low : data.data.data[5].Count,
          fast_out : data.data.data[6].Count,
          inactive : data.data.data[3].Count,
          productCountData: data.data.data,
          totalProduct : this.state.ProductType=="Active" && this.state.statusId==2?data.data.data[0].Count:
          this.state.ProductType=="Active" && this.state.statusId==1?data.data.data[1].Count:
          this.state.ProductType=="Active" && this.state.statusId==0?data.data.data[2].Count:
          this.state.ProductType=="Fast" && this.state.statusId==2?data.data.data[4].Count:
          this.state.ProductType=="Fast" && this.state.statusId==1?data.data.data[5].Count:
          this.state.ProductType=="Fast" && this.state.statusId==0?data.data.data[6].Count:
          this.state.ProductType=="Inactive" && this.state.statusId==0?data.data.data[3].Count:"", 
          IsError:false,
          isLoading:false
        },()=>{
          let product_count = [];
          data.data.data.forEach(item => {
            if(item.title == "Active Good Products" || item.title == "Active Low Products" || item.title == "Active Out Products"){
              product_count.push({
                Count : data.data.data[0].Count + data.data.data[1].Count + data.data.data[2].Count,
                title : "Active Products",
                sort : "Active"
              })
            }else if(item.title == "Fast Active" || item.title == "Fast Low" || item.title == "Fast Out"){
              product_count.push({
                Count : data.data.data[4].Count + data.data.data[5].Count + data.data.data[6].Count,
                title : "Moving Products",
                sort : "Fast"
              })
            }else{
              product_count.push({
                Count : data.data.data[3].Count,
                title : "Disabled Products",
                sort : "Inactive"
              })
            }
          })
          let unique = this.unique(product_count,['Count','title','sort']);
          function sortArray(array, property, direction) {
            direction = direction || 1;
            array.sort(function compare(a, b) {
                let comparison = 0;
                if (a[property] > b[property]) {
                    comparison = 1 * direction;
                } else if (a[property] < b[property]) {
                    comparison = -1 * direction;
                }
                return comparison;
            });
            return array; // Chainable
        }
          let sortedUnique = sortArray(unique, "sort"); 
          // console.log(sortedUnique);
          this.setState({
            productCount : sortedUnique
          })
        })
      })
      .catch(error=>{
        this.setState({
          IsError:true,
          isLoading:false,
          message:error.response ? error.response.data.message : 'network error'
        })
      })
  }
  getRelatedProducts=(e)=>{
    let product_type = e.currentTarget.getAttribute('product_type');
    let status_id = e.currentTarget.id;
    // console.log(product_type);
    this.setState({
      statusId : status_id,
      ProductType : product_type,
      isTotalRequired : 1,
      status : product_type,
      page : 1,
      priceUpdate : false,
      productUpdateId : 0,
      p_quantity : '',
      productIdtobeUpdated : 0,
    },()=>{
      this.getProducts();
      this.getProductCountByStatus();
    })
  }

  unique=(arr, keyProps)=> {
    return Object.values(arr.reduce((uniqueMap, entry) => {
      const key = keyProps.map(k => entry[k]).join('|');
      if (!(key in uniqueMap)) uniqueMap[key] = entry;
      return uniqueMap;
    }, {}));     
  }
  getVendor=()=>{
    const requestOptions = {
      method : 'get',
      headers : authHeader()
    }
    return axios(apiUrl+'/get_vendor_name',requestOptions)
    .then(data=>{
      this.setState({
        vendor_name : data.data.data[0].vendor_name
      })
    })
    .catch(error=>console.log(error))
  }
  componentDidMount(){
    this.setState({
      isTotalRequired : 1,
      isLoading : true
    },()=>{
      this.getProductCountByStatus();
      this.getProducts();
      this.getVendor();
    })
    
  }
  getProducts = async () => {
    this.setState({
      isLoadingTable :true,
      selected: []
    })
    const requestOptions = {
      method:"Post",
      headers: authHeader(),
      data:{
        status :this.state.statusId,
        product_type : this.state.ProductType,
        search : this.state.search,
        required_total_count : this.state.isTotalRequired,
        item : this.state.rowsPerPage
      }
    }
    // console.log(this.state.isTotalRequired);
    return await axios(apiUrl+'/getProducts/'+this.state.page,requestOptions,{cancelToken:this.source.token})
    .then(data=>{
      this.setState({
        productData:data.data.data.result,
        allProductData : data.data.data.total_product,
        totalProduct: this.state.isTotalRequired === 1 ? data.data.data.total_count : this.state.totalProduct,
        isLoadingTable:false
      })
    })
    .catch(error=>{
      this.setState({
        IsError:true,
        isLoading:false,
        message:error.response ? error.response.data.message : 'network error'
      })
    })
  }
  GetStatusProduct=(e)=>{
    let buttonTitle = e.currentTarget.getAttribute('title');
    let item = e.currentTarget.getAttribute('id');
    let count = e.currentTarget.getAttribute('count');
    let title = buttonTitle.split(' ')[0];
    this.setState({
      item:item,
      totalProduct:parseInt(count),
      search:"",
      isSearching:false
    })
    console.log(title);
    if(title==="Active" || title==="Inactive" || title==="Out" || title === "Low"){
        this.setState({
          status : title,
          page:1
        },()=>{
          this.getProducts()
        })
    }else{
      this.setState({
        isLoadingTable:true
      },()=>{
          this.setState({
            isLoadingTable:false,
            productData:[]
          })  
      })
    } 
  }
  handleSelectAllChange=()=>{
    this.setState({
      selectAll : !this.state.selectAll
    })
  }
  pageChangeHandler=(e)=>{
    let title = e.currentTarget.title;
    if(title==='Next page'){
      this.setState({
        page: this.state.page +1,
        isTotalRequired:0,
        selected:[]
      },()=>this.getProducts())
    }else{
      this.setState({
        page: this.state.page -1,
        isTotalRequired:0,
        selected:[]
      },()=>this.getProducts())
    }
  }

  handleClick = (event,product_id) => {
    const selectedIndex = this.state.selected.indexOf(product_id);

    let newSelected = [];

    if(selectedIndex === -1){
      newSelected= newSelected.concat(this.state.selected,product_id)
    }else if(selectedIndex === 0){
      newSelected = newSelected.concat(this.state.selected.slice(1));
    }else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }
    this.setState({
      selected : newSelected
    })
  }
   isSelected = name => this.state.selected.indexOf(name) !== -1;

   onSelectAllClick=(e)=>{
     if(e.target.checked){
       const newSelecteds = this.state.productData.map(n=>n.product_id);
       this.setState({
         selected : newSelecteds
       })
       return;
     }
     this.setState({
       selected:[]
     })
   }
   searchProductHandler=(e)=>{
    if(e.target.value.length > 0){
      this.setState({
        search : e.target.value,
        isSearching:true,
        isTotalRequired:1,
        page:1
      },()=>this.getProducts()
      )
    }else{
      this.setState({
        isSearching : false,
        search : e.target.value,
        isTotalRequired : 1,
        page : 1
      },()=>this.getProducts())
    }
    
   }
   handleDownloadChange=(e)=>{
     if(e.target.value){
       this.setState({
         download_as : e.target.value
       },()=>{
         this.handleDownload();
       })
     }else{
       
     }
   }
   componentWillUnmount(){
    this.source.cancel('operation cancelled')
   }
   productAction = async (e)=>{
    let action = e.currentTarget.getAttribute('act');
    const requestOptions={
      method : 'post',
      headers :authHeader(),
      data:{
        product_id : this.state.selected,
        action : action
      }
    }
    return axios(apiUrl+'/product_action',requestOptions)
    .then(data=>{
      if(data.data.data.result.length>0){
        this.setState({
          open : true,
          IsError : true,
          message : 'As transfer price is not set so you cannot active the products having product id : '+ data.data.data.result,
         },()=>{
          setTimeout(() => {
            this.setState({
             open: false,
             IsError : false,
             message : ""
            })
          }, 5000);
        })
      }else{
        this.setState({
          open : false
        })
      }
      this.setState({
        selected : []
      },()=>this.getProducts(),this.getProductCountByStatus())
    })
    .catch(error=>console.log(error))
  }

   

   sendNotification = (product_id) => {
     const requestOptions={
       method : 'get',
       headers : authHeader()
     }
     return axios(apiUrl+'/get_vendor_name',requestOptions).then(data=>{
      const requestOptions = {
        method : 'POST',
        headers : authHeader(),
        data : {
            template : "PRODUCTSUPDATED",
            name : data.data.data[0].vendor_name,
            product_id : product_id
        }
    }
    // console.log('reeqq',requestOptions);
    return axios(apiUrl+'/send-notification',requestOptions)
    .then(data=>data)
    .catch(error=>error)
       }).catch(error=>{
         console.log(error)
       })
}

   handleUpload=(e)=>{
     let user = localStorage.getItem('seller');
      let userData = JSON.parse(user)
     let user_id = JSON.parse(userData.user_id);
    //  console.log(user_id);
    //  return false;
    // console.log(e.target.files);
     if(e.target.files[0].name.includes('xls')){
        this.setState({
            file : e.target.files[0],
            backdropOpen :true
        },async ()=>{
          let formdata = new FormData();
          formdata.append('file',this.state.file);
          formdata.append('user_id',user_id);

          const requestOptions ={
            method : 'POST',
            headers : authHeader(),
            data : formdata
          }
          return axios(apiUrl+'/bulk_update',requestOptions)
          .then(data=>{
            this.setState({
              backdropOpen : false,
              open : true,
              message : data.data.message,
              IsError : false,
            },()=>{
              setTimeout(() => {
                this.setState({
                  open: false,
                  message : ''
                },()=>{if(data.data.data.length>0){
                    // console.log(data.data.data);
                    return this.getProductCountByStatus(),
                    this.getProducts(),
                    this.sendNotification(data.data.data)
                  }else{
                    return this.getProductCountByStatus(),
                    this.getProducts()
                  }}
                )
              }, 2000);
            })
          })
          .catch(err=>{
            this.setState({
              backdropOpen : false,
              open : true,
              message : err.response ? err.response.data.message : 'network error',
              IsError : true
            },()=>{
              setTimeout(() => {
                this.setState({
                  open: false,
                },()=>{
                  setTimeout(() => {
                    this.setState({
                      isError : false,
                      message : ''
                    })
                  }, 1000);
                })
              }, 3000);
            })
          })
        })
     }else{
       this.setState({
        IsError : true,
         open : true,
         message : 'upload xls file only',
         
       },()=>{
         setTimeout(() => {
           this.setState({
             open : false
           },()=>{
             setTimeout(() => {
               this.setState({
                 message : '',
                 IsError : false
               })
             }, 1000);
           })
         }, 3000);
       })
     }
   }

   handleDownload=()=>{
     this.setState({
      backdropOpen : true
     },()=>{
      let requestOptions = {
        method : "post",
        headers : authHeader(),
        data : {
                format : this.state.download_as,
                product_type : this.state.ProductType,
                status : this.state.statusId
           }
        }
        return axios(apiUrl+'/getProductFile',requestOptions)
        .then(data=>{
          // console.log(data);
          let response = data.data.data;
         
          // let fileName= "";
          // // let json = JSON.stringify(data.data)
          // // console.log(json);  
          // let buffer = Buffer.from(data.data.data.file);
          // fileName = data.data.data.fileName;
          // let readUTF8 = buffer.toString('utf8')
          
          // // console.log(buffer);
          // // return false;
         
          // let blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' })
          
          // console.log(fileName);
          // saveSync(blob, fileName);
        // FileSaver.saveAs(blob, fileName)


          // let xls = json2xls(response);
          // console.log(response);
          saveSync(response, 'ProductList.xls');
          this.setState({
            backdropOpen : false,
            download_as : ""
          })
        })
        .catch(error=>{
          console.log(error);
          this.setState({
            backdropOpen : false
          })
        })
     })
   }

   handleRowPerPage=(e)=>{
    //  console.log(e);
      this.setState({
        rowsPerPage : e.target.value,
        isTotalRequired : 1,
        page : 1
      },()=>{
        this.getProductCountByStatus();
      this.getProducts();
      // this.getVendor();
      })
   }
   handleChange = (e)=>{
     this.setState({
       [e.target.name] : e.target.value
     })
   }
   handleUpdatePrice = (e)=>{
     this.setState({
      priceUpdate : true,
      productUpdateId : e.currentTarget.id,
      p_quantity : e.currentTarget.getAttribute('quantity'),
      productIdtobeUpdated : e.currentTarget.getAttribute('productid')
     })
   }
   handleProductUpdate=(e)=>{
     let index = e.currentTarget.id;
     this.setState({
       priceUpdate : false,
     },()=>{
       const requestOptions = {
         method : 'post',
         headers : authHeader(),
         data : {
           product_id : this.state.productIdtobeUpdated,
           quantity : this.state.p_quantity
         }
       }
       return axios(apiUrl+'/update_product_quantity',requestOptions)
       .then(data=>{
          this.setState({
            productUpdateId : 0,
          },()=>{
            let productData = this.state.productData;
            productData[index].quantity = this.state.p_quantity;
            this.setState({
              productData
            },()=>{
              this.getProductCountByStatus();
            })
          })
       })
       .catch(error=>{
          console.log(error)
       })
     })
   }
  render() {
    return manageProducts.call(this);
  }
}

export default withStyles(styles)(ManageProducts);

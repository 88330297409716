import React from 'react';
import {Container,Grid,Card,CardHeader,CardContent,Typography,Tooltip,TextField,FormControl,InputLabel,Select,MenuItem,TableContainer,Table,TableHead,Paper,TableRow,TableCell,TableBody,Button,FormHelperText,CircularProgress,Snackbar, Backdrop,Dialog,Toolbar,Fab,DialogTitle,DialogContent,DialogActions,} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ViewListIcon from '@material-ui/icons/ViewList';
import WorkIcon from '@material-ui/icons/Work';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import {Link} from 'react-router-dom';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import Alert from '@material-ui/lab/Alert';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

// const dimension = ["Length","Width","Height","Weight"]

function orderDetail(){
    const {classes} = this.props;
    const {orderDetail} = this.state;
    let ship1 = orderDetail.shipping_firstname;
    let ship2 =  orderDetail.shipping_lastname;
    let ship_city = orderDetail.shipping_address_1;
    let ship_zone = orderDetail.shipping_zone;
    let ship_post = orderDetail.shipping_postcode;
    let date = '';
     
    let address =   ship1 + " "+
                    ship2+","+
                    ship_city+","+
                    ship_zone+","+
                    ship_post;
    // let month = new Date(orderDetail.date_added).getMonth();
    // let orderMonth = Number(month)+1;
    // // console.log((orderDetail.date_added).toISOString().substr(0, 10))    
    // let date =  new Date(orderDetail.date_added).getDate()+"/"+
    //             orderMonth+"/"+
    //             new Date(orderDetail.date_added).getFullYear();
            if(orderDetail.date_added){
                let order_date = new Date(orderDetail.date_added)
                                                
                let isoDate = new Date(order_date).toISOString().substr(0,10);
                let orderDay = new Date(isoDate).getDate();
                let orderMonth = Number(new Date(isoDate).getMonth()) + Number(1);
                let orderYear = new Date(isoDate).getFullYear();

                if(orderDay < 10){
                    orderDay = '0'+orderDay
                }else{
                    orderDay = orderDay
                }

                if(orderMonth < 10){
                    orderMonth = '0'+orderMonth
                }else{
                    orderMonth = orderMonth
                }
                date = orderDay+"/"+orderMonth+"/"+orderYear
            }

                return (
                    !this.state.orderFound
                            ?
                    <div>
                        <div style={{textAlign:'center',paddingTop:30}}>
                            <SentimentVeryDissatisfiedIcon style={{fontSize:200,color:"#ccc"}}/>
                        <h1 style={{fontSize:40,color:"#ccc",margin:'30px 0px'}}>
                        Order Details Not found
                        </h1>
                        <Link to='/manage-orders' style={{background:'#3f51b5',color:'#fff',textDecoration:'none',padding:'10px 15px',borderRadius:3,fontWeight:'bold',boxShadow:'2px 5px 7px #666'}}>Go to Order Page</Link>
                        </div>
                    </div>
                    :
        <React.Fragment>
            <Container className={classes.pt_25}>
                {
                    this.state.isLoading
                        ?
                        <>
                    <Skeleton variant="rect" height={150} width={350} style={{marginBottom:30,marginRight:50,display:'inline-block'}}/>
                    <Skeleton variant="rect" height={150} width={350} style={{marginBottom:30,marginRight:50,display:'inline-block'}}/>
                    <Skeleton variant="rect" height={150} width={350} style={{marginBottom:30,marginRight:50,display:'inline-block'}}/>
                    <Skeleton variant="rect" height={400} />
                        </>
                            :
                <React.Fragment>
                    {/* <Link to={{pathname:'manage-orders',state:{}}} color="primary" variant="contained" className={classes.backButton}>
                        <KeyboardBackspaceIcon style={{marginRight:5}} />
                        <span style={{verticalAlign:'super'}}>
                            Back to Orders
                        </span>
                        </Link> */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardHeader 
                            avatar={
                                <ShoppingCartIcon />
                            }
                            title="Order Details" 
                            classes={{root : classes.cardHeader}}
                            />
                            <CardContent>
                                <Tooltip title="Date Added" placement="top">
                                    <EventAvailableIcon />
                                </Tooltip>
                                <Typography className={classes.date}>{date}</Typography>
                            </CardContent>   
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardHeader 
                            avatar={
                                <PermContactCalendarIcon />
                            }
                            title="Shipping Address" 
                            classes={{root : classes.cardHeader}}
                            />
                            <CardContent>
                                <ul className={classes.shippingUL}>
                                    <li className={classes.list}>
                                        <span className={classes.spanHeading}>Buyers Name :</span>
                                        <span className={classes.text}>{this.state.orderDetail.shipping_firstname+ "  " + this.state.orderDetail.shipping_lastname}
                                        </span>
                                    </li>
                                    <li className={classes.list}>
                                        <div className={classes.displayFlex}>
                                            <div className={classes.spanAddress}>Address : </div> 
                        <span className={classes.text}>{ship1+" "+ship2},<br/>{ship_city},<br/>{ship_zone}-{ship_post}</span>
                                        </div>
                                            
                                    </li>
                                </ul> 
                            </CardContent>   
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardHeader 
                            avatar={
                                <WorkIcon />
                            }
                            title="Package Details" 
                            classes={{root : classes.cardHeader}}
                            />
                            <CardContent>
                                {
                                    (()=> {switch (this.state.orderDetail.order_status_id) {
                                        case 1:
                                        return  <div>
                                                    <p style={{width:'100%',textAlign:"left",fontSize:16}}>
                                                    Status : <span style={{background:'#5caf5a',color:"#fff",padding:5,borderRadius:5,}}>Acceptance Pending</span> 
                                                </p>
                                                    <Button 
                                                    variant="contained" 
                                                    color="primary"
                                                    id="15" 
                                                    className={classes.btn}
                                                    classes={{root:classes.accept}} 
                                                    startIcon={
                                                        <DoneIcon />
                                                    }
                                                    onClick={this.changeOrderStatus}
                                                    vendor_order_id = {this.state.orderDetail.vendor_order_id}
                                                    status="confirm"
                                                    >
                                                    Confirm Order
                                                    </Button>
                                                <Button 
                                                    variant="contained" 
                                                    color="secondary"
                                                    classes={{root:classes.reject}}
                                                    className={classes.can_btn}
                                                    onClick={this.changeOrderStatus}
                                                    id="8"
                                                    status=""
                                                    startIcon={
                                                        <CloseIcon />
                                                    }
                                                    vendor_order_id = {this.state.orderDetail.vendor_order_id}
                                                    >
                                                        Cancel Order
                                                    </Button>
                                                </div>
                                        case 15:
                                        return <div> 
                                            <p style={{width:'100%',textAlign:"left",fontSize:16}}>
                                                    Status : <span style={{background:'#5caf5a',color:"#fff",padding:5,borderRadius:5,}}>Book</span> 
                                                </p>
                                         <form onSubmit={this.shippingProductHandler}>
                                                 <React.Fragment>
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={6}>
                                                                    <Typography className={classes.form_label_style}><strong>Length</strong> (Decimal upto 2 places in centimeter [cm])</Typography>   
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField id={"outlined-basic"+"length"} 
                                                                    className={classes.mt_0} margin="dense" label="Length" variant="outlined" name="Length" onChange={this.handleInputChange} value={this.state.Length}
                                                                    inputRef={this.LengthRef}
                                                                    autoFocus
                                                                    error={this.state.submitted && !this.state.Length? true:false}
                                                                    helperText={this.state.submitted && !this.state.Length ? 'Length Required':''}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography className={classes.form_label_style}><strong>Width</strong> (Decimal upto 2 places in centimeter [cm])</Typography>   
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField id={"outlined-basic"+"Width"} className={classes.mt_0} margin="dense" label="Width" variant="outlined" name="Width" onChange={this.handleInputChange} value={this.state.Width}
                                                                    inputRef={this.WidthRef}
                                                                    error={this.state.submitted && !this.state.Width ? true:false}
                                                                    helperText={this.state.submitted && !this.state.Width ? 'Width Required':''}
                                                                    />
                                                                </Grid>
                                                                <Divider className={classes.m_5}/>
                                                                <Grid item xs={6}>
                                                                    <Typography className={classes.form_label_style}><strong>Height</strong> (Decimal upto 2 places in centimeter [cm])</Typography>   
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField id={"outlined-basic"+"Height"} className={classes.mt_0} margin="dense" label="Height" variant="outlined" name="Height" onChange={this.handleInputChange} value={this.state.Height}
                                                                    inputRef={this.HeightRef}
                                                                    error={this.state.submitted && !this.state.Height ? true:false}
                                                                    helperText={this.state.submitted && !this.state.Height ? 'Height Required':''}
                                                                    />
                                                                </Grid>
                                                                <Divider className={classes.m_5}/>
                                                                <Grid item xs={6}>
                                                                    <Typography className={classes.form_label_style}><strong>Weight</strong> (Decimal upto 2 places in Kilogram [kg])</Typography>   
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <TextField id={"outlined-basic"+"Weight"} className={classes.mt_0} margin="dense" label="Weight" variant="outlined" name="Weight" onChange={this.handleInputChange} value={this.state.Weight}
                                                                    inputRef={this.WeightRef}
                                                                    error={this.state.submitted && !this.state.Weight ? true:false}
                                                                    helperText={this.state.submitted && !this.state.Weight ? 'Weight Required':''}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Divider className={classes.m_5}/>
                                                            {
                                                                this.state.prevPurchaseInvoiceNumber.length > 0
                                                                        ?
                                                                <Grid container>
                                                                    <Grid item sm={8}>
                                                                    <div>
                                                                    <label
                                                                        style={{
                                                                            fontSize:13,
                                                                            fontWeight:'bold'
                                                                        }}
                                                                    >
                                                                        Purchase Invoice Number :
                                                                    </label> 
                                                                    <span 
                                                                        style={{
                                                                            fontSize: 13
                                                                        }}
                                                                    >
                                                                        {this.state.prevPurchaseInvoiceNumber}
                                                                    </span>
                                                                    <br/>
                                                                    <label
                                                                        style={{
                                                                            fontSize: 13,
                                                                            fontWeight:'bold'
                                                                        }}
                                                                    >
                                                                        Purchase Invoice Date : 
                                                                    </label> 
                                                                    <span
                                                                        style={{
                                                                            fontSize: 13
                                                                        }}
                                                                    >
                                                                        {this.state.prevPurchaseInvoiceDate}
                                                                    </span>
                                                                    <br/>
                                                                    {
                                                                        this.state.prevPurchaseInvoiceUrl
                                                                            ?
                                                                            <>
                                                                        <label
                                                                           style={{
                                                                            fontSize: 13,
                                                                            fontWeight:'bold'
                                                                        }} 
                                                                        >
                                                                            Purchase Invoice Url :
                                                                        </label> 
                                                                        <a 
                                                                        href=
                                                                            {this.state.prevPurchaseInvoiceUrl}
                                                                        target="_blank"
                                                                            >
                                                                           View File 
                                                                        </a>
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                    
                                                                </div>
                                                                    </Grid>
                                                                    <Grid item sm={4} style={{textAlign:'center'}}>
                                                                        <Button
                                                                            title="edit"
                                                                            color="primary"
                                                                            variant="contained"
                                                                            onClick={this.EditPurchaseInvoice}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                                
                                                                :
                                                                <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.OpenPurchaseInvoiceUpload}
                                                                    title="add"
                                                                >
                                                                    Set purchase Invoice
                                                                </Button>
                                                            }
                                                        </React.Fragment>
                                                    
                                                <FormControl variant="outlined" className={classes.formControl} error={this.state.submitted && !this.state.selectedAddress ? true : false}>
                                                    <InputLabel id="demo-simple-select-outlined-label">Select Warehouse</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={this.state.selectedAddress}
                                                    onChange={this.handleChange}
                                                    label="Select Warehouse"
                                                    name="selectedAddress"
                                                    fullWidth
                                                    inputRef={this.addressRef}
                                                    //disabled={!this.state.Length || !this.state.Width || !this.state.Height || !this.state.Weight || !this.state.prevPurchaseInvoiceNumber }
                                                    disabled={!this.state.Length || !this.state.Width || !this.state.Height || !this.state.Weight}
                                                    >                                                    
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {
                                                        this.state.AddressList.map(address=>(
                                                            <MenuItem key={address.address_id} value={address.address_id}>
                                                                {address.warehouse_address+","+address.waddress_city+","+address.waddress_state+","+address.waddress_pincode}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                    </Select>
                                                    {
                                                        this.state.submitted && !this.state.selectedAddress
                                                                  ?
                                                        <FormHelperText error>Address Required</FormHelperText>
                                                                    :
                                                                    null
                                                    }
                                                </FormControl>                                             
                                                {/*<Button fullWidth type="submit" color="primary" variant="contained" className={classes.ship_button}
                                                disabled={this.state.addressVerified && this.state.prevPurchaseInvoiceNumber ? false : true}
                                                >*/}
                                                <Button fullWidth type="submit" color="primary" variant="contained" className={classes.ship_button}
                                                disabled={this.state.addressVerified ? false : true}
                                                >
                                                {
                                                    this.state.isLoadingShippping
                                                                ?
                                                    <CircularProgress color="inherit" size={24} />
                                                                :
                                                                <>
                                                    <LocalShippingIcon className={classes.mr_10}/>
                                                    Packed and Ready to Ship
                                                    </>
                                                }
                                                </Button>
                                                
                                            </form>
                                            </div>
                                        case 17:
                                            return <div>
                                                <p style={{width:'100%',textAlign:"left",fontSize:16}}>
                                                    Status : <span style={{background:'#5caf5a',color:"#fff",padding:5,borderRadius:5,}}>Pickup Pending</span> 
                                                </p>
                                                <div>
                                                    <Button
                                                        variant="outlined" 
                                                        color="primary"
                                                        onClick={this.generateShippingLabel}
                                                        style={{marginBottom:10}}
                                                    >
                                                    Generate Shipping Label
                                                    </Button>
                                                    
                                                </div>
                                                <div>
                                                    <Button
                                                        variant="outlined" 
                                                        color="primary"
                                                        style={{display:'inline-block',marginBottom:10}}
                                                        onClick={this.generateInvoice}
                                                        // disabled={this.state.invoice_path.length>0 }
                                                        
                                                    >
                                                        Generate Invoice
                                                    </Button>
                                                </div>
                                                    <Button
                                                    variant="outlined" 
                                                    color="primary"
                                                    style={{display:'block',marginBottom:10}}
                                                    onClick={this.generateManifest}
                                                    disabled={!this.state.shippingLabel.length > 0}
                                                >
                                                 Generate Manifest
                                                </Button>
                                                
                                                
                                                
                                            </div>
                                        case 3 :
                                            return <div>
                                                <p style={{width:'100%',textAlign:"left",fontSize:16}}>
                                                    Status : <span style={{background:'#5caf5a',color:"#fff",padding:5,borderRadius:5,}}>In Transit</span> 
                                                </p>
                                                <Button 
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={this.trackOrder}
                                                    >
                                                    Track Order
                                                </Button>
                                            </div>
                                        case 18 : 
                                        return <div>
                                            <p style={{width:'100%',textAlign:"left",fontSize:16}}>
                                                    Status : <span style={{background:'#5caf5a',color:"#fff",padding:5,borderRadius:5,}}>delivered</span> 
                                                </p>
                                        </div>
                                        case 7 : 
                                        return <div>
                                            <p style={{width:'100%',textAlign:"left",fontSize:16}}>
                                                    Status : <span style={{background:'#5caf5a',color:"#fff",padding:5,borderRadius:5,}}>Cancelled</span> 
                                                </p>
                                        </div>
                                        case 8:
                                            return <div>
                                            <p style={{width:'100%',textAlign:"left",fontSize:16}}>
                                                    Status : <span style={{background:'#5caf5a',color:"#fff",padding:5,borderRadius:5,}}>Denied</span> 
                                                </p>
                                        </div> 
                                        case 9 : 
                                        return <div>
                                                <p style={{width:'100%',textAlign:"left",fontSize:16}}>
                                                    Status : <span style={{background:'#5caf5a',color:"#fff",padding:5,borderRadius:5,}}>RTO</span> 
                                                </p>
                                                </div>
                                        default:
                                        return null;
                                    }})()
                                }
                                
                                
                            </CardContent>   
                            
                        </Card>
                    </Grid>
                </Grid>
                <div className={classes.mt_20}>
                    <Card>
                        <CardHeader 
                            avatar={
                                <ViewListIcon />
                            }
                            title={"Order " + "(" +this.state.vendor_order_id + ")" }
                            classes={{root : classes.cardHeader}}
                        />
                        <CardContent>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="spanning table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Product</TableCell>
                                    <TableCell align="center">Model</TableCell>
                                    <TableCell align="center">Unit Price</TableCell>
                                    <TableCell align="center">Quantity</TableCell>
                                    <TableCell align="center">Net Amount</TableCell>
                                    <TableCell align="center">GST</TableCell>
                                    <TableCell align="center">Tax Amount</TableCell>
                                    <TableCell align="right">Total</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    this.state.productData.map((product,index)=>{
                                        // console.log(product)
                                        let tax = product.tax;
                                        let quantity = product.quantity;
                                        let finalTax = 0;
                                        let unitAmount = product.mrp > 0 ? product.transfer_price : product.transfer_price > 0 ? product.transfer_price : product.price
                                        let tax_amount = product.mrp > 0 ? product.tax_amount : product.transfer_price > 0 ? (product.transfer_price * tax*quantity)/100 : (product.price*tax*quantity)/100;
                                        // console.log(tax_amount,unitAmount)
                                        let net_amount = (product.quantity * unitAmount).toFixed(2);
                                        let fixedUnitAmount = Number(unitAmount).toFixed(2);
                                        let fixedTaxAmount = Number(tax_amount).toFixed(2);
                                        let total = product.mrp > 0 ? product.total : (Number(fixedTaxAmount) + Number(product.total)).toFixed(2);
                                        return <TableRow key={product.model+index}>
                                        <TableCell>{product.name}</TableCell>
                                        <TableCell align="center">{product.model}</TableCell>
                                        <TableCell align="center">{unitAmount}</TableCell>
                                        <TableCell align="center">{product.quantity}</TableCell>
                                        <TableCell align="center">{net_amount}</TableCell>
                                        <TableCell align="center">{tax}</TableCell>
                                        <TableCell align="center">{Number(tax_amount).toFixed(2)}</TableCell>
                                        <TableCell align="right">{total}</TableCell>
                                        </TableRow>
                                    })
                                }
                               
                                {
                                    this.state.PriceData.map((price,index)=>{
                                        return (
                                            <TableRow key={price.order_total_id+index}>
                                                {index !==0 ? null : <TableCell colSpan={6} rowSpan={5} style={{boxShadow:'0px 1px 1px #ccc'}} />}
                                                <TableCell colSpan={1}>{price.title}</TableCell>
                                                <TableCell align="right">{Number(price.value).toFixed(2)}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </div>
                </React.Fragment>
                }
                
            </Container>
            <Snackbar open={this.state.open}>
                <Alert severity={this.state.isError ? 'error':'success'}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={this.state.backdropOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            <Dialog open={this.state.modalopen} onClose={this.handleModalClose} fullWidth  classes={{paperFullWidth:classes.track_dialog}} className={classes.p_10}>
                 
                 <Toolbar className={classes.toolbar}>
                 <h2 className={classes.trackHeading}>Track Order</h2>
                 <Tooltip title="Close">
                     <Fab className={classes.closeIcon} size="small" onClick={this.handleModalClose} >
                         <CloseIcon />
                     </Fab>
                 </Tooltip>
             </Toolbar>
             {
                 this.state.isLoadingTracking
                             ?
                     <div className={classes.t_center}>
                         <CircularProgress/>
                     </div>
                             :
             <TableContainer className={classes.table}>
                 <Table>
                     <TableHead>
                         <TableRow>
                             <TableCell>Sr. No.</TableCell>
                             <TableCell align="left">Status</TableCell>
                             <TableCell align="center">Location</TableCell>
                             <TableCell align="center">Date</TableCell>
                         </TableRow>
                     </TableHead>
                     <TableBody>
                         {
                             this.state.trackingData.map((item,index)=>{
                                 let date = new Date(item.Time).getDate();
                                 let month = new Date(item.Time).getMonth();
                                 let Year = new Date(item.Time).getFullYear();
                                 let hour = new Date(item.Time).getHours();
                                 let newMonth = Number(month) + 1;
                                 let Tracktime = new Date(item.Time);
                                 // console.log('track',Tracktime)
                                 let newTime =  Tracktime.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', hour12: true })
                                 return <TableRow key={item.Remarks+index}>
                                     <TableCell className={classes.tableCellTrack}>
                                     {
                                         index===0
                                             ?
                                             null
                                             :
                                         <Divider orientation="vertical" className={classes.divider}/>
                                     }
                                         <Fab className={classes.trackIndex}>
                                             {this.state.trackingData.length-(index)}
                                         </Fab>
                                     </TableCell>
                                     <TableCell className={classes.tableCellTrack} align="left">
                                         <span>
                                             {item.Remarks}
                                         </span>
                                     </TableCell>
                                     <TableCell className={classes.tableCellTrack} align="center">
                                     <span>
                                     {
                                         item.Location
                                     }
                                     </span>
                                     </TableCell>
                                     <TableCell className={classes.tableCellTrack} align="center">
                                     {
                                         date + '/' + newMonth + '/' + Year + ' ' + newTime 
                                     }
                                     </TableCell>
                                 </TableRow>
                             })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
        </Dialog>
        <Dialog  open={this.state.purchaseInvoiceModalopen} onClose={this.ClosePurchaseInvoiceDialog} >
                        <DialogTitle id="form-dialog-title">Purchase Invoice Details</DialogTitle>
                        <DialogContent style={{width:420}}>
                            <form>
                                
                                <TextField 
                                    variant="outlined" 
                                    label="Purchase Invoice Number"
                                    autoFocus 
                                    value={this.state.purchaseInvoiceNumber} 
                                    onChange={this.handleInputChange} 
                                    name="purchaseInvoiceNumber" 
                                    fullWidth 
                                    style={{marginBottom:20}}
                                    inputRef={this.purchaseInvoiceNumber}
                                    error={this.state.p_invoiceSubmitted && !this.state.purchaseInvoiceNumber ? true : false}
                                    helperText = {this.state.p_invoiceSubmitted && !this.state.purchaseInvoiceNumber ? "Purchase Invoice Number is required":""}
                                />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                margin="normal"
                                id="date-picker-dialog"
                                label="Purchase Invoice Date"
                                format="MM/dd/yyyy"
                                value={this.state.selectedDate}
                                onChange={this.handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                format = "dd/MM/yyyy"
                                />
                                </MuiPickersUtilsProvider>
                                <div>
                                <label style={{fontSize:12,letterSpacing:'0.02px',color:"#777",margin:'20px 0 10px 0',display:'block'}}>Upload Purchase Invoice(optional3)</label>
                                </div>
                                <div>
                                    
                                <input
                                    accept="pdf/*"
                                    className={classes.input}
                                    id="contained-button-file"
                                    multiple
                                    type="file"
                                    onChange={this.handleInvoiceUpload}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" color="primary" component="span">
                                    Upload
                                    </Button>
                                </label>
                                {
                                    this.state.purchaseInvoicePdf !== null
                                            ?
                                    this.state.purchaseInvoicePdf.name
                                            :
                                            null
                                }
                                </div>
                                
                            </form>
                            <DialogActions >
                                <div style={{textAlign:"center",display:'block',width:'100%'}}>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        title="single"
                                        style={{marginRight:10}}
                                        onClick={this.handlePurchaseInvoiceUpload}
                                        >
                                            Save
                                    </Button>
                                    <Button 
                                        variant="contained" 
                                        color="primary" 
                                        onClick={this.ClosePurchaseInvoiceDialog}
                                        >
                                            Cancel
                                    </Button>
                                </div>
                        </DialogActions>
                        </DialogContent>
                    </Dialog>
        </React.Fragment>
    )
}
export default orderDetail;
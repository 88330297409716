import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';

function loginwith_forgot_password(){
    return (
        <Fragment>
            <Tooltip title="Click to go back" placement="left">
              <i className="material-icons back" onClick={()=>this.props.changeStep('password')}>
              keyboard_backspace
              </i>
              </Tooltip>
            <p style={{fontSize:16}}>Otp sent to {this.props.username}</p>
            <form onSubmit={this.handleSubmit}>
            <TextField
                label="otp"
                autoFocus
                name ="otp"
                variant="outlined"
                fullWidth
                style={{marginBottom:20}}
                inputRef={this.otpRef}
                value={this.state.otp}
                onChange={this.handleChange}
                error={this.state.submitted && (this.state.otp.length !==4 || this.state.otpError) }
                helperText={this.state.submitted && this.state.otp.length !==4?"Enter valid OTP":""}
            >
            </TextField>
            <TextField
                label="New password"
                variant="outlined"
                name="password"
                value={this.state.password}
                fullWidth
                style={{marginBottom:20}}
                type={this.state.show && this.state.id =='1' ? "text": "password"}
                onChange={this.handleChange}
                inputRef={this.passwordRef}
                error={this.state.submitted && this.state.password.length < 6}
                helperText={this.state.submitted && this.state.password.length < 6 ? "Password must be 6 digits long":""}
                InputProps={{
                    endAdornment : <InputAdornment position="end">
                    {
                        this.state.show && this.state.id == '1'
                            ?
                        <i className="material-icons visible" id="1" onClick={this.handleShow}>visibility_off</i>
                            :
                        <i className="material-icons visible" id="1" onClick={this.handleShow}>visibility</i>
                    }
                        
                    </InputAdornment>
                    
                }}
            >
            </TextField>
            <TextField
                label="Confirm password"
                name="cnf_password"
                variant="outlined"
                fullWidth
                value={this.state.cnf_password}
                onChange={this.handleChange}
                style={{marginBottom:10}}
                type="password"
                inputRef={this.cnfPasswordRef}
                error={this.state.submitted && (!this.state.cnf_password || this.state.password !== this.state.cnf_password)}
                helperText={this.state.submitted && this.state.password !== this.state.cnf_password ? "Password not match":""}
                
            >
            </TextField>
            <Button variant="text" style={{float:'right'}} onClick={this.resendOtp} >Resend OTP</Button>
            <Button 
                variant="contained"
                fullWidth
                color="primary"
                style={{padding:12}}
                type="submit"
            >
                {
                    this.state.isLoading
                        ?
                    <CircularProgress size="24px" color="inherit"/>
                        :
                    "Login"
                }
            </Button>
        </form>
        </Fragment>
    )
}
export default loginwith_forgot_password;
import React, { Component } from 'react';
import {Container,Paper,Stepper,Step,StepLabel} from '@material-ui/core';
import SignupNext1 from './SignupNext1/SignupNext1';
import SignupNext2 from './SignupNext2/SignupNext2';
import SignupNext3 from './SignupNext3/SignupNext3';

const steps = ["Business Information","Warehouse Details"," Bank Account Details"]

export default class SignupNext extends Component {
    state = {
        step: 1

    }
    formstep = () => {
        switch (this.state.step) {
            case 2:
                return <SignupNext2 previous={this.handlePrevious} next={this.handleNext}/>;
            case 3:
                return <SignupNext3 next={this.handleNext} previous={this.handlePrevious}/>;
            default:
                return <SignupNext1 next={this.handleNext}/>
        }
    }
    handleNext = () => {
        this.setState({
            step : this.state.step + 1
        })
    }
    handlePrevious = () => {
        this.setState({
            step : this.state.step - 1
        })
    }
  render() {
    return (
      <div style={{backgroundColor:'#ccc',padding:'50px 0'}}>
          <Container>
              <Paper style={{width:'85%',margin:'0 auto'}}>
                  <h1 style={{background:'#0e2a4c',color:"#fff",fontSize:'22px',textAlign:'center',padding:'15px 0'}}>Complete your seller profile</h1>
                    <Stepper alternativeLabel activeStep={this.state.step-1} style={{padding:'40px 0'}}>
                        {
                            steps.map(label=>(
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))
                        }
                    </Stepper>
                    <Container>
                        {
                            this.formstep()
                        }
                    </Container>
              </Paper>
          </Container>
      </div>
    );
  }
}

import React,{useState,useRef,useEffect} from 'react';
import { FormControl,InputLabel,Select,MenuItem,TextField } from '@material-ui/core';
import './ManageQuantity.css';

const ManageQuantity = (props) => {

    // console.log(props,":MANAGEQUANTITYTTT")
    const [mainIndex,setMainIndex]=useState(props.mainIndex)
    // console.log(mainIndex,"MAININDEX-------->")
    const[boxArray,setBoxArray]=useState(props.boxArray)
    // console.log(boxArray[mainIndex]?.data?.[0].quantity,"MAINBOXARRAY-------->Quantityt")
    const [quantity,setQuantity] = useState('');
    const [product,setProduct] = useState('');
    const quantity_ref = useRef(null);
    // console.log("---",props)

    const handleChange = (e) => {
        setProduct(e.target.value);
        quantity_ref.current.focus();
    }
    const handleQuantity = (e,id) => {
        setQuantity(e.target.value);
        // console.log(e.target.value,"---",product,'---',props.mainIndex,'---',id)
        let quantityChange=e.target.value
        props.handleQuantityUpdate({
                quantity : quantityChange,product,index : props.mainIndex,pr_index : id
            })
    }
    useEffect(()=> {
        setProduct(props.productData[props.productIndex].product_id)
    })
    return (
        <div className="quantity_update">
            <FormControl variant="outlined">
                <InputLabel id="box">Product</InputLabel>
                <Select
                    name="box"
                    labelId="box"
                    id="box"
                    value={product}
                    onChange={handleChange}
                    label="Product"
                    margin="dense"
                    style={{width:'140px'}}
                    >
                    {
                        props.productData.map((item,index)=>{
                            return <MenuItem key={item.product_name} value={item.product_id} disabled={product != item.product_id ? true : false}>
                                {item.product_name}
                            </MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <TextField
                variant="outlined"
                name="quantity"
                value={boxArray[mainIndex]?.data?.[props.productIndex].quantity?boxArray[mainIndex]?.data?.[props.productIndex].quantity:quantity}
                onChange={(e)=>handleQuantity(e,props.productIndex)}
                label="Quantity"
                margin="dense"
                style={{width:140,marginBottom:10}}
                ref={quantity_ref}
                error={props.submitted && !(quantity|| boxArray[mainIndex]?.data?.[0].quantity)}
            />
        </div>
    )
}
export default ManageQuantity;
import React from 'react';
import {Grid,Container,Paper, Typography,Divider,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Tooltip,IconButton,Badge} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {Link} from 'react-router-dom';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import AirlineSeatReclineExtraIcon from '@material-ui/icons/AirlineSeatReclineExtra';
import Alert from '@material-ui/lab/Alert';
import HelpIcon from '@material-ui/icons/Help';


function maindashboard(){
    const {classes} = this.props;
    return(
        <React.Fragment>
            <Container style={{marginTop:25}}>
            {
                this.state.isLoading
                        ?
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item md={3} xs={6}>
                            <Skeleton variant="rect" height={118} className={classes.br_5}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Skeleton variant="rect" height={118} className={classes.br_5}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Skeleton variant="rect" height={118} className={classes.br_5}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <Skeleton variant="rect"  height={118} className={classes.br_5}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.mt_20}>
                        <Grid item xs={6}>
                            <Skeleton variant="rect"  height={window.innerHeight - 350} className={classes.br_5}/>
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton variant="rect"  height={window.innerHeight - 350} className={classes.br_5}/>
                        </Grid>
                    </Grid>
                </React.Fragment>
                    :
                    <React.Fragment>

                        {
                            this.props.status == 1
                                    ?
                                    null
                                    :
                                    <>
                                <Alert severity="info" style={{width:'50%',margin:'0 auto',marginBottom:20,position:'sticky',top:90,zIndex:99}}>
                                     Your account approval is under process,
                                    You will be notified when the process is complete.
                                    {' '}<Link to="/support" style={{verticalAlign:'middle'}}>
                                        <Typography variant="body1" component="span">
  Contact us
</Typography>
                                 {/* <Tooltip title="help">
                                     <IconButton 
                                         aria-label="show 17 new notifications" 
                                         color="inherit"
                                         >
                                         <Badge 
                                             badgeContent={
                                                         this.props.notificationCount
                                                         } 
                                             color="secondary"
                                         >
                                             <HelpIcon 
                                                 color="action"
                                             />
                                         </Badge>
                                     </IconButton>
                                 </Tooltip> */}
                             </Link>
                                </Alert>
                                
                             </>
                        }


                 { this.props.status ==1 &&  <><Grid container spacing={3}>
                            {this.state.Countdata.length > 0
                                    ?
                            <React.Fragment>
                            <Grid item md={3} sm={12} style={{width:"100%"}}>
                                <Link to="/manage-orders" style={{textDecoration:'none'}}>
                                    <Paper className={classes.orderCount}>
                                        <div style={{display:'inline-block',width:'45%'}}>
                                        <h2 className={classes.count}>{this.state.Countdata[0].Count}</h2>
                                        <Typography className={classes.count_text}>{this.state.Countdata[0].text}</Typography>
                                        </div>
                                        <Divider orientation="vertical" style={{display:'inline-block'}}/>
                                        <div style={{display:'inline-block',marginLeft:15,width:'48%'}}>
                                        <Typography 
                                            variant="h4"
                                            className={classes.count}
                                            style={{fontSize:20,fontWeight:600}}
                                            >
                                                <span>&#8377;</span>
                                                    {this.state.Countdata[1].Count}
                                        </Typography>
                                        <Typography className={classes.count_text}>{this.state.Countdata[1].text}</Typography>
                                        </div>
                                    </Paper>
                                </Link>
                            </Grid>
                            <Grid item md={3} sm={12} style={{width:'100%'}}>
                                <Link to="/manage-orders" style={{textDecoration:'none'}}>
                                    <Paper className={classes.orderCount}>
                                        <h2 className={classes.count}>{this.state.Countdata[2].Count}</h2>
                                        <Typography className={classes.count_text}>{this.state.Countdata[2].text}</Typography>
                                    </Paper>
                                </Link>
                            </Grid>
                            <Grid item md={3} sm={12} style={{width:'100%'}}>
                                <Link to="/manage-products" style={{textDecoration:'none'}}>
                                <Paper className={classes.orderCount}>
                                    <h2 className={classes.count}>{this.state.Countdata[3].Count}</h2>
                                    <Typography className={classes.count_text}>{this.state.Countdata[3].text}</Typography>
                                </Paper>
                                </Link>
                            </Grid>
                            <Grid item md={3} sm={12} style={{width:'100%'}}>
                                <Link to="/manage-products" style={{textDecoration:'none'}}>
                                <Paper className={classes.orderCount}>
                                    <h2 className={classes.count}>{this.state.Countdata[4].Count}</h2>
                                    <Typography className={classes.count_text}>{this.state.Countdata[4].text}</Typography>
                                </Paper>
                                </Link>
                            </Grid>
                            </React.Fragment>
                                :null    
                        }
                            
                        
                    </Grid>
                    <Grid container spacing={3} className={classes.mt_20}>
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.latest_order_div}>
                                <h4 className={classes.heading}>Recent Orders</h4>
                                {
                                    this.state.orderList.length>0
                                                    ?
                                    <TableContainer component={Paper} style={{padding:0,height:window.innerHeight - 370}}>
                                        <Table className={classes.table} aria-label="simple table" stickyHeader >
                                            <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Image</TableCell>
                                                <TableCell align="center">Product Name</TableCell>
                                                <TableCell align="right">Quantity</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {this.state.orderList.map((row,index) => (
                                                <TableRow key={row.name+index}>
                                                <TableCell component="th" scope="row">
                                                    <img src={row.image} alt="product" style={{height:60,width:60,border:'1px solid #ccc'}}/>
                                                </TableCell>
                                                <TableCell align="center">{row.name}</TableCell>
                                                <TableCell align="center">{row.quantity}</TableCell>
                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <div style={{textAlign:'center'}}>
                                            <AirlineSeatReclineExtraIcon 
                                            style={{
                                                fontSize:100,
                                                marginTop:30,
                                                color:'#ccc'
                                            }}
                                            />
                                            <h2 style={{fontSize:32,fontWeight:400,color:"#ccc"}}>Hang on, Orders are coming soon</h2>
                                    </div>
                                }
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper className={classes.notification_div}>
                                <h4 className={classes.heading}>Low Stock Products</h4>
                                {
                                    this.state.lowStockProduct.length>0
                                            ?
                                            <TableContainer component={Paper} style={{padding:0,height:window.innerHeight - 370,maxHeight:window.innerHeight - 370}}>
                                            <Table className={classes.table} aria-label="simple table" stickyHeader >
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">Image</TableCell>
                                                  <TableCell align="center">Product Name</TableCell>
                                                  <TableCell align="right">Quantity</TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {this.state.lowStockProduct.map((row) => (
                                                  <TableRow key={row.name}>
                                                    <TableCell component="th" scope="row">
                                                      <img src={row.image} alt="product" style={{height:60,width:60,border:'1px solid #ccc'}}/>
                                                    </TableCell>
                                                    <TableCell align="center">{row.name}</TableCell>
                                                    <TableCell align="center">{row.quantity}</TableCell>
                                                    
                                                  </TableRow>
                                                ))}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        :
                                <div style={{textAlign:'center'}}>
                                    <SentimentVerySatisfiedIcon 
                                    style={{
                                        fontSize:100,
                                        marginTop:30,
                                        color:'#ccc'
                                    }}
                                    />
                                    <h2 style={{fontSize:32,fontWeight:400,color:"#ccc"}}>No Product is Out of Stock</h2>
                                </div>
                                }
                            </Paper>
                        </Grid>
                    </Grid></>}
                </React.Fragment>
            }
                
            </Container>
        </React.Fragment>
    )
}

export default maindashboard;
import React,{ Component } from 'react';
import { withStyles } from '@material-ui/core';
import dimension from './DimensionDialog.jsx';

const styles = (theme)=> ({
    root: {
      flexGrow: 1,
      position : 'sticky',
      top:-15,
      zIndex:9,
      background : '#fff'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
  });

class DimensionDialog extends Component{
    constructor(props){
        super(props);
        this.state = {
            isdimensionDialogOpen : false,
            value : 0,
            allidentical : false,
            box : 2,
            boxArray : [],
            submitted : false,
            length:0,
            width:0,
            height:0,
            weight:0,
            orderData :[]
        }
    }
    
    static getDerivedStateFromProps(props,state){
        return {
            isdimensionDialogOpen : props.open,
            length : props.length,
            width : props.width,
            height : props.height,
            weight : props.weight,
            orderData : props.orderData,
        }
    }
    componentDidMount(){

        let boxArray = [];
        for(let i=0;i<this.state.box;i++){
            boxArray.push({
                length : '',
                width:'',
                height: '',
                weight: '',
            })
        }
        this.setState({
            boxArray
        })
    }

    CloseDimensionDialog =()=>{
        this.setState({
            isdimensionDialogOpen : false,
            submitted : false,
            box : 2,
            value : 0
        },()=>{
            this.props.closeDimensionDialog()
        })
    } 
    handleTabChange=(e,value)=>{
        // console.log(this.state.orderData,"ORDER DATA IN TAB CHANGE",e.target.index,this.state,"STATE---PROPS",this.props)
        if(value === 0){
            this.setState({
                value : value,
                allidentical : false,
                submitted : false
            })
        }else{
            this.setState({
                value : value,
                allidentical : false
            },()=>{
                this.setState({
                    submitted : false,
                    box : 2
                },()=>{
                    let boxArray = [];
                    for(let i=0;i<this.state.box;i++){
                        boxArray.push({
                            length : '',
                            width:'',
                            height: '',
                            weight: '',
                            data : this.state.orderData[this.props.orderIndex].orderDetail.productData.map((item,index)=>{
                                // console.log(this.state.orderData[this.props.orderIndex].orderDetail.productData,"ORDERPRODUCTDATA")
                                return {
                                    "quantity" : "",
                                    "product_id" : ""
                                }
                            })
                        })
                    }
                    this.setState({
                        boxArray
                    })
                })
            })
        }
    }
    handleChange = (e) => {
        e.persist();
        this.setState({
            allidentical : !this.state.allidentical
        },()=>{
            if(e.target.checked){
                let boxArray = [
                    {
                        length : '',
                        width : '',
                        height : '',
                        weight : '',
                        data : this.state.orderData[this.props.orderIndex].orderDetail.productData.map((item,index)=>{
                            return {
                                "quantity" : "",
                                "product_id" : ""
                            }
                        })
                    }
                ];
                this.setState({
                    boxArray
                })
            }else{
                let boxArray = [];
                for(let i=0;i<this.state.box;i++){
                    boxArray.push({
                        length : '',
                        width:'',
                        height: '',
                        weight: '',
                        data : this.state.orderData[this.props.orderIndex].orderDetail.productData.map((item,index)=>{
                            return {
                                "quantity" : "",
                                "product_id" : ""
                            }
                        })
                    })
                }
                this.setState({
                    boxArray
                })
            }
        })
    }
    handleBoxChange = (e)=>{
        this.setState({
            box : e.target.value,
            mpsBoxCount:e.target.value
        }
        ,()=>{
            if(!this.state.allidentical){
                let boxArray = [...this.state.boxArray];
                for(let i=this.state.boxArray.length;i<this.state.box;i++){
                    boxArray.push({
                        length : '',
                        width:'',
                        height: '',
                        weight: '',
                        data : this.state.orderData[this.props.orderIndex].orderDetail.productData.map((item,index)=>{
                            return {
                                "quantity" : "",
                                "product_id" : ""
                            }
                        })
                    })
                }
                this.setState({
                    boxArray
                })
                if(this.state.box<this.state.boxArray.length){
                    let sub = Number(this.state.boxArray.length)-Number(this.state.box)
                    boxArray.splice(this.state.box,sub)
                    this.setState({
                        boxArray
                    })
                }
            }
        })
    }
    handleDimensionChange=(e)=>{
        this.props.handleDimensionChange({
            name : [e.target.name],value: e.target.value
        })
    }
    setDimension = (e,otherState) => {
        e.persist()
       
        this.setState({
            submitted : true
        },()=>{
            if(this.state.value === 0){

                if(this.state.submitted && this.state.length > 0 && this.state.width > 0 && this.state.height > 0 && this.state.weight > 0){
                    this.props.setDimension({
                        length : this.state.length,
                        width : this.state.width,
                        height : this.state.height,
                        weight : this.state.weight,
                        
                    })
                }
            }else{
console.log("MPS",this.state)
                if(this.state.submitted && this.state.boxArray.every(item => item.length > 0 && item.width > 0 && item.height > 0 && item.weight > 0 && item.data.every(item1=> item1.quantity >=0 && item1.product_id > 0))){
                    this.props.setDimension(this.state.boxArray,this.state)
                }
            }
        })
    }
    getDimension = (data)=>{
        let index = data.index;
        let value = data.value;
        let name = data.name;
        let boxArray = this.state.boxArray;
        boxArray[index][name] = value;
        this.setState(prevState=>{
            const boxArray=[...prevState.boxArray];
            boxArray[index].name=value;
            return {boxArray}
        })

    }
    getMpsDimension = (data) => {
        // e.persist()
        var mpsData = [...this.state.boxArray];
        let index = data.index;
        let internal_id = data.pr_index;
        let product_id = data.product;
        let quantity = data.quantity;
        
       mpsData[index].data[internal_id].quantity = quantity;
        mpsData[index].data[internal_id].product_id = product_id;
        this.setState({
            boxArray : mpsData
        })
    }
    render(){
        return dimension.call(this);
    }
}
export default withStyles(styles)(DimensionDialog);
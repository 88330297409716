import React, { Component } from 'react';
import { Avatar,Container,Typography,Paper,Card,CardActionArea,CardHeader,CardContent,CardMedia,Grid } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
// import Money from '../../assets/images/money.jpg';
import { grey } from '@material-ui/core/colors';
import { authHeader } from '../../helper/auth-header';
import siteconfig from '../../config';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';

const apiUrl = siteconfig.apiUrl;

const styles = theme => ({
    root: {
        maxWidth: 285,
        width:280
    },
    media: {
        height: 140,
        opacity:0.4,
    },
    heading :{
        fontSize:16
    },
    contact_details:{
        fontSize:13,
        width:'100%',
        textAlign:'left',
        margin: '0',
        fontWeight:'500'
    },
    icon:{
        color:'#666',
        fontSize : 20
    },
    contact_email:{
        verticalAlign:'top',
        marginTop:3,
        marginLeft:5,
        display:'inline-block'
    },
    avatar: {
        backgroundColor: '#519fd8',
      },
      skeleton:{
          marginTop: 20,
          marginRight:20,
          display:'inline-block'
      },
      blankDiv:{

      }
})



class Support extends Component{
    state = {
        supportInfo : [],
        isLoading :false,
        isError : false
    }
    componentDidMount(){
        this.getSupportInfo()
    }

    getSupportInfo = () => {
        this.setState({
            isLoading : true
        },()=>{
            const requestOptions = {
                method : 'GET',
                headers : authHeader()
            }
            return axios(apiUrl+'/support_contact_info',requestOptions)
            .then(data=>{
                this.setState({
                    supportInfo : data.data.data,
                    isLoading : false
                })
            }).catch(error=>{
                this.setState({
                    isError : true,
                    isLoading : false
                })
            })
        })
    }
    
    render(){
        const {classes} = this.props;
    
        return (
            
            <React.Fragment>
            
            <Container style={{marginTop:20}}>
                <Grid container spacing={4}>
                    {
                        this.state.isLoading    
                            ?
                        <Container>
                        <Skeleton variant="rect" width={280} height={250} className={classes.skeleton}/>
                        <Skeleton variant="rect" width={280} height={250} className={classes.skeleton}/>
                        <Skeleton variant="rect" width={280} height={250} className={classes.skeleton}/>
                        <Skeleton variant="rect" width={280} height={250} className={classes.skeleton}/>
                        </Container>  
                            :
                        this.state.supportInfo.length > 0
                                ?
(this.props.status ==0 ?
                        this.state.supportInfo.filter(item=>item.title==="Onboarding" || item.title==="Category Head").map(item=>{
                            return <Grid item key={item.title}>
                                        <Card className={classes.root}>
                                        <CardHeader
                                                avatar={
                                                <Avatar aria-label="recipe" className={classes.avatar}>
                                                    {item.title.split('')[0].toUpperCase()}
                                                </Avatar>
                                                }
                                                title={item.title}
                                                className={classes.heading}
                                                subheader={item.sub_title}
                                            />
                                            <CardActionArea>
                                                <CardContent>
                                                <p
                                                    className={classes.contact_details} 
                                                >
                                                    <PersonIcon 
                                                        className={classes.icon}
                                                    />
                                                    <span className={classes.contact_email}>
                                                        {item.contact_person_name}
                                                    </span>
                                                </p>
                                                <p
                                                    className={classes.contact_details}
                                                >
                                                    <EmailIcon 
                                                            className={classes.icon}
                                                        />
                                                    <span className={classes.contact_email}>
                                                        {item.contact_person_email}
                                                    </span>
                                                </p>
                                               {
                                                   item.contact_person_phone
                                                            ?
                                                            <p
                                                            className={classes.contact_details}
                                                        >
                                                            <PhoneIphoneIcon 
                                                                    className={classes.icon}
                                                                />
                                                            <span className={classes.contact_email}>
                                                                {item.contact_person_phone}
                                                            </span>
                                                        </p>
                                                            :
                                                            null
                                               }
                                                
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                        }):this.state.supportInfo.map(item=>{
                            return <Grid item key={item.title}>
                                        <Card className={classes.root}>
                                        <CardHeader
                                                avatar={
                                                <Avatar aria-label="recipe" className={classes.avatar}>
                                                    {item.title.split('')[0].toUpperCase()}
                                                </Avatar>
                                                }
                                                title={item.title}
                                                className={classes.heading}
                                                subheader={item.sub_title}
                                            />
                                            <CardActionArea>
                                                <CardContent>
                                                <p
                                                    className={classes.contact_details} 
                                                >
                                                    <PersonIcon 
                                                        className={classes.icon}
                                                    />
                                                    <span className={classes.contact_email}>
                                                        {item.contact_person_name}
                                                    </span>
                                                </p>
                                                <p
                                                    className={classes.contact_details}
                                                >
                                                    <EmailIcon 
                                                            className={classes.icon}
                                                        />
                                                    <span className={classes.contact_email}>
                                                        {item.contact_person_email}
                                                    </span>
                                                </p>
                                               {
                                                   item.contact_person_phone
                                                            ?
                                                            <p
                                                            className={classes.contact_details}
                                                        >
                                                            <PhoneIphoneIcon 
                                                                    className={classes.icon}
                                                                />
                                                            <span className={classes.contact_email}>
                                                                {item.contact_person_phone}
                                                            </span>
                                                        </p>
                                                            :
                                                            null
                                               }
                                                
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                        }))
                        :
                        <Paper 
                            style={{
                                    display:'block',
                                    width:'100%',
                                    paddingTop:70,
                                    textAlign:'center',
                                    height:300,
                                    marginTop:20
                                }}>
                            <SupervisorAccountIcon style={{fontSize: 100,color:'#666'}} />
                            <Typography variant="h4" style={{color:'#666'}}>No support team is assigned to you right now,</Typography>
                        </Paper>
                    }
                </Grid>
            </Container>
        </React.Fragment>
        )
    }
}

export default withStyles(styles)(Support);
import React,{ Component } from 'react';
import login_otp from './LoginWotp.jsx';
import { connect } from 'react-redux';
import { userActions } from '../../../actions';
import {history} from '../../../helper/history';
import axios from 'axios';
import siteConfig from '../../../config';

const apiUrl = siteConfig.apiUrl;


const styles = theme =>({
  back:{
    cursor:'pointer',
    position: 'absolute',
    top:18,
    padding:10,
    left:10,
    transition: '0.1s'
  }
})


class LoginWotp extends Component {
  constructor(props){
    super(props);
    this.state={
      otp:'',
      isLoading: false,
      errorLogin : '',
      submitted:false,
      open: false,
      otpError :false,
      IsError:false,
      message:''
    }
    this.otpRef = React.createRef('otpRef')
  }
  
  handleChange=(e)=>{
    this.setState({
        otp : e.target.value,
        otpError:false
    })
  }
  handleSubmit =(e)=>{
    e.preventDefault();
    this.setState({
      isLoading : true,
      submitted:true
    },()=>{
      if(this.state.submitted && this.state.otp){
          this.props.verifyAndLogin(this.props.username,this.state.otp,"").then(user=>{
            this.setState({
              isLoading:false
            },()=>{
              history.push('/dashboard');
            }) 
          }).catch(error=>{
            this.setState({
              isLoading:false,
              otpError:true
            },()=>{
              this.props.setOpen(true);
              this.props.setMessage(error.response ? error.response.data.message : 'network error');
              this.props.setError(true)
              this.otpRef.current.focus()
          })
        })
      }else{
        this.setState({
          isLoading:false
        },()=>{
          if(!this.state.otp){
              this.otpRef.current.focus();
          }
        })
      }
    }
  )}
  handleOpen =()=>{
    this.setState({
      open : true
    },()=>{
      setTimeout(() => {
        this.setState({
          open: false
        })
      }, 2000);
    })
  }

resendOtp = (e) =>{
  this.setState({
    isLoading:true,
    otp:''
  },async ()=>{
    const requestOptions = {
      method: "post",
      data : {
        key : "otp_mobile",
        username : this.props.username,
        user_id : "0"
      }
    }
    return axios(apiUrl+'/resend-otp',requestOptions)
    .then(data=>{
      this.setState({
        isLoading:false
      },()=>{
        this.props.setOpen(true);
        this.props.setMessage('OTP successfully sent to '+this.props.username);
        this.props.setError(false);
        this.otpRef.current.focus();
      })
    })
    .catch(error=>{
      this.setState({
        isLoading:false,
        otpError:true
      },()=>{
        this.props.setOpen(true);
        this.props.setMessage(error.response ? error.response.data.message : 'network error');
        this.props.setError(true)
      })
    })
  })
}

  render() {
    return login_otp.call(this,this.otpRef);
  }
}



const mapStateToProps = ( state ) => {
  const loggingIn = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  verifyAndLogin : userActions.verifyAndLogin
}

export default connect(mapStateToProps,actionCreators)(LoginWotp);
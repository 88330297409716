import React,{Component} from 'react';
import step1 from './step1.jsx';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import siteConfig from '../../../../../config';
import { authHeader } from '../../../../../helper/auth-header';

const apiUrl = siteConfig.apiUrl; 

const styles = () =>({
    selectCategory:{
        width:'85%',
        marginRight:10,
      },
      selectCategoryMobile:{
        width:'100%',
      },
      button:{
        width: 165,
        fontSize: 14,
        padding: 14
      },
      buttonMobile:{
        width:'100%',
        marginTop:20
      },
      heading:{
          display: 'inline-block',
          fontSize : 18,
          fontWeight: '500',
          color:"#333",
          marginLeft: 5,
          marginTop:0
      },
      list:{
          padding:0
      },
      listItem:{
          padding:7
      },
      cancelIcon:{
        backgroundColor:"#eaeaea",
        padding:"27px 15px",
        cursor:'pointer',
        position :'absolute',
        right:0
      },
      p_20:{
          padding:20
      },
      cat_paper:{
        width:270,
        position:"absolute",
        maxHeight:300,
        overflowY:"scroll"
      },
      cat_paper_1:{
        width:270,
        position:"absolute",
        left:'356px',
        maxHeight:300,
        overflowY:"scroll"
      },
      cat_paper_2:{
        width:270,
        position:"absolute",
        left:'626px',
        maxHeight:300,
        overflowY:"scroll"
      },
      cat_paper_3:{
        width:270,
        position:"absolute",
        left:'876px',
        maxHeight:300,
        overflowY:"scroll"
      },
      loading:{
          padding:20,
          textAlign:"center"
      }
})


class Step1 extends Component{
    state = {
        category_id : 0,
        loading:false,
        selected:'',
        isLoading:false,
        step:0,
        submitted:false,
        search :'',
        BrandList:[],
        selectedBrandName:'',
        selectedBrandId:0,
        selectedId : 0
    }

    setSearch = (e) => {
        this.setState({
            search : e.target.value
        })
    }

    getBrand = () => {
        this.setState({
            loading:true
        },()=>{
            const requestOptions = {
                method:'POST',
                headers : authHeader(),
                data : {
                    search : this.state.search
                }
            }
            return axios(apiUrl+'/get_brand',requestOptions)
            .then(data=>{
                this.setState({
                    loading:false,
                    BrandList : data.data.data
                })
            })
            .catch(error=>{
                this.setState({
                    loading:false
                })
            })
        })
    }
    

    setValue=(e)=>{
       this.setState({
        selectedBrandName : e.target.value
        },()=>{
            let index = this.state.BrandList.findIndex(x=>x.name === this.state.selectedBrandName)
            let brand = this.state.BrandList[index];
            if(brand){
              this.setState({
                  selectedBrandId : brand
              })
            }
       })
    }
    
      submitCategory=(e)=>{
        e.preventDefault();
        this.setState({
            submitted:true,
            selectedId : this.state.selectedBrandId.manufacturer_id
        },()=>{
          if(this.state.submitted && this.state.selectedId>0){
              return this.props.getProduct(this.state.selectedId)
          }  
        })
      }
      cancelEvent=(e)=>{
        //if(e.currentTarget.type==='button'){
            this.props.deleteCategory()
        //}
      }
    render(){
        return step1.call(this);
    }
}

export default withStyles(styles)(Step1);
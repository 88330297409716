import React,{Component} from 'react';
import { Paper,TextField,Button,Snackbar,InputAdornment,Slide,Dialog,DialogTitle,DialogContent,DialogActions,DialogContentText,CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import siteConfig from '../../../config';
import { authHeader } from '../../../helper/auth-header';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {history} from '../../../helper/history';

const apiUrl = siteConfig.apiUrl;

const style = theme => ({
    paper:{
        width:320,
        margin:"0 auto",
        padding:"20px",
        marginTop:50,
        textAlign:"center"
    },
    text_center:{
        textAlign:'center'
    },
    ch_pas_text:{
        fontSize:20,
        fontWeight:500,
        marginBottom:20,
        marginTop:0
    },
    mb_20:{
        marginBottom: 20,
    },
    visible:{
        cursor : 'pointer'
    },
    fullWidth:{
        width:'100%'
    }
})

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

class ChangePassword extends Component{
    constructor(props){
        super(props);
        this.state={
            old_password: '',
            new_password: '',
            confirm_password:'',
            snackbarOpen :false,
            message:'',
            hasError:false,
            ModalOpen:false,
            submitted:false,
            visibleOldPassword:false,
            visibleNewPassword:false,
            isLoading:false

        }
        this.oldPasswordRef = React.createRef('old_password');
        this.newPasswordRef = React.createRef('new_password');
        this.confirmPasswordRef = React.createRef('confirm_password');
    }
    handleChange=(e)=>{
        this.setState({
            [e.target.name] : e.target.value,
            hasError:false
        })
    }
    handleClose=()=>{
        this.setState({
            ModalOpen:false
        })
    }
    handleOldPasswordVisiblity=()=>{
        this.setState({
            visibleOldPassword:!this.state.visibleOldPassword
        })
    }
    handleNewPasswordVisiblity=()=>{
        this.setState({
            visibleNewPassword:!this.state.visibleNewPassword
        })
    }
    handleSubmit= async (e) =>{
        e.preventDefault();
        this.setState({
            submitted:true,
            isLoading:true
        },()=>{
            if(this.state.submitted && this.state.old_password && this.state.new_password && this.state.confirm_password && this.state.new_password=== this.state.confirm_password){
                const username = localStorage.getItem('seller_username');
                const requesOptions={
                    method:'post',
                    headers : authHeader(),
                    data : {
                        old_password : this.state.old_password,
                        new_password : this.state.new_password,
                        username : username
                    }
                }
                return axios(apiUrl+'/update_password',requesOptions)
                .then(data=>{
                   this.setState({
                       submitted:false,
                       hasError:false,
                       snackbarOpen:true,
                       message:data.data.message +', Redirected you to dashboard page',
                       isLoading:false,
                       old_password:'',
                       new_password:'',
                       confirm_password:''
                   },()=>{
                       setTimeout(() => {
                           this.setState({
                               snackbarOpen:false,
                               message:''
                           },()=>history.push('/dashboard'))
                       }, 3000);
                   }) 
                })
                .catch(err=>{
                    this.setState({
                        submitted:false,
                        isLoading:false,
                        hasError:true,
                        message:err.response.data.message,
                        snackbarOpen:true,
                        new_password:'',
                        confirm_password:''
                    },()=>{
                        setTimeout(() => {
                            this.setState({
                                snackbarOpen:false,
                                message:''
                            })
                        }, 3000);
                        this.oldPasswordRef.current.focus();
                    })
                })
            }else{
                this.setState({
                    isLoading:false
                },()=>{
                    if(this.state.submitted && !this.state.old_password){
                        this.oldPasswordRef.current.focus();
                    }else if(this.state.submitted && !this.state.new_password){
                        this.newPasswordRef.current.focus();
                    }else if(this.state.submitted && !this.state.confirm_password){
                        this.confirmPasswordRef.current.focus();
                    }
                })
            }
        })
    }
    render(){
        const {classes} = this.props;
        const {old_password,new_password,confirm_password,visibleOldPassword,submitted,visibleNewPassword,message,snackbarOpen,hasError,ModalOpen,isLoading} = this.state;
        return (
            <div>
                <Paper className={classes.paper}>
                    <h2 className={classes.ch_pas_text}>Change Password</h2>
                    <form onSubmit={this.handleSubmit}>
                        <TextField
                            name="old_password"
                            type={
                                visibleOldPassword ? "text" : "Password"
                            }
                            variant="outlined"
                            fullWidth
                            label="Old Password"
                            className={classes.mb_20}
                            onChange={this.handleChange}
                            value={old_password}
                            inputRef={this.oldPasswordRef}
                            error={(submitted && !old_password || hasError) ? true:false}
                            helperText={submitted && !old_password ? 'Old Password Required':''}
                            InputProps={{
                                endAdornment :  <InputAdornment 
                                                    position="end" 
                                                    className={classes.visible}
                                                    onClick={this.handleOldPasswordVisiblity}
                                                    >
                                                    {
                                                        visibleOldPassword
                                                            ?
                                                        <VisibilityOffIcon />
                                                            :
                                                        <VisibilityIcon />
                                                    }
                                                </InputAdornment>
                            }}
                        />
                        <TextField
                            name="new_password"
                            type={
                                visibleNewPassword ? "text" : "Password"
                            }
                            variant="outlined"
                            fullWidth
                            label="New Password"
                            className={classes.mb_20}
                            onChange={this.handleChange}
                            value={new_password}
                            inputRef={this.newPasswordRef}
                            error={submitted && !new_password?true:false}
                            helperText={submitted && !new_password?'New Password Required':''}
                            InputProps={{
                                endAdornment :  <InputAdornment 
                                                    position="end" 
                                                    className={classes.visible}
                                                    onClick={this.handleNewPasswordVisiblity}
                                                    >
                                                    {
                                                        visibleNewPassword
                                                            ?
                                                        <VisibilityOffIcon />
                                                            :
                                                        <VisibilityIcon />
                                                    }
                                                </InputAdornment>
                            }}
                        />
                        <TextField
                            name="confirm_password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            label="Confirm Password"
                            className={classes.mb_20}
                            onChange={this.handleChange}
                            value={confirm_password}
                            inputRef={this.confirmPasswordRef}
                            error={submitted && confirm_password !== new_password?true : false}
                            helperText={ submitted && confirm_password !== new_password ? 'Password not match':''}
                        />
                        <Button 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            disabled={isLoading}
                        >
                            {
                                isLoading
                                    ?
                                <CircularProgress 
                                    color="inherit"
                                    size={24}
                                />
                                    :
                                "Update"    
                            }
                        </Button>
                    </form>
                </Paper>
                <Snackbar open={snackbarOpen}>
                    <Alert severity={hasError ? 'error':'success'}>
                        {message}
                    </Alert>
                </Snackbar>
                <Dialog open={ModalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Verify OTP</DialogTitle>
                    <DialogContent>
                    <DialogContentText className={classes.fullWidth}>
                        Enter the OTP received on your registered Mobile Number.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        variant="outlined"
                        id="otp"
                        label="OTP"
                        type="text"
                        fullWidth
                    />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.handleClose} color="primary">
                        Subscribe
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withStyles(style)(ChangePassword);
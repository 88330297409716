import React, { Component } from 'react';
import step3 from './SignupNext3.jsx';
import { authHeader } from '../../../../helper/auth-header.js';
import axios from 'axios';
import siteConfig from '../../../../config';
// import {base64StringtoFile,extractImageFileExtensionFromBase64} from '../../../../helper/reusableUtils';
import {history} from '../../../../helper/history';
import { withStyles } from '@material-ui/core/styles';

const apiUrl = siteConfig.apiUrl;
const image_url = siteConfig.image_url;
const maxImageSize = 8e+6
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map(item=>{return item.trim()})

const style =()=>({
  formLabel:{
    marginBottom:20,
    display:'block'
  },
  errFormLabel:{
    color:'#f44336',
    marginBottom:20,
    display:'block'
  },
  outer_div:{
    border:'1px solid #ccc',
    padding:30,
    position:'relative',
    borderRadius:5,
    width:'45%'
  },
  err_outer_div:{
    border:'1px solid red',
    width:"45%",
    padding:30,
    position:'relative'
  },
  doc_div:{
    width:'200px',
    height:'200px',
    margin:'0 auto',
    textAlign:'center'
  },
  text:{
    fontSize:14,
    fontWeight:'normal',
    width:'100%',
    marginTop:10,
    color:"#999"
  },
})



class SignupStep3 extends Component {
    constructor(props){
      super(props);
      this.state={
        account_name:'',
        accountNo:'',
        ConfirmaccountNo:'',
        bank_name:'',
        bank_address:'',
        ifsc:'',
        bank_state:'',
        city:'',
        branch:'',
        submitted:false,
        isLoading:false,
        cheque_image: null,
        cheque_image_file : null,
        imgSrc : null,
        image_type:'',
        message : '',
        hasError:false,
        open:false,
        bank_details:[]
      }
      this.name = React.createRef('name');
      this.accountNo = React.createRef('account_no');
      this.confirm_account = React.createRef('confirm_account');
      this.bank_name = React.createRef('bank_name');
      this.ifsc = React.createRef('ifsc');
      this.State = React.createRef('State');
      this.city = React.createRef('city');
      this.branch = React.createRef('branch');
      this.bankAddress = React.createRef('bank_address');
    }
    componentDidMount(){
      this.getAccountDetails()
    }
    getAccountDetails = () =>{
      const requestOptions = {
        method : 'GET',
        headers : authHeader()
      }
      return axios(apiUrl+'/bank_details',requestOptions)
      .then(data=>{
        this.setState({
          bank_details : data.data.data
        },()=>{
          if(this.state.bank_details.length>0){
            this.setState({
              account_name : this.state.bank_details[0].account_holder_name,
              accountNo : this.state.bank_details[0].account_number,
              ConfirmaccountNo : this.state.bank_details[0].account_number,
              bank_name : this.state.bank_details[0].bank_name,
              ifsc : this.state.bank_details[0].ifsc,
              bank_address : this.state.bank_details[0].bank_address,
              bank_state : this.state.bank_details[0].state,
              city : this.state.bank_details[0].city,
              branch : this.state.bank_details[0].branch,
              cheque_image : this.state.bank_details[0].cancelled_cheque ? this.state.bank_details[0].cancelled_cheque : null 
            })
          }
        })
      })
      .catch(error=>{
        this.setState({
          hasError :true,
          open:true,
          message:"Error in fetching details"
        },()=>{
          setTimeout(() => {
            this.setState({
              open:false,
            })
          }, 3000);
        })
      })
    }
    handleChange =(e)=>{
      this.setState({
        [e.target.name]:e.target.value
      })
    }
    verifyFile =(files)=> {
      if(files){
          const currentFile = files
          const currentFileType = currentFile.type
          const currentFileSize = currentFile.size
          if(currentFileSize > maxImageSize){
            this.setState({
              hasError :true,
              open:true,
              message:"This file size should be less than 8MB"
            },()=>{
              setTimeout(() => {
                this.setState({
                  open:false,
                })
              }, 3000);
            })
              return false
          }
          if(!acceptedFileTypesArray.includes(currentFileType)){
            this.setState({
              hasError :true,
              open:true,
              message:'This file is not allowed.Only Images are allowed.'
            },()=>{
              setTimeout(() => {
                this.setState({
                  open:false,
                })
              }, 3000);
            })
              return false
          }
          return true
      }
  }
    handleChequeUpload=(e)=>{
      const cheque = e.target.files[0];
      const isVerified = this.verifyFile(cheque);
      if(isVerified){
        this.setState({
          imgSrc:cheque,
          image_type : 'cheque'
        },()=>{
          this.uploadCheque()
        })
      }
    }



    getImage = (e) => {
      let file = e.target.files[0];
      const isVerified = this.verifyFile(file);
      if(isVerified){
          let reader = new FileReader();

          reader.onloadend = () => {
              this.setState({
                  cheque_image : reader.result
              })
          }
          if(file){
              reader.readAsDataURL(file)
              this.setState({
                  cheque_image : reader.result,
                  cheque_image_file : file
              },()=>console.log(this.state.cheque_image_file))
          }
      }else{
          this.setState({
              isError :true,
              open:true,
              message:'This file is not allowed.Only Images are allowed.'
            },()=>{
              setTimeout(() => {
                this.setState({
                  open:false,
                  isError:false,
                  message:""
                })
              }, 3000);
            })
      }
  }


  updateBankDetails = (e) => {
    e.preventDefault();
      this.setState({
        submitted: true,
        isLoading: true
      },()=>{
        if(this.state.submitted && this.state.account_name && this.state.accountNo && this.state.ConfirmaccountNo && this.state.accountNo === this.state.ConfirmaccountNo && this.state.bank_name && this.state.bank_address && this.state.ifsc && this.state.bank_state && this.state.city && this.state.branch && this.state.cheque_image_file){

          let seller = JSON.parse(localStorage.getItem('seller'));
          let seller_id = seller.user_id;
          let formData = new FormData();
          formData.append('image_type','cheque');
          formData.append('user_id',seller_id);
          formData.append('proof',this.state.cheque_image_file);
          let requestOptions = {
            method: 'POST',
            headers : authHeader(),
            data : formData
          }
          return axios(apiUrl+'/upload_cheque',requestOptions)
          .then(data=>{
            const requestdata = {
              method: 'POST',
              headers: authHeader(),
              data: {
                account_holder_name : this.state.account_name,
                account_number: this.state.accountNo,
                ifsc : this.state.ifsc,
                bank_name : this.state.bank_name,
                bank_address : this.state.bank_address,
                city : this.state.city,
                state : this.state.bank_state,
                branch : this.state.branch,
                cancelled_cheque : data.data.data,
                is_default : '1'
              }
            }
            return axios(apiUrl+'/update-bank-details',requestdata)
            .then(data=>{
              this.setState({
                isLoading : false
              },()=>history.replace('/dashboard'))
            })
            .catch(error=>{
              this.setState({
                hasError:true,
                open:true,
                message:error.response?error.response.data.message:"Network Error",
                isLoading : false
              },()=>{
                setTimeout(() => {
                  this.setState({
                    open:false
                  })
                }, 3000);
              })
            })
          })
          .catch(error=>{
            this.setState({
              isLoading:false,
              hasError:true,
              open:true,
              message:error.response?error.response.data.message:"Server Error"
            },()=>{
              setTimeout(() => {
                this.setState({
                  open:false
                })
              }, 3000);
            })
          })  
        }else{
          this.setState({
            isLoading:false
          },()=>{
            if(!this.state.name){
              this.name.current.focus();
            }else if(!this.state.accountNo){
              this.accountNo.current.focus();
            }else if(!this.state.ConfirmaccountNo){
              this.confirm_account.current.focus();
            }else if(this.state.accountNo !== this.state.ConfirmaccountNo){
              this.confirm_account.current.focus();
            }else if(!this.state.bank_name){
              this.bank_name.current.focus();
            }else if(!this.state.bank_address){
              this.bankAddress.current.focus();
            }else if(!this.state.ifsc){
              this.ifsc.current.focus();
            }else if(!this.state.bank_state){
              this.State.current.focus();
            }else if(!this.state.city){
              this.city.current.focus();
            }else if(!this.state.branch){
              this.branch.current.focus();
            }
          })
        }
    })
  }
  render() {
    return step3.call(this,this.name,this.accountNo,this.confirm_account,this.bank_name,this.ifsc,this.State,this.city,this.branch,this.bankAddress);
  }
}
export default withStyles(style)(SignupStep3);
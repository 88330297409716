import React, { Component } from "react";
import manageOrders from "./ManageOrders.jsx";
import { authHeader } from "../../../../helper/auth-header";
import axios from "axios";
import siteConfig from "../../../../config";
import { withStyles } from "@material-ui/core/styles";
import mergeBetweenPDF from "../../../../helper/pdfProvider";
import { saveSync } from "save-file";
import { withSnackbar } from "notistack";
import moment from "moment";

const apiUrl = siteConfig.apiUrl;

const styles = (theme) => ({
  pr_status: {
    padding: 20,
    width: "100%",
    backgroundColor: "#FFF",
  },
  pr_status_active: {
    padding: 20,
    width: "100%",
    backgroundColor: "#3f51b5",
    color: "#FFF",
  },
  progress: {
    position: "absolute",
    top: 30,
    right: 30,
  },
  st_button: {
    padding: "10px 20px",
    marginRight: 10,
  },
  accept: {
    color: "#156734",
    border: "1px solid #156700",
    marginRight: 10,
    "&:hover": {
      color: "#156734",
      border: "1px solid #156734",
    },
  },
  reject: {
    color: "#e03333",
    border: "1px solid #e03333",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #e03333",
    },
  },
  track_dialog: {
    maxWidth: 900,
  },
  toolbar: {
    boxShadow: "0px 0px 5px #ccc",
  },
  closeIcon: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  tableCell: {
    border: "none",
    position: "relative",
  },
  t_center: {
    textAlign: "center",
    margin: "40px 0",
  },
  trackIndex: {
    color: "#fff",
    backgroundColor: "#4eaece",
    zIndex: 1,
  },
  divider: {
    position: "absolute",
    width: 3,
    height: 50,
    left: 43,
    top: -21,
    zIndex: 0,
    background: "#ccc",
  },
  trackHeading: {
    fontWeight: "400",
  },
  rd_ship_button: {
    color: "#fff",
    textDecoration: "none",
    "&: hover": {
      color: "#fff",
      textDecoration: "none",
    },
  },
  mr_5: {
    marginRight: 5,
  },
  stickyDiv: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 60,
    background: "#fff",
    zIndex: "9",
    padding: 20,
    boxShadow: "2px 2px 5px #ccc",
  },
  mul_span: {
    display: "inline-block",
    marginTop: 0,
    padding: 4,
  },
  setDialog: {
    display: "inline-block",
    cursor: "pointer",
    fontSize: 12,
    textDecoration: "underline",
    color: "#3c17d0",
  },
  address: {
    width: 400,
  },
  page_root: {
    flexShrink: "2",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  bounce: {
    alignSelf: "flex-end",
    animationDuration: "0.5s",
    animationIterationCount: "infinite",
    backgroundColor: "#F44336",
    margin: "0 auto 0 auto",
    transformorigin: "bottom",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  checkStep: {
    "& span": {
      "&:hover": {
        color: "#000 !important",
        "& svg": {
          opacity: "0 !important",
        },
      },
    },
  },
  editDimension: {
    display: "inline",
    fontSize: 14,
    marginLeft: 10,
    color: "#3f51b5",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  tablecell: {
    border: "1px solid #ccc !important",
  },
  input: {
    display: "none",
  },
});

class ManageOrders extends Component {
  constructor(props) {
    super(props);
    console.log(this.props,"props data here")
    this.state = {
      isLoading: false,
      orderCount: [],
      item_id: 0,
      logined_vendor_id: 0,
      order_status_id: 0,
      orderData: [],
      totalOrders: 0,
      page: 1,
      newCount: 0,
      shipCount: 0,
      deliveredCount: 0,
      deniedCount: 0,
      RTOCount: 0,
      RTODlvdCount: 0,
      cancelCount: 0,
      acceptCount: 0,
      pendingPickupCount: 0,
      pendingManifestCount: 0,
      isLoadingStatus: false,
      open: false,
      modalopen: false,
      message: "",
      isLoadingTab: false,
      vendor_order_id: 0,
      trackingData: [],
      isError: false,
      isLoadingTracking: false,
      selected: [],
      TabValue: 0,
      isdimensionDialogOpen: false,
      length: 0,
      width: 0,
      height: 0,
      weight: 0,
      quantity: 0,
      product_id: 0,
      isLoadingDimension: false,
      submitted: false,
      multiple: false,
      index: 0,
      addressList: [],
      selectedAddress: "",
      rowsPerPage: 10,
      AddressModal: false,
      isdimensionAvailable: false,
      isShipping: false,
      multipleProduct: false,
      bookOrderArray: [],
      bulkShipping: false,
      order_type: "new",
      buttonTitle: "",
      isLoadingBulkManifest: false,
      isGeneratingInvoice: false,
      backdropOpen: false,
      DownloadInvoiceData: [],
      mergedPdf: null,
      dimensionCheck: false,
      v_o_id: 0,
      orderInc: "desc",
      orderBy: "order_id",
      shippingLabelData: [],
      detailId: "",
      orderDetailVisible: false,
      isLoadingDetail: false,
      purchaseId: "",
      prevPurchaseId: "",
      openPurchaseInvoice: false,
      p_invoice: "",
      p_submitted: false,
      action: "",
      search_by: "",
      search: "",
      searchOrderVisible: false,
      searchData: [],
      isLoadingOrder: false,
      orderSubmitted: false,
      apiError: false,
      blankResponse: false,
      regenerateShipping: false,
      regenerateInvoice: false,
      readyToShip: false,
      addressCheckedVendorOrderId: [],
      purchaseInvoiceModalopen: false,
      uploadInvoiceError: false,
      shippingAddressModalopen: false,
      purchaseInvoiceNumber: "",
      selectedDate: new Date(),
      purchaseInvoicePdf: null,
      p_invoiceSubmitted: false,
      selectedVendorOrderId: "",
      purchaseActiontitle: "",
      ManifestData: [],
      downloadmerged: false,
      payment_method: "",
      invoiceLoading: false,
      cancelReasonDialogOpen: false,
      cancel_reason: "",
      cancel_order_loading: false,
      cancel_other: "",
      orderProductData: [],
      productSelected: [],
      cancel_error: false,
      cancel_error_message: "",
      shipping_firstname: "",
      shipping_lastname: "",
      shipping_address_1: "",
      shipping_city: "",
      shipping_zone: "",
      shipping_country: "",
      shipping_postcode: "",
      mpsFlag: false,
      mpsSuborder: [],
      mpsDimension: [],
      mpsWeight: "",
      mpsBoxCount: "",
      mpsBoxIndex: "",
      messagePopUp:false, // add new success message key
      isSuccess: true,
      showMessage: "",
      cancel_order_error: false,
      cancel_order_error_message: "",
      success_order:false,
      success_order_message:""
    };
    this.length = React.createRef("length");
    this.width = React.createRef("width");
    this.height = React.createRef("height");
    this.weight = React.createRef("weight");
    this.purchase_invoice = React.createRef("purchase_invoice");
    this.searchOrder = React.createRef("search_order");
    this.purchaseInvoiceNumber = React.createRef("purchase_invoice");
    this.other_reason = React.createRef("other_reason");
    this.modalHeading = React.createRef("modal_heading");
    this.getOrders = this.getOrders.bind(this);
  }
  componentDidMount() {
    let loginedUserDetails = JSON.parse(localStorage.getItem("seller"));
    window.scrollTo(0, 0);
    this.setState(
      {
        order_status_id: "1",
        item_id: 1,
        logined_vendor_id: loginedUserDetails.user_id,
        buttonTitle: "new",
        isLoading: true,
        isLoadingTable: true,
        search_by: "order_id",
      },
      () => {
        this.getOrderCount();
        this.getOrders();
        this.getWarehouseAddress();
      }
    );
  }
  handleSearchChange = (e) => {
    this.setState(
      {
        search_by: e.target.value,
      },
      () => this.searchOrder.current.focus()
    );
  };

  getTrackandRelatedOrders = (e) => {
    let order_type = e.currentTarget.getAttribute("order_type");
    let title = e.currentTarget.getAttribute("title");
    let id = e.currentTarget.id;
    // console.log(id);
    this.setState(
      {
        item_id: id,
        order_status_id: id,
        page: 1,
        isLoadingTable: true,
        selected: [],
        order_type: order_type,
        buttonTitle: title,
        // dimensionIndex:''
      },
      () => {
        this.getOrders();
        this.getOrderCount();
      }
    );
  };

  unique = (arr, keyProps) => {
    return Object.values(
      arr.reduce((uniqueMap, entry) => {
        const key = keyProps.map((k) => entry[k]).join("|");
        if (!(key in uniqueMap)) uniqueMap[key] = entry;
        return uniqueMap;
      }, {})
    );
  };

  getWarehouseAddress = () => {
    const requestOptions = {
      method: "get",
      headers: authHeader(),
    };
    return axios(apiUrl + "/seller_address", requestOptions)
      .then((data) => {
        // console.log(data);
        this.setState({
          addressList: data.data.data,
          selectedAddress:
            data.data.data.length > 1
              ? ""
              : data.data.data[0].seller_address_id,
        });
      })
      .catch((error) => console.log(error));
  };

  handleChange = (e) => {
    // console.log(e.target)
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  // handleAddressChange = (e) =>{
  //   this.setState({
  //     [e.target.name] : e.target.value
  //   })
  // }
  handleOpenMessage = () => {
    this.setState({
      messagePopUp:true
    })
  };
  handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      messagePopUp: false
    })
  }
  handleAddressChange = (e) => {
    console.log("this state>>>> ORDERDetail.js", this.state);

    let OrderArray = [];
    let filterDAta = this.state.orderData.filter((item) =>
      this.state.selected.includes(item.vendor_order_id)
    );
    OrderArray = filterDAta.map((item) => {
      item.seller_address_id = this.state.selectedAddress;
      return item;
    });
    let selectedData = OrderArray.map((item) => {
      return {
        length: item.length,
        width: item.width,
        height: item.height,
        weight: item.weight,
        vendor_order_id: item.vendor_order_id,
        payment_method: item.orderDetail.result[0].payment_code,
        order_total: item.orderDetail.result[0].total,
      };
    });
    this.setState(
      {
        selectedAddress: e.target.value,
        backdropOpen: true,
      },
      () => {
        console.log(this.state, "get Courier Services 0", selectedData);
        if (selectedData[0].length == 0) {
          this.setState(
            {
              open: true,
              isError: true,
              dimensionCheck: true,
              v_o_id: selectedData[0].vendor_order_id,
              message: "please set the dimension to select order",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  open: false,
                  dimensionCheck: true,
                  backdropOpen: false,
                });
              }, 1000);
            }
          );
          return null;
        } else {
          const requestOptions = {
            method: "post",
            headers: authHeader(),
            data: {
              vendor_order_id: selectedData,
              address_id: this.state.selectedAddress,
              is_mps: this.state.mpsFlag,
            },
          };

          return axios(apiUrl + "/get_courier_service", requestOptions)
            .then((data) => {
              const serviceableArray = [];
              const servicedArray = data.data.data.filter(
                (serviced) => serviced.servicable === true
              );
              servicedArray.map((servicedArray) => {
                serviceableArray.push(servicedArray.vendor_order_id);
              });

              var notservicedOrderIDArray = [];
              var notservicedArray = data.data.data.filter(
                (notserviced) => notserviced.servicable === false
              );
              notservicedArray.map((notserviced) => {
                notservicedOrderIDArray.push(notserviced.vendor_order_id);
              });
              if (servicedArray.length > 0) {
                this.setState(
                  {
                    addressCheckedVendorOrderId: serviceableArray,
                    backdropOpen: false,
                  },
                  () => {
                    if (notservicedArray.length > 0) {
                      this.setState(
                        {
                          isError: true,
                          open: true,
                          message:
                            "Orders [" +
                            notservicedOrderIDArray.toString() +
                            "] not processed due to not servicable.",
                        },
                        () => {
                          setTimeout(() => {
                            this.setState({
                              isError: false,
                              open: false,
                              message: "",
                            });
                          }, 3000);
                        }
                      );
                    }
                    //let selectedarray = this.state.selected.sort(function(a,b){return a-b});
                    let checkedAddress =
                      this.state.addressCheckedVendorOrderId.sort(function (
                        a,
                        b
                      ) {
                        return a - b;
                      });
                    this.setState(
                      {
                        selected: serviceableArray,
                        addressCheckedVendorOrderId: checkedAddress,
                      },
                      () => {
                        if (
                          this.state.addressCheckedVendorOrderId.join(",") ===
                          this.state.selected.join(",")
                        ) {
                          this.setState({
                            readyToShip: true,
                          });
                        } else {
                          this.setState({
                            readyToShip: false,
                            selectedAddress:""

                          });
                        }
                      }
                    );
                  }
                );
              } else {
                if (notservicedArray.length > 0) {
                  this.setState(
                    {
                      isError: true,
                      selected: serviceableArray,
                      open: true,
                      readyToShip: false,
                      backdropOpen: false,
                      message:
                        "Orders [" +
                        notservicedOrderIDArray.toString() +
                        "] not processed due to not servicable.",
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          isError: false,
                          open: false,
                          message: "",
                          selectedAddress:""
                        });
                      }, 3000);
                    }
                  );
                }
              }
            })
            .catch((error) => {
              this.setState(
                {
                  backdropOpen: false,
                  open: true,
                  isError: true,
                  message: "This pincode service is not available.",
                },
                () => {
                  setTimeout(() => {
                    this.setState(
                      {
                        open: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            isError: false,
                            message: "",
                          });
                        }, 2000);
                      }
                    );
                  }, 2000);
                }
              );
            });
        }
      }
    );
  };

  getOrderCount = () => {
    const requestOptions = {
      headers: authHeader(),
      method: "get",
    };
    return axios(apiUrl + "/get_order_count", requestOptions)
      .then((data) => {
        this.setState(
          {
            newCount: data.data.data[0].Count,
            shipCount: data.data.data[2].Count,
            deliveredCount: data.data.data[5].Count,
            deniedCount: data.data.data[4].Count,
            cancelCount: data.data.data[3].Count,
            acceptCount: data.data.data[1].Count,
            pendingPickupCount: data.data.data[6].Count,
            pendingManifestCount: data.data.data[7].Count,
            RTOCount: data.data.data[8].Count,
            RTODlvdCount: data?.data?.data[9]?.Count,
            isLoading: false,
          },
          () => {
            let order_count = [];
            data.data.data.forEach((item) => {
              if (item.id === "3" || item.id === "18") {
                order_count.push({
                  Count: data.data.data[2].Count + data.data.data[5].Count,
                  title: "Shipped Orders",
                  id: "3",
                });
              } else if (item.id === "5") {
                order_count.push({
                  Count: data.data.data[9].Count,
                  title: "Return Delivered",
                  id: "5",
                });
              } else if (
                item.id === "7" ||
                item.id === "8" ||
                item.id === "30"
              ) {
                order_count.push({
                  Count:
                    data.data.data[3].Count +
                    data.data.data[4].Count +
                    data.data.data[8].Count,
                  title: "Cancelled Orders",
                  id: "7",
                });
              } else if (
                item.id === "15" ||
                item.id === "17" ||
                item.id === "21"
              ) {
                order_count.push({
                  Count:
                    data.data.data[1].Count +
                    data.data.data[6].Count +
                    data.data.data[7].Count,
                  title: "Processing Orders",
                  id: "15",
                });
              } else {
                order_count.push({
                  Count: item.Count,
                  title: item.title,
                  id: item.id,
                });
              }
            });
            let unique = this.unique(order_count, ["Count", "title", "id"]);
            this.setState({
              orderCount: unique,
            });
          }
        );
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  onSelectAllClick = (e) => {
    //console.log('this.state.orderData', this.state.orderData);

    if (e.target.checked) {
      // const newSelecteds = this.state.product_data.map(n=>n.product_id);
      let newSelecteds = [];
      let notSelected = [];
      for (let i = 0; i < this.state.orderData.length; i++) {
        if (!this.state.orderData[i].isPresent) {
          if (this.state.order_status_id == "15") {
            /*if(this.state.orderData[i].length>0){
                    //if(this.state.orderData[i].length>0 && (this.state.orderData[i].purchaseInvoice_no.length > 1 )){
                    newSelecteds.push(this.state.orderData[i].vendor_order_id);
                    } else {
                        notSelected.push(this.state.orderData[i].vendor_order_id);
                    }*/
            console.log("notSelected", notSelected);
            if (this.state.orderData[i].length > 0) {
              // console.log("this.state.logined::::::",this.state.logined_vendor_id);
              if (this.state.logined_vendor_id != 16) {
                if (this.state.orderData[i].purchaseInvoice_no.length > 1) {
                  // && this.state.orderData[i].purchaseInvoice_no.length!=undefined
                  newSelecteds.push(this.state.orderData[i].vendor_order_id);
                } else {
                  notSelected.push(this.state.orderData[i].vendor_order_id);
                }
              } else {
                newSelecteds.push(this.state.orderData[i].vendor_order_id);
              }
            } else {
              notSelected.push(this.state.orderData[i].vendor_order_id);
            }
          } else {
            newSelecteds.push(this.state.orderData[i].vendor_order_id);
          }
        }
      }
      //console.log('notSelected', notSelected);
      let sortnewSelected = newSelecteds.sort(function (a, b) {
        return a - b;
      });
      this.setState(
        {
          selected: sortnewSelected,
        },
        () => {
          if (
            this.state.addressCheckedVendorOrderId.join(",") ===
            this.state.selected.join(",")
          ) {
            this.setState({
              readyToShip: true,
            });
          } else {
            this.setState({
              readyToShip: false,
            });
          }

          console.log("notSelected>>>>>>>>>>>>>>", notSelected);

          if (notSelected.length > 0) {
            this.setState(
              {
                isError: true,
                open: true,
                message:
                  "Dimension and Purchase invoice should be set for order Id " +
                  notSelected,
                // message : "Dimension should be set for order Id " + notSelected
              },
              () => {
                setTimeout(() => {
                  this.setState(
                    {
                      open: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          isError: false,
                          message: "",
                        });
                      }, 1000);
                    }
                  );
                }, 4000);
              }
            );
          }
        }
      );
      return;
    }
    this.setState(
      {
        selected: [],
      },
      () => {
        if (
          this.state.addressCheckedVendorOrderId.join(",") ===
          this.state.selected.join(",")
        ) {
          this.setState({
            readyToShip: true,
          });
        } else {
          this.setState({
            readyToShip: false,
          });
        }
      }
    );
  };

  isSelected = (vendor_order_id) =>
    this.state.selected.indexOf(vendor_order_id) !== -1;

  isproductSelected = (product_id) =>
    this.state.productSelected.indexOf(product_id) !== -1;

  handleClick = (event, vendor_order_id, length, purchaseInvoice_no) => {
    if (this.state.order_status_id == "15") {
      console.log("this one");
      // if(length > 0){
      // console.log("111111111111purchaseInvoice_no::::::::::",purchaseInvoice_no);
      if (
        (length > 0 &&
          purchaseInvoice_no != 0 &&
          this.state.logined_vendor_id != 16) ||
        (length > 0 &&
          purchaseInvoice_no == 0 &&
          this.state.logined_vendor_id == 16) ||
        (length > 0 &&
          purchaseInvoice_no != 0 &&
          this.state.logined_vendor_id == 16)
      ) {
        if (event.target.checked) {
          const selectedIndex = this.state.selected.indexOf(vendor_order_id);
          let newSelected = [];
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(
              this.state.selected,
              vendor_order_id
            );
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(this.state.selected.slice(1));
          } else if (selectedIndex === this.state.selected.length - 1) {
            newSelected = newSelected.concat(this.state.selected.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              this.state.selected.slice(0, selectedIndex),
              this.state.selected.slice(selectedIndex + 1)
            );
          }
          this.setState(
            {
              selected: newSelected,
            },
            () => {
              if (
                this.state.addressCheckedVendorOrderId.join(",") ===
                this.state.selected.join(",")
              ) {
                this.setState({
                  readyToShip: true,
                });
              } else {
                this.setState({
                  readyToShip: false,
                });
              }
            }
          );
        } else {
          const selectedIndex = this.state.selected.indexOf(vendor_order_id);
          let selectedArray = this.state.selected.filter(
            (item) => item !== this.state.selected[selectedIndex]
          );
          this.setState(
            {
              selected: selectedArray,
            },
            () => {
              if (
                this.state.addressCheckedVendorOrderId.join(",") ===
                this.state.selected.join(",")
              ) {
                this.setState({
                  readyToShip: true,
                });
              } else {
                this.setState({
                  readyToShip: false,
                });
              }
            }
          );
        }
      } else {
        if (length == 0) {
          this.setState(
            {
              open: true,
              isError: true,
              dimensionCheck: true,
              v_o_id: vendor_order_id,
              message: "please set the dimension to select order",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  open: true,
                  dimensionCheck: true,
                });
              }, 3000);
            }
          );
        }
        console.log("purchaseInvoice_no::::::::::", purchaseInvoice_no);
        if (purchaseInvoice_no == 0) {
          this.setState(
            {
              open: true,
              isError: true,
              dimensionCheck: true,
              v_o_id: vendor_order_id,
              message: "Please set the purchase invoice number to select order",
            },
            () => {
              setTimeout(() => {
                this.setState({
                  open: false,
                  dimensionCheck: false,
                });
              }, 3000);
            }
          );
        }
      }
    } else {
      const selectedIndex = this.state.selected.indexOf(vendor_order_id);

      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, vendor_order_id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          this.state.selected.slice(0, selectedIndex),
          this.state.selected.slice(selectedIndex + 1)
        );
      }
      this.setState({
        selected: newSelected,
      });
    }
  };

  handleProductSelect = (event, product_id) => {
    const selectedIndex = this.state.productSelected.indexOf(product_id);
    let productSelected = this.state.productSelected;
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(productSelected, product_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(productSelected.slice(1));
    } else if (selectedIndex === productSelected.length - 1) {
      newSelected = newSelected.concat(productSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        productSelected.slice(0, selectedIndex),
        productSelected.slice(selectedIndex + 1)
      );
    }
    this.setState({
      productSelected: newSelected,
    });
  };

  getRelatedOrders = (e) => {
    // console.log(e.currentTarget);
    let order_type = e.currentTarget.getAttribute("title");
    let title = e.currentTarget.getAttribute("title");
    let id = e.currentTarget.getAttribute("id");
    console.log(order_type, title, id, "GET REKATED orders");
    // console.log(id);
    this.setState(
      {
        item_id: id,
        order_status_id: id,
        page: 1,
        isLoadingTable: true,
        selected: [],
        order_type: order_type,
        buttonTitle: title,
        readyToShip: false,
      },
      () => {
        // console.log(this.state.buttonTitle)
        this.getOrders();
        this.getOrderCount();
        if (
          this.state.buttonTitle == "pending_pickup" ||
          this.state.buttonTitle == "transit"
        ) {
          const requestOptions = {
            method: "post",
            headers: authHeader(),
            data: {
              order_status_id: this.state.order_status_id,
              required_total_count: 1,
              item: this.state.rowsPerPage,
              order_type: this.state.order_type,
              order_by: this.state.orderBy,
              order_inc: this.state.orderInc,
            },
          };
          return axios(apiUrl + "/get_order/" + this.state.page, requestOptions)
            .then((data) => {
              console.log("getOrderYe wali1?");

              let vendor_order_id = data.data.data.vd_order_id;
              this.setState({
                orderData: data.data.data.result,
              });
              // this.setState({
              //   isLoadingTable : true,
              // })
              const requestOptions1 = {
                method: "post",
                headers: authHeader(),
                data: {
                  vendor_order_id: vendor_order_id,
                  order_status_id: this.state.order_status_id,
                },
              };
              return axios(apiUrl + "/bulk_track", requestOptions1)
                .then((data) => {
                  this.getOrders();
                  this.getOrderCount();
                })
                .catch((error) => {
                  this.getOrders();
                  this.getOrderCount();
                });
            })
            .catch((error) => {
              if (error.response) {
                this.setState(
                  {
                    isLoadingStatus: false,
                    open: true,
                    message: error.response.data.message,
                    isLoadingTable: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        open: false,
                        message: "",
                      });
                    }, 2000);
                  }
                );
              } else {
                this.setState({
                  isLoadingStatus: false,
                  isLoadingTable: false,
                  blankResponse: true,
                });
              }
            });
        }
      }
    );
  };

  getOrders = () => {
    window.scrollTo(0, 0);
    this.setState(
      {
        isLoadingStatus: true,
        isLoadingTab: true,
      },
      async () => {
        let orderStatusId = this.state.order_status_id;
        // console.log('orderStatusId',orderStatusId)
        const requestOptions = {
          method: "post",
          headers: authHeader(),
          data: {
            order_status_id: orderStatusId,
            required_total_count: 1,
            item: this.state.rowsPerPage,
            order_type: this.state.order_type,
            order_by: this.state.orderBy,
            order_inc: this.state.orderInc,
          },
        };
        // console.log(this.state.order_type,this.state.orderBy,this.state.order_inc);
        return axios(apiUrl + "/get_order/" + this.state.page, requestOptions)
          .then((data) => {
            console.log(data.data.data.result,'getOrderYe wali final?',requestOptions)
            this.setState({
              orderData: data?.data?.data?.result,
              totalOrders: data?.data?.data?.total_count,
              isLoadingStatus: false,
              isLoadingTable: false,
            });
          })
          .catch((error) => {
            // console.log(error);
            if (error.response) {
              this.setState(
                {
                  isLoadingStatus: false,
                  open: true,
                  message: error.response.data.message,
                  isLoadingTable: false,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      open: false,
                      message: "",
                    });
                  }, 2000);
                }
              );
            } else {
              // console.log('bnladjfk')
              this.setState({
                isLoadingStatus: false,
                isLoadingTable: false,
                blankResponse: true,
              });
            }
          });
      }
    );
  };
  refreshPage = () => {
    this.setState(
      {
        page: 1,
        blankResponse: false,
      },
      () => {
        this.getOrderCount();
        this.getOrders();
      }
    );
  };
  changeOrderStatus = (e) => {
    console.log(this.state, "change order status");

    // console.log(e.currentTarget)
    let v_id = [];
    let order_status_id = e.currentTarget.id;
    // console.log(order_status_id);
    let action = e.currentTarget.getAttribute("status");
    let vendor_order_id = e.currentTarget.getAttribute("vendor_order_id");
    vendor_order_id != null ? v_id.push(vendor_order_id) : v_id.push(null);
    this.setState(
      {
        vendor_order_id: vendor_order_id,
        isLoadingStatus: true,
        backdropOpen: true,
      },
      async () => {
        const requestOptions = {
          method: "post",
          headers: authHeader(),
          data: {
            order_id: this.state.order_id,
            order_status_id: order_status_id,
            vendor_order_id:
              vendor_order_id != null ? v_id : this.state.selected,
            action: action,
          },
        };
        return axios(apiUrl + "/change_order_status", requestOptions)
          .then((data) => {
            // console.log(data,"data seller")
            let successMessage = data.data.message && data.data.message['updatedOrder/s'].map((item) => {
              return(
                <React.Fragment key={item.vendor_order_id}>
                   {item.vendor_order_id} : {item.status}
                   <br/> 
                </React.Fragment>
              )
            })
            // console.log(successMessage,"successMessage")
            let failMessage = data.data.message && data.data.message['failedOrder/s'].map((item) => {
              return(
                <React.Fragment key={item.vendor_order_id}>
                  {item.vendor_order_id} : {item.status}
                  <br />
                </React.Fragment>
              )
            })
            // console.log(failMessage,"failMessage")

            if(successMessage && successMessage.length && !failMessage.length){
              // console.log("in success case...",successMessage);
              this.setState({
                isError:false,
                // open:true,
                // message:successMessage
                success_order:true,
                success_order_message:successMessage
              },
              () => {
                this.setState({
                  // open:true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      // isError:false,
                      // message:"",
                      // open:false
                      success_order:false,
                      success_order_message:""
                    })
                  },10000)
                }
                );
              },3000)
              // console.log(successMessage,"successMessage 123")
            }
            else if(successMessage.length && failMessage.length) {
              // console.log("in success and failed case",successMessage, failMessage);
              // success case start here
              this.setState({
                isError:false,
                // open:true,
                // message:successMessage,
                success_order:true,
                success_order_message:successMessage
              },
              () => {
                this.setState({
                  // open:true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      // isError:false,
                      // message:"",
                      // open:false,
                      success_order:false,
                      success_order_message:""
                    })
                  },10000)
                }
                );
              },3000)
              // success case ends here
              // failure case start here....
              this.setState({
                isError:true,
                // open:true,
                // message: failMessage
                cancel_order_error:true,
                cancel_order_error_message:failMessage
              },
              () => {
                this.setState({
                  // open:true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      // isError:false,
                      // message:"",
                      // open:false
                      cancel_order_error:false,
                      cancel_order_error_message:""
                    })
                  },300000)
                }
                );
              },3000)
              // failure case ends here
            } 
            else {
              // console.log("in fail case...", failMessage);
              this.setState({
                isError:true,
                // open:true,
                // message:failMessage,
                cancel_order_error:true,
                cancel_order_error_message:failMessage
              },
              () => {
                this.setState({
                  // open:true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      // isError:false,
                      // message:"",
                      // open:false,
                      cancel_order_error:false,
                      cancel_order_error_message:""
                    })
                  },300000)
                }
                );
              },3000)
            } 
            setTimeout(() => {
              this.setState(
                {
                  // open:false,
                  isLoadingStatus: false,
                  selected: [],
                  selectedAddress:"",
                  backdropOpen: false,
                  readyToShip: !this.state.readyToShip,
                },
                () => this.getOrderCount(),
                this.getOrders()
              );
            }, 1000);
          })
          .catch((error) => {
            console.log(error , "THIS is the error");
              this.setState({
                isLoadingStatus: false,
                backdropOpen: false,
                // isError:true,
                // message:"Warehouse not selected",
                // open:true
              },   () => {
                // setTimeout(() => {
                //   this.setState({
                //     open: false,
                //   });
                // }, 3000);
              });
          });
      }
    );
  };
  // cancel_order function start here
  handleCloseCancelOrder = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      cancel_order_error:false
    })
  };
  // cancel_order function ends here
  // success_order function start here
  handleCloseSuccessOrder = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      success_order:false
    })
  }
  // success_order function ends here
  pageChangeHandler = (e) => {
    let title = e.currentTarget.title;
    if (title === "Next page") {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => this.getOrders()
      );
    } else {
      this.setState(
        {
          page: this.state.page - 1,
        },
        () => this.getOrders()
      );
    }
  };
  handleModalClose = () => {
    this.setState({
      modalopen: false,
      trackingData: [],
    });
  };
  trackOrder = (e) => {
    this.setState(
      {
        vendor_order_id: e.currentTarget.id,
        isLoadingTracking: true,
        modalopen: true,
      },
      async () => {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          data: {
            vendor_order_id: this.state.vendor_order_id,
          },
        };
        return axios(apiUrl + "/track_order", requestOptions)
          .then((data) => {
            // console.log(data);
            if (data.data.data.service == "ecom") {
              this.setState({
                trackingData: data.data.data.trackingData,
                isLoadingTracking: false,
              });
            } else {
              if (
                data.data.data.service == "shiprocket" ||
                data.data.data.service == "gati"
              ) {
                // console.log(data.data.data.trackingData)
                this.setState({
                  trackingData: data.data.data.trackingData,
                  isLoadingTracking: false,
                });
              } else {
                this.setState({
                  trackingData: JSON.parse(data.data.data.trackingData).data
                    .events,
                  isLoadingTracking: false,
                });
              }
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response) {
              this.setState(
                {
                  open: true,
                  message: error.response.data.message,
                  isLoadingTracking: false,
                  isError: true,
                },
                () => {
                  // setTimeout(() => {
                  //   this.setState(
                  //     {
                  //       open: false,
                  //     },
                  //     () => {
                  //       setTimeout(() => {
                  //         this.setState({
                  //           isError: false,
                  //           message: "",
                  //         });
                  //       }, 1000);
                  //     }
                  //   );
                  // }, 3000);
                }
              );
            } else {
              this.setState(
                {
                  isLoadingTracking: false,
                  isError: true,
                  message: "error in getting track data",
                  open: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState(
                      {
                        open: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            message: "",
                            isError: false,
                          });
                        }, 1000);
                      }
                    );
                  }, 3000);
                }
              );
            }
          });
      }
    );
  };
  OpenDimensionDialog = (e) => {
    let product_id = e.currentTarget.getAttribute("product_id");
    let quantity = e.currentTarget.getAttribute("quantity");
    let multiple = e.currentTarget.getAttribute("product_index");
    let index = e.currentTarget.getAttribute("index");
    // console.log(index,"OPENDIALOGUEINDEX")
    let OrderArray = this.state.orderData;
    if (
      OrderArray[index].length > 0 &&
      OrderArray[index].width > 0 &&
      OrderArray[index].height > 0 &&
      OrderArray[index].weight > 0
    ) {
      this.setState({
        length: Number(OrderArray[index].length).toFixed(2),
        width: Number(OrderArray[index].width).toFixed(2),
        height: Number(OrderArray[index].height).toFixed(2),
        weight: Number(OrderArray[index].weight).toFixed(2),
        mpsBoxIndex: index,
      });
    }
    this.setState(
      {
        isdimensionDialogOpen: true,
      },
      () => {
        let payment_code = OrderArray[index].orderDetail.result[0].payment_code;
        this.setState({
          product_id: product_id,
          quantity: quantity,
          multipleProduct: multiple > 1 ? true : false,
          index: index,
          mpsBoxIndex: index,
          payment_method: payment_code,
        });
      }
    );
  };
  CloseDimensionDialog = () => {
    this.setState(
      {
        isdimensionDialogOpen: false,
      },
      () => {
        this.setState({
          length: 0,
          width: 0,
          height: 0,
          weight: 0,
          submitted: false,
          product_id: 0,
          quantity: 0,
          multipleProduct: false,
          index: 0,
          payment_method: "",
        });
      }
    );
  };
  handleDimensionChange = (e) => {
    let name = e.name[0];
    this.setState({
      [name]: e.value,
    });
  };

  // setDimension=(data)=>{

  //   console.log(data,'itscalled2');
  // }

  setDimension = (data, passedstate) => {
    console.log(data);
    var count = Object.keys(data).length;

    let isMps = Array.isArray(data);
    // mps TRUE AND ALL BOXES ARE DIFFERENT
    if (isMps == true && passedstate.allidentical == false) {
      var res = Object.values(
        data.reduce((a, { length, width, height }) => {
          a[(length, width, height)] = a[(length, width, height)] || {
            length,
            width,
            height,
            count: 0,
          };
          a[(length, width, height)].count++;
          return a;
        }, Object.create(null))
      );

      res.forEach((box) => {
        box.length = parseFloat(box.length);
        box.width = parseFloat(box.width);
        box.height = parseFloat(box.height);
      });
      console.log(res, "ARRAY FOR DIMENSIONS");
      let totalCount = res.reduce((acc, obj) => {
        return acc + obj.count;
      }, 0);
      let totalWeight = 0;

      for (let i = 0; i < data.length; i++) {
        const weight = Number(data[i].weight);
        totalWeight += weight;
      }

      let suborders = [];
      res.map((item, i) => {
        console.log(item);
        suborders.push({
          ident: "",
          count: item.count,
          description: `Box-${i + 1}`,
        });
      });
      console.log(
        totalWeight,
        "TOTALWEIGHT",
        suborders,
        "SUBORDERS",
        "MPS--->RES[0]",
        res[0]
      );

      this.setState({
        mpsBoxCount: totalCount,
        mpsDimension: res,
        mpsWeight: totalWeight,
        mpsSuborder: suborders,
        mpsFlag: true,
        length: res[0].length ? res[0].length : res.length,
        height: res[0].height ? res[0].height : res.height,
        weight: totalWeight,
        width: res[0].width ? res[0].width : res.width,
      });
    }
    // MPS TRUE BUT BOXES ARE IDENTICAL
    else if (isMps == true && passedstate.allidentical == true) {
      let totalCount, totalWeight, dimension;
      let suborders = [];

      var res = Object.values(
        data.reduce((a, { length, width, height }) => {
          a[(length, width, height)] = a[(length, width, height)] || {
            length,
            width,
            height,
            count: passedstate.box,
          };

          return a;
        }, Object.create(null))
      );

      res.forEach((box) => {
        box.length = parseFloat(box.length);
        box.width = parseFloat(box.width);
        box.height = parseFloat(box.height);
      });
      console.log(res, "ARRAY FOR DIMENSIONS");

      res.map((item, i) => {
        console.log(item);
        suborders.push({
          ident: "",
          count: passedstate.box,
          description: `Box-${i + 1}`,
        });
      });

      this.setState({
        mpsBoxCount: passedstate.box,
        mpsDimension: res,
        mpsWeight: parseFloat(res[0].count * data[0].weight),
        mpsSuborder: suborders,
        mpsFlag: true,
        length: res[0].length ? res[0].length : res.length,
        height: res[0].height ? res[0].height : res.height,
        weight: parseFloat(res[0].count * data[0].weight),
        width: res[0].width ? res[0].width : res.width,
      });
    } else if (isMps == false) {
      this.setState({
        mpsFlag: false,
      });
    }
    this.setState(
      {
        isLoadingDimension: true,
        submitted: true,
      },
      () => {
        console.log(
          "came till here",
          this.state.submitted,
          this.state.length,
          this.state.width,
          this.state.height,
          this.state.weight,
          "QTY",
          this.state.quantity,
          this.state.orderData,
          this.state.index
        );
        if (
          this.state.submitted &&
          this.state.length > 0 &&
          this.state.width > 0 &&
          this.state.height > 0 &&
          this.state.weight > 0 &&
          this.state.quantity &&
          !isNaN(this.state.length) &&
          !isNaN(this.state.width) &&
          !isNaN(this.state.height) &&
          !isNaN(this.state.weight)
        ) {
          if (this.state.multipleProduct) {
            let i = this.state.index;
            let OrderArray = this.state.orderData;
            OrderArray[i].length = this.state.length;
            OrderArray[i].width = this.state.width;
            OrderArray[i].height = this.state.height;
            OrderArray[i].weight = this.state.weight;
            console.log(this.state, "MULTIPLE STATE DIMENSIONS");
            this.setState(
              {
                orderData: OrderArray,
                isdimensionDialogOpen: false,
                submitted: false,
                length: "",
                width: "",
                height: "",
                weight: "",
                multipleProduct: false,
              },
              () => {
                console.log("came till here");
                if (this.state.isShipping) {
                  this.setState({
                    AddressModal: true,
                  });
                } else {
                  // console.log(this.state.isShipping)
                }
              }
            );
          } else {
            console.log("came till here");
            let i = this.state.index;
            let OrderArray = this.state.orderData;
            OrderArray[i].length = Number(this.state.length).toFixed(2);
            OrderArray[i].width = Number(this.state.width).toFixed(2);
            OrderArray[i].height = Number(this.state.height).toFixed(2);
            OrderArray[i].weight = Number(this.state.weight).toFixed(2);
            this.setState(
              {
                orderData: OrderArray,
              },
              () => {
                const requestOptions = {
                  method: "post",
                  headers: authHeader(),
                  data: {
                    length: this.state.length,
                    width: this.state.width,
                    height: this.state.height,
                    weight: this.state.weight,
                    quantity: this.state.quantity,
                    product_id: this.state.product_id,
                  },
                };
                return axios(apiUrl + "/set_product_dimension", requestOptions)
                  .then((data) => {
                    this.setState(
                      {
                        isLoadingDimension: false,
                        isdimensionDialogOpen: false,
                        submitted: false,
                        length: 0,
                        width: 0,
                        height: 0,
                        weight: 0,
                        multipleProduct: false,
                      },
                      () => {
                        if (this.state.isShipping) {
                          this.setState({
                            AddressModal: true,
                          });
                        }
                      }
                    );
                  })
                  .catch((error) => {
                    this.setState({
                      isLoadingDimension: false,
                      isdimensionDialogOpen: false,
                    });
                  });
              }
            );
          }
        } else {
          if (
            this.state.submitted &&
            !this.state.length > 0 &&
            isNaN(this.state.length)
          ) {
            this.length.current.focus();
          } else if (
            this.state.submitted &&
            !this.state.width > 0 &&
            isNaN(this.state.width)
          ) {
            this.width.current.focus();
          } else if (
            this.state.submitted &&
            !this.state.height > 0 &&
            isNaN(this.state.height)
          ) {
            this.height.current.focus();
          } else if (
            this.state.submitted &&
            !this.state.weight > 0 &&
            isNaN(this.state.weight)
          ) {
            this.weight.current.focus();
          }
        }
      }
    );
  };
  bookbulkorders = (e) => {
    this.setState(
      {
        backdropOpen: true,
      })
    e.persist();
    let title = e.currentTarget.getAttribute("title");
    const invrequestOptions = {
      method: "Post",
      headers: authHeader(),
      data: {
        vendor_order_id: this.state.selected,
        multiple: true,
      },
    };
    //console.log('invrequestOptions', invrequestOptions); return false;
    return axios(apiUrl + "/generate_invoice", invrequestOptions)
      .then((data) => {
        this.setState(
          {
            backdropOpen: true,
            AddressModal: false,
          },
          () => {
            // let newOrder = [];
            setTimeout(() => {
              let OrderArray = [];
              let SingleOrderArray = [];
              let requestOptions = {};
              if (title == "bulk") {
                let filterDAta = this.state.orderData.filter((item) =>
                  this.state.selected.includes(item.vendor_order_id)
                );

                OrderArray = filterDAta.map((item) => {
                  return {
                    length: item.length,
                    width: item.width,
                    height: item.height,
                    weight: item.weight,
                    vendor_order_id: item.vendor_order_id,
                  };
                });
              } else {
                let bookOrderArray = this.state.bookOrderArray;
                let SellerAddressId = {
                  seller_address_id: this.state.selectedAddress,
                };
                Object.assign(bookOrderArray, SellerAddressId);
                this.setState(
                  {
                    bookOrderArray,
                  },
                  () => {
                    SingleOrderArray.push(this.state.bookOrderArray);
                  }
                );
              }
              this.state.mpsFlag == true
                ? (requestOptions = {
                    method: "POST",
                    headers: authHeader(),
                    data: {
                      orderData:
                        title == "bulk" ? OrderArray : SingleOrderArray,
                      multiple: true,
                      mps: {
                        suborders: this.state.mpsSuborder,
                        weight: this.state.mpsWeight,
                        dimension: this.state.mpsDimension,
                      },
                    },
                  })
                : (requestOptions = {
                    method: "POST",
                    headers: authHeader(),
                    data: {
                      orderData:
                        title == "bulk" ? OrderArray : SingleOrderArray,
                      multiple: true,
                    },
                  });
              return axios(apiUrl + "/create_order", requestOptions)
                .then((data) => {
                  setTimeout(() => {
                    this.setState(
                      {
                        selected: [],
                        addressCheckedVendorOrderId: [],
                      },
                      () => {
                        this.setState(
                          {
                            backdropOpen: false,
                          },
                          () => {
                            setTimeout(() => {
                              this.getOrders();
                              this.getOrderCount();
                              this.getWarehouseAddress();
                            }, 5000);
                          }
                        );
                      }
                    );
                  }, 5000);
                  data.data.data.map((item) => {
                    this.props.enqueueSnackbar(
                      item.vendor_order_id + " : " + item.message
                    );
                  });
                })
                .catch((error) => {
                  this.setState(
                    {
                      isError: true,
                      open: true,
                      message: "error in creating order",
                      backdropOpen: false,
                      addressCheckedVendorOrderId: [],
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          open: false,
                        });
                      }, 3000);
                    }
                  );
                });
            }, 2000);
          }
        );
      })
      .catch((error) => {
        this.setState(
          {
            isError: true,
            open: true,
            message: "error in creating order",
            backdropOpen: false,
            addressCheckedVendorOrderId: [],
          },
          () => {
            setTimeout(() => {
              this.setState({
                open: false,
              });
            }, 3000);
          }
        );
      });
  };
  handleChangeRowsPerPage = (e) => {
    this.setState(
      {
        rowsPerPage: e.target.value,
        page: 1,
      },
      () => this.getOrderCount(),
      this.getOrders()
    );
  };

  BulkShippingLabelAndInvoice = () => {
    this.setState(
      {
        downloadmerged: true,
        backdropOpen: true,
        selected: this.state.selected.sort(function (a, b) {
          return b - a;
        }),
      },
      () => {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          data: {
            vendor_order_id: this.state.selected,
            multiple: false,
          },
        };
        return axios(apiUrl + "/get_shipment_slip", requestOptions)
          .then((data) => {
            let sortedData = data.data.data.sort(function (a, b) {
              return b - a;
            });
            // console.log('shippingLabeldata',data);
            let downloadOptions = {
              method: "post",
              headers: authHeader(),
              data: {
                vendor_order_id: sortedData,
              },
            };
            return axios(
              apiUrl + "/download_shipping_label",
              downloadOptions
            ).then((data) => {
              this.setState(
                {
                  shippingLabelData: data.data.data,
                },
                () => {
                  const requestOptions = {
                    method: "Post",
                    headers: authHeader(),
                    data: {
                      vendor_order_id: this.state.selected,
                      multiple: true,
                    },
                  };
                  return axios(
                    apiUrl + "/generate_invoice",
                    requestOptions
                  ).then((data) => {
                    let aselected = this.state.selected;
                    let newUrl = [];
                    aselected.map((item) => {
                      return newUrl.push(item);
                    });
                    const requestOptions = {
                      method: "post",
                      headers: authHeader(),
                      data: {
                        vendor_order_id: newUrl,
                      },
                    };
                    return axios(apiUrl + "/download_invoice", requestOptions)
                      .then((data) => {
                        console.log("apiResponse", data);
                        let datatobedownload = [];
                        this.setState(
                          {
                            DownloadInvoiceData: data.data.data,
                            backdropOpen: false,
                            selected: [],
                          },
                          () => {
                            this.state.DownloadInvoiceData.map(
                              (item, index) => {
                                if (this.state.shippingLabelData[index]) {
                                  datatobedownload.push(
                                    this.state.shippingLabelData[index]
                                  );
                                }
                                datatobedownload.push(item);
                              }
                            );
                          }
                        );
                        mergeBetweenPDF
                          .mergeBetweenPDF(datatobedownload)
                          .then((data) => {
                            const fileName =
                              "output_merge_" +
                              new Date()
                                .toISOString()
                                .replace(":", "_")
                                .replace("T", "_")
                                .replace("Z", "") +
                              ".pdf";
                            this.setState(
                              {
                                backdropOpen: false,
                                downloadmerged: false,
                                open: true,
                                message:
                                  "invoice and shipping label generated successfully",
                                isError: false,
                              },
                              () => {
                                saveSync(data.pdfFile, fileName);
                                setTimeout(() => {
                                  this.setState(
                                    {
                                      open: false,
                                    },
                                    () => {
                                      this.getOrders();
                                      this.getOrderCount();
                                      this.setState({
                                        message: "",
                                        isError: false,
                                      });
                                    }
                                  );
                                }, 3000);
                              }
                            );
                          });
                      })
                      .catch((error) => {
                        console.log(
                          "AAAAAAwhat is the invoice Error live",
                          error
                        );
                        this.setState(
                          {
                            backdropOpen: false,
                            downloadmerged: false,
                            open: true,
                            isError: true,
                            message:
                              "processing invoice and shipping label, Try after some time",
                          },
                          () => {
                            setTimeout(() => {
                              this.setState(
                                {
                                  open: false,
                                },
                                () => {
                                  setTimeout(() => {
                                    this.setState({
                                      message: "",
                                      isError: false,
                                    });
                                  }, 1000);
                                }
                              );
                            }, 3000);
                          }
                        );
                      });
                  });
                }
              );
            });
          })
          .catch((error) => {
            this.setState(
              {
                backdropOpen: false,
                isError: true,
                message:
                  "processing invoice and shipping label, Try after sometime",
                open: true,
                downloadmerged: false,
              },
              () => {
                setTimeout(() => {
                  this.setState(
                    {
                      open: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          message: "",
                          isError: false,
                        });
                      }, 1000);
                    }
                  );
                }, 3000);
              }
            );
          });
      }
    );
  };

  BulkShippingLabel = () => {
    this.setState(
      {
        bulkShipping: true,
        backdropOpen: true,
        regenerateShipping: true,
      },
      () => {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          data: {
            vendor_order_id: this.state.selected,
            multiple: false,
          },
        };
        return axios(apiUrl + "/get_shipment_slip", requestOptions)
          .then((data) => {
            // console.log(data);
            let downloadOptions = {
              method: "post",
              headers: authHeader(),
              data: {
                vendor_order_id: data.data.data,
              },
            };
            return axios(apiUrl + "/download_shipping_label", downloadOptions)
              .then((data) => {
                // console.log(data);
                this.setState(
                  {
                    shippingLabelData: data.data.data,
                  },
                  () => {
                    if (this.state.shippingLabelData.length > 0) {
                      mergeBetweenPDF
                        .mergeBetweenPDF(this.state.shippingLabelData)
                        .then((data) => {
                          const fileName =
                            "output_merge_" +
                            new Date()
                              .toISOString()
                              .replace(":", "_")
                              .replace("T", "_")
                              .replace("Z", "") +
                            ".pdf";

                          this.setState(
                            {
                              open: true,
                              isError: false,
                              message: "shipping label generated successfully",
                              selected: [],
                              backdropOpen:
                                this.state.buttonTitle == "pending_pickup"
                                  ? false
                                  : true,
                            },
                            () => {
                              saveSync(data.pdfFile, fileName);
                              setTimeout(() => {
                                this.getOrders();
                                this.getOrderCount();
                                this.setState({
                                  open: false,
                                  regenerateShipping: false,
                                });
                              }, 2000);
                            }
                          );
                        })
                        .catch((error) => {
                          this.setState(
                            {
                              open: true,
                              isError: true,
                              message: "error in generating shipping label",
                              backdropOpen:
                                this.state.buttonTitle == "pending_pickup"
                                  ? false
                                  : true,
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({
                                  open: false,
                                  regenerateShipping: false,
                                });
                              }, 3000);
                            }
                          );
                        });
                    } else {
                      this.setState(
                        {
                          open: true,
                          isError: true,
                          message: "no data found",
                          regenerateShipping: false,
                          backdropOpen:
                            this.state.buttonTitle == "pending_pickup"
                              ? false
                              : true,
                        },
                        () => {
                          setTimeout(() => {
                            this.setState({
                              open: false,
                            });
                          }, 3000);
                        }
                      );
                    }
                  }
                );
              })
              .catch((error) => {
                // console.log(error);
                this.setState(
                  {
                    open: true,
                    isError: true,
                    message: "error in generating shipping-label",
                    bulkShipping: false,
                    backdropOpen:
                      this.state.buttonTitle == "pending_pickup" ? false : true,
                    regenerateShipping: false,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        open: false,
                      });
                    }, 3000);
                  }
                );
              });
          })
          .catch((error) => {
            this.setState(
              {
                open: true,
                isError: true,
                message: "error in generating shipping-label",
                bulkShipping: false,
                regenerateShipping: false,
                backdropOpen:
                  this.state.buttonTitle == "pending_pickup" ? false : true,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    open: false,
                  });
                }, 3000);
              }
            );
          });
      }
    );
  };

  checkDimension = (e) => {
    let index = e.currentTarget.getAttribute("index");
    console.log("CHECKDIMENSIONINDEX", index);
    let productIndex = e.currentTarget.getAttribute("product_index");
    let orderArray = this.state.orderData[index];
    if (
      orderArray.length > 0 &&
      orderArray.width > 0 &&
      orderArray.height > 0 &&
      orderArray.height > 0
    ) {
      this.setState({
        AddressModal: true,
        isdimensionAvailable: true,
        isShipping: false,
        multipleProduct: productIndex > 1 ? true : false,
        quantity: orderArray.quantity,
        bookOrderArray: orderArray,
        mpsBoxIndex: index,
      });
    } else {
      this.setState({
        isdimensionAvailable: false,
        isdimensionDialogOpen: true,
        isShipping: true,
        index: index,
        multipleProduct: productIndex > 1 ? true : false,
        quantity: orderArray.quantity,
        bookOrderArray: orderArray,
        mpsBoxIndex: index,
      });
    }
  };

  openAddressDialog = () => {
    this.setState({
      AddressModal: true,
    });
  };

  CloseAddressDialog = () => {
    this.setState({
      AddressModal: false,
    });
  };

  generateBulkManifest = () => {
    this.setState(
      {
        isLoadingBulkManifest: true,
        backdropOpen: true,
      },
      () => {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          data: {
            vendor_order_id: this.state.selected,
            multiple: true,
          },
        };
        return axios(apiUrl + "/get_manifest", requestOptions)
          .then((data) => {
            this.setState(
              {
                ManifestData: data.data.data,
              },
              () => {
                mergeBetweenPDF
                  .mergeBetweenPDF(this.state.ManifestData)
                  .then((data) => {
                    const fileName =
                      "output_merge_" +
                      new Date()
                        .toISOString()
                        .replace(":", "_")
                        .replace("T", "_")
                        .replace("Z", "") +
                      ".pdf";
                    this.setState(
                      {
                        backdropOpen: false,
                        open: true,
                        isError: false,
                        message: "Manifest generated successfully",
                        selected: [],
                        isLoadingBulkManifest: false,
                      },
                      () => {
                        saveSync(data.pdfFile, fileName);
                        setTimeout(() => {
                          this.setState({
                            open: false,
                          });
                        }, 3000);
                      }
                    );
                  })
                  .catch((error) => {
                    this.setState(
                      {
                        open: true,
                        isError: true,
                        message: "error in generating manifest",
                        regenerateInvoice: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            open: false,
                          });
                        }, 3000);
                      }
                    );
                  });
              }
            );
          })
          .catch((error) => {
            this.setState(
              {
                isLoadingBulkManifest: false,
                open: true,
                isError: true,
                message: error.response
                  ? error.response.data.message
                  : "server error",
                backdropOpen: false,
              },
              () => {
                setTimeout(() => {
                  this.setState(
                    {
                      open: false,
                    },
                    () => {
                      this.getOrderCount();
                      this.getOrders();
                      this.getWarehouseAddress();
                    }
                  );
                }, 3000);
              }
            );
          });
      }
    );
  };
  BulkInvoice = () => {
    this.setState(
      {
        isGeneratingInvoice: true,
        backdropOpen: true,
        regenerateInvoice: true,
      },
      () => {
        const requestOptions = {
          method: "Post",
          headers: authHeader(),
          data: {
            vendor_order_id: this.state.selected,
            multiple: true,
          },
        };
        return axios(apiUrl + "/generate_invoice", requestOptions)
          .then((data) => {
            this.setState(
              {
                isGeneratingInvoice: false,
              },
              () => {
                let aselected = this.state.selected;
                let newUrl = [];
                aselected.map((item) => {
                  return newUrl.push(item);
                });
                setTimeout(() => {
                  const requestOptions = {
                    method: "post",
                    headers: authHeader(),
                    data: {
                      vendor_order_id: newUrl,
                    },
                  };
                  return axios(apiUrl + "/download_invoice", requestOptions)
                    .then((data) => {
                      this.setState(
                        {
                          DownloadInvoiceData: data.data.data,
                        },
                        () => {
                          mergeBetweenPDF
                            .mergeBetweenPDF(this.state.DownloadInvoiceData)
                            .then((data) => {
                              const fileName =
                                "output_merge_" +
                                new Date()
                                  .toISOString()
                                  .replace(":", "_")
                                  .replace("T", "_")
                                  .replace("Z", "") +
                                ".pdf";
                              this.setState(
                                {
                                  backdropOpen: false,
                                  open: true,
                                  isError: false,
                                  message: "invoice generated successfully",
                                  selected: [],
                                  regenerateInvoice: false,
                                },
                                () => {
                                  saveSync(data.pdfFile, fileName);
                                  setTimeout(() => {
                                    this.setState({
                                      open: false,
                                    });
                                  }, 3000);
                                }
                              );
                            })
                            .catch((error) => {
                              this.setState(
                                {
                                  open: true,
                                  isError: true,
                                  message: "error in generating invoice",
                                  regenerateInvoice: false,
                                },
                                () => {
                                  setTimeout(() => {
                                    this.setState({
                                      open: false,
                                    });
                                  }, 3000);
                                }
                              );
                            });
                        }
                      );
                    })
                    .catch((error) => {
                      this.setState(
                        {
                          open: true,
                          isError: true,
                          message: "error in generating invoice",
                          backdropOpen: false,
                          regenerateInvoice: false,
                        },
                        () => {
                          setTimeout(() => {
                            this.setState({
                              open: false,
                            });
                          }, 3000);
                        }
                      );
                    });
                });
              },
              this.state.selected * 1000 + 3000
            );
          })
          .catch((error) => {
            this.setState({
              isGeneratingInvoice: false,
              regenerateInvoice: false,
            });
          });
      }
    );
  };

  setOrder = (e) => {
    this.setState({
      orderInc: e,
    });
  };
  setOrderBy = (e) => {
    this.setState({
      orderBy: e,
    });
  };

  handleRequestSort = (event, property) => {
    this.setState(
      {
        orderBy: property,
      },
      () => {
        const isAsc =
          this.state.orderBy === property && this.state.orderInc === "asc";
        this.setState(
          {
            orderInc: isAsc ? "desc" : "asc",
            setOrderBy: property,
          },
          () => {
            this.getOrders();
          }
        );
      }
    );
  };
  openOrderInfo = (e) => {
    // console.log(e.target.id);
    this.setState({
      detailId: e.currentTarget.id,
      orderDetailVisible: true,
    });
  };
  closeOrderInfo = () => {
    this.setState({
      detailId: "",
      orderDetailVisible: false,
    });
  };
  handlePurchaseInvoice = (e) => {
    this.setState({
      p_invoice: e.target.value,
    });
  };
  setPurchaseInvoice = (e) => {
    let id = e.currentTarget.id;
    let title = e.currentTarget.title;
    this.setState(
      {
        openPurchaseInvoice: true,
        purchaseId: id,
        prevPurchaseId: id,
        action: title,
      },
      () => {
        this.purchase_invoice.current.focus();
      }
    );
  };
  // handleSetPurchaseInvoice=(e)=>{
  //   this.setState({
  //     p_submitted : true
  //   },()=>{
  //     if(this.state.p_submitted && this.state.p_invoice){
  //       let orderdata = this.state.orderData;
  //       orderdata[this.state.purchaseId].purchaseInvoice_no = this.state.p_invoice;
  //       let vendor_order_id = orderdata[this.state.purchaseId].vendor_order_id;
  //       this.setState({
  //         openPurchaseInvoice : false,
  //         orderData : orderdata,
  //         p_invoice : ''
  //       },()=>{
  //         const requestOptions = {
  //           method : "post",
  //           headers : authHeader(),
  //           data : {
  //             purchase_invoice : this.state.purchaseInvoice_no,
  //             purchase_invoice_date : this.state.selectedDate,
  //             vendor_order_id : vendor_order_id,
  //             action : this.state.action
  //           }
  //         }
  //         return axios(apiUrl+'/handle_purchase_invoice',requestOptions)
  //           .then(data=>{
  //             this.setState({
  //               action : ''
  //             })
  //           }).catch(error=>{
  //             this.setState({
  //               action : ''
  //             })
  //           })
  //       })
  //     }else{
  //       this.setState({
  //         purchaseId : '',
  //         prevPurchaseId : '',
  //         openPurchaseInvoice : false
  //       })
  //     }
  //   })
  // }
  handleEditPurchaseInvoice = (e) => {
    let index = e.target.id;
    let orderData = this.state.orderData;
    let currentPurchase = orderData[index];
    let currentPurchaseInvoice = currentPurchase.purchaseInvoice_no;
    let title = e.target.title;
    this.setState(
      {
        openPurchaseInvoice: true,
        purchaseId: Number(index),
        p_invoice: currentPurchaseInvoice,
        action: this.state.title,
      },
      () => this.purchase_invoice.current.focus()
    );
  };

  handleSearchOrder = (e) => {
    this.setState({
      search: e.target.value,
    });
  };
  searchOrderBy = () => {
    this.setState(
      {
        isLoadingOrder: true,
        orderSubmitted: true,
      },
      () => {
        if (this.state.orderSubmitted && this.state.search.length > 0) {
          const requestOptions = {
            method: "post",
            headers: authHeader(),
            data: {
              search: this.state.search,
              search_by: this.state.search_by,
            },
          };
          return axios(apiUrl + "/search_order", requestOptions)
            .then((data) => {
              this.setState({
                searchData: data.data.data.order_result,
                isLoading: false,
                searchOrderVisible: true,
              });
            })
            .catch((error) => {
              // console.log(error.response.data.message)
              this.setState({
                isLoading: false,
                searchOrderVisible: true,
                // orderSearchMessage : error.response.data.message
              });
            });
        }
      }
    );
  };

  handledclick = (e) => {
    if (e.target.name != "Y32d3") {
      this.setState({
        searchOrderVisible: false,
        searchData: [],
      });
    }
  };

  ClosePurchaseInvoiceDialog = () => {
    this.setState({
      purchaseInvoiceModalopen: false,
      purchaseInvoiceNumber: "",
      purchase_invoice_date: "",
      purchase_invoice_url: "",
      selectedVendorOrderId: "",
      p_invoiceSubmitted: false,
      purchaseInvoicePdf: null,
    });
  };
  CloseShippingAddressDialog = () => {
    this.setState({
      shippingAddressModalopen: false,
      shipping_firstname: "",
      shipping_lastname: "",
      shipping_address_1: "",
      shipping_city: "",
      shipping_postcode: "",
      shipping_zone: "",
      shipping_country: "",
    });
  };
  handleDateChange = (e) => {
    this.setState({
      selectedDate: e,
    });
  };
  OpenPurchaseInvoiceUpload = (e) => {
    let index = e.currentTarget.id;
    let purchaseActiontitle = e.currentTarget.getAttribute("title");
    let vendor_order_id = this.state.orderData[index].vendor_order_id;
    this.setState({
      selectedVendorOrderId: vendor_order_id,
      purchaseActiontitle: purchaseActiontitle,
      purchaseInvoiceModalopen: true,
      purchaseId: index,
    });
  };
  OpenShippingAddressModal = (e) => {
    let index = e.currentTarget.id;
    let orderDetail = this.state.orderData[index].orderDetail.result[0];
    this.setState({
      shippingAddressModalopen: true,
      shipping_firstname: orderDetail.shipping_firstname,
      shipping_lastname: orderDetail.shipping_lastname,
      shipping_address_1: orderDetail.shipping_address_1,
      shipping_city: orderDetail.shipping_city,
      shipping_postcode: orderDetail.shipping_postcode,
      shipping_zone: orderDetail.shipping_zone,
      shipping_country: orderDetail.shipping_country,
    });
  };
  handleInvoiceUpload = (e) => {
    if (e.target.files[0]) {
      this.setState({
        purchaseInvoicePdf: e.target.files[0],
      });
    } else {
      this.setState({
        purchaseInvoicePdf: null,
      });
    }
  };
  handlePurchaseInvoiceUpload = () => {
    this.setState(
      {
        p_invoiceSubmitted: true,
        invoiceLoading: true,
      },
      () => {
        if (this.state.p_invoiceSubmitted && this.state.purchaseInvoiceNumber) {
          let requestOptions;
          let api_endpoint = "";

          var d = new Date(this.state.selectedDate);
          var date = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
          var dateValidUpto = year + "-" + month + "-" + date;
          let loginedUserDetails = JSON.parse(localStorage.getItem("seller"));
          if (this.state.purchaseInvoicePdf !== null) {
            let formData = new FormData();
            formData.append(
              "purchase_invoice_number",
              this.state.purchaseInvoiceNumber
            );
            formData.append("purchase_invoice_date", dateValidUpto);
            formData.append("vendor_order_id", [
              this.state.selectedVendorOrderId,
            ]);
            formData.append("title", this.state.purchaseActiontitle);
            formData.append("document", this.state.purchaseInvoicePdf);
            formData.append("vendorName", loginedUserDetails.name);
            requestOptions = {
              method: "post",
              headers: authHeader(),
              data: formData,
            };
            api_endpoint = "/handle_purchase_invoice_doc";
          } else {
            // this.state.purchaseInvoicePdf
            this.setState({
              backdropOpen: false,
              invoiceLoading: false,
            });
            return window.alert("Please upload invoice pdf");
            // requestOptions = {
            //   method: "post",
            //   headers: authHeader(),
            //   data: {
            //     purchaseInvoiceNumber: this.state.purchaseInvoiceNumber,
            //     purchase_invoice_date: dateValidUpto,
            //     vendor_order_id: this.state.selectedVendorOrderId,
            //     title: this.state.purchaseActiontitle,
            //     vendorName: loginedUserDetails.name,
            //   },
            // };
            // api_endpoint = "/handle_purchase_invoice";
          }

          //star war
          if (
            !moment(this.state.selectedDate).isValid() ||
            moment(this.state.selectedDate).isAfter(moment())
          ) {
            this.setState({
              backdropOpen: false,
              invoiceLoading: false,
            });
            return window.alert("Please select valid date");
          }
          return axios(apiUrl + api_endpoint, requestOptions)
            .then((data) => {
              let changedOrderData = this.state.orderData;
              changedOrderData[this.state.purchaseId].purchaseInvoice_no =
                this.state.purchaseInvoiceNumber;
              changedOrderData[this.state.purchaseId].purchase_invoice_date =
                this.state.selectedDate;
              changedOrderData[this.state.purchaseId].purchase_invoice_url =
                this.state.purchaseInvoicePdf;
              if (data.data.data != null) {
                if (data.data.data.length > 0) {
                  changedOrderData[this.state.purchaseId].purchase_invoice_url =
                    data.data.data;
                }
              }
              this.setState({
                purchaseInvoiceModalopen: false,
                invoiceLoading: false,
              });
            })
            .catch((error) => {
              console.log("error", error);
              this.setState({
                invoiceLoading: false,
              });
            });
        } else {
          if (
            this.state.p_invoiceSubmitted &&
            !this.state.purchaseInvoiceNumber
          ) {
            this.setState(
              {
                invoiceLoading: false,
              },
              () => {
                this.purchaseInvoiceNumber.current.focus();
              }
            );
          }
        }
      }
    );
  };
  EditPurchaseInvoice = (e) => {
    let index = e.currentTarget.id;
    let title = e.currentTarget.getAttribute("title");
    let vendor_order_id = this.state.orderData[index].vendor_order_id;
    let purchaseInvoiceNumber = this.state.orderData[index].purchaseInvoice_no;
    let purchase_invoice_date = this.state.orderData[index]
      .purchase_invoice_date
      ? this.state.orderData[index].purchase_invoice_date
      : new Date();
    let purchase_invoice_url = this.state.orderData[index].purchase_invoice_url;
    this.setState({
      purchaseInvoiceModalopen: true,
      purchaseActiontitle: title,
      selectedDate: purchase_invoice_date,
      //purchaseInvoicePdf : purchase_invoice_url,
      purchaseInvoicePdf: null,
      purchaseInvoiceNumber,
      selectedVendorOrderId: vendor_order_id,
      purchaseId: index,
    });
  };
  handlePurchaseInvoiceChange = (e) => {
    this.setState(
      {
        purchaseInvoiceNumber: e.target.value,
      },
      () => {
        const requestOptions = {
          method: "post",
          headers: authHeader(),
          data: {
            purchase_invoice_no: this.state.purchaseInvoiceNumber,
          },
        };
        return axios(apiUrl + "/get_purchase_invoice_data", requestOptions)
          .then((data) => {
            if (data.data.data.length > 0) {
              // console.log(data.data.data)
              this.setState({
                selectedDate: new Date(data.data.data[0].date_added),
              });
            } else {
              this.setState({
                selectedDate: new Date(),
              });
            }
          })
          .catch((error) => {});
      }
    );
  };

  handleCancelReasonChange = (e) => {
    this.setState(
      {
        cancel_reason: e.target.value,
      },
      () => {
        if (this.state.cancel_reason === "Others") {
          this.other_reason.current.focus();
        } else if (this.state.cancel_reason === "Out of Stock") {
          let orderData = this.state.orderData;
          let selected = this.state.selected;
          let orderProductData = this.state.orderProductData;
          orderProductData = orderData.filter((item) => {
            return selected.indexOf(item.vendor_order_id) > -1;
          });
          this.setState({
            orderProductData,
          });
        }
      }
    );
  };

  handleCancelOrders = (e) => {
    this.setState({
      cancelReasonDialogOpen: !this.state.cancelReasonDialogOpen,
      cancel_reason: "",
      cancel_other: "",
      show_success_message: "",
    });
  };
  SubmitCancelOrder = () => {
    this.setState(
      {
        cancel_order_loading: true,
      },
      () => {
        if (this.state.cancel_reason === "Out of Stock") {
          if (this.state.productSelected.length > 0) {
            const requestOptions = {
              method: "POST",
              headers: authHeader(),
              data: {
                cancel_reason: this.state.cancel_reason,
                order: this.state.selected,
                other: "",
                product: this.state.productSelected,
              },
            };
            return axios(apiUrl + "/cancel_order", requestOptions)
              .then((data) => {
                this.setState(
                  {
                    show_success_message:
                      "Selected Products has been marked as out of stock",
                    cancel_reason: "",
                    cancel_other: "",
                    selected: [],
                    productSelected: [],
                    cancel_order_loading: false,
                  },
                  () => {
                    this.getOrders();
                    this.getOrderCount();
                  }
                );
                setTimeout(() => {
                  this.setState({
                    show_success_message: "",
                    cancelReasonDialogOpen: false,
                  });
                }, 3000);
              })
              .catch((error) => {
                this.setState(
                  {
                    cancel_order_loading: false,
                    cancel_error: true,
                    cancel_error_message: error.response.data.message,
                  },
                  () => {
                    setTimeout(() => {
                      this.setState(
                        {
                          cancel_error: false,
                        },
                        () => {
                          setTimeout(() => {
                            this.setState({
                              cancel_error_message: "",
                            });
                          }, 1000);
                        }
                      );
                    }, 3000);
                  }
                );
              });
          } else {
            this.setState(
              {
                cancel_order_loading: false,
                cancel_error: true,
                cancel_error_message: "Select product which are out of stock",
              },
              () => {
                this.modalHeading.current.scrollIntoView({
                  behaviour: "smooth",
                });
                setTimeout(() => {
                  this.setState(
                    {
                      cancel_error: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          cancel_error_message: "",
                        });
                      }, 1000);
                    }
                  );
                }, 3000);
              }
            );
          }
        } else if (this.state.cancel_reason === "Others") {
          if (this.state.cancel_other) {
            const requestOptions = {
              method: "POST",
              headers: authHeader(),
              data: {
                cancel_reason: this.state.cancel_reason,
                order: this.state.selected,
                other: this.state.cancel_other,
              },
            };
            return axios(apiUrl + "/cancel_order", requestOptions)
              .then((data) => {
                console.log(data, "CAPTURE TICKET");
                this.setState(
                  {
                    cancelReasonDialogOpen: false,
                    cancel_reason: "",
                    cancel_other: "",
                    selected: [],
                    productSelected: [],
                    cancel_order_loading: false,
                  },
                  () => {
                    this.getOrders();
                    this.getOrderCount();
                  }
                );
              })
              .catch((error) => {
                this.setState(
                  {
                    cancel_order_loading: false,
                    cancel_error: true,
                    cancel_error_message: error?.response?.data?.message
                      ? error?.response?.data?.message
                      : "Cancel Order Timeout",
                  },
                  () => {
                    setTimeout(() => {
                      this.setState(
                        {
                          cancel_error: false,
                        },
                        () => {
                          setTimeout(() => {
                            this.setState({
                              cancel_error_message: "",
                            });
                          }, 1000);
                        }
                      );
                    }, 3000);
                  }
                );
              });
          } else {
            this.setState(
              {
                cancel_order_loading: false,
                cancel_error: true,
                cancel_error_message: "Specify other reason",
              },
              () => {
                setTimeout(() => {
                  this.setState(
                    {
                      cancel_error: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          cancel_error_message: "",
                        });
                      }, 1000);
                    }
                  );
                }, 3000);
              }
            );
          }
        } else {
          const requestOptions = {
            method: "POST",
            headers: authHeader(),
            data: {
              cancel_reason: this.state.cancel_reason,
              order: this.state.selected,
            },
          };
          return axios(apiUrl + "/cancel_order", requestOptions)
            .then((data) => {
              if (this.state.cancel_reason === "Transfer Price Issue") {
                this.setState(
                  {
                    cancelReasonDialogOpen: false,
                    productSelected: [],
                    selected: [],
                    cancel_order_loading: false,
                  },
                  () => {
                    const requestOptions = {
                      method: "post",
                      headers: authHeader(),
                      data: {
                        vendor_order_id: data.data.data,
                      },
                    };
                    return axios(
                      apiUrl + "/get_vd_order_product",
                      requestOptions
                    )
                      .then((data) => {
                        let pr_data = data.data.data.map((item) => {
                          return item.product_id + "-" + item.name;
                        });
                        this.sendNotification(pr_data);
                        this.getOrders();
                        this.getOrderCount();
                      })
                      .catch((error) => {
                        this.setState({
                          cancel_reason: "",
                        });
                      });
                  }
                );
              } else {
                this.setState(
                  {
                    cancelReasonDialogOpen: false,
                    cancel_reason: "",
                    cancel_other: "",
                    selected: [],
                    productSelected: [],
                    cancel_order_loading: false,
                  },
                  () => {
                    this.getOrders();
                    this.getOrderCount();
                  }
                );
              }
            })
            .catch((error) => {
              this.setState(
                {
                  cancel_order_loading: false,
                  cancel_error: true,
                  cancel_error_message: error.response.data.message,
                },
                () => {
                  setTimeout(() => {
                    this.setState(
                      {
                        cancel_error: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            cancel_error_message: "",
                          });
                        }, 1000);
                      }
                    );
                  }, 3000);
                }
              );
            });
        }
      }
    );
  };
  sendNotification = (product_id) => {
    const requestOptions = {
      method: "get",
      headers: authHeader(),
    };
    return axios(apiUrl + "/get_vendor_name", requestOptions)
      .then((data) => {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          data: {
            template: "TRANSFERPRICEISSUE",
            name: data.data.data[0].vendor_name,
            product_id: product_id.map((item) => {
              return item + " <br/>";
            }),
          },
        };
        // console.log('reeqq',requestOptions);
        return axios(apiUrl + "/send-notification", requestOptions)
          .then((data) => data)
          .catch((error) => error)
          .catch((error) => error);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
   const Allresult = manageOrders.call(
      this,
      this.length,
      this.width,
      this.height,
      this.weight,
      this.purchase_invoice,
      this.searchOrder,
      this.purchaseInvoiceNumber,
      this.other_reason,
      this.modalHeading
    );
    return (
    <>
      {Allresult}
    </>
    )
  }
}

export default withSnackbar(withStyles(styles)(ManageOrders));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions';
import dashboard from './Dashboard.jsx';
import siteConfig from '../../config';
import axios from 'axios';
import { authHeader } from '../../helper/auth-header';
import './Dashboard.css';

const apiUrl = siteConfig.apiUrl;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            open:false,
            message:'',
            Iserror:false,
            menu:[],
            logo:[],
            user:[],
            notificationCount:'',
            userMenu:[],
            height:0,
            accountStatus : 0
        }
    }
    getTopHeaderItems = async () =>{
        let HeaderData = localStorage.getItem('header_navigation');
        let ParsedHeaderData = JSON.parse(HeaderData);
        // console.log(ParsedHeaderData);
        if(ParsedHeaderData){
            this.setState({
                menu : ParsedHeaderData.menu,
                logo : ParsedHeaderData.logo,
                user : ParsedHeaderData.profile,
                userMenu : ParsedHeaderData.profile.submenu,
                notificationCount : ParsedHeaderData.profile.notification
            })
        }else{
            const requestOptions = {
                method:"GET",
                headers : authHeader()
            }
            return axios(apiUrl+'/get-top-header',requestOptions)
            .then(data=>{
                this.setState({
                    menu : data.data.data.menu,
                    logo : data.data.data.logo,
                    user : data.data.data.profile,
                    userMenu : data.data.data.profile.submenu,
                    notificationCount : data.data.data.notification
                },()=>{
                    let HeaderData = JSON.stringify(data.data.data);
                    localStorage.setItem('header_navigation',HeaderData)
                })
            })
            .catch(error=>{
                this.setState({
                    Iserror:true,
                    open:true,
                    message: error.response.data.message
                },()=>{
                    setTimeout(() => {
                        this.setState({
                            open:false
                        })
                    }, 2000);
                })
            })
        }
    }

    componentDidMount(){
        // console.log(localStorage.getItem('is_superUser'));
        let SuperUser = localStorage.getItem('is_superUser');
        if(SuperUser === null){
            this.getSuperUser()
        }
        this.getTopHeaderItems()
        this.getAccountStatus()
        this.setState({
            height:window.innerHeight
        })
    }
    

    getSuperUser = () => {
        let requestOptions = {
            method : 'get',
            headers : authHeader()
        }
        return axios(apiUrl+'/check_super_user',requestOptions)
        .then(data=>{
                let seller = localStorage.getItem('seller');
                localStorage.setItem('is_superUser',JSON.parse(data.data.data[0].is_superuser));
                if(data.data.data[0].is_superuser > 0){
                    localStorage.setItem('superUser',seller)
                    let sellerUser = JSON.parse(seller);
                    localStorage.setItem('selectedVendor',sellerUser.user_id);
                    // console.log(sellerUser);
                }
        })
        .catch(error=>console.log(error))
    }


    getAccountStatus = () => {
        let requestOptions = {
            method:"get",
            headers : authHeader(),
        }
        return axios(apiUrl+'/account_status',requestOptions)
        .then(data=>{
            this.setState({
                accountStatus : data.data.data[0].is_account_verified
            })
        })
        .catch(error=>{
            console.log(error)
        })
    }
    
    logout = () => {
        this.props.logout();
    }

    getSelectedBrand=(e)=>{
        if(e){
            let superUser = JSON.parse(localStorage.getItem('superUser'));
        let token = superUser.access_token;
        // console.log(superUser);
        const requestOptions = {
            method : 'post',
            headers :{
                'Authorization' : 'Bearer ' + token
            },
            data : {
                user_id : e.vendor_id
            }
        }
        return axios(apiUrl+'/getUserFullData',requestOptions)
        .then(data=>{
            localStorage.setItem('seller',JSON.stringify(data.data.data))
            localStorage.setItem('selectedVendor',e.vendor_id)
            window.location.reload(true);
        })
        .catch(error=>console.log(error))
        }
    }


    render() {
        return dashboard.call(this);
    }
}
 
const actionCreator = {
    logout : userActions.logout
}

export default connect(null,actionCreator)(Dashboard);
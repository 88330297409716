import React, { Component } from 'react';
import myaccount from './MyAccount.jsx';
import { authHeader } from '../../../helper/auth-header.js';
import siteConfig from '../../../config';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles';

const apiUrl = siteConfig.apiUrl;
const image_url = siteConfig.image_url;
const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const styles = theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        marginTop:30,
        [theme.breakpoints.down('md')]:{
            display:'block'
        },
    },
    leftTab:{
        backgroundColor:"#FFF",
        width:300,
        boxShadow: '0 2px 4px 0 rgba(0,0,0,.15)',
        borderRadius:4,
        padding:'10px 0',
        height:250,
        [theme.breakpoints.down('md')]:{
            width:'100%',
            marginBottom:20
        },
    },
    tabPanel:{
        position:'relative'
    },
    indicator:{
        left:0
    },
    scrollButtons:{
        textTransform :'lowercase'
    },
    wrap:{
        alignItems: 'baseline',
    },
    rootButton:{
        paddingLeft: 25,
        textTransform:'capitalize',
        fontSize:16,
        fontWeight:400
    },
    selectedTab:{
        color:'#3069f0',
        backgroundColor:'#eaeaea'
    },
    rightPanel:{
        backgroundColor:"#FFF",
        marginLeft:30,
        width:'100%',
        boxShadow:' 0 2px 4px 0 rgba(0,0,0,.15)',
        borderRadius:5,
        position:'relative',
        marginBottom:20,
        [theme.breakpoints.down('md')]:{
            marginLeft:0
        },
    },
    cont:{
        width:'65%'
    },
    UserPaper:{
        padding:'20px 10px',
        margin:'10px 0'
    },
    heading : {
        fontSize:20,
        fontWeight: 400,
        display:'inline-block',
        color:"#555",

    },
    user_details_u:{
        listStyleType : 'none',
        padding:0,
    },
    user_details_l:{
        lineHeight : "40px"
    },
    conts:{
        [theme.breakpoints.up('md')]:{
            width:'65%',
        },
        width:"100%"
    },
    notificationPaper:{
        display:'inline-block',
        textAlign:'left',
        padding:20
    },
    addform:{
        marginTop:20,
        padding:20
    }
})


class MyAccount extends Component{
    constructor(props){
    super(props);
        this.state={
            isLoading:false,
            isLoadingUpdate:false,
            userInfo : [],
            address:[],
            IsError:false,
            message:'',
            open:false,
            value:0,
            name : '',
            account_no:'',
            ifsc:'',
            branch:'',
            bank_name:'',
            bank_city:'',
            bank_state:'',
            IsAccountEditing:false,
            isLoadingAccount:false,
            openDialog:false,
            mobile:'',
            otp:'',
            message : '',
            snackOpen:false,
            accountSubmitted: false,
            user_id : 0,
            isLoadingAddress:false,
            cheque_image:null,
            ToggleNotification : false,
            emailList:[],
            newEmail:'',
            submitEmail : false,
            notificationOpen:false,
            notificationMessage:'',
            notificationError:false,
            vendor_name:'',
            vendor_email:'',
            vendor_telephone:'',
            blankResponse : false
        }
        
    }
    componentDidMount(){
        this.setState({
            isLoading:true
        },()=>{
            this.getUserInfo();
            this.getWarehouseAddress();
        })
    }

    
    openDialog = () =>{
        this.setState({
            openDialog : true
        })
    }
    closeDialog = () => {
        this.setState({
            openDialog : false
        })
    }
    setImage=(e)=>{
        this.setState({
            cheque_image : e
        })
    }
    refreshPage=()=>{
        this.setState({
            isLoading:true,
            blankResponse : false
        },()=>{
            this.getUserInfo();
            this.getWarehouseAddress();
        })
    }
    getUserInfo = () => {
        const requestOptions = {
            method:'GET',
            headers : authHeader()
        }
        return axios(apiUrl+'/get-user-info',requestOptions)
        .then(data=>{
            // console.log(data.data.data.notification_email)
            this.setState({
                isLoading:false,
                vendor_name : data.data.data.name == "undefined" || data.data.data.name == null ? '' : data.data.data.name,
                vendor_email : data.data.data.email == "undefined" || data.data.data.email == null ? '' : data.data.data.email,
                vendor_telephone: data.data.data.telephone == "undefined" || data.data.data.telephone == null ? '' : data.data.data.telephone,
                ToggleNotification : data.data.data.is_notification_enable === 0 ? false : true,
                emailList : data.data.data.notification_email != null || this.state.notification_email ? data.data.data.notification_email.split(','):[]
            })
        })
        .catch(error=>{
            if(error.response){
                this.setState({
                    isLoading:false,
                    open:true,
                    message:error.response ? error.response.data.message : 'network error',
                    IsError:true
                },()=>{
                    setTimeout(() => {
                        this.setState({
                            open:false,
                            message:'',
                            IsError:false
                        })
                    }, 3000);
                })
            }else{
                this.setState({
                    blankResponse : true,
                    isLoading : false
                })
            }
        });
    }
    getWarehouseAddress = async () => {
        this.setState({
            isLoadingAddress : true,
        },()=>{
            const requestOptions = {
                method:'get',
                headers: authHeader()
            }
            return axios(apiUrl + '/warehouse-details',requestOptions)
            .then(data=>{
                // console.log(data.data.data);
                this.setState({
                    address : data.data.data,
                    isLoading:false
                },()=>{
                    setTimeout(() => {
                        this.setState({
                            isLoadingAddress:false
                        })
                    }, 1000);
                })
            })
            .catch(error=>{
                this.setState({
                    isLoadingAddress:false,
                    isLoading:false
                })
            })
        })
    }
    handleTabChange=(e,id)=>{
        this.setState({
            value:id,
            IsAccountEditing:false
        })
    }
    handleChange=(e)=>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    resendOTP = ()=>{
        return new Promise(async (resolve,reject)=>{
            const requestOptions = {
                method:"POST",
                data:{
                    username : this.state.mobile,
                    key : 'otp_mobile',
                    user_id : 0
                }
            }
            return axios(apiUrl+"/resend-otp",requestOptions)
            .then(data=>resolve(data))
            .catch(error=>reject(error))
        })
    }
    setNotification=()=>{
        this.setState({
            ToggleNotification : !this.state.ToggleNotification
        },()=>{
            const requestOptions={
                method : "POST",
                headers : authHeader(),
                data : {
                    notification : this.state.ToggleNotification
                }
            }
            return axios(apiUrl+'/toggle_notification',requestOptions)
            .then(data=>{
                this.setState({
                    notificationOpen:true,
                    notificationMessage: this.state.ToggleNotification === true ? 'notification enabled':' notification disabled'
                },()=>{
                    setTimeout(() => {
                        this.setState({
                            notificationOpen:false,
                            
                        })
                    }, 3000);
                })
            })
            .catch(error=>console.log(error))
        })
    }
    handleDelete=(e)=>{
        let index = this.state.emailList.indexOf(e);
        let newList = this.state.emailList.splice(index,1);
        this.setState({
            emailList : this.state.emailList.filter(item=>{
                return item !== newList
            })
        }) 
    }
    addId=(e)=>{
        e.preventDefault();
        // console.log(this.state.emailList);
        this.setState({
            submitEmail : true
        },()=>{
            if(this.state.submitEmail && this.state.newEmail.length>0 && regex.test(this.state.newEmail)){
                let newEmailList = [];
                newEmailList = this.state.emailList;
                let newList = newEmailList.push(this.state.newEmail);
                // console.log(this.state.newEmail,newList)
                this.setState({
                    emailList : newEmailList,
                    newEmail : '',
                    submitEmail : false
                })
            }
        })
    }
    saveEmail=()=>{
        const requestOptions = {
            method : "POST",
            headers : authHeader(),
            data : {
                email : this.state.emailList
            }
        }
        return axios(apiUrl+'/save_email',requestOptions)
        .then(data=>{
            this.setState({
                notificationOpen:true,
                notificationMessage: 'update success'
            },()=>{
                setTimeout(() => {
                    this.setState({
                        notificationOpen:false,
                    })
                }, 3000);
            })
        })
        .catch(error=>{
            this.setState({
                notificationOpen:true,
                notificationError:true,
                notificationMessage: 'error in saving email address'
            },()=>{
                setTimeout(() => {
                    this.setState({
                        notificationOpen:false,
                        notificationError:false
                    })
                }, 3000);
            })
        })
    }
    render(){
        return myaccount.call(this);
    }
}
export default withStyles(styles)(MyAccount);
import React from 'react';
import { Container,Paper,Tabs,Tab,Typography,Box,TextField,Grid,FormControl,InputLabel,MenuItem,Button,Select,CircularProgress,Snackbar,FormLabel,Divider} from '@material-ui/core';
import ProductDescription from './Product_description/product_description';
import VendorInfo from './VendorInfo/vendorInfo';
import Alert from '@material-ui/lab/Alert';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'

function TabPanel(props) {
    const { children, value,classes, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
        className={classes.rightPanel}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }


  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

function editProduct(){
    const { classes } = this.props;
    return (
        <React.Fragment>
            <Container className={classes.cont}>
                <h1 className={classes.heading}>Edit product</h1>
                <div className={classes.root}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={this.state.value}
                        onChange={this.handleChange}
                        aria-label="Vertical tabs example"
                        className={classes.leftTab}
                        indicatorColor="primary"
                        classes={{
                            indicator:classes.indicator,
                            root:classes.scrollButtons
                        }}
                    >
                        <Tab label="Vendor Info" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(0)} />
                        <Tab label="General" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(1)} />
                        <Tab label="Data" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(2)} />
                        <Tab disabled label="option" classes={{root:classes.rootButton,wrapper:classes.wrap,selected:classes.selectedTab}} {...a11yProps(3)} />
                    </Tabs>
                    <TabPanel value={this.state.value} index={0} classes={classes}>
                     <div className={classes.m_name}>
                         <FormLabel>Vendor Name : </FormLabel>
                         <span>{this.state.manufacturer_name}</span>

                     </div>
                     <Divider />
                        <form onSubmit={this.submitHandler}>
                                <TextField 
                                name="transfer_price"
                                variant="outlined"
                                label="Transfer Price"
                                fullWidth
                                className={classes.m_b_t_20}
                                placeholder="0"
                                value={this.state.transfer_price}
                                disabled={true}
                                onChange={this.handleValueChange}
                                inputRef={this.transfer_price}
                                // error={this.state.submitted && !this.state.transfer_price}
                                // helperText = {this.state.submitted && !this.state.transfer_price ? "Enter valid transfer price":""}
                                />
                                <TextField 
                                name="quantity"
                                variant="outlined"
                                label="Quantity"
                                fullWidth
                                value={this.state.quantity}
                                onChange={this.handleValueChange}
                                className={classes.mb_20}
                                inputRef={this.quantity}
                                error={this.state.submitted && (!this.state.quantity > 0 ) || isNaN(this.state.quantity)}
                                helperText = {this.state.submitted && (!this.state.quantity > 0 || isNaN(this.state.quantity)) ? "Enter valid quantity":""}
                                />
                                <TextField 
                                name="minimum_quantity"
                                variant="outlined"
                                label="Minimum Quantity"
                                className={classes.mb_20}
                                fullWidth
                                value={this.state.minimum_quantity}
                                onChange={this.handleValueChange}
                                inputRef={this.min_quantity}
                                error={this.state.submitted && !this.state.minimum_quantity}
                                helperText = {this.state.submitted && !this.state.minimum_quantity ? "Enter valid quantity":""}
                                />
                                <TextField
                                name="maximum_quantity" 
                                variant="outlined"
                                label="Maximum Quantity"
                                className={classes.mb_20}
                                fullWidth
                                value={this.state.maximum_quantity}
                                onChange={this.handleValueChange}
                                inputRef={this.max_quantity}
                                />
                                <TextField 
                                name="pack_quantity"
                                variant="outlined"
                                label="Pack Quantity"
                                className={classes.mb_20}
                                fullWidth
                                disabled
                                value={this.state.pack_quantity}
                                />
                                
                            {/* <FormControl variant="outlined" className={classes.fullWidth}>
                                <InputLabel id="demo-simple-select-outlined-label" ref={this.inputLabel}>
                                Out Of Stock Status
                                </InputLabel>
                                <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={this.state.stock_status_id}
                                onChange={this.handleValueChange}
                                labelWidth={this.state.labelWidth}
                                onClick={this.setLabelWidth}
                                fullWidth
                                className={classes.mb_20}
                                name="stock_status_id"
                                >
                                {
                                    this.state.stock_list.map(item=>{
                                        return <MenuItem value={item.stock_status_id} key={item.stock_status_id}>{item.name}</MenuItem>
                                    })
                                }
                                </Select>
                            </FormControl> */}
                            <FormControl variant="outlined" className={classes.fullWidth}>
                                <InputLabel id="select_status" ref={this.StockLabel}>
                                    Status
                                </InputLabel>
                                <Select
                                labelId="demo-simple-select-outlined-label"
                                id="select_status"
                                value={this.state.status}
                                onChange={this.handleValueChange}
                                labelWidth={50}
                                // onClick={this.setStatusLabelWidth}
                                fullWidth
                                className={classes.mb_20}
                                name="status"
                                >
                                <MenuItem value="1" >Enable</MenuItem>
                                <MenuItem value="0" >Disable</MenuItem>
                                </Select>
                            </FormControl>
                                <Button disabled={this.state.isSaving} variant="contained" type="submit" color="primary">{
                                this.state.isSaving
                                    ?
                                    <CircularProgress color="inherit" size={20} />
                                    :
                                    "Save"
                                }</Button>
                            </form>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1} classes={classes}>
                        {/* <div>
                            <label>Product Name</label>
                            <h4>{this.state.product_name}</h4>
                        </div> */}
                        <div className={classes.m_name}>
                         <FormLabel>Product Name : </FormLabel>
                         <h4>{this.state.product_name}</h4>

                     </div>
                        <div className={classes.desc_div}>
                        <FormLabel className={classes.desc}>Product Description : </FormLabel>
                        { ReactHtmlParser(this.state.product_description) }
                        </div>
                        {/* <TextField
                            label="Product_description"
                            variant="outlined"
                            fullWidth
                            value={ this.state.product_description}
                            disabled
                            rowsMax="20"
                            multiline
                        /> */}
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2} classes={classes}>
                        <ProductDescription 
                            product_model={this.state.product_model}
                            product_sku={this.state.product_sku}
                            product_price={this.state.product_price}
                            selectedTax={this.state.selectedTax}
                            product_length={this.state.product_length}
                            product_width={this.state.product_width}
                            product_height={this.state.product_height}
                            product_length_class ={this.state.product_length_class}
                            product_weight ={this.state.product_weight}
                            product_weight_class ={this.state.product_weight_class}
                            status ={this.state.product_status}
                        />
                    </TabPanel>
                    <TabPanel value={this.state.value} index={3} classes={classes}>
                        Text
                    </TabPanel>
                    </div>
            </Container>
            <Snackbar open={this.state.open} >
                <Alert severity={this.state.error ? "error" : "success"} >{this.state.message}</Alert>
            </Snackbar>
        </React.Fragment>
    )
}

export default editProduct;
import React, { Component } from 'react';
import warehouseDetails from './warehouseDetails.jsx';
import {withStyles} from '@material-ui/core/styles';
import axios from 'axios';
import siteConfig from '../../../../config';
import { authHeader } from '../../../../helper/auth-header.js';

const apiUrl = siteConfig.apiUrl;

const styles = theme =>({
    addressUl:{
        listStyleType:'none',
        padding:0
    },
    addressList:{
        border:'1px solid #eaeaea',
        boxShadow:' 0 2px 4px 0 rgba(0,0,0,.15)',
        borderRadius:5,
        marginBottom:10,
        padding:10,
        position:'relative'
    },
    full_address:{
        color: "#666",
        fontSize: 14,
        textAlign: "left",
        width: '58%',
        whiteSpace: "none",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block",
        verticalAlign: "bottom",
        marginLeft:5
    },
    full_time:{
        color: "#666",
        fontSize: 14,
        textAlign: "left",
        width: '50%',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block",
        verticalAlign: "bottom",
        marginLeft:5
    },
    full_address_weekly:{
        display:'inline-block',
        textAlign:'left',
        fontSize:14,
        width:'auto',
        color: '#666',
        textTransform : 'capitalize',
        marginLeft:5
    },
    f_right:{
        display:'inline-block',
        textAlign:'right',
        position:'absolute',
        right:10,
        top:10
    },
    add_heading:{
        width:'100%',
        textAlign:'left',
        margin:0,
        fontSize:16,
        marginLeft: 10,
    },
    address_ul:{
        display:'inline-block',
        padding:0,
        listStyleType:'none',
        width:'100%'
    },
    address_li:{
        lineHeight:'25px',
        width:'100%'
    },
    mar_5:{
        marginRight: 5,
    },
    mar_auto:{
        margin:'0 auto'
    },
    mar_top_10:{
        marginTop:10,
        textAlign:'center'
    },
    text_center:{
        textAlign:'center'
    },
    mb_10:{
        marginBottom:10
    },
    ml_5:{
        marginLeft:5
    },
    popover: {
        pointerEvents: 'none',
      },
      paper: {
        padding: theme.spacing(1),
      },
      address_icon:{
          cursor:'pointer',
          verticalAlign:'text-bottom',
          marginLeft:5
      },
      verified:{
          backgroundColor : 'green',
          color:'#fff',
          padding:5,
          fontSize : 14
      },
      not_verified:{
        backgroundColor : '#ca3d3d',
        color:'#fff',
        padding:5,
        borderRadius : 5,
        fontSize : 14
      }
})

class WarehouseDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            itemId : 0,
            open:false,
            add_edit:false,
            address:'',
            pincode:'',
            city:'',
            State:'',
            gstin:'',
            address_id:0,
            start_time:new Date('2020-01-18T09:00:00'),
            end_time:new Date('2020-01-18T18:00:00'),
            weekly_off:[],
            selectedOptions:[],
            message:'',
            submitted:false,
            openDialog:false,
            deleteDialog : false,
            isError : false,
            Popover : null,
            popoveropen : false,
            fullAddress : ""
        }
        this.addressRef = React.createRef('address');
        this.pincodeRef = React.createRef('pincode');
        this.cityRef = React.createRef('city');
        this.StateRef = React.createRef('State');
        this.gstinRef = React.createRef('gstin');
        this.WeeklyOffRef = React.createRef('weekly_off');

        this.options = [
            { value: 'sunday', label: 'Sunday' },
            { value: 'monday', label: 'Monday' },
            { value: 'tuesday', label: 'Tuesday' },
            { value: 'wednesday', label: 'Wednesday' },
            { value: 'thursday', label: 'Thursday' },
            { value: 'friday', label: 'Friday' },
            { value: 'saturday', label: 'Saturday' },
            ];   
    }
    AddEditAddress=(e)=>{
        let id = e.currentTarget.id;
        let address_id = e.currentTarget.getAttribute('address_id');
        if(address_id > 0){
            let currentAddress = this.props.address[id];
            // console.log(currentAddress);
            let w_hours = address_id > 0 ? currentAddress.working_hours ? currentAddress.working_hours : "09:00 - 18:00":"";
            let start1 = w_hours.split(" ");
            let days = address_id>0? currentAddress.weekly_on_off.split(","):[];
            let weekly_off = days.length>0 ? days.map(val=>{
            return {
                value : val,
                label : val
            }
        }):[]
        this.setState({
            open:true,
            itemId:id,
            address_id : address_id,
            address : currentAddress.address,
            pincode : currentAddress.pincode,
            city : currentAddress.city,
            State : currentAddress.state,
            gstin : currentAddress.gstin,
            start_time : new Date('2014-08-18T'+start1[0]),
            end_time : new Date('2014-08-18T'+start1[2]),
            
        },()=>{
            this.setState({
                selectedOptions : address_id >0 ? weekly_off : []
            },()=>this.setState({openDialog:true}))
        })
        }else{
            this.setState({
                open:true,
                itemId:id,
                address_id : 0,
                address : "",
                pincode :"",
                city : "",
                State : "",
                gstin : "",
                start_time:new Date('2020-01-18T09:00:00'),
                end_time:new Date('2020-01-18T18:00:00'),
                selectedOptions:[]
            },()=>this.setState({
                openDialog:true
            }))
        }
        
    }
    openPopover=(e)=>{
        let index = e.currentTarget.getAttribute('index');
        this.setState({
            Popover : e.currentTarget,
            popoveropen : true
        },()=>{
           
        let address = this.props.address[index];
        let f_address = address.address +',' + address.city+', ' +address.state+', '+ address.pincode  
        this.setState({
            fullAddress : f_address
        })
        })
    }
    closePopover = () => {
        this.setState({
            Popover : null,
            popoveropen : false
        })
    }
    closeDialog=()=>{
        this.setState({
            openDialog:false
        })
    }
    handleChange=(e)=>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    handleStartChange = (e) => {
        this.setState({
            start_time:e
        })
    }
    handleEndChange =(e)=>{
        this.setState({
            end_time:e
        })
    }
    selectChange = (e) =>{
        this.setState({
            selectedOptions:e
        },()=>{
            if(this.state.selectedOptions == null){
                this.setState({
                    selectedOptions : []
                })
            }
        })
    }
    getAddress = async () =>{
        const requestOptions={
            headers: authHeader()
        }
        return axios.get(apiUrl+'/warehouse-details',requestOptions)
        .then(data=>{
            console.log(data)
        })
        .catch(error=>console.log(error))
    }
    cancelEditing=()=>{
        this.setState({
            open:false,
            itemId :0
        })
    }
    submitWareHouseDetails=(e)=>{
        e.preventDefault();
         this.setState({
            submitted:true,
            isLoading:true
         },
         ()=>{
            if(this.state.submitted && this.state.address && this.state.city && this.state.pincode && this.state.gstin && this.state.State && this.state.selectedOptions.length>0 && this.state.start_time != 'Invalid Date' && this.state.end_time != 'Invalid Date'){
                 const days = this.state.selectedOptions.map(val=>{
                     return val.value
                 })
                 let start_hours = this.state.start_time.getHours();
                 let start_minutes = this.state.start_time.getMinutes();
                 let end_hours = this.state.end_time.getHours();
                 let end_minutes = this.state.end_time.getMinutes();
                 if(start_hours.toString().length < 2){
                    start_hours = "0"+start_hours
                 }
                 if(start_minutes.toString().length < 2){
                    start_minutes = "0"+start_minutes
                 }
                 if(end_hours.toString().length < 2){
                    end_hours = "0"+end_hours
                 }
                 if(end_minutes.toString().length < 2){
                    end_minutes = "0"+end_minutes
                 }
                 let s_time = start_hours +":"+start_minutes;
                 let e_time = end_hours + ":" + end_minutes;
                //  console.log(this.state.start_time.toISOString())
                 const requestOptions={
                     method:'post',
                     headers: authHeader(),
                     data:{
                         same_as_business_address:'',
                         address:this.state.address,
                         pincode:this.state.pincode,
                         city:this.state.city,
                         state:this.state.State,
                         gstin:this.state.gstin,
                         working_hours: s_time +" - "+ e_time,
                         weekly_on_off:days,
                         address_id : this.state.address_id
                     }
                 }
                 return axios(apiUrl+'/warehouse-details',requestOptions)
                 .then(data=>{
                     this.props.getWarehouseAddress();
                     if(this.state.address_id>0){
                        this.sendNotification('update')
                     }else{
                        this.sendNotification('add')
                     }
                     this.setState({
                         openDialog:false,
                         submitted:false,
                         alertMessage : true,
                         isError : false,
                         message : this.state.address_id > 0 ? 'address modified successfully' : 'address added successfully'
                     },()=>{
                         setTimeout(() => {
                            this.setState({
                                alertMessage : false,
                                message :''
                            })
                         }, 3000);
                     })
                 })
                 .catch(error=>{
                     this.setState({
                         isError : true,
                         alertMessage : true,
                         message : error.response ? error.response.data.message : 'network error'
                     },()=>{
                         setTimeout(() => {
                             this.setState({
                                 alertMessage : false,
                                 message:''
                             })
                         }, 3000);
                     })
                 })
            }else{
                this.setState({
                    isLoading:false
                },()=>{
                    if(!this.state.address){
                        this.addressRef.current.focus();
                    }else if(!this.state.pincode){
                        this.pincodeRef.current.focus();
                    }else if(!this.state.city){
                        this.cityRef.current.focus();
                    }else if(!this.state.State){
                        this.StateRef.current.focus();
                    }else if(!this.state.gstin){
                        this.gstinRef.current.focus();
                    }else if(!this.state.selectedOptions.length>0){
                        this.WeeklyOffRef.current.focus();
                    }
                })
            }
         })
    }

    sendNotification = (e) => {
        const requestOptions={
          method : 'get',
          headers : authHeader()
        }
        return axios(apiUrl+'/get_vendor_name',requestOptions).then(data=>{
         const requestOptions = {
           method : 'POST',
           headers : authHeader(),
           data : {
               template : "ADDADDRESS",
               name : data.data.data[0].vendor_name,
               action : e
           }
       }
       return axios(apiUrl+'/send-notification',requestOptions)
       .then(data=>data)
       .catch(error=>error)
          }).catch(error=>{
            console.log(error)
          })
   }

    openDeleteDialog=(e)=>{
        let address_id = e.currentTarget.getAttribute('address_id');
        this.setState({
            deleteDialog: true,
            address_id : address_id
        })
    }
    deleteAddress=(e)=>{
        const requestOptions={
            method:"POST",
            headers : authHeader(),
            data:{
                address_id : this.state.address_id
            }
        }
        return axios(apiUrl+'/delete_address',requestOptions)
        .then(data=>{
            this.setState({
                deleteDialog : false,
                message:'Address deleted successfully',
                alertMessage : true,
                isError: false
            },()=>{
                this.props.getWarehouseAddress();
                this.sendNotification('delete');
                setTimeout(() => {
                    this.setState({
                        message:'',
                        alertMessage : false
                    })
                }, 3000);
            })
        })
        .catch(error=>{
            this.setState({
                isError:true,
                message : error.response ? error.response.data.message : 'network error',
                alertMessage:true
            },()=>{
                setTimeout(() => {
                    this.setState({
                        alertMessage:false
                    })
                }, 3000);
            })
        })
    }
    cancelDeletion=()=>{
        this.setState({
            deleteDialog : false,
            address_id : 0
        })
    }
    render() {
        return warehouseDetails.call(this,this.addressRef,
            this.pincodeRef,
            this.cityRef,
            this.StateRef,
            this.gstinRef,
            this.WeeklyOffRef);
    }
}
export default withStyles(styles)(WarehouseDetails);
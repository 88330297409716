import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Report from './Reports.jsx';
import { authHeader } from '../../../../helper/auth-header';
import siteConfig from '../../../../config';
import axios from 'axios';
import { saveSync } from 'save-file';

const apiUrl = siteConfig.apiUrl;

const style = (theme) => ({
  coming_soon:{
    fontSize:"20rem",
    color:"rgba(0,0,0,0.3)"
  },
  text_center:{
    textAlign:'center',
    marginTop:65,
    paddingBottom:40
  },
  c_text:{
    fontSize:"3rem",
    marginTop:0,
    fontWeight:500,
    color:"#999"
  },
  th:{
    fontSize:13,
    border:'1px solid #eaeaea'
  },
  td:{
    fontSize:12,
    border:'1px solid #eaeaea',
  },
  page_root:{
    flexShrink : '2'
  },
})


  class Reports extends Component {
    constructor(props){
      super(props);
      this.state={
        fromDate : new Date(),
        toDate : new Date(),
        orderData : [],
        message : '',
        isError : false,
        rowsPerPages : 10,
        page : 1,
        totalCount : 0,
        download :false,
        searched : false,
        isLoading : false
      }
    }
    handlefromDateChange = (e) => {
      this.setState({
        fromDate : e,
        orderData : [],
        searched : false
      })
    }
    handleToDateChange = (e) => {
      this.setState({
        toDate : e,
        orderData : [],
        searched : false
      },()=>{
        if(this.state.fromDate > this.state.toDate){
          this.setState({
            toDate : new Date(),
            isError : true,
            open : true,
            message : "To Date should not be less than From Date"
          },()=>{
            setTimeout(() => {
              this.setState({
                open : false
              },()=>{
                setTimeout(() => {
                  this.setState({
                    isError : false,
                    message : ""
                  })
                }, 1000);
              })
            }, 3000);
          })
        }
      })
    }

    getOrders = () => {
      this.setState({
        isLoading : true
      },()=>{
        const requestOptions = {
          method : "POST",
          headers : authHeader(),
          data : {
            fromDate : this.state.fromDate,
            toDate : this.state.toDate,
            item : this.state.rowsPerPages,
            download : this.state.download
          }
        }
        return axios(apiUrl+'/get_filtered_orders/'+this.state.page,requestOptions)
        .then(data=>{
          if(this.state.download){
            saveSync(data.data.data, 'order_report.xls');
            this.setState({
              orderData : [],
              totalCount : 0,
              download : false,
              isLoading : false,
              searched : false
            })
          }else{
            this.setState({
              orderData : data.data.data.data,
              totalCount : data.data.data.total_count,
              isLoading : false,
              searched : true
            })
          }
        }).catch(error=>{
          this.setState({
            isLoading : false
          })
        })
      })
    }



    handleChangePage = (e)=>{
      let title=e.currentTarget.title;
      if(title === 'Next page'){
        this.setState({
          page : this.state.page + 1
        },()=>{
          this.getOrders()
        })
      }else{
        this.setState({
          page : this.state.page - 1
        },()=>{
          this.getOrders()
        })
      }
    }
    handleChangeRowsPerPage = (e) => {
      // console.log(e.target)
      this.setState({
        rowsPerPages : e.target.value,
        page:1
      },()=>{
          this.getOrders()
      })
    }
    downloadReport = (e) => {
      this.setState({
        download : true
      },()=>{
        this.getOrders();
      })
    }
    clearData = () => {
      this.setState({
        searched : false,
        orderData : []
      })
    }
  render() {
    return Report.call(this);
  }
}
export default withStyles(style)(Reports);
import { Component, createRef } from 'react';
import login_password from './LoginWpassword.jsx';
import { connect } from 'react-redux';
import { userActions } from '../../../actions';
import axios from 'axios';
import siteConfig from '../../../config';
import { history } from '../../../helper/history';

const apiUrl = siteConfig.apiUrl;
const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

class LoginWpassword extends Component {
    constructor(props){
        super(props);
        this.state={
            username: '',
            password:'',
            isEmail:false,
            isSubmitted:false,
            isSubmitted1: false,
            isLoading: false,
            checked:false,
            message :"",
            open:false,
            IsError:false,
            hasError:false
        }
    this.username = createRef('username');
    this.password = createRef('password');
    }
    componentDidMount = () => {
      if(this.props.username.length>0){
          this.setState({
              username : this.props.username
          })
      }
    };
    
    changeHandler = (e) => {
        this.setState({
            username : e.target.value
        },()=>{
            if(isNaN(this.state.username) || (!isNaN(this.state.username) &&  this.state.username.length > 10)){
                this.setState({
                    isEmail: true
                })
            }else{
                this.setState({
                    isEmail: false
                })
            }
        },this.props.getUsername(e.target.value),
        this.props.setErrorFalse()
        )
    }
    login = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
            isSubmitted: true
        },()=>{
            if(this.state.isSubmitted && ((this.state.isEmail && regex.test(this.state.username))||(!this.state.isEmail && this.state.username.length == 10)) && this.state.password.length>0){
                this.props.login(this.state.username,this.state.password)
                .then(data=>{
                    history.push('/dashboard')
                })
                .catch(error=>{
                    this.setState({
                        isLoading:false,
                    },()=>{
                        this.props.setOpen(true);
                        this.props.setError(true);
                        this.props.setMessage(error.response ? error.response.data.message: 'network error');
                        if(error.response){
                            if(error.response.data.message.includes('User')){
                                this.props.setUserError(true);
                                this.username.current.focus();
                            }
                            if(error.response.data.message.includes('password')){
                                this.props.setPasswordError(true);
                                this.password.current.focus();
                            }
                        }
                    })
                })
            }else{
                this.setState({
                    isLoading:false
                },()=>{
                    if((this.state.isEmail && !regex.test(this.state.username))||(!this.state.isEmail && this.state.username.length != 10)){
                        this.username.current.focus();
                    }else if(!this.state.password){
                        this.password.current.focus();
                    } 
                })
                
            }
        })
    }
    PasswordHandler = (e)=>{
        this.setState({
            password : e.target.value
        },()=>{
            this.props.setErrorFalse()
        })
    }
    logout = (e) =>{
        e.preventDefault();
        this.props.logout();
    }
    changeScreen = (e) => {
        this.setState({
            isLoading: true,
            isSubmitted1: true
        }, async ()=>{
            if(this.state.isSubmitted1 && ((!this.state.isEmail && this.state.username.length == "10")|| (this.state.isEmail && regex.test(this.state.username))) ){
                this.setState({
                    checked : e==="otp" ? true : false
                })
                return this.resendOtp().then(data=>{
                    this.setState({
                        isLoading:false
                    },()=>{
                        this.props.setOpen(true);
                        this.props.setMessage("OTP successfully sent to "+this.state.username);
                        this.props.setError(false);
                        this.props.changeScreen(e); 
                        })
                }).catch(error=>{
                    this.setState({
                        isLoading:false,
                        checked:false
                    },()=>{
                        this.props.setOpen(true);
                        this.props.setMessage(error.response ? error.response.data.message: 'network error');
                        this.props.setError(true);
                        this.username.current.focus();
                        if(error.response){
                            if(error.response.data.message.includes('User')){
                                this.props.setUserError(true);
                                this.username.current.focus();
                            }
                        }
                    })
                })
            }else{
                this.setState({
                    isLoading: false
                },()=>{
                    this.username.current.focus();
                })
            }
        })
    }
    resendOtp = () =>{
        return new Promise((resolve,reject)=>{
            const requestOptions = {
                method: "post",
                data : {
                  key : "otp_mobile",
                  username : this.state.username,
                  user_id : "0"
                }
              }
              return axios(apiUrl+'/resend-otp',requestOptions)
              .then(data=>resolve(data))
              .catch(error=>reject(error))
        })
      }
    render() {
        return login_password.call(this,this.username,this.password);
    }
}

const mapStateToProps = ( state ) => {
    const loggingIn = state.authentication;
    return { loggingIn };
}

const actionCreators = {
    login : userActions.login
}

export default connect(mapStateToProps,actionCreators)(LoginWpassword);
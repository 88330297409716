import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { authHeader } from "../../../../helper/auth-header";
import siteConfig from "../../../../config";
import axios from "axios";
import moment from "moment";
import purchase_invoice from "./purchase_invoice.jsx";

const apiUrl = siteConfig.apiUrl;

const style = (theme) => ({
  coming_soon: {
    fontSize: "20rem",
    color: "rgba(0,0,0,0.3)",
  },
  text_center: {
    textAlign: "center",
    marginTop: 65,
    paddingBottom: 40,
  },
  c_text: {
    fontSize: "3rem",
    marginTop: 0,
    fontWeight: 500,
    color: "#999",
  },
  page_root: {
    flexShrink: "2",
  },
  input: {
    display: "none",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchase_invoice_data: [],
      isLoading: false,
      page: 1,
      filter_order_id: "",
      rowsPerPages: 10,
      orderBy: "invoice_no",
      order: "desc",
      required_total_count: 0,
      selectedDate: new Date(),
      purchaseInvoiceNumber: "",
      purchaseInvoicePdf: null,
      purchaseInvoiceModalopen: false,
      purchaseInvoiceUrl: "",
      p_invoiceSubmitted: false,
      purchaseActiontitle: "add",
      totalCount: 0,
      allOrders: [],
      selectedVendor_order_id: "",
      selectedVendorOrderId: [],
      backdropOpen: false,
      isLoadingSave: false,
    };
    this.purchaseInvoiceNumber = React.createRef("purchase_invoice");
  }
  componentDidMount() {
    this.setState(
      {
        required_total_count: 1,
        isLoading: true,
      },
      () => {
        this.getPurchaseInvoiceData();
      }
    );
  }
  getPurchaseInvoiceData = () => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      data: {
        item: this.state.rowsPerPages,
        required_total_count: this.state.required_total_count,
        filter_order_id: this.state.filter_order_id,
      },
    };
    return axios(
      apiUrl + "/purchase_invoice_list/" + this.state.page,
      requestOptions
    )
      .then((data) => {
        this.setState({
          purchase_invoice_data: data.data.data.data,
          totalCount: data.data.data.count,
          isLoading: false,
          allOrders: data.data.data.orderData.map((item) => {
            return {
              title: JSON.stringify(item.vendor_order_id),
              value: JSON.stringify(item.vendor_order_id),
            };
          }),
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
      });
  };
  handleChangePage = (e) => {
    let title = e.currentTarget.title;
    if (title === "Next page") {
      this.setState(
        {
          page: this.state.page + 1,
        },
        () => {
          this.getPurchaseInvoiceData();
        }
      );
    } else {
      this.setState(
        {
          page: this.state.page - 1,
        },
        () => {
          this.getPurchaseInvoiceData();
        }
      );
    }
  };

  handleSearchOrder = (e) => {
    // console.log("-------t-----------t:::::::",e.target.value);
    let filter_order_id = e.target.value;
    if (filter_order_id != "") {
      this.setState(
        {
          filter_order_id: filter_order_id,
        },
        () => {
          this.getPurchaseInvoiceData();
        }
      );
    } else {
      this.setState(
        {
          filter_order_id: "",
        },
        () => {
          this.getPurchaseInvoiceData();
        }
      );
    }
  };

  handleChangeRowsPerPage = (e) => {
    // console.log(e.target)
    this.setState({
      rowsPerPages: e.target.value,
    });
  };
  handleUpload = (e) => {
    e.persist();
    // console.log(e.currentTarget);
    let index = e.currentTarget.getAttribute("index");
    let filetobeuploaded = e.target.files[0];
    // console.log(index);
    this.setState(
      {
        backdropOpen: true,
        isLoadingSave: true,
      },
      () => {
        // console.log(filetobeuploaded)
        if (filetobeuploaded) {
          if (!filetobeuploaded.name.includes(".pdf")) {
            alert("Please select pdf file");
            this.setState({
              backdropOpen: false,
            });
          } else {
            this.setState(
              {
                purchaseInvoicePdf: filetobeuploaded,
                purchaseActiontitle: "update",
              },
              () => {
                let selectedData = this.state.purchase_invoice_data;
                let selectedVendorOrderId =
                  selectedData[index].purchase_vendor_order_id;
                let purchaseInvoiceNumber =
                  selectedData[index].purchase_invoice_no;
                let loginedUserDetails = JSON.parse(
                  localStorage.getItem("seller")
                );
                let formData = new FormData();
                formData.append(
                  "purchase_invoice_number",
                  purchaseInvoiceNumber
                );
                formData.append("purchase_invoice_date", new Date());
                formData.append("vendor_order_id", selectedVendorOrderId);
                formData.append("title", this.state.purchaseActiontitle);
                formData.append("document", this.state.purchaseInvoicePdf);
                formData.append("vendorName", loginedUserDetails.name);
                let requestOptions = {
                  method: "post",
                  headers: authHeader(),
                  data: formData,
                };
                let api_endpoint = "/handle_purchase_invoice_doc";
                return axios(apiUrl + api_endpoint, requestOptions)
                  .then((data) => {
                    this.setState(
                      {
                        purchaseInvoiceModalopen: false,
                        backdropOpen: false,
                        purchaseInvoicePdf: null,
                        purchaseActiontitle: "add",
                        isLoadingSave: false,
                      },
                      () => {
                        this.getPurchaseInvoiceData();
                      }
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                    this.setState({
                      backdropOpen: false,
                      isLoadingSave: false,
                    });
                  });
              }
            );
          }
        } else {
          this.setState({
            backdropOpen: false,
            isLoadingSave: false,
          });
        }
      }
    );
  };
  handleRequestSort = (event, property) => {
    this.setState(
      {
        orderBy: property,
      },
      () => {
        const isAsc =
          this.state.orderBy === property && this.state.orderInc === "asc";
        this.setState({
          orderInc: isAsc ? "desc" : "asc",
          setOrderBy: property,
        });
      }
    );
  };
  openDialog = (e) => {
    let action = e.currentTarget.getAttribute("title");
    this.setState({
      purchaseInvoiceModalopen: true,
      purchaseActiontitle: action,
      selectedVendorOrderId: [],
    });
  };
  ClosePurchaseInvoiceDialog = () => {
    this.setState({
      purchaseInvoiceModalopen: false,
      p_invoiceSubmitted: false,
      purchaseInvoiceNumber: "",
      selectedVendorOrderId: [],
      selectedDate: new Date(),
      purchaseActiontitle: "add",
    });
  };
  handlePurchaseInvoiceChange = (e) => {
    this.setState(
      {
        purchaseInvoiceNumber: e.target.value,
      },
      () => {
        const requestOptions = {
          method: "post",
          headers: authHeader(),
          data: {
            purchase_invoice_no: this.state.purchaseInvoiceNumber,
          },
        };
        return axios(apiUrl + "/get_purchase_invoice_data", requestOptions)
          .then((data) => {
            if (data.data.data.length > 0) {
              // console.log(data.data.data)
              this.setState({
                selectedDate: new Date(data.data.data[0].date_added),
              });
            } else {
              this.setState({
                selectedDate: new Date(),
              });
            }
          })
          .catch((error) => {});
      }
    );
  };
  handleDateChange = (e) => {
    console.log(e);
    this.setState({
      selectedDate: e,
    });

    console.log("after selecting the date", this.state);
  };

  handlePurchaseInvoiceUpload = () => {
    this.setState(
      {
        p_invoiceSubmitted: true,
        backdropOpen: true,
        isLoadingSave: true,
      },
      () => {
        if (
          this.state.p_invoiceSubmitted &&
          this.state.purchaseInvoiceNumber &&
          // this.state.purchaseInvoicePdf!=null&&
          this.state.selectedVendorOrderId.length > 0
        ) {
          let selectedId = this.state.selectedVendorOrderId;
          let vd_id = selectedId.map((item) => {
            return item.title;
          });
          let requestOptions;
          let api_endpoint = "";

          // let selectedDate = this.state.selectedDate;
          var d = new Date(this.state.selectedDate);
          var date = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
          var dateValidUpto = year + "-" + month + "-" + date;
          let loginedUserDetails = JSON.parse(localStorage.getItem("seller"));
          if (this.state.purchaseInvoicePdf !== null) {
            let formData = new FormData();
            formData.append(
              "purchase_invoice_number",
              this.state.purchaseInvoiceNumber
            );
            formData.append("purchase_invoice_date", dateValidUpto);
            formData.append("vendor_order_id", vd_id);
            formData.append("title", this.state.purchaseActiontitle);
            formData.append("document", this.state.purchaseInvoicePdf);
            formData.append("vendorName", loginedUserDetails.name);

            requestOptions = {
              method: "post",
              headers: authHeader(),
              data: formData,
            };
            api_endpoint = "/handle_purchase_invoice_doc_multiple";
          } else {
            let selectedId = this.state.selectedVendorOrderId;
            let vd_id = selectedId.map((item) => {
              return item.title;
            });
            requestOptions = {
              method: "post",
              headers: authHeader(),
              data: {
                purchaseInvoiceNumber: this.state.purchaseInvoiceNumber,
                purchase_invoice_date: dateValidUpto,
                vendor_order_id: vd_id,
                title: this.state.purchaseActiontitle,
                purchase_invoice_url: this.state.purchaseInvoiceUrl,
                vendorName: loginedUserDetails.name,
              },
            };
            api_endpoint = "/handle_purchase_invoice_multiple"; //fcuk
          }

          if (
            !moment(this.state.selectedDate).isValid() ||
            moment(this.state.selectedDate).isAfter(moment())
          ) {
            this.setState({
              backdropOpen: false,
              isLoadingSave: false,
            });
            return window.alert("Please select valid date");
          }
          return axios(apiUrl + api_endpoint, requestOptions)
            .then((data) => {
              this.setState(
                {
                  purchaseInvoiceModalopen: false,
                  purchaseActiontitle: "",
                  purchaseInvoicePdf: null,
                  purchaseInvoiceNumber: "",
                  backdropOpen: false,
                  p_invoiceSubmitted: false,
                  selectedDate: new Date(),
                  isLoadingSave: false,
                },
                () => {
                  this.getPurchaseInvoiceData();
                }
              );
            })
            .catch((error) => {
              this.setState({
                purchaseInvoiceModalopen: false,
                purchaseActiontitle: "add",
                purchaseInvoicePdf: null,
                purchaseInvoiceNumber: "",
                backdropOpen: false,
                p_invoiceSubmitted: false,
                selectedDate: new Date(),
                isLoadingSave: false,
              });
            });
        } else {
          this.setState({
            backdropOpen: false,
          });
          if (
            this.state.p_invoiceSubmitted &&
            !this.state.purchaseInvoiceNumber
          ) {
            this.purchaseInvoiceNumber.current.focus();
          }
        }
      }
    );
  };

  deleteVendorId = (data) => {
    console.log(data);
    const requestOptions = {
      method: "Post",
      headers: authHeader(),
      data: {
        vendor_order_id: data[0].title,
        title: "delete",
        purchase_invoice_date: new Date(),
      },
    };
    return axios(apiUrl + "/handle_purchase_invoice", requestOptions)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addVendorOrderId = (event, value) => {
    // console.log(value.length,this.state.selectedVendorOrderId)
    if (
      this.state.selectedVendorOrderId.length > 0 &&
      this.state.purchaseActiontitle == "update" &&
      value.length < this.state.selectedVendorOrderId.length
    ) {
      if (value.length > 0) {
        let filtered = this.state.selectedVendorOrderId.filter((item) => {
          return item.title != value[0].title;
        });
        let availableid = this.state.selectedVendorOrderId.filter((item) => {
          return item.title == value[0].title;
        });
        // console.log(this.state.selectedVendorOrderId,filtered)
        this.setState(
          {
            selectedVendorOrderId: availableid,
          },
          () => {
            this.deleteVendorId(filtered);
            this.getPurchaseInvoiceData();
          }
        );
      } else {
        // console.log(this.state.selectedVendorOrderId)
        let tobedelete = this.state.selectedVendorOrderId;
        this.setState(
          {
            selectedVendorOrderId: [],
          },
          () => {
            this.deleteVendorId(tobedelete);
            this.getPurchaseInvoiceData();
          }
        );
      }
    } else {
      this.setState({
        selectedVendorOrderId: value,
      });
    }
  };
  handleInvoiceUpload = (e) => {
    if (e.target.files[0]) {
      // console.log(e.target.files[0])
      this.setState({
        purchaseInvoicePdf: e.target.files[0],
      });
    } else {
      this.setState({
        purchaseInvoicePdf: null,
      });
    }
  };
  handleEdit = (e) => {
    // e.persist();
    let index = e.currentTarget.getAttribute("index");
    let title = e.currentTarget.getAttribute("name");
    let p_invoice_data = this.state.purchase_invoice_data;
    //console.log('p_invoice_data[index]', p_invoice_data[index]);
    let purchase_invoice_number = p_invoice_data[index].purchase_invoice_no;
    let purchase_invoice_date = p_invoice_data[index].purchase_invoice_date;
    let purchaseInvoiceUrl = p_invoice_data[index].p_inv_url;
    let VendorOrderId =
      p_invoice_data[index].purchase_vendor_order_id.split(",");
    let selectedVendorOrderId = VendorOrderId.map((item) => {
      return {
        title: item,
      };
    });
    console.log("purchase_invoice_date", purchase_invoice_date);
    this.setState({
      purchaseInvoiceModalopen: true,
      purchaseInvoiceNumber: purchase_invoice_number,
      selectedVendorOrderId: selectedVendorOrderId,
      selectedDate: new Date(purchase_invoice_date),
      purchaseActiontitle: title,
      purchaseInvoiceUrl: purchaseInvoiceUrl,
    });
  };
  render() {
    return purchase_invoice.call(this, this.purchaseInvoiceNumber);
  }
}
export default withStyles(style)(Invoices);

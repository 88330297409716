import React,{ Component } from 'react';
import loginwith_forgot_password from './LoginWforgotpassword.jsx';
import {connect} from 'react-redux';
import { userActions } from '../../../actions';
import {history} from '../../../helper/history';
import axios from 'axios';
import siteConfig from '../../../config';

const apiUrl = siteConfig.apiUrl;

const styles = theme =>({
  back:{
    cursor:'pointer',
    position: 'absolute',
    top:18,
    padding:10,
    left:10,
    transition: '0.1s'
  }
})


class LoginWforgotpassword extends Component {
  constructor(props){
    super(props);
    this.state ={
      show:false,
      submitted:false,
      otp : '',
      password : '',
      cnf_password : '',
      id:'',
      isLoading: false,
      otpError:false
    }
    this.otpRef = React.createRef('otp');
    this.passwordRef = React.createRef('password');
    this.cnfPasswordRef = React.createRef('cnf_password');
  }
  handleChange=(e)=>{
    this.setState({
      [e.target.name] : e.target.value,
      otpError:false
    })
  }
  handleShow=(e)=>{
    let id = e.currentTarget.getAttribute('id');
    this.setState({
      show : !this.state.show,
      id : id
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isLoading: true,
      submitted :true
    },()=>{
      if(this.state.submitted && this.state.otp && this.state.password.length > 5 && this.state.cnf_password && (this.state.password=== this.state.cnf_password)){
        this.props.verifyAndLogin(this.props.username,this.state.otp,this.state.password).then(data=>{
          history.push('/dashboard')
        })
        .catch(error=>{
          this.setState({
            isLoading:false
          },()=>{
            this.props.setOpen(true);
            this.props.setMessage(error.response ? error.response.data.message : 'network error');
            this.props.setError(true)
            if(error.response){
              this.otpRef.current.focus();
              this.setState({
                otpError:true
              })
            }
          })
        })
      }else{
        this.setState({
          isLoading : false
        },()=>{
          if(!this.state.otp){
            this.otpRef.current.focus();
          }else if(this.state.password.length < 6){
            this.passwordRef.current.focus();
          }else if(!this.state.cnf_password){
            this.cnfPasswordRef.current.focus();
          }else if(this.state.password !== this.state.cnf_password){
            this.cnfPasswordRef.current.focus();
          }
        })
      }
    })
  }
  resendOtp = (e) =>{
    this.setState({
      isLoading:true
    },async ()=>{
      const requestOptions = {
        method: "post",
        data : {
          key : "otp_mobile",
          username : this.props.username,
          user_id : "0"
        }
      }
      return axios(apiUrl+'/resend-otp',requestOptions)
      .then(data=>{
        this.setState({
          isLoading:false
        },()=>{
          this.props.setOpen(true);
          this.props.setMessage('OTP successfully sent to '+this.props.username);
          this.props.setError(false);
          this.otpRef.current.focus();
        })
      })
      .catch(error=>{
        this.setState({
          isLoading:false
        },()=>{
          this.props.setOpen(true);
          this.props.setMessage(error.response ? error.response.data.message : 'network error');
          this.props.setError(true)
        })
      })
    })
  }
  render() {
    return loginwith_forgot_password.call(this,this.otpRef,this.passwordRef,this.cnfPasswordRef);
  }
}

const actionCreators = {
  verifyAndLogin : userActions.verifyAndLogin
}

export default connect(null,actionCreators)(LoginWforgotpassword);
import React from 'react';
import DashboardNavbar from './DashboardNavbar/DashboardNavbar';
import AddProduct from './DashboardContent/AddProduct/AddProduct';
import EditProduct from './DashboardContent/EditProduct/EditProduct';
import MainDashboard from './DashboardContent/MainDashboard/MainDashboard';
import Invoices from './DashboardContent/Invoices/Invoices';
import ManageCancellation from './DashboardContent/ManageCancellation/ManageCancellation';
import ManageOrdersWithSnackbar from './DashboardContent/ManageOrders/ManageOrdersWithSnackbar';
import ManageProducts from './DashboardContent/ManageProducts/ManageProducts';
import Statements from './DashboardContent/Statements/Statements';
import ManageReturns from './DashboardContent/ManageReturns/ManageReturns';
import OrderDetail from './DashboardContent/ManageOrders/OrderDetail/OrderDetail';
import ChangePassword from './ChangePassword/changePassword';
import Reports from './DashboardContent/Reports/Reports';
import Support from '../Support/Support';
import Sm_wt_wrng from '../../ErrorPage/sm_wnt_wrng';
import MyAccount from './MyAccount/MyAccount';
import {Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import PageNotFound from './DashboardContent/NotFound/PageNotFound';

function dashboard(){
    let page_type = this.props.match.params;
    const locationState = this.props.location.state;
    let exclude_page_array = ['seller-signup']
    // const page_array = ['dashboard','add-product'];
    const page_array=['dashboard','add-product','edit-product','manage-products','invoices','statements','manage-returns','manage-orders','manage-cancellation','my-account','order-detail','change-password','reports','support']
    const filteredPageArray = page_array.filter(val=>{
        return val === page_type.page_type
    })
    if(!filteredPageArray.length >0 ){
      return  <Redirect to="/not-found" />
    } 
    return (
        <React.Fragment>
            
            <React.Fragment>
                    {exclude_page_array.indexOf(page_type) > -1? null:
            <div className="wrapper" >
                <div className="main-panel">
                <div style={{height:'70px'}}></div>
                    <DashboardNavbar 
                        logo={this.state.logo} 
                        menu={this.state.menu} 
                        notificationCount={this.state.notificationCount} 
                        userMenu={this.state.userMenu}
                        getSelectedBrand={this.getSelectedBrand}
                        />
                    
                    <div style={{paddingBottom:50,height:"auto"}}>
                        {
                                (
                                    ()=> {
                                        switch(page_type.page_type){
                                            case 'not-found':
                                                return <PageNotFound />
                                            case 'add-product':
                                                return <AddProduct locationState={locationState} status={this.state.accountStatus}/>
                                            case 'edit-product':
                                                return <EditProduct locationState={locationState}/>
                                            case 'manage-products':
                                                return <ManageProducts />
                                            case 'manage-orders':
                                                return <ManageOrdersWithSnackbar locationState={locationState}/>
                                            case 'manage-returns':
                                                return <ManageReturns />
                                            case 'manage-cancellation':
                                                return <ManageCancellation />
                                            case 'invoices':
                                                return <Invoices />
                                            case 'statements':
                                                return <Statements />
                                            case 'my-account' :
                                                return <MyAccount />
                                            case 'order-detail':
                                                return <OrderDetail  locationState={locationState}/>
                                            case 'change-password' :
                                                return <ChangePassword />
                                            case 'reports':
                                                return <Reports />
                                            case 'support':
                                                return <Support status={this.state.accountStatus}/>                                            
                                            default:
                                                return <MainDashboard status={this.state.accountStatus}/>; 
                                        }
                                    }
                                )()
                            }
                        </div>
                </div>
            </div>}
            </React.Fragment>
            <Snackbar open={this.state.open} anchorOrigin={{horizontal:'center',vertical:'top'}} >
                <Alert severity={this.state.Iserror ? "error" :"success"} >
                    {
                        this.state.message
                    }
                </Alert>
            </Snackbar>
            
        </React.Fragment>
    )
}
export default dashboard;
import React from 'react';
import {Paper,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Typography,Button,CircularProgress,Toolbar,InputBase,Checkbox,Tooltip,Fab,FormLabel,TablePagination,Select,MenuItem,InputLabel,FormControl,Grid,Hidden} from '@material-ui/core'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import reactStringReplace  from 'react-string-replace';
import {Link} from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';

const EnhancedToolbar = props => {
    const {classes,search,numSelected,totalProduct,searchProductHandler,isLoadingTable,addProduct,isLoadingAdd,selectedCategory,manufacturerHandler,categories,labelWidth,manufacturerRef} = props;
    return (
        <Toolbar className={classes.toolbar}>
            {
                numSelected > 0 ?
                (
                    <React.Fragment>
                        <Typography style={{width:'100%',display:'inline-block',textAlign:'left'}}>
                            {numSelected} Selected
                        </Typography>
                        
                        <Button startIcon={!isLoadingAdd ?<AddIcon />:null} variant="contained" onClick={addProduct} color="secondary" disabled={isLoadingAdd}>
                        {
                           isLoadingAdd
                            ?
                            <CircularProgress color="inherit" size={25}/>
                            :
                            "Add"
                        }
                        </Button>
                    </React.Fragment>
                )
                :
                (
                    
                    <React.Fragment>
                        <Hidden mdDown>
                        <Grid container>
                            <Grid item sm={12} md={4}>
                                <FormatListBulletedIcon/>
                                <Typography  
                                    style={{
                                        display:'inline-block',
                                        textAlign:'left',
                                        fontSize:20,
                                        marginLeft:10,
                                        verticalAlign:'bottom'
                                        }}
                                >
                                    Product List
                                </Typography>
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <FormControl 
                                    variant="outlined" 
                                    className={classes.formControl}
                                >
                                    <InputLabel 
                                        id="demo-simple-select-outlined-label"
                                    >
                                        Search By Category
                                    </InputLabel>
                                    <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectedCategory}
                                    onChange={manufacturerHandler}
                                    label="Search By Category"
                                    // labelWidth={200}
                                    fullWidth
                                    inputRef={manufacturerRef}
                                    margin="dense"
                                    >
                                        <MenuItem value="0">
                                            <em>All</em>
                                        </MenuItem>
                                        {
                                            categories.map(item=>(
                                                <MenuItem 
                                                    value={item.category_id} 
                                                    key={item.category_id}
                                                >
                                                    {
                                                        item.name
                                                    }
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={4}>
                                <div className={classes.searchAbsolute}>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                    <InputBase
                                        placeholder="Search By Name"
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={ searchProductHandler}
                                        value={search}
                                        classes={{
                                            root: classes.inputRoot,
                                            input:classes.inputInput
                                        }}
                                    />
                                    {
                                        isLoadingTable
                                            ?
                                            <div className={classes.searchloader}>
                                            <CircularProgress size={14} />
                                        </div>
                                        :
                                        null
                                    }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid container>
                        <Grid item sm={12}>
                        <FormatListBulletedIcon
                            className={classes.valign}
                        />
                        <h4 className={classes.mobileheading}>Product List</h4>
                        </Grid>
                    <Grid item sm={12}>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                    <InputBase
                                        placeholder="Search By Name"
                                        inputProps={{ 'aria-label': 'search' }}
                                        onChange={ searchProductHandler}
                                        value={search}
                                        classes={{
                                            root: classes.inputRoot,
                                            input:classes.inputInput
                                        }}
                                    />
                                    {
                                        isLoadingTable
                                            ?
                                            <div className={classes.searchloader}>
                                            <CircularProgress size={14} />
                                        </div>
                                        :
                                        null
                                    }
                                    </div>
                                </Grid>
                                <Grid item sm={12} className={classes.fullWidth}>
                    <FormControl 
                            variant="outlined" 
                            className={classes.formControlMobile}
                        >
                            <InputLabel 
                                id="demo-simple-select-outlined-label"
                            >
                                Search By Manufacturer
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={selectedCategory}
                            onChange={manufacturerHandler}
                            label="Search By Manufacturer"
                            // labelWidth={200}
                            fullWidth
                            inputRef={manufacturerRef}
                            margin="dense"
                            >
                                <MenuItem value="0">
                                    <em>All</em>
                                </MenuItem>
                                {
                                    categories.map(item=>(
                                        <MenuItem 
                                            value={item.category_id} 
                                            key={item.category_id}
                                        >
                                            {
                                                item.name
                                            }
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>             
                    </Grid>
                    </Grid>
                </Hidden>
                </React.Fragment>
                )
            }
        </Toolbar>
    )
}



function step2(){
    const {classes} = this.props;
    return (
            <React.Fragment>
                <Paper className={classes.step2}>
                    <FormLabel>Step2 : </FormLabel>
                    <h4 className={classes.heading}>Select Product</h4>
                    <EnhancedToolbar 
                        search={this.state.search} 
                        numSelected={this.state.selected.length} 
                        classes={classes} 
                        searchProductHandler={this.searchProductHandler} 
                        addProduct={this.addProduct}
                        isLoadingAdd={this.state.isLoadingAdd} 
                        categories={this.state.categories} 
                        selectedCategory={this.state.selectedCategory} 
                        manufacturerHandler={this.manufacturerHandler} 
                        labelWidth={this.state.labelWidth} 
                        manufacturerRef={this.manufacturerRef} 
                    />
                    <TableContainer component={Paper} className={classes.tablecontainer}>
                            <Table stickyHeader  >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                        <Checkbox
                                              indeterminate={this.state.selected.length>0}
                                              checked={this.state.selected.length>0 }  
                                              onChange={this.onSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell>Image</TableCell>
                                        <TableCell align="left">Product Name</TableCell>
                                        <TableCell align="left">Model</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    
                                    {
                                        this.state.product_data.length>0
                                            ?     
                                        this.state.product_data.map(item=>{
                                           const isItemSelected = this.isSelected(item.product_id)
                                           let name = item.name
                                           let search = this.state.search
                                          
                                            
                                            return <TableRow 
                                                key={item.model} 
                                                hover role={Checkbox}
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={item.isPresent ? null : isItemSelected}
                                                onClick={event => item.isPresent ? null : this.handleClick(event, item.product_id)}
                                                >
                                                <TableCell>
                                                    {
                                                        item.isPresent
                                                                ?
                                                                null
                                                                :
                                                    <Checkbox checked={isItemSelected}/>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <img src={item.image} style={{width:60,border:'1px solid #ccc'}}/>
                                                </TableCell>
                                                <TableCell>{this.state.search.length > 0 ? reactStringReplace(name,search,(search,i)=>(
                                               <span key={i} className={classes.searchText}>{search}</span>
                                           )): name}</TableCell>
                                                <TableCell>
                                                    {item.model}
                                                </TableCell>
                                            </TableRow>
                                    })
                                    :
                                    <TableRow>
                                        <TableCell ></TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell >No Product Found</TableCell>
                                        <TableCell ></TableCell>
                                    </TableRow>
                                    }
                                </TableBody>
                                
                            </Table>
                        </TableContainer> 
                        <TablePagination
                                component="div"
                                rowsPerPage={this.state.rowsPerPage}
                                labelRowsPerPage="Total Products"
                                rowsPerPageOptions={[10,25,50,75]}
                                count={this.state.totalProduct}
                                page={this.state.page -1}
                                onChangePage={this.pageChangeHandler}
                                classes={{
                                    caption: classes.page_root
                                }}
                                onChangeRowsPerPage={this.handleRowsPerPage}
                                />
                </Paper>
            </React.Fragment>
    )
}

export default step2;
import React, { Component } from 'react';
import step2 from './step2.jsx';
import { withStyles } from '@material-ui/core/styles';
import {authHeader} from '../../../../../helper/auth-header';
import siteConfig from '../../../../../config';
import axios from 'axios';
import {history} from '../../../../../helper/history';

const apiUrl = siteConfig.apiUrl


const styles =(theme)=>({
    heading:{
        display: 'inline-block',
        fontSize : 18,
        fontWeight: '500',
        color:"#333",
        marginLeft: 5,
        marginTop:0
    },
    step2:{
        padding:20,
        marginTop:30
    },
    search: {
        position: 'relative',
        borderRadius: 3,
        backgroundColor: '#eee',
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      
  page_root:{
    flexShrink : '2'
  },
      searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:'#eee',
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 120,
          '&:focus': {
            width: 200,
          },
        },
      },
      toolbar:{
          boxShadow : '0px 0px 7px #ccc'
      },
      mobileheading:{
        fontSize:22,
        marginLeft:5,
        margin:0,
        fontWeight:400,
        marginBottom:10,
        display:'inline-block'
      },
      tablecontainer:{
        borderBottomLeftRadius:0,
        boxShadow : '0px -1px 7px #ccc'
      },
      searchText:{
        background:'#dde7fd',
        color:'#063dd6',
        padding:"5px 2px",
        borderRadius:2,
        paddingRight: 0,
      },
      searchAbsolute:{
        position:'absolute',
        right:30
      },
      formControl:{
        width:'50%'
      },
      formControlMobile:{
        width:'100%',
        marginTop:20,
        marginBottom:20
      },
      fullWidth:{
        width:'100%'
      },
      pr_heading:{
        width: '100%',
      textAlign: 'left',
      marginTop: 6,
      marginBottom: 2,
      },
      valign:{
        verticalAlign:'sub'
      }
})

class Step2 extends Component{
    constructor(props){
        super(props);
        this.state={
            search:"",
            selected:[],
            totalProduct:0,
            isSearching:false,
            isTotalRequired:0,
            product_data : [],
            page:1,
            manufacturer_id :0,
            isLoadingAdd : false,
            categories : [],
            selectedCategory : 0,
            labelWidth : 200,
            rowsPerPage : 10
        }

        this.manufacturerRef = React.createRef('manufacturer');
    }


    componentDidMount(){
        this.setState({
            manufacturer_id : this.props.manufacturer_id
        },()=>{this.getAllCategoryProducts()
                this.getCategoryList()
        })
    }

    getCategoryList = async ()=>{
        const requestOptions ={
            method : "post",
            headers : authHeader(),
            data : {
                manufacturer_id : this.state.manufacturer_id
            }
        }
        return axios(apiUrl+'/product_category_list',requestOptions)
        .then(data=>{
            this.setState({
                categories : data.data.data
            })
        })
        .catch(error=>console.log(error))
    }

    manufacturerHandler=(e)=>{
        this.setState({
          selectedCategory : e.target.value
        },()=>{
            this.getAllCategoryProducts()
        })
    }

    handleClick = (event,name) => {
        // console.log(event,name)
        const selectedIndex = this.state.selected.indexOf(name);
    
        let newSelected = [];
    
        if(selectedIndex === -1){
          newSelected= newSelected.concat(this.state.selected,name)
        }else if(selectedIndex === 0){
          newSelected = newSelected.concat(this.state.selected.slice(1));
        }else if (selectedIndex === this.state.selected.length - 1) {
          newSelected = newSelected.concat(this.state.selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
          );
        }
        this.setState({
          selected : newSelected
        })
      }
    onSelectAllClick=(e)=>{
    if(e.target.checked){
        // const newSelecteds = this.state.product_data.map(n=>n.product_id);
        let newSelecteds =[]
        for(let i=0;i<this.state.product_data.length;i++){
            if(!this.state.product_data[i].isPresent){
                newSelecteds.push(this.state.product_data[i].product_id)
            }
        }
        this.setState({
        selected : newSelecteds
        })
        return;
    }
    this.setState({
        selected:[]
    })
    }

    isSelected = name => this.state.selected.indexOf(name) !== -1;


    getAllCategoryProducts = async () =>{
        this.setState({
            isTotalRequired : 1
        },async ()=>{
            return await this.getAllProducts()
        .then(data=>{
            this.setState({
                product_data : data.data.data.result,
                totalProduct : data.data.data.total_count
            })
        })
        .catch(error=>console.log(error));
        })
    }

    getAllProducts =()=>{
        return new Promise(async(resolve,reject)=>{
                const requestOptions = {
                    method : "post",
                    headers : authHeader(),
                    data : {
                    manufacturer_id : this.state.manufacturer_id,
                    status : "Active",
                    search : this.state.search,
                    category_id : this.state.selectedCategory,
                    required_total_count : this.state.isTotalRequired,
                    item : this.state.rowsPerPage
                    }
                }
                return axios(apiUrl+"/get_all_products/"+this.state.page,requestOptions)
                .then(data=>resolve(data))
                .catch(error=>reject(error))
        })
    }
    searchProductHandler=(e)=>{
        this.setState({
            search : e.target.value,
            page : 1
        },()=>this.getAllCategoryProducts())
    }
    pageChangeHandler=(e)=>{
        let title = e.currentTarget.title;
        if(title==='Next page'){
          this.setState({
            page: this.state.page +1,
            isTotalRequired:0,
            selected:[]
          },()=>this.getAllCategoryProducts())
        }else{
          this.setState({
            page: this.state.page -1,
            isTotalRequired:0,
            selected:[]
          },()=>this.getAllCategoryProducts())
        }
      }
      addProduct = async () => {
          this.setState({
              isLoadingAdd : true
          },()=>{
            const requestOptions = {
                method : 'post',
                headers : authHeader(),
                data : {
                    product_id : this.state.selected
                  }
              }
              return axios(apiUrl+'/add_product',requestOptions)
              .then(data=>{
                  this.setState({
                      isLoadingAdd : false
                    },()=>history.push('/manage-products'))
                })
                .catch(error=>{
                    this.setState({
                        isLoadingAdd : false
                    })
                })
            })
        }
        handleRowsPerPage=(e)=>{
          this.setState({
            rowsPerPage : e.target.value,
            isTotalRequired : 1,
            page : 1
          },()=>{
            this.getAllCategoryProducts()
          })
        }
    render(){
        return step2.call(this,this.manufacturerRef);
    }
}

export default withStyles(styles)(Step2);
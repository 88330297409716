import React from 'react';
import { Router,Switch,Route } from 'react-router-dom';
import Homepage from './Component/Homepage/Homepage';
import PrivateRoute from './Container/privateRoute';
import { history } from './helper/history';
import Dashboard from './Component/Dashboard/Dashboard';
import SignupNext from './Component/Signup/SignupNext/SignupNext';
import PageNotFound from './Component/Dashboard/DashboardContent/NotFound/PageNotFound';
// import GetUserInfo from './Component/GetUserInfo/GetUnserInfo';

const Routes = () => (
        <Router history={history}>
            <Switch>
                <Route exact path="/not-found" component={PageNotFound} />
                <Route exact path="/" component={Homepage} />
                {/* <PrivateRoute path="/get-info" component={GetUserInfo} /> */}
                <PrivateRoute path="/seller-signup" component = {SignupNext} />
                <PrivateRoute path="/:page_type/:param1?" component ={Dashboard} />
            </Switch>
        </Router>
    )
export default Routes;
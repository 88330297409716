import React,{Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

function login_password(){
    return (
        <Fragment>
            
            <form onSubmit={this.login}> 
                <TextField 
                    name="username"
                    variant="outlined"
                    label="Email/Mobile"
                    fullWidth
                    style={{marginBottom:20}}
                    autoFocus
                    value={this.state.username}
                    onChange={this.changeHandler}
                    error={(this.state.isSubmitted || this.state.isSubmitted1) && 
                        (this.state.isEmail? (regex.test(this.state.username) || this.state.username.length < 10 ? false : true) || this.props.userError : (this.state.username.length <10 || this.props.userError))
                    }
                    helperText={(this.state.isSubmitted || this.state.isSubmitted1) && (this.state.isEmail ? (regex.test(this.state.username)? false : true) : (this.state.username.length !== 10)) ? "Enter valid Mobile/Email id" : ""}
                    InputProps={
                        isNaN(this.state.username)||(this.state.isEmail || this.state.username.length < 1 || this.state.username.length > 10)
                            ?
                        null
                        :
                        {
                            startAdornment : <InputAdornment position="start" >
                        +91  
                        </InputAdornment>
                    }}
                    inputRef={this.username}
                >
                </TextField>
                <TextField 
                    variant="outlined"
                    label="Password"
                    value={this.state.password}
                    onChange={this.PasswordHandler}
                    fullWidth
                    style={{marginBottom:20}}
                    type={this.props.show ? "text": "password"}
                    error={this.state.isSubmitted && (this.state.password.length < 1 || this.props.passwordError)}
                    helperText={this.state.isSubmitted && this.state.password.length < 1 ? "Enter valid password":""}
                    inputRef={this.password}
                    InputProps={{
                        endAdornment : <InputAdornment position="end">
                        {
                            !this.props.show 
                                ?
                            <i className="material-icons visible" onClick={this.props.change}>visibility</i>
                                :
                            <i className="material-icons visible" onClick={this.props.change}>visibility_off</i>
                        }
                            
                        </InputAdornment>
                    }}
                >
                </TextField>
                <FormControl>
                    <FormGroup>
                        <FormControlLabel
                        value="end"
                        control={<Checkbox color="primary"/>}
                        label="Login with OTP"
                        labelPlacement="end"
                        classes={{label:"labelClass"}}
                        onClick={()=> this.state.isLoading ? null : this.changeScreen('otp')}
                        disabled={this.state.isLoading}
                        checked={this.state.checked}
                    />
                    </FormGroup>
                </FormControl>
                <Button
                    variant="text"
                    style={{textTransform:"lowercase",fontSize:'12px',float:'right'}}
                    onClick={()=>this.changeScreen('forgot_password')}
                    disabled={this.state.isLoading}
                >
                    Forgot Password
                </Button>
                <Button 
                    variant="contained" 
                    fullWidth 
                    color="primary"
                    style={{padding:12}}
                    disabled={this.state.isLoading}
                    type="submit"
                    >
                    {
                        this.state.isLoading
                            ?
                        <CircularProgress color="inherit" size={25} />
                            :
                            "Login"   
                    }
                </Button>
            </form>
        </Fragment>
    )
}

export default login_password;
import React from 'react';
import {Grid,Toolbar,Container,Paper,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Button,TablePagination,Checkbox,Typography,InputBase,CircularProgress,Snackbar,Fab,Tooltip,FormControl,InputLabel,Select,MenuItem,Backdrop,ButtonGroup,TextField} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import Edit from '@material-ui/icons/Edit';
import Alert from '@material-ui/lab/Alert';
import reactStringReplace  from 'react-string-replace';
import {Link} from 'react-router-dom';
import { CSVLink } from "react-csv";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import {history} from '../../../../helper/history';
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';

const EnhancedToolbar = props => {
    const { numSelected,classes,status,searchProductHandler,search,isLoadingTable,totalProduct,download_as,handleDownloadChange,productData,productAction,allProductData,handleUpload,selectAll,handleSelectAllChange,handleDownload} = props;
    
    return (
        <Toolbar>
            {
                numSelected > 0 ?
                (
                    <React.Fragment>
                        <Typography 
                            style={{width:'100%',display:'inline-block',textAlign:'left'}}>
                            {numSelected} 
                            Selected
                        </Typography>
                        {
                            status === "Active" || status === "Fast"
                                    ?
                            <Button 
                                startIcon={<BlockIcon />} 
                                className={classes.Deactive} 
                                variant="outlined" 
                                color="secondary" 
                                act="inactive" 
                                onClick={productAction}
                            >
                                    DeActivate
                            </Button>
                                    :
                            status === "Inactive"
                                    ?
                                    <Button 
                                    style={{marginRight:5}} 
                                    variant="outlined" 
                                    color="primary" 
                                    act="active" 
                                    onClick={productAction}
                                    disabled
                                    >Activate</Button>
                                    :
                                    null
                        }
                        
                    </React.Fragment>
                )
                :
                (
                    <div style={{display:'block',width:'100%'}}>
                    <div style={{display:'inline-block'}}>
                    <Typography 
                        className={classes.mp_heading}
                    >
                        Update Products
                    </Typography>
                    </div>
                    <React.Fragment>
                        <div style={{padding:0,textAlign:'center',display:'inline-block',verticalAlign:'middle'}} >
                            <FormControl variant="outlined" style={{width:180}} margin="dense">
                                <InputLabel id="catalog">Download Catalog</InputLabel>
                                    <Select
                                    labelId="catalog"
                                    id="catalog"
                                    value={download_as}
                                    onChange={handleDownloadChange}
                                    label="Download Catalog"
                                    >
                                    <MenuItem value="">
                                        <em>Download Catalog</em>
                                    </MenuItem>
                                    {/* <MenuItem value = "">Download Catalog</MenuItem> */}
                                    <MenuItem value="all">All Products</MenuItem>
                                    <MenuItem value="active">Active Products</MenuItem>
                                    <MenuItem value="moving">Moving Products</MenuItem>
                                    <MenuItem value="inactive">Inactive Products</MenuItem>
                                </Select>
                            </FormControl>
                    </div>
                    <div className={classes.root} style={{display:'inline-block'}}>
                            <input
                                accept="*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleUpload}
                                value=""
                            />
                            <label htmlFor="contained-button-file">
                                <Button variant="outlined" color="primary" component="span">
                                    <PublishIcon style={{marginRight:10}}/>
                                Update Stock
                                </Button>
                            </label>
                        </div>
                    </React.Fragment>
                    
                    {
                        totalProduct>0 || search.length>0
                        ?
                    <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      onChange={searchProductHandler}
                      value={search}
                      classes={{
                          root: classes.inputRoot,
                          input:classes.inputInput
                      }}
                    />
                    {
                        isLoadingTable
                            ?
                            <div className={classes.searchloader}>
                            <CircularProgress size={14} />
                          </div>
                          :
                          null
                    }
                   
                  </div>:null}
                  </div>
                )
            }
        </Toolbar>
    )
}

const csvheaders = [
    {label : "Product Name", key: "name"},
    {label : "Product ID", key: "ProductID"},
    {label : "Model Number",key: "model"},
    {label : "Transfer Price",key: "transfer_price"},
    {label : "Quantity",key: "quantity"},
    {label : "Minimum Quantity",key: "minimum_qty"},
    {label : "Maximum Quantity",key: "maximum_qty"},
    {label : "Length",key: "length"},
    {label : "Width",key: "width"},
    {label : "Height",key: "height"},
    {label : "Weight",key: "weight"},
    {label : "Status",key: "status"},
    {label : "Pack Quantity",key: "pack_quantity"},
    {label : "SKU",key: "sku"},
    {label : "Category", key : "category"}
]


function manageProducts(){
    const { classes } = this.props;
    return (
        <React.Fragment>
            <div style={{margin:'0 20px'}}>
                <React.Fragment>
                    <h2 className={classes.heading}>Manage Products</h2>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        className={classes.add_button} 
                        onClick={()=>history.push('/add-product')}
                    >
                        Add Product
                    </Button>
                </React.Fragment>
                    {/* <Grid container spacing={3}>
                        {
                             this.state.productCountData.map((item,index)=>(
                                <Grid item xs={6} md={3} key={item.title}>
                                    {
                                        this.state.isLoading
                                                ?
                                        <Skeleton variant="rect" height={100} style={{borderRadius:5}} />
                                                :
                                        <Button 
                                            variant="contained" 
                                            style={{borderRadius:5,padding:0,width:'100%'}} 
                                            onClick={this.GetStatusProduct} 
                                            title={item.title} 
                                            id={index} 
                                            count={item.Count}
                                        >
                                            <Paper 
                                                className={
                                                                this.state.item == index
                                                                        ?
                                                                classes.pr_status_active
                                                                        : 
                                                                classes.pr_status} id={index} 
                                            >
                                            <Typography
                                                className={classes.b_heading} 
                                            >
                                                {item.Count}
                                            </Typography>
                                            <Typography 
                                                className={classes.sub_heading}
                                            >
                                                {item.title}
                                            </Typography>
                                            {
                                                this.state.isLoadingTable && !this.state.isSearching && this.state.item == index
                                                                        ?
                                                <CircularProgress className={classes.progress} color="inherit" size={20}/>
                                                    :
                                                    null
                                            }
                                            
                                            </Paper>
                                        </Button>
                                    }
                                </Grid>
                             ))  
                        }
                    </Grid> */}
                    
                    <Grid container className={classes.stickyDiv}>
                        {
                            this.state.isLoading
                                    ?
                                    <>
                                    <Skeleton variant="rect" width={250} height={50} style={{marginRight:30}} />
                                    <Skeleton variant="rect" width = {250} height = {50} style={{marginRight:30}}/>
                                    <Skeleton variant="rect" width = {250} height = {50} style={{marginRight:30}}/>
                                    <Skeleton variant="rect" width = {250} height = {50} style={{marginRight:30}}/>
                                    </>
                                :
                            this.state.productCount.map((item,index)=>(
                                <div 
                                   
                                    key={item.title}
                                    style={{marginRight : 30}}
                                    >
                                    <Typography style={{textAlign:'left',width:'100%',fontSize:14}}>
                                        {item.title}({item.Count})
                                    </Typography>
                                    {
                                        (
                                            () => {
                                                switch (item.title) {
                                                    case 'Active Products':
                                                        return <> 
                                                        <ButtonGroup color="primary" variant="contained" aria-label="contained primary button group">
                                                            <Button 
                                                                color={this.state.statusId == "2" && this.state.ProductType == 'Active' ? "primary":"default"}
                                                                id="2" 
                                                                onClick={this.getRelatedProducts}
                                                                title="Good Stocks"
                                                                product_type="Active"
                                                                style={{textTransform : 'capitalize',fontSize:12}}
                                                                >Good Stock({this.state.active_good})</Button>
                                                            <Button 
                                                                color={this.state.statusId == "1" && this.state.ProductType == 'Active' ? "primary":"default"}
                                                                id="1" 
                                                                title="pending_manifest"
                                                                onClick={this.getRelatedProducts}
                                                                style={{textTransform : 'capitalize',fontSize:12}}
                                                                product_type="Active"
                                                                >Low Stock({this.state.active_low})</Button>
                                                            <Button 
                                                                color={this.state.statusId == "0" && this.state.ProductType == 'Active' ? "primary":"default"}
                                                                id="0" 
                                                                title="pending_pickup"
                                                                onClick={this.getRelatedProducts}
                                                                style={{textTransform : 'capitalize',fontSize:12}}
                                                                product_type="Active"
                                                                >Out of Stock({this.state.active_out})</Button>
                                                        </ButtonGroup>
                                                        </>
                                                    case 'Moving Products':
                                                        return <>
                                                        <ButtonGroup color="primary" variant="contained" aria-label="contained primary button group">
                                                            <Button 
                                                                color={this.state.statusId == "2" && this.state.ProductType == 'Fast' ? "primary":"default"}
                                                                id="2" 
                                                                onClick={this.getRelatedProducts}
                                                                title="accepted"
                                                                style={{textTransform : 'capitalize',fontSize:12}}
                                                                product_type="Fast"
                                                                >Good Stock({this.state.fast_good})</Button>
                                                            <Button 
                                                                color={this.state.statusId == "1" && this.state.ProductType == 'Fast' ? "primary":"default"}
                                                                id="1" 
                                                                title="pending_manifest"
                                                                onClick={this.getRelatedProducts}
                                                                style={{textTransform : 'capitalize',fontSize:12}}
                                                                product_type="Fast"
                                                                >Low Stock({this.state.fast_low})</Button>
                                                            <Button 
                                                                color={this.state.statusId == "0" && this.state.ProductType == 'Fast' ? "primary":"default"}
                                                                id="0" 
                                                                title="pending_pickup"
                                                                onClick={this.getRelatedProducts}
                                                                style={{textTransform : 'capitalize',fontSize:12}}
                                                                product_type="Fast"
                                                                >Out of Stock({this.state.fast_out})</Button>
                                                        </ButtonGroup>
                                                        </>
                                                    case 'Disabled Products' :
                                                        return <Button
                                                            variant="contained"
                                                            color={this.state.statusId == "0" && this.state.ProductType == 'Inactive' ? "primary":"default"} 
                                                            id="0" 
                                                            title="Inactive Products"
                                                            onClick={this.getRelatedProducts}
                                                            product_type="Inactive"
                                                            style={{textTransform : 'capitalize',fontSize:12}}
                                                        >
                                                            Disabled Products({this.state.inactive})
                                                        </Button>
                                                    default:
                                                        break;
                                                }
                                            }
                                        )()
                                    }
                                </div>
                            ))
                        }
                    </Grid>


                    {
                        this.state.isLoadingTable && !this.state.isSearching
                                ?
                        <Skeleton variant="rect" style={{marginTop:30,borderRadius:5}} height={360}>
                            <Container>
                            <Skeleton variant="text" height={70} />
                            </Container>
                            <Skeleton variant="text" height={70} />
                            <Container>
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>
                                        <Skeleton variant="rect" height={50}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Skeleton variant="text" height={50} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>
                                        <Skeleton variant="rect" height={50}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Skeleton variant="text" height={50} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={1}>
                                        <Skeleton variant="rect" height={50}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Skeleton variant="text" height={50} />
                                    </Grid>
                                </Grid>
                                
                            </Container>
                        </Skeleton>
                                :
                        <Paper style={{width:'100%',marginTop:30,boxShadow:'0px 1px 8px #b7b7b6'}}>
                        <EnhancedToolbar 
                            numSelected={this.state.selected.length} 
                            classes={classes} 
                            status={this.state.status} 
                            searchProductHandler={this.searchProductHandler} 
                            search={this.state.search} 
                            isLoadingTable={this.state.isLoadingTable} 
                            totalProduct={this.state.totalProduct} 
                            isSearching={this.state.isSearching} 
                            download_as={this.state.download_as} 
                            handleDownloadChange={this.handleDownloadChange} 
                            productData={this.state.productData} 
                            productAction={this.productAction}
                            allProductData = {this.state.allProductData}
                            handleUpload = {this.handleUpload}
                            handleSelectAllChange={this.handleSelectAllChange}
                            selectAll = {this.state.selectAll}
                            handleDownload={this.handleDownload}
                        />
                        <TableContainer 
                            component={Paper} 
                            className={classes.t_container}
                        >
                            <Table stickyHeader  >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                        <Checkbox
                                              indeterminate={this.state.selected.length>0}
                                              checked={this.state.selected.length>0}  
                                              onChange={this.onSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell>Image</TableCell>
                                        <TableCell align="left">Product Name</TableCell>
                                        <TableCell align="left">Company</TableCell>
                                        <TableCell align="left">Transfer Price</TableCell>
                                        <TableCell align="left">Quantity</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    
                                    {
                                        this.state.productData.length>0
                                            ?     
                                        this.state.productData.map((item,index)=>{
                                           const isItemSelected = this.isSelected(item.product_id)
                                           let name = item.name
                                           let search = this.state.search
                                          
                                            
                                            return <TableRow 
                                                key={item.name} 
                                                hover 
                                                // role={Checkbox}
                                                // aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                                
                                                >
                                                <TableCell>
                                                    <Checkbox 
                                                    checked={isItemSelected}
                                                    onClick={event => this.handleClick(event, item.product_id)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <img src={item.image} style={{width:60,border:'1px solid #ccc'}}/>
                                                </TableCell>
                                                <TableCell>{this.state.search.length > 0 ? reactStringReplace(name,search,(search,i)=>(
                                                <span 
                                                    key={i}
                                                    className={classes.searchSpan}
                                                >
                                                    {search}
                                                </span>
                                           )): name}</TableCell>
                                                <TableCell>{item.company}</TableCell>
                                                <TableCell>
                                                    {item.price}
                                                </TableCell>
                                                <TableCell style={{position:'relative'}}>
                                                    {
                                                        this.state.ProductType == 'Inactive'
                                                            ?
                                                        item.quantity
                                                            :
                                                    this.state.priceUpdate && this.state.productUpdateId == index
                                                    ?
                                                    <>
                                                    <TextField 
                                                        name="p_quantity"
                                                        variant="outlined"
                                                        value={this.state.p_quantity}
                                                        onChange={this.handleChange}
                                                        margin="dense"
                                                        style={{width:60}}
                                                        autoFocus
                                                    />
                                                    {/* <Button 
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={this.handleProductUpdate}
                                                        id={index}
                                                        >
                                                        Save
                                                    </Button> */}
                                                    <SaveIcon 
                                                        style={{
                                                            fontSize:25,
                                                            color:'blue',
                                                            position:'absolute',
                                                            top:40
                                                        }}
                                                        onClick={this.handleProductUpdate}
                                                        id={index}
                                                        />
                                                    </>
                                                    :
                                                    <Tooltip title="Update Quantity" placement="top">
                                                    <span
                                                        id={index}
                                                        onClick={this.handleUpdatePrice}
                                                        quantity = {item.quantity}
                                                        productid = {item.product_id}
                                                        className={classes.editQuantity}
                                                    >
                                                        {item.quantity}
                                                                <Edit 
                                                                style={{
                                                                    fontSize:15
                                                                }}
                                                                />
                                                    </span>
                                                        </Tooltip>
                                                    }
                                                </TableCell>
                                                <TableCell style={item.status ===1 ? {color:"green"}:{color:"red"}} >{item.status===1?'Active':'Inactive'}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="Edit" placement="top">
                                                        <Link to={{
                                                            pathname:"edit-product",
                                                            state:{product_id:item.product_id}
                                                        }}>
                                                            <Fab color="primary" size="small">
                                                                <Edit />
                                                            </Fab>
                                                        </Link>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                    })
                                    :
                                    <TableRow>
                                        <TableCell ></TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell >No Product Found</TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell ></TableCell>
                                        <TableCell ></TableCell>
                                    </TableRow>
                                    }
                                </TableBody>
                                
                            </Table>
                        </TableContainer> 
                        <TablePagination
                                component="div"
                                rowsPerPage={this.state.rowsPerPage}
                                labelRowsPerPage="Total Products"
                                rowsPerPageOptions={[10,25,50,75]}
                                count={this.state.totalProduct}
                                page={this.state.page -1}
                                onChangePage={this.pageChangeHandler}
                                classes={{
                                    caption: classes.page_root
                                }}
                                onChangeRowsPerPage={this.handleRowPerPage}
                                />
                        </Paper> 
                    }
            </div>
            <Snackbar open={this.state.open} anchorOrigin={{horizontal:'center',vertical:'top'}} >
                <Alert severity={this.state.IsError ? "error" :"success"}>
                    {this.state.message}
                </Alert>
            </Snackbar>
            <Backdrop className={classes.backdrop} open={this.state.backdropOpen} >
                    <CircularProgress color="inherit" />
                </Backdrop>
        </React.Fragment>
    )
}

export default manageProducts;
import React from 'react';
import { Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Container,Button,Dialog, TablePagination,Typography,TableSortLabel,DialogContent,DialogTitle,TextField,DialogActions,Backdrop,CircularProgress,Grid,Fab,Tooltip} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
  import Alert from '@material-ui/lab/Alert';

import { ArrowRight } from '@material-ui/icons';

const headCells = [
    { id: 'invoice_no', numeric: true, disablePadding: false, label: 'Invoice Number',active : false },
    { id: 'order_id', numeric: true, disablePadding: false, label: 'Order Id',active:false},
    { id: 'invoice_value', numeric: true, disablePadding: false, label: 'Total Invoice Value',active : false },
    { id: 'invoice_copy', numeric: false, disablePadding: false, label: 'Invoice Copy',active : false },
    { id: 'purchase_invoice_date', numeric: false, disablePadding: false, label: 'Date',active : false },
    { id: 'action', numeric: false, disablePadding: false, label: 'Action',active : false },
  ];


function purchase_invoice(){
    const {classes} = this.props;   
    const createSortHandler = (property) => (event) => {
        this.handleRequestSort(event, property);
      };
    return (
        <>
        <Container >
            {
                this.state.isLoading
                        ?
                    <>
                        <Skeleton variant="rect" style={{marginTop:30,borderRadius:5}} height={360}>
                                <Container>
                                <Skeleton variant="text" height={70} />
                                </Container>
                                <Skeleton variant="text" height={70} />
                                <Container>
                                    <Grid container spacing={3}>
                                        <Grid item xs={1}>
                                            <Skeleton variant="rect" height={50}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Skeleton variant="text" height={50} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={1}>
                                            <Skeleton variant="rect" height={50}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Skeleton variant="text" height={50} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={1}>
                                            <Skeleton variant="rect" height={50}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Skeleton variant="text" height={50} />
                                        </Grid>
                                    </Grid>
                                    
                                </Container>
                            </Skeleton>
                    </>
                        :
            <>
            <TextField id="filter_order_id" style={{marginLeft:'82%'}} name="filter_order_id" value={this.state.filter_order_id} label="Order ID / Invoice No." variant="outlined" onChange={this.handleSearchOrder} />
            <div style={{margin:'10px 0px',height:40}}>
                <Typography
                    variant="h4"
                    style={{
                        display:'inline-block',
                        fontSize:20,
                        verticalAlign:'middle',
                        marginTop:5
                    }}
                >
                    Purchase Invoice
                </Typography>
                
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={this.openDialog}
                    title="add"
                    style={{
                        float:'right'
                        }}>
                            Add
                </Button>
            </div>
            {
                this.state.purchase_invoice_data.length > 0
                                ?
            <Paper >
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={headCell.numeric ? 'right' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'default'}
                                    sortDirection={this.state.orderBy === headCell.id ? this.state.order : false}
                                    align="center"
                                >
                                    <TableSortLabel
                                    active={this.state.orderBy === headCell.id}
                                    direction={this.state.orderBy === headCell.id ? this.state.order : 'asc'}
                                    onClick={headCell.active ? createSortHandler(headCell.id) : null}
                                    >
                                    {headCell.label}
                                    {this.state.orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                        {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                    ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                ))}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                            this.state.purchase_invoice_data.map((item,index)=>(
                                <TableRow key={item.purchase_invoice_no}>
                                    <TableCell align="center">{item.purchase_invoice_no}</TableCell>
                                    <TableCell align="center">{item.purchase_vendor_order_id}</TableCell>
                                    <TableCell align="center">₹ {item.total}</TableCell>
                                    <TableCell align="center">{
                                    item.p_inv_url
                                            ?
                                    < a href={item.p_inv_url} target="_blank">View File</a>
                                            :
                                        <div className={classes.root}>
                                            <input
                                            accept="image/jpeg,image/gif,image/png,application/pdf,"
                                            className={classes.input}
                                            id={"purchase_invoice"+index}
                                            multiple
                                            type="file"
                                            index={index}
                                            onChange={this.handleUpload}
                                            value={""}
                                            />
                                            <label htmlFor={"purchase_invoice"+index}>
                                            <Button variant="contained" color="primary" component="span" >
                                                Upload
                                            </Button>
                                            </label>
                                        </div>
                                    }
                                    </TableCell>
                                    <TableCell align="center">{item.purchase_invoice_date}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Edit">
                                            <Fab 
                                                color="primary" 
                                                aria-label="add"
                                                index={index}
                                                onClick={this.handleEdit}
                                                name="update"
                                                >
                                                <EditIcon 
                                                />
                                            </Fab>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination 
                rowsPerPageOptions={[10, 25,50]}
                component="div"
                count={this.state.totalCount}
                rowsPerPage={this.state.rowsPerPages}
                page={this.state.page - 1}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                classes={{
                    caption: classes.page_root
                }}
                />
            </Paper>
            :
            <Paper 
                style={{
                    height:400,
                    textAlign:'center'
                }}
            >
                <div style={{paddingTop:100}}>
                <DescriptionIcon style={{fontSize:100,color:'#999'}}/>
                <Typography variant="h4" >
                        No data found
                </Typography>
                </div>
            </Paper>
    }
            <Dialog  open={this.state.purchaseInvoiceModalopen} onClose={this.ClosePurchaseInvoiceDialog} >
                <DialogTitle id="form-dialog-title">Purchase Invoice Details</DialogTitle>
                    <DialogContent style={{width:420}}>
                        <form>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={this.state.allOrders}
                                getOptionLabel={(option) => option.title}
                                filterSelectedOptions
                                onChange={this.addVendorOrderId}
                                value={this.state.selectedVendorOrderId}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Order Id"
                                    placeholder="Order Id"
                                    // value={this.state.selectedVendor_order_id}
                                    error={this.state.p_invoiceSubmitted && !this.state.selectedVendorOrderId.length > 0 }
                                    helperText={this.state.p_invoiceSubmitted && !this.state.selectedVendorOrderId.length > 0 ? "Please Enter Order Id":""}
                                />
                                )}
                                style={{
                                    marginBottom:20
                                }}
                            />
                            <TextField 
                                variant="outlined" 
                                label="Purchase Invoice Number" 
                                value={this.state.purchaseInvoiceNumber} 
                                onChange={this.handlePurchaseInvoiceChange} 
                                name="purchaseInvoiceNumber" 
                                fullWidth 
                                style={{marginBottom:20}}
                                inputRef={this.purchaseInvoiceNumber}
                                error={this.state.p_invoiceSubmitted && !this.state.purchaseInvoiceNumber ? true : false}
                                helperText = {this.state.p_invoiceSubmitted && !this.state.purchaseInvoiceNumber ? "Purchase Invoice Number is required":""}
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Purchase Invoice Date"
                            format="dd/MM/yyyy"
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            format = "dd/MM/yyyy"
                            />
                            </MuiPickersUtilsProvider>
                            <div>
                            <label style={{fontSize:12,letterSpacing:'0.02px',color:"#777",margin:'20px 0 10px 0',display:'block'}}>Upload Purchase Invoice(optional)</label>
                            </div>
                            <div>
                                
                            <input
                                accept="pdf/*"
                                className={classes.input}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={this.handleInvoiceUpload}
                            />
                            {/* {(this.state.p_invoiceSubmitted && !this.state.purchaseInvoicePdf) && <Alert variant="filled" severity="error">
                            Please upload invoice file first</Alert>} */}
                            <label htmlFor="contained-button-file">
                                <Button variant="contained" color="primary" component="span" disabled={this.state.isLoadingSave}>
                                Upload
                                </Button>
                            </label>
                            {
                                this.state.purchaseInvoicePdf !== null
                                        ?
                                this.state.purchaseInvoicePdf.name
                                        :
                                        null
                            }
                            </div>
                            
                        </form>
                    <DialogActions >
                        <div style={{textAlign:"center",display:'block',width:'100%'}}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                title="single"
                                style={{marginRight:10}}
                                onClick={this.handlePurchaseInvoiceUpload}
                                disabled={this.state.isLoadingSave}
                                >
                                    {
                                        this.state.isLoadingSave
                                                ?
                                        <CircularProgress 
                                            color="inherit"
                                            size={25}
                                        />
                                          :
                                        "Save"
                                    }
                            </Button>
                            <Button
                                variant="contained" 
                                color="primary" 
                                onClick={this.ClosePurchaseInvoiceDialog}
                                disabled={this.state.isLoadingSave}
                                >
                                    Cancel
                            </Button>
                        </div>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            </>
        }
        </Container>
        <Backdrop className={classes.backdrop} open={this.state.backdropOpen} style={{zIndex:99}}>
            <CircularProgress color="inherit" />
        </Backdrop>
        </>
    )
}
export default purchase_invoice;
import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const style = {
    textAlign: 'center',
    height:430,
    
}


function Sm_wt_wrng(){
    return (
        <div style={style}>
                <h1 style={{marginTop:100,marginBottom:50}}>Oops...</h1>
                <ErrorOutlineIcon  style={{fontSize:'100px'}}/>
                <h1>Something went Wrong</h1>
        </div>
    )
}

export default Sm_wt_wrng;
import React, { Component } from 'react';
import product_action from './addProduct.jsx';
import {authHeader} from '../../../../helper/auth-header';
import axios from 'axios';
import siteConfig from '../../../../config';
import { withStyles } from '@material-ui/core/styles'

const apiUrl = siteConfig.apiUrl;

const styles = ()=>({
  heading:{
    fontSize:25,
    fontWeight:400
  },
  root:{
    width:'100%'
  },
  listItem :{
    width:300
  },
  selectCategory:{
    width:'85%',
    marginRight:10
  },
  button:{
    width: 165,
    fontSize: 14,
    padding: 14
  }
})

class AddProduct extends Component {
  state={
    product_id:0,
    value:0,
    category_id : 0,
    product_data : [], 
    first_step_complete : false
  }
  componentDidMount(){
    this.setState({
      product_id : this.props.locationState ? this.props.locationState.product_id : 0
    })
  }
 
  handleTabChange=(e,value)=>{
    this.setState({
      value : value
    })
  }
   
  getAllProducts = async (e) => {
      let manufacturer_id = e;
      this.setState({
        manufacturer_id : manufacturer_id,
        first_step_complete : true
      })
          
  }
  deleteCategory=()=>{
    this.setState({
      first_step_complete : false
    })
  }
  render() {
    return product_action.call(this);
  }
}

export default withStyles(styles)(AddProduct);
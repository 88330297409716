import React, { Component } from 'react';
import SignupStep1 from './SignupStep1/SignupStep1';
import SignupStep2 from './SignupStep2/SignupStep2';
import {Paper,Button,Tooltip,Snackbar,withStyles} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import styles from './Signup.css';

const style=theme=>({
    outer_paper:{
        width:300,
        padding:20,
        position:'relative',
        [theme.breakpoints.down('md')]:{
            width:'100%',
            padding:20
          }
    }
})

class Signup extends Component {
    state ={
        step1:true,
        isLoading:false,
        name: '',
        mobile:'',
        isError:false,
        message:'',
        open:false
    }
    changeStep = () => {
        this.setState({
            step1:!this.state.step1
        })
    }
    changeName=(e)=>{
        this.setState({
            name: e
        })
    }
    changeMobile = (e) => {
        this.setState({
            mobile: e
        })
    }
    setError = (e) => {
        this.setState({
            isError:e
        },()=>{
            setTimeout(() => {
                this.setState({
                    isError:false
                })
            }, 4000);
        })
    }
    setOpen = () => {
        this.setState({
            open:true
        },()=>{
            setTimeout(() => {
                this.setState({
                    open:false
                })
            }, 3000);
        })
    }
    setMessage = (e)=>{
        this.setState({
            message:e
        },()=>{
            setTimeout(() => {
                this.setState({
                    message:''
                })
            }, 3000);
        })
    }
  render() {
      const {classes}=this.props;
    return (
      <React.Fragment>
          <Paper className={classes.outer_paper}>
          {
              !this.state.step1 ?
              <Tooltip title="Click to go back" placement="left">
              <i className="material-icons back" onClick={this.changeStep} style={styles.back}>
              keyboard_backspace
              </i>
              </Tooltip>
              :null
            }
          
          <h2 style={{marginTop:0,fontWeight:'normal',textAlign:'center'}}>Become a Seller</h2>
          {
              this.state.step1 === true 
                    ?
                <SignupStep1 
                    onChange={this.changeStep} 
                    nameChange={this.changeName} 
                    mobileChange={this.changeMobile} 
                    name={this.state.name} 
                    mobile={this.state.mobile}
                    setError={this.setError}
                    setMessage={this.setMessage}
                    setOpen={this.setOpen}
                    />
                    :
                <SignupStep2 mobile={this.state.mobile} name={this.state.name}/>
          }
          {
              this.state.step1 
                ?
                <Button variant="text" onClick={this.props.handleClickOpen}>Existing seller,login</Button>
                :
                null
          }
          
          </Paper>
          <Snackbar open={this.state.open} anchorOrigin={{vertical:"top",horizontal:"center"}}>
              <Alert severity={this.state.isError?'error':'success'}>
                  {
                      this.state.message
                  }
              </Alert>
          </Snackbar>
      </React.Fragment>
    );
  }
}

export default withStyles(style)(Signup)
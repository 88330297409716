import axios from 'axios';
import siteConfig from '../config';

const apiUrl = siteConfig.apiUrl;

export const UserService = {
    login,
    verifyAndLogin,
    logout
}

function login(username,password){
    return new Promise((reject,resolve)=>{
        const requestOptions = {
            method:'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*' 
            },
            data:{
                username : username,
                password : password,
                device_type : '1'
            }
        }
        return axios(apiUrl+'/signin',requestOptions)
        .then(user=> {
            localStorage.setItem('seller_username',username);
            localStorage.setItem('seller',JSON.stringify(user.data.data));
            return resolve(user);
        })
        .catch(error=>{
            return reject (error);
    })
    })
        
}

function logout(){
    localStorage.removeItem('seller')
    localStorage.removeItem('seller_username')
    localStorage.removeItem('is_superUser')
    localStorage.removeItem('superUser')
    localStorage.removeItem('header_navigation')
    localStorage.removeItem('selectedVendor')
}
function verifyAndLogin(username,otp,password){
    return new Promise((reject,resolve)=>{
        const requestOptions = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin':'*' 
            },
            data: {
                key:"login",
                username:username,
                otp:otp,
                password:password, // enter password on forgot case
                user_id :0,    // if user logged in use user_id else 0 
                fcm_token :"",
                device_id :"",
                device_type:"1"   //device_type=2 for Android
            }
        };
    
        return axios(apiUrl+'/verify-otp', requestOptions)
            // .then(data=>handleResponse(data))
            .then(user =>{
                localStorage.setItem('seller_username',username);
                localStorage.setItem('seller',JSON.stringify(user.data.data));
                return resolve(user);
            })
            .catch(error=>{
                return reject(error);
            })
    })
}
function handleResponse(response) {
    console.log("response",response)
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
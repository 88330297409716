// import React from 'react';
import { history} from '../helper/history';
// import { Redirect } from 'react-router-dom';
import { UserService } from '../Services/user.services';
import {LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAILURE, LOG_OUT} from '../constant/index';

export const userActions = {
    login,
    verifyAndLogin,
    logout
}

function login(username,password){
    return dispatch => { 
    return new Promise((reject,resolve)=>{
        
            // (request({username}))
        UserService.login(username,password)
    .then(
        user=> {
            // dispatch(success(user));
            return resolve(user)
            // history.push('/dashboard');
        },
        error=> {
            // dispatch(failure(error));
             return reject(error);
        })
    })
}  
    // function request(user){ return {
    //     type : LOGIN_REQUEST,user
    // }}
    // function success(user){return{
    //     type : LOGIN_SUCCESS, user
    // }}
    // function failure(error){return {
    //     type : LOGIN_FAILURE, error
    // }}
}
function verifyAndLogin(username,otp,password) {
    return dispatch => {
        // dispatch(request({ username }));
        return new Promise((reject,resolve)=>{
            UserService.verifyAndLogin(username,otp,password)
             .then(
                 user => { 
                //    resolve(dispatch(success(user)));
                return resolve(user)
                },
                error => { 
                    return reject(error);
                }
             );
        }) 
     };
 
    //  function request(user) { return { type: LOGIN_REQUEST, user } }
    //  function success(user) { return { type: LOGIN_SUCCESS, user } }
 //    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function logout(){
    UserService.logout();
    history.push('/')
    return {type: LOG_OUT};
}

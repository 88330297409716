import React, { Component } from 'react';
import {Divider, TextField, Grid, FormControl,InputLabel,MenuItem,Select,Typography} from '@material-ui/core';
import ManageQuantity from '../ManageQuantity/ManageQuantity';

class Dimension extends Component{
    state = {
        length : '',
        width : '',
        height : '',
        weight : '',
        quantity : '',
        index : '',
        productData : [],
        box : '',
        updatedProductData : []
    }
    handleChange = (e)=>{
        // console.log(e.target.value," value")
        e.persist();
        // console.log(this.props.boxArray,"BOXARRAY ")

          
const newValue= e.target.value;
const newTargetName=e.target.name
console.log(newValue,"NEW VALUE ")


        this.setState({
            [e.target.name] : e.target.value
        },()=>{
            this.props.getDimension({
                name : newTargetName,
                value : newValue,
                index : this.props.index
            })
        })
    }
    componentDidMount(){
        let productData = this.props.orderData.orderDetail.productData
        this.setState({
            index : Number(this.props.index) + 1,
            productData
        })
    }
    handleQuantityUpdate = (data) => {
        this.props.getMpsDimension(data)
    }
    render(){
        return (
            <div style={{border:'1px solid #eaeaea',padding:20,margin:"10px 0px",borderRadius:5,boxShadow : '0px 0px 2px #ccc'}}>
                <Grid container>
                    <Grid item xs={6}>
                        <div style={{width:"90%",display:'inline-block',verticalAlign:'top'}}>
                            <Typography style={{width:'100%',textAlign:'left',marginBottom:5}}>Box #{this.state.index} Details</Typography>
                                {
                                    this.state.productData.map((item,index)=>{
                                        // console.log(item)
                                        return <ManageQuantity 
                                                productData = {this.state.productData}
                                                key={item.product_name}
                                                boxArray={this.props.boxArray}
                                                handleQuantityUpdate={this.handleQuantityUpdate}
                                                mainIndex={this.props.index}
                                                productIndex={index}
                                                submitted={this.props.isSubmitted}
                                            />
                                    })
                                }
                        </div>
                        <Divider orientation="vertical" style={{display:'inline-block'}}/>
                    </Grid>
                    <Grid item xs={6}>
                        <div>
                            <TextField
                            name="length"
                            value={this.state.length?this.state.length:this.props.item.length}
                            onChange={this.handleChange}
                            variant="outlined"
                            label="length"
                            error={this.props.isSubmitted && (!this.props.item.length || isNaN(this.state.length))}
                            margin="dense"
                            fullWidth
                            />
                            <TextField
                            name="width"
                            value={this.state.width?this.state.width:this.props.item.width}
                            onChange={this.handleChange}
                            variant="outlined"
                            label="width"
                            error={this.props.isSubmitted && (!this.props.item.width || isNaN(this.state.width))}
                            margin="dense"
                            fullWidth
                            />
                            <TextField
                            name="height"
                            value={this.state.height?this.state.height:this.props.item.height}
                            onChange={this.handleChange}
                            variant="outlined"
                            label="height"
                            error={this.props.isSubmitted && (!this.props.item.height || isNaN(this.state.height))}
                            margin="dense"
                            fullWidth
                            />
                            <TextField
                            name="weight"
                            value={this.state.weight?this.state.weight:this.props.item.weight}
                            onChange={this.handleChange}
                            variant="outlined"
                            label="weight"
                            error={this.props.isSubmitted && (!this.props.item.weight || isNaN(this.state.weight))}
                            margin="dense"
                            fullWidth
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default Dimension
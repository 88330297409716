import React,{Component} from 'react';
import LoginWpassword from './LoginWPassword/LoginWPassword';
import LoginWotp from './LoginWotp/LoginWotp';
import LoginWforgotpassword from './LoginWforgotpassword/LoginWforgotpassword';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert'
import './Login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            show: false,
            loginWith : 'password',
            isLoading: false,
            username : '',
            open:false,
            IsError:false,
            message:"",
            userError:false,
            passwordError:false,
            otpError:false
        }
    }
    setErrorFalse=()=>{
        this.setState({
            userError:false,
            passwordError:false
        })
    }
    setUserError=()=>{
        this.setState({
            userError:true
        })
    }
    setPasswordError=()=>{
        this.setState({
            passwordError:true
        })
    }
    changeVisibility =() => {
        this.setState({
            show: !this.state.show
        })
    }
    getUsername = (e) => {
        this.setState({
            username : e
        })
    }
    LoginScreen = () => {
        switch(this.state.loginWith){
            case 'forgot_password':
            return <LoginWforgotpassword
                        username={this.state.username}
                        setOpen = {this.setOpen}
                        setMessage ={this.setMessage}
                        setError = {this.setError}
                        changeStep={this.changeLoginScreen}
                    />
            case 'otp':
            return <LoginWotp 
                        isloading={this.state.isLoading}
                        username={this.state.username}
                        getUsername = {this.getUsername}
                        setOpen = {this.setOpen}
                        setMessage ={this.setMessage}
                        setError = {this.setError}
                        changeStep={this.changeLoginScreen}
                        otpError = {this.state.otpError}
                    />
            default :
            return <LoginWpassword 
                        show={this.state.show} 
                        change={this.changeVisibility} 
                        changeScreen={this.changeLoginScreen} 
                        getUsername = {this.getUsername}
                        username={this.state.username}
                        setOpen = {this.setOpen}
                        setMessage ={this.setMessage}
                        setError = {this.setError}
                        userError= {this.state.userError}
                        passwordError={this.state.passwordError}
                        setErrorFalse={this.setErrorFalse}
                        setUserError={this.setUserError}
                        setPasswordError={this.setPasswordError}

                    />
        }
    }
    changeLoginScreen = (loginWith) => {
        this.setState({
            loginWith: loginWith,
            otpError:false,
            userError:false,
            passwordError:false
        })
    }
    setOpen = (e) => {
        this.setState({
            open:e
        },()=>{
            setTimeout(() => {
                this.setState({
                    open: !this.state.open
                })
            }, 3000);
        })
    }
    setMessage = (e) => {
        this.setState({
            message : e
        })
    }
    setError = (e) => {
        this.setState({
            IsError : e
        })
    }
    render() { 
        return ( 
            <React.Fragment>
                {this.LoginScreen()}
                <Snackbar open={this.state.open} anchorOrigin={this.state.IsError ? {vertical:'top',horizontal:'center'}:{vertical:"bottom",horizontal:'center'}} >
                    <Alert severity={this.state.IsError ? "error" :"success"}>{this.state.message}</Alert>
                </Snackbar>
            </React.Fragment>
        );
    }
}

 
export default Login; 
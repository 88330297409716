import React from 'react';
import { Grid,TextField,Button,FormLabel,Fab,Tooltip,IconButton,Snackbar,CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Add,Edit } from '@material-ui/icons';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import pdf from '../../../../assets/images/pdf.png';
import './SignupNext3.css';


function step3(){
    const {classes}=this.props;
    return (
        <React.Fragment>
            <form onSubmit={this.updateBankDetails}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                <TextField 
                    variant="outlined" 
                    fullWidth label="Account Holder Name" 
                    style={{marginBottom:20}}
                    name="account_name"
                    value={this.state.account_name}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.account_name}
                    helperText={this.state.submitted && !this.state.account_name ? "Enter valid name":""}
                    inputRef={this.name}
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth label="Account Number" 
                    style={{marginBottom:20}}
                    name="accountNo"
                    value={this.state.accountNo}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.accountNo}
                    helperText={this.state.submitted && !this.state.accountNo ? "Enter valid account no":""}
                    inputRef={this.accountNo}
                    >
                    </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth label="Retype Account Number" 
                    style={{marginBottom:20}}
                    name="ConfirmaccountNo"
                    value={this.state.ConfirmaccountNo}
                    onChange={this.handleChange}
                    error={this.state.submitted && (!this.state.ConfirmaccountNo || this.state.accountNo !== this.state.ConfirmaccountNo)}
                    helperText={
                                    this.state.submitted 
                                        && 
                                    (
                                        !this.state.ConfirmaccountNo 
                                                    || 
                                        this.state.accountNo !== this.state.ConfirmaccountNo
                                    ) 
                                            ? 
                                    "Account Number not match"
                                            :
                                        ""
                                    }
                    inputRef={this.confirm_account}
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth label="Bank Name" 
                    style={{marginBottom:20}}
                    name="bank_name"
                    value={this.state.bank_name}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.bank_name}
                    helperText={this.state.submitted && !this.state.bank_name ? "Enter valid Bank name":""}
                    inputRef={this.bank_name}
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth label="Bank Address" 
                    style={{marginBottom:20}}
                    name="bank_address"
                    value={this.state.bank_address}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.bank_address}
                    helperText={this.state.submitted && !this.state.bank_address ? "Enter valid Address":""}
                    inputRef={this.bankAddress}
                    >
                </TextField>
                </Grid>
                <Grid item xs={6}>
                <TextField 
                    variant="outlined" 
                    fullWidth label="IFSC" 
                    style={{marginBottom:20}}
                    name="ifsc"
                    value={this.state.ifsc}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.ifsc}
                    helperText={this.state.submitted && !this.state.ifsc ? "Enter valid ifsc":""}
                    inputRef={this.ifsc}
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth label="State" 
                    style={{marginBottom:20}}
                    name="bank_state"
                    value={this.state.bank_state}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.bank_state}
                    helperText={this.state.submitted && !this.state.bank_state ? "Enter valid state":""}
                    inputRef={this.State}
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth label="City" 
                    style={{marginBottom:20}}
                    name="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.city}
                    helperText={this.state.submitted && !this.state.city ? "Enter valid city":""}
                    inputRef={this.city}
                    >
                </TextField>
                <TextField 
                    variant="outlined" 
                    fullWidth label="Branch" 
                    style={{marginBottom:20}}
                    name="branch"
                    value={this.state.branch}
                    onChange={this.handleChange}
                    error={this.state.submitted && !this.state.branch}
                    helperText={this.state.submitted && !this.state.branch ? "Enter valid branch":""}
                    inputRef={this.branch}
                    >

                </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormLabel 
                        className={
                            this.state.submitted && this.state.cheque == null
                                        ?
                                    classes.errFormLabel
                                        :
                                    classes.formLabel
                        }
                    >
                        Upload Cancelled Cheque
                    </FormLabel>
                    <div 
                        className={
                            this.state.submitted && this.state.cheque_image === null
                                        ?
                                    classes.err_outer_div
                                        :
                                    classes.outer_div
                        }
                    >
                            <div className={classes.doc_div}>
                                {
                                    this.state.cheque_image !== null
                                            ?
                                            this.state.cheque_image_file !== null 
                                             ?
                                    this.state.cheque_image_file.name.includes('pdf')
                                            ?
                                            <>
                                    
                                    <img src={pdf} alt="pdf" style={{height:100,width:100}}/>
                                    {
                                        this.state.cheque_image_file !== null 
                                                ? 
                                        <p style={{width:'100%'}}>{this.state.cheque_image_file.name}</p>
                                                :
                                                null
                                    }
                                    </>
                                    :
                                    <img src={this.state.cheque_image} style={{width:'100%',height:150}} alt="bank_proof"/>
                                    :
                                    null
                                    :
                                    null
                                }
                            </div>
                                    
                            <Tooltip title={this.state.cheque_image === null ? "Upload Cheque":"Edit Cheque"} placement="top">
                            <Fab color={this.state.submitted && this.state.cheque_image === null ? "secondary" :"primary"} style={this.state.cheque_image === null ? {position: 'absolute',top:10,left:'45%'}:{position:'absolute',right:"45%",bottom:10}} size="small">
                            {
                                this.state.cheque_image !== null
                                        ?
                                        <React.Fragment>
                                        <input accept="image/*" name="proof"  id="icon-button-file" type="file" onChange={this.getImage}/>
                                        <label htmlFor="icon-button-file">
                                          <IconButton color="inherit" aria-label="upload picture" component="span" className="icon_button" size="medium" > 
                                          <Edit fontSize="small" />
                                          </IconButton>
                                        </label>
                                        </React.Fragment>
                                        :
                                    <React.Fragment>
                                        <Add />
                                <input type="file" name="proof" style={{background:'#ccc',width:28,position: 'absolute',opacity:0}} onChange={this.getImage}/>
                                </React.Fragment>
                            }
                                
                            </Fab>
                            </Tooltip>
                    </div>
                    {
                                this.state.submitted && this.state.cheque === null
                                                ?
                                <p style={{fontSize:13,color:'red',textAlign:'left'}}>Cancelled cheque is required</p>
                                                :
                                            null
                    }
                </Grid>
            </Grid>
            <div style={{padding:'40px 0',textAlign:'center',}}>
                <Button variant="contained" color="primary" style={{marginRight:10}} onClick={this.props.previous} disabled={this.state.isLoading}>Back</Button>
                <Button variant="contained" type="submit" color="primary" disabled={this.state.isLoading}>
                    {
                        this.state.isLoading
                                ?
                        <CircularProgress color="inherit" size={25}/>
                                :
                            "Save"
                    }
                </Button>
            </div>
            </form>
            <Snackbar open={this.state.open}>
                <Alert  severity={this.state.hasError ? "error" :"success"} >
                            {this.state.message}
                </Alert>
            </Snackbar>
        </React.Fragment>
    )
}
export default step3;
import React from 'react';
import { Container,Paper,Button, Typography,Grid, Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TablePagination, CircularProgress,Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
// import { Skeleton } from '@material-ui/lab';
import format from 'date-fns/format';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

function report(){
    const { classes } = this.props;
    return (
        <>
        <div style={{margin:'0px 20px'}}>
            <Typography variant="h5" style={{marginBottom:10}}>Search Order</Typography>
            <Paper 
                style={{
                    position:'relative'
                }}
                >
                <Grid container >
                    <Grid item sm={11}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                            margin="normal"
                            id="from-date-picker-dialog"
                            label="From"
                            format="MM/dd/yyyy"
                            value={this.state.fromDate}
                            onChange={this.handlefromDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{
                                margin : 20
                            }}
                            format = "dd/MM/yyyy"
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                            margin="normal"
                            id="to-date-picker-dialog"
                            label="To"
                            format="MM/dd/yyyy"
                            value={this.state.toDate}
                            onChange={this.handleToDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{
                                margin : 20
                            }}
                            format = "dd/MM/yyyy"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item sm={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                position:'absolute',
                                right: 20,
                                top:20
                            }}
                            size="large"
                            onClick={this.getOrders}
                            disabled={this.state.isLoading}
                            >
                                {
                                    this.state.isLoading
                                            ?
                                    <CircularProgress 
                                        color="inherit"
                                        size={28}
                                    />
                                            :
                                        "Search"
                                }
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            {
                !this.state.orderData.length > 0 && this.state.searched
                                ?
                <Paper style={{height:300,textAlign:'center',marginTop:20,paddingTop:100}} elevation={4}>
                    <CloudOffIcon style={{fontSize:100,color:'#ccc'}}/>
                    <Typography variant="h4" style={{color:'#ccc'}}>No data found</Typography>
                </Paper>
                :null
            }
            {
                this.state.orderData.length > 0
                            ?
                <>
                <div style={{textAlign:'center',margin:'20px 0',position:'sticky',top:150}}>
                    <Button variant="contained" color="primary" onClick={this.downloadReport}>
                        Export Data
                    </Button>
                    <Button variant="contained" color="primary" style={{marginLeft:10}} onClick={this.clearData}>
                        Clear
                    </Button>
                </div>
                <Paper>
                <TableContainer component={Paper} style={{marginTop:20}}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="center" className={classes.th}>Order Id</TableCell>
                        <TableCell align="center" className={classes.th}>Order Status</TableCell>
                        <TableCell align="center" className={classes.th}>Order Date</TableCell>
                        <TableCell align="center" className={classes.th}>Product Name</TableCell>
                        <TableCell align="center" className={classes.th}>Model No</TableCell>
                        <TableCell align="center" className={classes.th}>No. of Products</TableCell>
                        <TableCell align="center" className={classes.th}>Transfer Price</TableCell>
                        <TableCell align="center" className={classes.th}>Net Sales Value</TableCell>
                        <TableCell align="center" className={classes.th}>GST Value</TableCell>
                        <TableCell align="center" className={classes.th}>Total</TableCell>
                        <TableCell align="center" className={classes.th}>Category</TableCell>
                        <TableCell align="center" className={classes.th}>Customer State</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.orderData.map((row,index) => {
                        let date_added = format(new Date(row.date_added),'dd-MM-yyyy');
                        let Total = Number(row.net_sales_value) + Number(row.tax_amount);
                       return  <TableRow key={row.vendor_order_id+index}>
                        <TableCell component="th" scope="row" align="center" className={classes.td}>
                            {row.vendor_order_id}
                        </TableCell>
                        <TableCell align="center" className={classes.td}>{row.order_status_id}</TableCell>
                        <TableCell align="center" className={classes.td}>{date_added}</TableCell>
                        <TableCell align="center" className={classes.td}>{row.name}</TableCell>
                        <TableCell align="center" className={classes.td}>{row.model}</TableCell>
                        <TableCell align="center" className={classes.td}>{row.quantity}</TableCell>
                        <TableCell align="center" className={classes.td}>{row.transfer_price}</TableCell>
                        <TableCell align="center" className={classes.td}>{row.net_sales_value}</TableCell>
                        <TableCell align="center" className={classes.td}>{row.tax_amount}</TableCell>
                        <TableCell align="center" className={classes.td}>{Total.toFixed(2)}</TableCell>
                        <TableCell align="center" className={classes.td}>{row.category}</TableCell>
                        <TableCell align="center" className={classes.td}>{row.customer_state}</TableCell>
                        </TableRow>
})}
                    </TableBody>
                </Table>
                </TableContainer>
                <TablePagination 
                rowsPerPageOptions={[10,25,50]}
                component="div"
                count={this.state.totalCount}
                rowsPerPage={this.state.rowsPerPages}
                page={this.state.page - 1}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                classes={{
                    caption: classes.page_root
                }}
                />
                </Paper>
                </>
                    :
                    null
            }
        </div>
        <Snackbar open={this.state.open} anchorOrigin={{vertical:'top',horizontal:'center'}}>
            <Alert severity={this.state.isError? "error":"success"}>{this.state.message}</Alert>
        </Snackbar>
        </>
    )
}
export default report;
import React, { Component } from 'react';
import step1 from './SignupNext1.jsx';
import siteConfig from '../../../../config';
import {authHeader} from '../../../../helper/auth-header';
import axios from 'axios';
import {Slide} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import './SignupNext1.css';


const styles = theme => ({
  formControl : {
    margin :theme.spacing(1)
  },
  image_message:{
    fontSize:12,
    color:"#999",
    fontWeight:"normal"
  },
  pdfdocument:{
    width:"100%",
    margin:'0 auto',
    marginTop:20,
    textAlign:'center'
  },
  imagedocument:{
    width:100,
    margin:'0 auto',
    marginTop:20,
    textAlign:'center'
  },
  text:{
    fontSize:14,
    fontWeight:'normal',
    width:'100%',
    marginTop:10,
    color:"#999"
  },
  image:{
    height:100,
    width:100,
    cursor:'pointer'
  }
})
const maxImageSize = 8e+6
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map(item=>{return item.trim()})
const apiUrl = siteConfig.apiUrl;
const b2bUrl = siteConfig.b2bUrl;
const image_url = siteConfig.image_url;
 class SignupNext1 extends Component {
        constructor(props){
              super(props);
              this.state = {
                Submitted: false,
                business_types:[],
                selectedBusiness: '',
                business_name: '',
                brand_name:'',
                business_description:'',
                business_address:'',
                pincode:'',
                city:'',
                state:'',
                pan_no:'',
                gstin_no:'',
                addressProof:null,
                panProof:null,
                gstinProof:null,
                selectedImage : null,
                isLoading:false,
                open: false,
                image_type : '',
                proof: '',
                message: '',
                hasError:'',
                labelWidth:0,
                pincodeError : false,
                isLoadingImage : false
              }
              this.business_select = React.createRef('BusinessType');
              this.businessName = React.createRef('Business_name');
              this.brandName = React.createRef('Brand_name');
              this.Description = React.createRef('Description');
              this.Address = React.createRef('Address');
              this.pinCode = React.createRef('pincode');
              this.City = React.createRef('City');
              this.State = React.createRef('State');
              this.PanNo = React.createRef('PanNo');
              this.GSTNo = React.createRef('GSTNo');
        }
  
  componentDidMount(){
    this.getBusinessInfo()
    this.businessTypes()
  }

        getBusinessInfo = () => {
          const requestOptions = {
            method : 'GET',
            headers : authHeader()
          }
          return axios(apiUrl+'/get-user-info',requestOptions)
          .then(data=>{
            if(data){
              this.setState({
                business_name : data.data.data.vendor_name === null ? "" : data.data.data.vendor_name,
                selectedBusiness : data.data.data.business_type === null ? "" : data.data.data.business_type,
                brand_name : data.data.data.brand_name === null ? "" : data.data.data.brand_name,
                business_description : data.data.data.vendor_description === null ? "" : data.data.data.vendor_description,
                business_address : data.data.data.business_address === null ? "" : data.data.data.business_address,
                pincode : data.data.data.address_pincode === null ? "" : data.data.data.address_pincode,
                city : data.data.data.address_city === null ? "" : data.data.data.address_city,
                state : data.data.data.address_state === null ? "" : data.data.data.address_state,
                pan_no : data.data.data.pan_no === null ? "" : data.data.data.pan_no,
                gstin_no : data.data.data.gstin_no === null ? "" : data.data.data.gstin_no,
                addressProof : data.data.data.address_proof === null ? null : data.data.data.address_proof,
                panProof : data.data.data.pan_card_proof=== null ?null: data.data.data.pan_card_proof,
                gstinProof: data.data.data.pan_card_proof === null ? null : data.data.data.gstin_proof 
              })
            }
          })
          .catch(error=>{
            this.setState({
              open:true,
              hasError:true,
              message :error.response ? error.response.data.message : 'network error'
            },()=>{
              setTimeout(() => {
                this.setState({
                  open:false
                })
              }, 3000);
            })
          })
        }



  TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }
  handleOpen = Transition => () => {
    this.setState({
      open: !this.state.open
    })
  }
  businessTypes = () => {
    return axios.get(apiUrl+'/business-types')
    .then(data=>{
      this.setState({
        business_types : data.data.data
      })
    })
    .catch(error=>{
      console.log(error)
    })
  }
  handleChange = (e) => {
    let labelWidth = e.currentTarget.offsetWidth;
    this.setState({
      [e.target.name] : e.target.value,
      labelWidth : labelWidth
    })
  }

  verifyFile =(files)=> {
    if(files){
        const currentFile = files
        const currentFileType = currentFile.type
        const currentFileSize = currentFile.size
        if(currentFileSize > maxImageSize){
          this.setState({
            error :true,
            open:true,
            error:"This file size should be less than 8MB"
          },()=>{
            setTimeout(() => {
              this.setState({
                open:false,
                error:false,
                error:""
              })
            }, 3000);
          })
            return false
        }
        if(!acceptedFileTypesArray.includes(currentFileType)){
          this.setState({
            error :true,
            open:true,
            error:'This file is not allowed.Only Images are allowed.'
          },()=>{
            setTimeout(() => {
              this.setState({
                open:false,
                error:false,
                error:""
              })
            }, 3000);
          })
            return false
        }
        return true
    }
}


  uploadProofHandler = (e) => {
    let image_type = e.currentTarget.getAttribute('image_type');
    let file = e.target.files[0];
    const isVerified = this.verifyFile(file);
    if(isVerified){
      this.setState({
        image_type : image_type,
        selectedImage : file
      },()=>this.uploadImage())
    }
  }
  uploadImage = () => {
    // let image_type = this.state.image_type;
    this.setState({
      isLoadingImage : true
    },async ()=>{
      let seller = JSON.parse(localStorage.getItem('seller'));
    let seller_id = seller.user_id;
    let formData = new FormData();
    formData.append('image_type',this.state.image_type);
    formData.append('user_id',seller_id);
    formData.append('proof',this.state.selectedImage);
    let requestOptions = {
      method: 'POST',
      headers : authHeader(),
      data : formData
    }
    return axios(apiUrl+'/upload_doc_images',requestOptions)
    .then(data=>{
      switch (this.state.image_type) {
        case "pan":
          this.setState({
            open: true,
            hasError:false,
            message : 'File uploaded successfully',
            panProof : data.data.data.image,
            isLoadingImage:false
          },()=>{
            setTimeout(() => {
              this.setState({
                open:false,
                message: ''
              })
            }, 2000);
          })
          break;
        case "gstin":
        this.setState({
          open: true,
          hasError:false,
          message : 'File uploaded successfully',
          gstinProof : data.data.data.image,
          isLoadingImage:false
        },()=>{
          setTimeout(() => {
            this.setState({
              open:false,
              message: ''
            })
          }, 2000);
        })
        break;
        default:
          this.setState({
            open: true,
            hasError:false,
            message : 'File uploaded successfully',
            addressProof : data.data.data.image,
            isLoadingImage:false
          },()=>{
            setTimeout(() => {
              this.setState({
                open:false,
                message: ''
              })
            }, 2000);
        })
      }
    })
    .catch(error=>{
      this.setState({
        open:true,
        hasError:true,
        message:error.response ? error.response.data.message : 'network error'
      },()=>{
        setTimeout(() => {
          this.setState({
            open:false,
            error:''
          })
        }, 3000);
      })
    })
    })
  }
  submitBusinessData=(e)=>{
    e.preventDefault()
    this.setState({
        Submitted:true,
        isLoading:true
    },()=>{
        if(this.state.Submitted && this.state.selectedBusiness && this.state.business_name && 
            this.state.brand_name && this.state.business_description && this.state.business_address &&
            this.state.pincode.length == 6 && this.state.city && this.state.state && this.state.pan_no.length == 10 && this.state.gstin_no.length == 15 && this.state.addressProof && this.state.panProof && this.state.gstinProof){
            const requestOptions={
                method:'post',
                headers:authHeader(),
                data:{
                    business_type:this.state.selectedBusiness,
                    business_name:this.state.business_name,
                    brand_name:this.state.brand_name,
                    business_description:this.state.business_description,
                    business_address :this.state.business_address, 
                    address_pincode :this.state.pincode,
                    pan_no :this.state.pan_no,
                    gstin_no:this.state.gstin_no,
                    address_city:this.state.city,
                    address_state:this.state.state
                }
            }
            return axios(apiUrl+'/update-business-info',requestOptions)
            .then(data=>{
              this.props.next()
            })
        }else{
          this.setState({
            isLoading:false
          },()=>{
            if(!this.state.selectedBusiness){
              this.business_select.current.focus()
          }else if(!this.state.business_name){
              this.businessName.current.focus()
          }else if(!this.state.brand_name){
            this.brandName.current.focus()
          }else if(!this.state.business_description){
            this.Description.current.focus()
          }else if(!this.state.business_address){
            this.Address.current.focus()
          }else if(this.state.pincode.length != 6){
            this.pinCode.current.focus()
          }else if(!this.state.city){
            this.City.current.focus()
          }else if(!this.state.state){
            this.State.current.focus()
          }else if(this.state.pan_no.length != 10){
            this.PanNo.current.focus()
          }else if(this.state.gstin_no.length != 15){
            this.GSTNo.current.focus()
          }
          })
        }
    })
}

getCityByPincode = (e) =>{
  this.setState({
    pincode : e.target.value,
    pincodeError :false
  },async ()=>{
    if(this.state.pincode.length == '6'){
      let requestOptions={
        method:"Post",
        headers : authHeader(),
        data: {
          pincode : this.state.pincode
        }
      }
      return axios(b2bUrl+'/search-by-pincode',requestOptions)
      .then(data=>{
        this.setState({
          state : data.data.data[0].state,
          city : data.data.data[0].city
        })
      })
      .catch(error=>{
        this.setState({
          pincodeError: true
        })
      })
    }else{
      this.setState({
        city:'',
        state:''
      })
    }
  })
}
  render() {
    return step1.call(this,this.business_select,this.businessName,this.brandName,this.Description,this.Address,this.pinCode,this.City,this.State,this.PanNo,this.GSTNo);
  }
}
export default withStyles(styles)(SignupNext1);
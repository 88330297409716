export function authHeader() {
    // return authorization header with jwt token
    let userdata = localStorage.getItem('seller');
    let user = JSON.parse(userdata)
//    console.log('test user',user.data.access_token);
    if (user && user.access_token) {
        return { 'Authorization': 'Bearer ' + user.access_token };
    } else {
        return {};
    }
}
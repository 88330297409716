import React, { Component } from 'react';
import axios from 'axios';
import signupstep1 from './SignupStep1.jsx';
import siteConfig from '../../../../config';
import { authHeader } from '../../../../helper/auth-header.js';
const apiUrl = siteConfig.apiUrl;

export default class SignupStep1 extends Component {
  constructor(props){
    super(props);
    this.state={
      name:'',
      mobile:'',
      open:false,
      isSubmitted: false,
      isLoading:false,
      nameRef:'',
      MobileError:false,
      hasError:false,
      message:'',

    }
    this.ref1 = React.createRef('name');
    this.ref2 = React.createRef('mobile')
  }
  
  handleNameChange=(e)=>{
    this.props.nameChange(e.target.value)
  }
  handleMobileChange=(e)=>{
    this.props.mobileChange(e.target.value)
    this.setState({
      MobileError:false
    })
  }
  changeStep = () => {
    this.props.onChange()
  }
  signupstep1 = (e) => {
    e.preventDefault();
    this.setState({
      isSubmitted: true,
      isLoading: true
    },()=>{
      if(this.state.isSubmitted && this.props.name.length>0 && this.props.mobile.length>0 && this.props.mobile.length<11){
        const requestOptions = {
          method: 'post',
          data:{
            name : this.props.name,
            mobile : this.props.mobile
          }
        }
        return axios(apiUrl+'/signup',requestOptions)
        .then(data=>{
          this.setState({
            isLoading:false,
          },()=>{
            this.props.setOpen()
            this.props.setMessage(data.data.message)
            this.props.setError(false)
            this.props.onChange()
          })
        }).then(()=>
        {
          console.log('RESENDOTPLIKHDO')
          let mobile = this.props.mobile;
          const requestOptions = {
            method:'post',
            headers : authHeader(),
            data:{
              username : mobile,
              key:'otp_mobile',
              user_id:"0"
            }
          }
          return axios(apiUrl+'/resend-otp',requestOptions)
          .then(data=>{
            console.log('OTPSENT')
            this.setState({
              hasError:false,
              message:data.data.message,
            })
          })
        })
        .catch(err=>{
          this.setState({
            isLoading: false,
            MobileError:true
          },()=>{
            this.props.setError(true)
            this.props.setOpen()
            this.props.setMessage(err.response ? err.response.data.message : 'network error')
            this.ref2.current.focus()
          })
        }) 
      }else{
        this.setState({
          isLoading:false,
        },()=>{
          if(this.state.isSubmitted && this.props.name.length <1){
            this.ref1.current.focus();
          }
          else if(this.state.isSubmitted && (this.props.mobile.length<1 || this.props.mobile.length>10)){
            this.ref2.current.focus();
          }
        })
      }
    })
  }
  render() {
    return signupstep1.call(this,this.ref1,this.ref2);
  }
}

import React, { Component } from 'react'
import product_description from './product_description.jsx';
import { withStyles } from '@material-ui/core/styles';

const styles=(theme)=>({
    mb_20:{
        marginBottom:20
    },
    fullWidth:{
        width:'100%'
    }
})

class ProductDescription extends Component{
    render(){
        return product_description.call(this);
    }
}

export default withStyles(styles)(ProductDescription);
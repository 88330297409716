import React, { Component } from 'react';
import step2 from './SignupNext2.jsx';
import {authHeader} from '../../../../helper/auth-header';
import axios from 'axios';
import siteConfig from '../../../../config';


const apiUrl = siteConfig.apiUrl

export default class SignupNext2 extends Component {
    constructor(props){
        super(props);
        this.state={
            addressList:[],
            address:'',
            pincode:'',
            gstin:'',
            city:'',
            State:'',
            start_time : new Date('2020-01-18T09:00:00'),
            end_time : new Date('2020-01-18T18:00:00'),
            selectedOptions : [],
            open:false,
            submitted:false,
            isLoading:false,
            dialog : false,
            id : null,
            addressId : 0,
            error:'',
            checked : false,
            backdropOpen: false,
            message:'',
            weeklyOff:'',
            newSelect:[]
        }
        this.options = [
            { value: 'sunday', label: 'Sunday' },
            { value: 'monday', label: 'Monday' },
            { value: 'tuesday', label: 'Tuesday' },
            { value: 'wednesday', label: 'Wednesday' },
            { value: 'thursday', label: 'Thursday' },
            { value: 'friday', label: 'Friday' },
            { value: 'saturday', label: 'Saturday' },
            ];
    
            this.addressRef = React.createRef('address');
            this.pincodeRef = React.createRef('pincode');
            this.gstinRef = React.createRef('gstin');
            this.cityRef = React.createRef('city');
            this.StateRef = React.createRef('State');
            this.WeeklyOffRef = React.createRef('weekly_off');
            
        }

        componentDidMount(){
           this.setState({
               isLoading: true
           },()=>{
            this.getAddress()
           })
        }
        openDialog = (e) => {
            let address_id = e.currentTarget.getAttribute('address_id');
            this.setState({
                dialog: true,
                address_id : address_id
            })
        }
        closeDialog = () => {
            this.setState({
                dialog: false
            })
        }
        changeNewAddress =()=> {
            this.setState({
                newAddress: !this.state.newAddress
            })
        }
        changeVisibility=(e)=>{
            let id = e.currentTarget.id
            let address_id = e.currentTarget.getAttribute('address_id');
            let days = id>0? this.state.addressList[id - 1].weekly_on_off.split(","):[];
            let weekly_off = days.length>0 ? days.map(val=>{
                return {
                    value : val,
                    label : val
                }
            }):[]
            let w_hours = id>0? this.state.addressList[id - 1].working_hours:"";
            let start1 = w_hours.split(" ");
            this.setState({
                open: !this.state.open,
                id: id,
                address : id>0? this.state.addressList[id -1].address :"",
                city: id>0?this.state.addressList[id-1].city:"",
                State : id>0?this.state.addressList[id-1].state:"",
                pincode : id>0?this.state.addressList[id-1].pincode:"",
                gstin : id>0?this.state.addressList[id-1].gstin:"",
                start_time :id>0? new Date('2014-08-18T'+start1[0]):new Date('2020-01-18T09:00:00'),
                end_time : id>0?new Date('2014-08-18T'+start1[2]):new Date('2020-01-18T18:00:00'),
                addressId : address_id
            },()=>{
                this.setState({
                    selectedOptions : id>0? weekly_off :""
                })
            })
        }
        closecollapse=()=>{
            this.setState({
                open:false
            })
        }
        handleChange = (e) => {
            this.setState({
                [e.target.name] : e.target.value
            })
        }
        handleStartChange = (e) => {
            this.setState({
                start_time:e
            })
        }
        handleEndChange =(e)=>{
            this.setState({
                end_time:e
            })
        }
        selectChange = (e) =>{
            // console.log(e.currentTarget)
            // let id = e.target.getAttribute('data-option-index');
            // let selecte = this.options[id];
            // this.setState(state=>{
            //     let newArray = state.selectedOptions.push(selecte);
            //     return newArray;
            // })
            this.setState({
                selectedOptions:e
            })
        }
        inputChange =(e)=>{
            console.log(e)
        }
        handleClose=()=>{
            this.setState({
                open: !this.state.open
            })
        }
        handleWeeklyOff =(e)=>{
            this.setState({
                weeklyOff:e.target.value
            })
        }
    getAddress = () =>{
        const requestOptions={
            headers: authHeader()
        }
        return axios.get(apiUrl+'/warehouse-details',requestOptions)
        .then(data=>{
            this.setState({
                addressList :data.data.data,
                isLoading: false,
                submitted:false
            })
        })
        .catch(error=>{
            this.setState({
                isLoading : false
            })
        })
    }






    getSameAddress = () =>{
        const requestOptions = {
            method : 'GET',
            headers : authHeader()
        }
        return axios(apiUrl+'/get-user-info',requestOptions)
        .then(data=>{
            this.setState({
                message:''
            },()=>{
                setTimeout(() => {
                    this.setState({
                        address:data.data.data.business_address === null ? "" : data.data.data.business_address,
                        pincode:data.data.data.address_pincode === null ? "" : data.data.data.address_pincode,
                        gstin:data.data.data.address_pincode === null ? "" : data.data.data.gstin_no,
                        city:data.data.data.address_city === null ? "" : data.data.data.address_city,
                        State:data.data.data.address_state === null ? "" : data.data.data.address_state,
                        backdropOpen:false
                    })
                }, 1000);
            })
        }).catch(error=>{
            this.setState({
                backdropOpen:false
            })
        })
    }
    sameAddress = (e) =>{
        let checked = e.currentTarget.checked;
        if(e.target.checked){
            this.setState({
                checked : true,
                backdropOpen : true
            },()=>this.getSameAddress())
        }else{
            this.setState({
                backdropOpen:true
            },()=>{
                setTimeout(() => {
                    this.setState({
                        backdropOpen:false,
                        checked:false,
                        address:"" ,
                        pincode:"" ,
                        city: "",
                        State: ""
                    })
                }, 1000);
            })
        }
        
    }
    weeklychange=(e)=>{
        console.log(e.target)
    }

    sendNotification = (e) => {
        const requestOptions={
          method : 'get',
          headers : authHeader()
        }
        return axios(apiUrl+'/get_vendor_name',requestOptions).then(data=>{
         const requestOptions = {
           method : 'POST',
           headers : authHeader(),
           data : {
               template : "ADDADDRESS",
               name : data.data.data[0].vendor_name,
               action: e
           }
       }
       return axios(apiUrl+'/send-notification',requestOptions)
       .then(data=>data)
       .catch(error=>error)
          }).catch(error=>{
            console.log(error)
          })
   }

    submitWareHouseDetails=(e)=>{
        // let action = e.target.getAttribute('action')
        e.preventDefault();
         this.setState({
            submitted:true,
            isLoading:true
         },
         ()=>{
            if(this.state.submitted && this.state.address && this.state.city && this.state.pincode && this.state.State && this.state.selectedOptions.length>0){
                 const days = this.state.selectedOptions.map(val=>{
                     return val.value
                 })
                 let start_hours = this.state.start_time.getHours();
                 let start_minutes = this.state.start_time.getMinutes();
                 let end_hours = this.state.end_time.getHours();
                 let end_minutes = this.state.end_time.getMinutes();
                 if(start_hours.toString().length < 2){
                    start_hours = "0"+start_hours
                 }
                 if(start_minutes.toString().length < 2){
                    start_minutes = "0"+start_minutes
                 }
                 if(end_hours.toString().length < 2){
                    end_hours = "0"+end_hours
                 }
                 if(end_minutes.toString().length < 2){
                    end_minutes = "0"+end_minutes
                 }
                 let s_time = start_hours +":"+start_minutes;
                 let e_time = end_hours + ":" + end_minutes;
                //  console.log(this.state.start_time.toISOString())
                 const requestOptions={
                     method:'post',
                     headers:authHeader(),
                     data:{
                         same_as_business_address:'',
                         address:this.state.address,
                         pincode:this.state.pincode,
                         city:this.state.city,
                         state:this.state.State,
                         working_hours: s_time +" - "+ e_time,
                         gstin:this.state.gstin,
                         weekly_on_off:days,
                         address_id : this.state.addressId
                     }
                 }
                 return axios(apiUrl+'/warehouse-details',requestOptions)
                 .then(data=>{
                    this.setState({
                        open:false,
                        id:null,
                        checked:false,
                        address:"" ,
                        pincode:"" ,
                        city: "",
                        State: ""
                    },()=>{
                        if(this.state.address_id > 0){
                            return  this.getAddress(),this.sendNotification('update')
                        }else{
                           return this.getAddress(),this.sendNotification('add')
                        }
                       
                    })
                 }).catch(error=>{
                     this.setState({
                         error : error.response ? error.response.data.message : 'network error'
                     })
                 })
            }else{
                this.setState({
                    isLoading:false
                },()=>{
                    if(!this.state.address){
                        this.addressRef.current.focus();
                    }else if(!this.state.pincode){
                        this.pincodeRef.current.focus();
                    }else if(!this.state.city){
                        this.cityRef.current.focus();
                    }else if(!this.state.gstin){
                        this.gstinRef.current.focus();
                    }
                    else if(!this.state.State){
                        this.StateRef.current.focus();
                    }else if(!this.state.selectedOptions.length>0){
                        this.WeeklyOffRef.current.focus();
                    }
                })
            }
         })
     }
    deleteAddress = (e) => {
        console.log(e.currentTarget)
        const requestOptions = {
            method : 'POST',
            headers : authHeader(),
            data : {
                address_id : this.state.address_id
            }
        }
        return axios(apiUrl+'/delete_address',requestOptions)
        .then(data=>{
            this.setState({
                dialog : false,
                address:"" ,
                pincode:"" ,
                city: "",
                State: "",
                checked:false
            },()=>this.getAddress(),this.sendNotification('delete'))
        })
        .catch(error=>{
            console.log(error)
        })
    }
    render() {
        
        return step2.call(this,this.addressRef,this.pincodeRef, this.gstinRef,this.cityRef,this.stateRef,this.WeeklyOffRef);
    }
}

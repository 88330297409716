import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../../assets/images/Mccoy-mart-logo.png';
import Signup from '../Signup/Signup/Signup';
import './homepage.css';
import Login from '../Login/Login';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });


function homepage(){
    const {classes}= this.props;
    return (
        <React.Fragment>
        <AppBar position="static">
            <Toolbar style={{background: '#fff'}} >
                <Container>
                    <img 
                        src={Logo} 
                        alt="company_icon" 
                        className={classes.cmp_logo}
                    />
                    <ul className={classes.login_ul}>
                        <li onClick={this.handleClickOpen}>Existing Sellers? -Sign In</li>
                    </ul>
                </Container>
            </Toolbar>
        </AppBar>
        <div 
            className={classes.background_div}
        >
            <Container>
                <Grid container>
                    <Grid item md={8} container justify="center" style={{color:'#fff'}}>
                        <Hidden mdDown>
                            <h1>Grow your <span className="f_bold">Business</span><br/>
                                with <span className="f_bold">McCoy Mart</span></h1>
                            <p>Join a community of 10,000+ Manufacturer, Supplier & Professionals
                                growing their business rapidly with us.</p>
                        </Hidden>
                        <Hidden mdUp> 
                            <h1 className={classes.main_content}>Grow your <span className={classes.bold_text}>Business</span> with <span className={classes.bold_text}>McCoy Mart</span></h1>
                            <p className={classes.main_text}>Join a community of 10,000+ Manufacturer, Supplier & Professionals
                                growing their business rapidly with us.</p>
                        </Hidden>
                    </Grid>
                    <Grid item md={4} container justify="center">
                            <Signup handleClickOpen={this.handleClickOpen}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={Transition}
        >
            <DialogTitle 
                id="responsive-dialog-title"
                style={{fontSize:25,textAlign:'center'}}
                disableTypography={true}
                >
                Login
            </DialogTitle>
            <DialogContent className={classes.loginModal}>
                <Login />
            </DialogContent>
        </Dialog>
    </React.Fragment>
    )
}
export default homepage;
import React, { Component } from "react";
import orderDetail from "./OrderDetail.jsx";
import { authHeader } from "../../../../../helper/auth-header.js";
import axios from "axios";
import siteconfig from "../../../../../config";
import { withStyles } from "@material-ui/core/styles";
import mergeBetweenPDF from "../../../../../helper/pdfProvider";
import { saveSync } from "save-file";
import moment from "moment";

const apiUrl = siteconfig.apiUrl;

const styles = (theme) => ({
  pt_25: {
    paddingTop: 25,
  },
  mt_0: {
    marginTop: 0,
  },
  m_5: {
    margin: "8px 0",
  },
  mt_20: {
    marginTop: 20,
  },
  date: {
    verticalAlign: "top",
    display: "inline-block",
    textAlign: "right",
  },
  cardHeader: {
    backgroundColor: "#f3f0f0",
  },
  spanHeading: {
    display: "inline-block",
    width: 120,
    textAlign: "right",
  },
  spanAddress: {
    display: "inline-block",
    width: 120,
    textAlign: "right",
    flexShrink: "0",
  },
  text: {
    textAlign: "left",
    width: "100%",
    display: "inline",
    marginLeft: 5,
  },
  shippingUL: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  list: {
    marginBottom: 10,
  },
  form_label_style: {
    width: "100%",
    textAlign: "left",
    fontSize: "14px",
  },
  formControl: {
    width: "100%",
    marginTop: 8,
  },
  displayFlex: {
    display: "flex",
  },
  acc_rej: {
    padding: 20,
    position: "sticky",
    bottom: 20,
    width: "97%",
    boxShadow: "0px 0px 10px #ccc",
    textAlign: "center",
    marginTop: 20,
  },
  btn: {
    padding: 10,
    marginRight: 25,
  },
  can_btn: {
    padding: 10,
    marginRight: 0,
  },
  accept: {
    color: "#156734",
    border: "1px solid #156700",
    backgroundColor: "#fff",
    marginRight: 10,
    "&:hover": {
      color: "#156734",
      backgroundColor: "#fff",
      border: "1px solid #156734",
    },
  },
  reject: {
    color: "#e03333",
    border: "1px solid #e03333",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      border: "1px solid #e03333",
    },
  },
  ship_button: {
    marginTop: 10,
    padding: "15px 0",
  },
  mr_10: {
    marginRight: 10,
  },
  backButton: {
    color: "#fff",
    background: "#3f51b5",
    padding: "4px 22px",
    marginBottom: 11,
    display: "inline-block",
    borderRadius: 6,
    paddingLeft: 14,
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 500,
    textDecoration: "none",
    letterSpacing: "0.02857em",
    marginBottom: 20,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  track_dialog: {
    maxWidth: 900,
  },
  toolbar: {
    boxShadow: "0px 0px 5px #ccc",
  },
  trackHeading: {
    fontWeight: "400",
  },
  closeIcon: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  t_center: {
    textAlign: "center",
    margin: "40px 0",
  },
  tablecell: {
    border: "1px solid #ccc !important",
  },
  tableCellTrack: {
    border: "none",
    position: "relative",
  },
  divider: {
    position: "absolute",
    width: 3,
    height: 50,
    left: 43,
    top: -21,
    zIndex: 0,
    background: "#ccc",
  },
  trackIndex: {
    color: "#fff",
    backgroundColor: "#4eaece",
    zIndex: 1,
  },
  input: {
    display: "none",
  },
});

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vendor_order_id: 0,
      isLoading: false,
      orderDetail: [],
      AddressList: [],
      PriceData: [],
      productData: [],
      productDimension: [],
      Length: "",
      Width: "",
      Height: "",
      Weight: "",
      inovice_number: "",
      purchase_invoice: "",
      selectedAddress: "",
      orderStatusId: "",
      isLoadingStatus: false,
      isLoadingShippping: false,
      isLoadingInvoice: false,
      isLoadingShippingLabel: false,
      invoice_path: "",
      shippingLabel: "",
      open: false,
      message: "",
      isError: false,
      submitInvoice: false,
      orderFound: true,
      orderStatus: "",
      backdropOpen: false,
      DownloadInvoiceData: [],
      shippingLabelData: [],
      trackingData: [],
      isLoadingTracking: false,
      modalopen: false,
      addressVerified: false,
      purchaseInvoiceModalopen: false,
      purchaseInvoiceNumber: "",
      selectedDate: new Date(),
      purchaseInvoicePdf: null,
      p_invoiceSubmitted: false,
      selectedVendorOrderId: "",
      purchaseActiontitle: "",
      purchase_invoice_date: "",
      selectedPurchaseInvoicedate: "",
      prevPurchaseInvoiceNumber: "",
      prevPurchaseInvoiceDate: "",
      prevPurchaseInvoiceUrl: "",
      p_invoiceSubmitted: false,
      ManifestData: [],
    };

    this.InvoiceRef = React.createRef("invoice");
    this.LengthRef = React.createRef("length");
    this.WidthRef = React.createRef("width");
    this.HeightRef = React.createRef("height");
    this.WeightRef = React.createRef("weight");
    this.addressRef = React.createRef("address");
    this.purchaseInvoiceNumber = React.createRef("purchase_invoice");
  }

  componentDidMount() {
    let pathname = window.location.pathname;
    let vendor_order_id = pathname.split("/")[2];
    if (vendor_order_id) {
      this.setState(
        {
          vendor_order_id: vendor_order_id,
        },
        () => this.getOrderDetail()
      );
    } else {
      this.setState({
        orderFound: false,
      });
    }
  }
  getOrderDetail = () => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        const requstOptions = {
          headers: authHeader(),
          method: "post",
          data: {
            vendor_order_id: this.state.vendor_order_id,
          },
        };
        return axios(apiUrl + "/order_detail", requstOptions)
          .then((data) => {
            //console.log(data.data.data)
            if (data.data.data.result) {
              this.setState(
                {
                  isLoading: false,
                  orderDetail: data.data.data.result[0],
                  AddressList: data.data.data.address,
                  PriceData: data.data.data.PriceData,
                  productData: data.data.data.productData,
                  shippingLabel: data.data.data.shippingLabel
                    ? data.data.data.shippingLabel
                    : "",
                  productDimension: data.data.data.productDimension,
                  invoice_path: data.data.data.invoice
                    ? data.data.data.invoice
                    : "",
                  orderStatus: data.data.data.orderStatus,
                  prevPurchaseInvoiceNumber:
                    data.data.data.purchaseInvoice_no.length > 0
                      ? data.data.data.purchaseInvoice_no
                      : "",
                  prevPurchaseInvoiceUrl: data.data.data.purchase_invoice_url
                    ? data.data.data.purchase_invoice_url
                    : "",
                  selectedVendorOrderId:
                    data.data.data.result[0].vendor_order_id,
                  prevPurchaseInvoiceDate: new Date(
                    data.data.data.purchase_invoice_date
                  ).toLocaleDateString("en-IN"),
                  selectedPurchaseInvoicedate:
                    data.data.data.purchase_invoice_date,
                },
                () => {
                  // console.log(this.state.shippingLabel,this.state.invoice_path)
                  if (this.state.productData.Length > 0) {
                    this.setState({
                      Length: this.state.productData.length,
                    });
                  } else if (this.state.productDimension[0].length > 0) {
                    this.setState({
                      Length: this.state.productDimension[0].length,
                    });
                  } else {
                    this.setState({
                      Length: "",
                    });
                  }

                  if (this.state.productData.Width > 0) {
                    this.setState({
                      Width: this.state.productData.width,
                    });
                  } else if (this.state.productDimension[0].width > 0) {
                    this.setState({
                      Width: this.state.productDimension[0].width,
                    });
                  } else {
                    this.setState({
                      Width: "",
                    });
                  }

                  if (this.state.productData.Height > 0) {
                    this.setState({
                      Height: this.state.productData.height,
                    });
                  } else if (this.state.productDimension[0].height > 0) {
                    this.setState({
                      Height: this.state.productDimension[0].height,
                    });
                  } else {
                    this.setState({
                      Height: "",
                    });
                  }

                  if (this.state.productData.Weight > 0) {
                    this.setState({
                      Weight: this.state.productData.weight,
                    });
                  } else if (this.state.productDimension[0].weight > 0) {
                    this.setState({
                      Weight: this.state.productDimension[0].weight,
                    });
                  } else {
                    this.setState({
                      Weight: "",
                    });
                  }
                }
              );
            } else {
              this.setState({
                isLoading: false,
                orderFound: false,
              });
            }
          })
          .catch((error) => {
            this.setState({
              isLoading: false,
            });
          });
      }
    );
  };
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleDateChange = (e) => {
    this.setState({
      selectedDate: e,
    });
  };
  handleChange = (e) => {
    console.log("this state>>>> ORDERDetail.js",this.state);
    if (
      this.state.prevPurchaseInvoiceNumber &&
      this.state.prevPurchaseInvoiceDate
    ) {
      this.setState(
        {
          [e.target.name]: e.target.value,
          backdropOpen: true,
          addressVerified: false,
        },
        () => {

          console.log(this.state,"get Courier Services 1")


          let selectedData = [];
          selectedData.push({
            length: this.state.Length,
            width: this.state.Width,
            height: this.state.Height,
            weight: this.state.Weight,
            vendor_order_id: this.state.vendor_order_id,
            payment_method: this.state.orderDetail.payment_code,
            order_total: this.state.orderDetail.total,
          });

          const requestOptions = {
            method: "POST",
            headers: authHeader(),
            data: {
              vendor_order_id: selectedData,
              address_id: this.state.selectedAddress,
            },
          };
          return axios(apiUrl + "/get_courier_service", requestOptions)
            .then((data) => {
              this.setState({
                backdropOpen: false,
                addressVerified: true,
              });
            })
            .catch((error) => {
              this.setState({
                backdropOpen: false,
                addressVerified: false,
              });
            });
        }
      );
    } else {
      console.log("invoice not found.......");
      this.setState({
        open: true,
        isError: true,
        message: "Please enter invoice number and date",
        backdropOpen: false,
      });
    }
  };
  changeOrderStatus = (e) => {
    let v_id = [];
    let order_status_id = e.currentTarget.id;
    let action = e.currentTarget.getAttribute("status");
    let vendor_order_id = e.currentTarget.getAttribute("vendor_order_id");
    v_id.push(vendor_order_id);
    console.log(this.state,"change order status")

    this.setState(
      {
        orderStatusId: order_status_id,
        isLoadingStatus: true,
      },
      async () => {
        const requestOptions = {
          method: "post",
          headers: authHeader(),
          data: {
            order_id: this.state.order_id,
            order_status_id: this.state.orderStatusId,
            vendor_order_id: v_id,
            action: action,
          },
        };
        return axios(apiUrl + "/change_order_status", requestOptions)
          .then((data) => {
            this.setState(
              {
                isLoadingStatus: false,
              },
              () => this.getOrderDetail()
            );
          })
          .catch((error) => {
            this.setState({
              isLoadingStatus: false,
            });
          });
      }
    );
  };
  shippingProductHandler = (e) => {
    e.preventDefault();
    this.setState(
      {
        submitted: true,
        isLoadingShippping: true,
        backdropOpen: true,
      },
      () => {
        if (
          this.state.submitted &&
          this.state.Length &&
          this.state.Width &&
          this.state.Height &&
          this.state.Weight &&
          this.state.selectedAddress
        ) {
          const invrequestOptions = {
            method: "Post",
            headers: authHeader(),
            data: {
              vendor_order_id: [this.state.vendor_order_id],
              multiple: true,
            },
          };
          return axios(apiUrl + "/generate_invoice", invrequestOptions)
            .then((data) => {
              setTimeout(() => {
                let orderData = [];
                orderData.push({
                  vendor_order_id: this.state.vendor_order_id,
                  length: this.state.Length,
                  width: this.state.Width,
                  height: this.state.Height,
                  weight: this.state.Weight,
                  seller_address_id: this.state.selectedAddress,
                });
                const requestOptions = {
                  method: "post",
                  headers: authHeader(),
                  data: {
                    orderData: orderData,
                  },
                };
                return axios(apiUrl + "/create_order", requestOptions)
                  .then((data) => {
                    // console.log(data.data.data)
                    if (data.data.data[0].error) {
                      this.setState(
                        {
                          backdropOpen: false,
                          isLoadingShippping: false,
                          isError: true,
                          open: true,
                          message: "error in creating order",
                        },
                        () => {
                          setTimeout(() => {
                            this.setState(
                              {
                                open: false,
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({
                                    isError: false,
                                    message: "",
                                  });
                                }, 1000);
                              }
                            );
                          }, 3000);
                        }
                      );
                    } else {
                      this.getOrderDetail();
                      this.setState(
                        {
                          isLoadingShippping: false,
                          backdropOpen: false,
                          open: true,
                          message: "order created successfully",
                          isError: false,
                        },
                        () => {
                          setTimeout(() => {
                            this.setState(
                              {
                                open: false,
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({
                                    message: "",
                                    isError: false,
                                  });
                                }, 1000);
                              }
                            );
                          }, 3000);
                        }
                      );
                    }
                  })
                  .catch((error) => {
                    this.setState(
                      {
                        isLoadingShippping: false,
                        open: true,
                        isError: true,
                        message: error.response
                          ? error.response.data.message
                          : "network error",
                        backdropOpen: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState(
                            {
                              open: false,
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({
                                  isError: false,
                                  message: "",
                                });
                              }, 1000);
                            }
                          );
                        }, 3000);
                      }
                    );
                  });
              }, 1500);
            })
            .catch((error) => {
              this.setState(
                {
                  isLoadingShippping: false,
                  open: true,
                  isError: true,
                  message: error.response
                    ? error.response.data.message
                    : "network error",
                  backdropOpen: false,
                },
                () => {
                  setTimeout(() => {
                    this.setState(
                      {
                        open: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            isError: false,
                            message: "",
                          });
                        }, 1000);
                      }
                    );
                  }, 3000);
                }
              );
            });
        } else {
          this.setState(
            {
              isLoadingShippping: false,
              backdropOpen: false,
            },
            () => {
              if (!this.state.Length) {
                this.LengthRef.current.focus();
              } else if (!this.state.Width) {
                this.WidthRef.current.focus();
              } else if (!this.state.Height) {
                this.HeightRef.current.focus();
              } else if (!this.state.Weight) {
                this.WeightRef.current.focus();
              }
            }
          );
        }
      }
    );
  };

  generateShippingLabel = () => {
    this.setState(
      {
        isLoadingShippingLabel: true,
        backdropOpen: true,
      },
      () => {
        const requstOptions = {
          method: "post",
          headers: authHeader(),
          data: {
            vendor_order_id: [this.state.vendor_order_id],
            multiple: false,
          },
        };
        return axios(apiUrl + "/get_shipment_slip", requstOptions)
          .then((data) => {
            if (data.data.data.length > 0) {
              this.setState(
                {
                  isLoadingShippingLabel: false,
                  shippingLabel: data.data.data,
                },
                () => {
                  let downloadOptions = {
                    method: "post",
                    headers: authHeader(),
                    data: {
                      vendor_order_id: data.data.data,
                    },
                  };
                  return axios(
                    apiUrl + "/download_shipping_label",
                    downloadOptions
                  )
                    .then((data) => {
                      // console.log(data);
                      this.setState(
                        {
                          shippingLabelData: data.data.data,
                        },
                        () => {
                          if (this.state.shippingLabelData.length > 0) {
                            mergeBetweenPDF
                              .mergeBetweenPDF(this.state.shippingLabelData)
                              .then((data) => {
                                const fileName =
                                  "output_merge_" +
                                  new Date()
                                    .toISOString()
                                    .replace(":", "_")
                                    .replace("T", "_")
                                    .replace("Z", "") +
                                  ".pdf";

                                this.setState(
                                  {
                                    backdropOpen: false,
                                    open: true,
                                    isError: false,
                                    message:
                                      "shipping label generated successfully",
                                    selected: [],
                                  },
                                  () => {
                                    saveSync(data.pdfFile, fileName);
                                    setTimeout(() => {
                                      this.setState({
                                        open: false,
                                      });
                                    }, 2000);
                                  }
                                );
                              })
                              .catch((error) => {
                                this.setState(
                                  {
                                    open: true,
                                    isError: true,
                                    message:
                                      "error in generating shipping label",
                                    backdropOpen: false,
                                  },
                                  () => {
                                    setTimeout(() => {
                                      this.setState({
                                        open: false,
                                      });
                                    }, 3000);
                                  }
                                );
                              });
                          } else {
                            this.setState(
                              {
                                open: true,
                                isError: true,
                                message: "no data found",
                                backdropOpen: false,
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({
                                    open: false,
                                  });
                                }, 3000);
                              }
                            );
                          }
                        }
                      );
                    })
                    .catch((error) => {
                      console.log(error);
                      this.setState(
                        {
                          open: true,
                          isError: true,
                          message: "error in generating shipping-label",
                          bulkShipping: false,
                          backdropOpen: false,
                        },
                        () => {
                          setTimeout(() => {
                            this.setState({
                              open: false,
                            });
                          }, 3000);
                        }
                      );
                    });
                }
              );
            } else {
              this.setState(
                {
                  open: true,
                  message: "no data found",
                  isError: true,
                  isLoadingShippingLabel: false,
                  backdropOpen: false,
                },
                () => {
                  setTimeout(() => {
                    this.setState(
                      {
                        open: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            message: "",
                            isError: false,
                          });
                        }, 1000);
                      }
                    );
                  }, 3000);
                }
              );
            }
          })
          .catch((error) => {
            this.setState({
              isLoadingShippingLabel: false,
              backdropOpen: false,
            });
          });
      }
    );
  };
  generateManifest = () => {
    this.setState(
      {
        backdropOpen: true,
      },
      () => {
        const requestOptions = {
          method: "post",
          headers: authHeader(),
          data: {
            vendor_order_id: [this.state.vendor_order_id],
          },
        };
        return axios(apiUrl + "/get_manifest", requestOptions)
          .then((data) => {
            console.log(data.data.data);
            this.setState(
              {
                ManifestData: data.data.data,
              },
              () => {
                mergeBetweenPDF
                  .mergeBetweenPDF(this.state.ManifestData)
                  .then((data) => {
                    const fileName =
                      "output_merge_" +
                      new Date()
                        .toISOString()
                        .replace(":", "_")
                        .replace("T", "_")
                        .replace("Z", "") +
                      ".pdf";
                    this.setState(
                      {
                        backdropOpen: false,
                        open: true,
                        isError: false,
                        message: "Manifest generated successfully",
                      },
                      () => {
                        saveSync(data.pdfFile, fileName);
                        setTimeout(() => {
                          this.setState(
                            {
                              open: false,
                            },
                            () => {
                              this.setState({
                                isError: false,
                                message: "",
                              });
                            }
                          );
                        }, 3000);
                      }
                    );
                  });
              }
            );
          })
          .catch((error) => {
            this.setState(
              {
                open: true,
                isError: true,
                message: "error in generating manifest",
                backdropOpen: false,
              },
              () => {
                setTimeout(() => {
                  this.setState(
                    {
                      open: false,
                    },
                    () => {
                      setTimeout(() => {
                        this.setState({
                          message: "",
                          isError: false,
                        });
                      }, 1000);
                    }
                  );
                }, 3000);
              }
            );
          });
      }
    );
  };
  generateInvoice = () => {
    // console.log('dfdfddff')
    this.setState(
      {
        isLoadingInvoice: true,
        submitInvoice: true,
        backdropOpen: true,
      },
      async () => {
        const requestOptions = {
          method: "Post",
          headers: authHeader(),
          data: {
            vendor_order_id: [Number(this.state.vendor_order_id)],
            purchase_invoice: this.state.purchase_invoice,
            multiple: true,
          },
        };
        return axios(apiUrl + "/generate_invoice", requestOptions)
          .then((data) => {
            this.setState(
              {
                isLoadingInvoice: false,
                invoice_path: data.data.data.invoice,
              },
              () => {
                let aselected = [Number(this.state.vendor_order_id)];
                let newUrl = [];
                aselected.map((item) => {
                  return newUrl.push(item);
                });
                setTimeout(() => {
                  const requestOptions = {
                    method: "post",
                    headers: authHeader(),
                    data: {
                      vendor_order_id: newUrl,
                    },
                  };
                  return axios(apiUrl + "/download_invoice", requestOptions)
                    .then((data) => {
                      this.setState(
                        {
                          DownloadInvoiceData: data.data.data,
                        },
                        () => {
                          mergeBetweenPDF
                            .mergeBetweenPDF(this.state.DownloadInvoiceData)
                            .then((data) => {
                              const fileName =
                                "output_merge_" +
                                new Date()
                                  .toISOString()
                                  .replace(":", "_")
                                  .replace("T", "_")
                                  .replace("Z", "") +
                                ".pdf";
                              this.setState(
                                {
                                  backdropOpen: false,
                                  open: true,
                                  isError: false,
                                  message: "invoice generated successfully",
                                  selected: [],
                                },
                                () => {
                                  saveSync(data.pdfFile, fileName);
                                  setTimeout(() => {
                                    this.setState({
                                      open: false,
                                    });
                                  }, 3000);
                                }
                              );
                            })
                            .catch((error) => {
                              this.setState(
                                {
                                  open: true,
                                  isError: true,
                                  message: "error in generating invoice",
                                  backdropOpen: false,
                                },
                                () => {
                                  setTimeout(() => {
                                    this.setState({
                                      open: false,
                                    });
                                  }, 3000);
                                }
                              );
                            });
                        }
                      );
                    })
                    .catch((error) => {
                      this.setState(
                        {
                          open: true,
                          isError: true,
                          message: "error in generating invoice",
                          backdropOpen: false,
                        },
                        () => {
                          setTimeout(() => {
                            this.setState({
                              open: false,
                            });
                          }, 3000);
                        }
                      );
                    });
                });
              }
            );
          })
          .catch((err) => {
            this.setState({
              isLoadingInvoice: false,
              backdropOpen: false,
            });
          });
      }
    );
  };
  trackOrder = (e) => {
    this.setState(
      {
        isLoadingTracking: true,
        modalopen: true,
      },
      async () => {
        const requestOptions = {
          method: "POST",
          headers: authHeader(),
          data: {
            vendor_order_id: this.state.vendor_order_id,
          },
        };
        return axios(apiUrl + "/track_order", requestOptions)
          .then((data) => {
            if (data.data.data.service == "ecom") {
              this.setState({
                trackingData: data.data.data.trackingData,
                isLoadingTracking: false,
              });
            } else if (data.data.data.service == "shyplite") {
              this.setState({
                trackingData: JSON.parse(data.data.data).trackingData.events,
                isLoadingTracking: false,
              });
            } else if (data.data.data.service == "shiprocket") {
              // console.log(data);
              let trackingData = data.data.data.trackingData;
              this.setState({
                trackingData: trackingData,
                isLoadingTracking: false,
              });
            }
          })
          .catch((error) => {
            // console.log(error)
            if (error.response) {
              this.setState(
                {
                  open: true,
                  message: error.response.data.message,
                  isLoadingTracking: false,
                  isError: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState(
                      {
                        open: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            isError: false,
                            message: "",
                          });
                        }, 1000);
                      }
                    );
                  }, 3000);
                }
              );
            } else {
              this.setState(
                {
                  open: true,
                  message: "error in getting tracking data",
                  isLoadingTracking: false,
                  isError: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState(
                      {
                        open: false,
                      },
                      () => {
                        setTimeout(() => {
                          this.setState({
                            isError: false,
                            message: "",
                          });
                        }, 1000);
                      }
                    );
                  }, 3000);
                }
              );
            }
          });
      }
    );
  };
  handleModalClose = () => {
    this.setState({
      modalopen: false,
      trackingData: [],
    });
  };
  handlePurchaseInvoiceUpload = () => {
    this.setState(
      {
        p_invoiceSubmitted: true,
      },
      () => {
        if (this.state.p_invoiceSubmitted && this.state.purchaseInvoiceNumber) {
          // console.log(this.state.purchaseInvoicePdf)
          let requestOptions;
          let api_endpoint = "";
          var d = new Date(this.state.selectedDate);
          var date = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
          var dateValidUpto = year + "-" + month + "-" + date;

          if (this.state.purchaseInvoicePdf !== null) {
            let formData = new FormData();
            formData.append(
              "purchase_invoice_number",
              this.state.purchaseInvoiceNumber
            );
            formData.append("purchase_invoice_date", dateValidUpto);
            formData.append(
              "vendor_order_id",
              this.state.selectedVendorOrderId
            );
            formData.append("title", this.state.purchaseActiontitle);
            formData.append("document", this.state.purchaseInvoicePdf);
            requestOptions = {
              method: "post",
              headers: authHeader(),
              data: formData,
            };
            api_endpoint = "/handle_purchase_invoice_doc";
          } else {
            requestOptions = {
              method: "post",
              headers: authHeader(),
              data: {
                purchaseInvoiceNumber: this.state.purchaseInvoiceNumber,
                purchase_invoice_date: dateValidUpto,
                vendor_order_id: this.state.selectedVendorOrderId,
                title: this.state.purchaseActiontitle,
              },
            };
            api_endpoint = "/handle_purchase_invoice";
          }

          if (
            !moment(this.state.selectedDate).isValid() ||
            moment(this.state.selectedDate).isAfter(moment())
          ) {
            this.setState({
              backdropOpen: false,
              isLoadingSave: false,
            });
            return window.alert("Please select valid date");
          }
          return axios(apiUrl + api_endpoint, requestOptions)
            .then((data) => {
              this.setState({
                purchaseInvoiceModalopen: false,
                prevPurchaseInvoiceDate: new Date(
                  this.state.selectedDate
                ).toLocaleDateString("en-IN"),
                prevPurchaseInvoiceNumber: this.state.purchaseInvoiceNumber,
              });
              if (data.data.data != null) {
                if (data.data.data.length > 0) {
                  this.setState({
                    prevPurchaseInvoiceUrl: data.data.data,
                  });
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          if (
            this.state.p_invoiceSubmitted &&
            !this.state.purchaseInvoiceNumber
          ) {
            this.purchaseInvoiceNumber.current.focus();
          }
        }
      }
    );
  };
  ClosePurchaseInvoiceDialog = () => {
    this.setState({
      purchaseInvoiceModalopen: false,
      purchaseInvoiceNumber: "",
      purchase_invoice_date: "",
      purchase_invoice_url: "",
      selectedDate: new Date(),
    });
  };
  handleInvoiceUpload = (e) => {
    if (e.target.files[0]) {
      // console.log(e.target.files[0])
      this.setState({
        purchaseInvoicePdf: e.target.files[0],
      });
    } else {
      this.setState({
        purchaseInvoicePdf: null,
      });
    }
  };
  OpenPurchaseInvoiceUpload = (e) => {
    // console.log(e.currentTarget);
    // let index = e.currentTarget.id;
    let purchaseActiontitle = e.currentTarget.getAttribute("title");
    // let vendor_order_id = this.state.orderData[index].vendor_order_id
    this.setState({
      purchaseActiontitle: purchaseActiontitle,
      purchaseInvoiceModalopen: true,
      //   purchaseId : index
    });
  };
  EditPurchaseInvoice = (e) => {
    let title = e.currentTarget.getAttribute("title");
    this.setState({
      purchaseInvoiceModalopen: true,
      purchaseInvoiceNumber: this.state.prevPurchaseInvoiceNumber,
      purchaseActiontitle: title,
      selectedDate: this.state.selectedPurchaseInvoicedate,
    });
  };
  render() {
    return orderDetail.call(
      this,
      this.InvoiceRef,
      this.LengthRef,
      this.WidthRef,
      this.HeightRef,
      this.WeightRef,
      this.addressRef,
      this.purchaseInvoiceNumber
    );
  }
}
export default withStyles(styles)(OrderDetail);

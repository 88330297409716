import React from 'react';
import {Container,Box,Typography,Paper} from '@material-ui/core';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import Step1 from './step1/step1';
import Step2 from './step2/step2';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
function product_action(){
    let {classes } = this.props;
    return (
        <React.Fragment>
            {
              this.props.status === 1
                      ?
                      <Container>
                      <h3 className={classes.heading}>
                          {
                          this.state.product_id >0
                              ?
                              "Edit Product"
                              :
                              "Add Product"
                          }
                      </h3>
                      <Step1 getProduct={this.getAllProducts} first_step_complete={this.state.first_step_complete} deleteCategory={this.deleteCategory}/>
                      
                  {
                      this.state.first_step_complete
                          ?
                         <Step2 product_data = {this.state.product_data} manufacturer_id={this.state.manufacturer_id}/> 
                        :null
                  } 
                  </Container>
                      :
                  <Container>
                    <Paper style={{padding:20,marginTop:20}}>
                      <div style={{textAlign:'center'}}>
                        <LocalLibraryIcon style={{fontSize:150,margin:'30px 0',color:'#ccc'}}/>
                      </div>
                      <Typography style={{width:'100%'}}>Your Account is under Process</Typography>
                      <Typography style={{width:'100%'}}>At that time you are not allowed to add any product.</Typography>
                    </Paper>
                  </Container>
            }
            
        </React.Fragment>
    )
}   
export default product_action;
import React from 'react';
import {Paper,TextField,Button,List,ListItem,ListItemText,InputAdornment,FormLabel,Fab,CircularProgress,Hidden} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Autocomplete from '@material-ui/lab/Autocomplete';

function step1(){
    const {classes}=this.props;
    return (
        <React.Fragment>
            <Paper style={{padding:20}}>
                <div>
                <FormLabel>Step 1 : </FormLabel><span>Select Brand</span>
                </div>
                <Autocomplete
                    id="Brand"
                    options={this.state.BrandList}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField 
                                                style={{width:'100%'}} 
                                                {...params} 
                                                label="Brand" 
                                                variant="outlined" 
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                      <React.Fragment>
                                                        {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                      </React.Fragment>

                                                    ),
                                                  }}
                                                onChange={this.setSearch}
                                                value={this.state.search}
                                                />}  
                    onFocus={this.getBrand}
                    style={{marginTop:20,width:300,display:'inline-block'}}
                    loading={this.state.loading}
                    // onChange={this.setValue}                    
                    onSelect={this.setValue}
                    onChange={this.cancelEvent}
                    // disabled={this.props.first_step_complete}
                    disableClearable={!this.props.first_step_complete}
                    />
                    <Button
                        variant="contained" 
                        color="primary" 
                        style={{display:'inline-block',marginLeft:15,verticalAlign:'text-top',padding:15}} disabled={!this.state.selectedBrandName || this.props.first_step_complete}
                        onClick={this.submitCategory}
                        >
                        Search Product
                    </Button>
                    {/* <p>didn't find your brand</p> */}
            </Paper>
        </React.Fragment>
    )
}
export default step1;
import React,{Component} from 'react';
import NotFound from '../../../../assets/images/not_found.jpg';
import { Link } from 'react-router-dom';

class PageNotFound extends Component {
  state={
    isUser : false
  }
  componentDidMount(){
    let user = localStorage.getItem('seller');
    if(user){
      this.setState({
        isUser : true
      })
    }
  }
    render() {
      return (
        <div style={{background:'#f2f2f2',height:'100vh'}}>
          <div style={{height:400,margin:'0 auto',backgroundImage : `url(${NotFound})`,backgroundRepeat:'no-repeat',width:575}}>

          </div>
          <div style={{textAlign:'center'}}> 
            <h2 style={{fontSize:40,textAlign:'center' }}>Page Not Found</h2>
            {
              this.state.isUser 
                  ?
                  <Link to="/dashboard" style={{background:'#3f51b5',color:'#fff',textDecoration:'none',padding:'10px 15px',borderRadius:3,fontWeight:'bold',boxShadow:'2px 5px 7px #666'}}>
                    Return to Dashboard
                  </Link>
                    :
                  <Link to="/" style={{background:'#3f51b5',color:'#fff',textDecoration:'none',padding:'10px 15px',borderRadius:3,fontWeight:'bold',boxShadow:'2px 5px 7px #666'}}>
                    Return to Homepage
                  </Link>
            }
          </div>
        </div>
      );
    }
  }
  export default PageNotFound;
import {ADD} from '../constant/index';
let initialState={
    number:1
}
export function newReducer(state= initialState, action){
    if(action.type === ADD){
        return {
            ...state,
            number : state.number + action.payload
        }
    }
    return state;
}

import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Typography,Tooltip,Fab, Dialog,DialogTitle,DialogContent,TextField,Button,DialogActions,FormControl,InputLabel,MenuItem,Select,Card,CardHeader,Divider,CardActionArea,CardContent,CardActions } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import Select from 'react-select/src/Select';

const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

function contactinfo(){
    const { classes } = this.props;
    return (
        <>
        {
            this.state.isLoading
                    ?
            <>
            <Skeleton variant="rect" width={"100%"} height={100} className={classes.skeleton}/>
            <Skeleton variant="rect" width={"100%"} height={100} className={classes.skeleton}/>
            </>
                    :
            <div>
                <Typography variant="h4" className={classes.contact_title}>Contact List</Typography>
                {
                    this.state.supportType.map((item,index)=>{
                        return <div className={classes.backdiv} key={item.title}>
                                    <Typography variant="h4" className={classes.heading}>
                                         <span className={classes.vsuper}>{item.title}</span>
                                    </Typography>
                                    {
                                        item.list.length > 0
                                            ?
                                        <div>
                                            {
                                                item.list.map(item1=>{
                                                    return <div key={item1.contact_person_name}><Card className={classes.root}>
                                                    
                                                        <CardActionArea>
                                                            <CardContent>
                                                            <p
                                                                className={classes.contact_details} 
                                                            >
                                                                <PersonIcon 
                                                                    className={classes.icon}
                                                                />
                                                                <span className={classes.contact_email}>
                                                                    {item1.contact_person_name}
                                                                </span>
                                                            </p>
                                                            <p
                                                                className={classes.contact_details}
                                                            >
                                                                <EmailIcon 
                                                                        className={classes.icon}
                                                                    />
                                                                <span className={classes.contact_email}>
                                                                    {item1.contact_person_email}
                                                                </span>
                                                            </p>
                                                            <p
                                                                className={classes.contact_details}
                                                            >
                                                                <PhoneIphoneIcon 
                                                                        className={classes.icon}
                                                                    />
                                                                <span className={classes.contact_email}>
                                                                    {item1.contact_person_phone}
                                                                </span>
                                                            </p>
                                                            </CardContent>
                                                        </CardActionArea>
                                                        <CardActions>
                                                            <Button 
                                                                size="small" 
                                                                color="primary" 
                                                                onClick={this.handleUpdate}
                                                                actions="update" 
                                                                index={index} 
                                                                id={item.support_type_id}
                                                                >
                                                                    Update
                                                            </Button>
                                                            <Button 
                                                                size="small" 
                                                                color="primary"
                                                                onClick={this.handleDelete}
                                                                actions="delete" 
                                                                index={index} 
                                                                id={item.support_type_id}
                                                                >
                                                                    Delete
                                                            </Button>
                                                        </CardActions>
                                                    </Card>
                                                    <Divider />
                                                    </div>
                                                })
                                            }
                                            
                                        </div>
                                            :
                                            <div className={classes.blankdiv}>
                                            <Tooltip title="Add" aria-label="add">
                                                <Fab color="primary" className={classes.fab} onClick={this.addContact} id={item.support_type_id} actions = "add">
                                                <AddIcon />
                                                </Fab>
                                            </Tooltip>
                                            </div>   
                                    }
                                </div>
                    })
                }
            </div>
        }
        <Dialog  open={this.state.openDialog} onClose={this.handleClose} >
        <DialogTitle id="form-dialog-title">Add Contact Details</DialogTitle>
        <DialogContent style={{width:420}}>
            <form>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="contact_type_label">Contact Type</InputLabel>
                    <Select
                        labelId="contact_type_label"
                        id="contact_type"
                        value={this.state.contact_type}
                        onChange={this.handleChange}
                        label="Contact Type"
                        margin="dense"
                        style={{marginBottom:20}}
                        disabled
                        >
                            {
                                this.state.supportType.map(item=>{
                                    return <MenuItem value={item.support_type_id} key={item.support_type_id}>{item.title}</MenuItem>
                                })
                            }
                    </Select>
                </FormControl>
                <TextField 
                    variant="outlined" 
                    label="Name"
                    autoFocus 
                    value={this.state.name} 
                    onChange={this.InputhandleChange} 
                    name="name" 
                    fullWidth 
                    style={{marginBottom:20}}
                    inputRef={this.name}
                    error={this.state.submitted && !this.state.name ? true : false}
                    helperText = {this.state.submitted && !this.state.name ? "Name is required":""}
                />
                <TextField 
                    variant="outlined" 
                    label="Email"
                    value={this.state.email} 
                    onChange={this.InputhandleChange} 
                    name="email" 
                    fullWidth 
                    style={{marginBottom:20}}
                    inputRef={this.email}
                    error={this.state.submitted && (!this.state.email || !regex.test(this.state.email)) ? true : false}
                    helperText = {this.state.submitted && (!this.state.email || !regex.test(this.state.email)) ? "Email is required":""}
                />
                <TextField 
                    variant="outlined" 
                    label="Mobile"
                    value={this.state.mobile} 
                    onChange={this.InputhandleChange} 
                    name="mobile" 
                    fullWidth 
                    style={{marginBottom:20}}
                    inputRef={this.mobile}
                    error={this.state.submitted && (this.state.mobile.length !== 10)? true : false}
                    helperText = {this.state.submitted && (this.state.mobile.length !== 10)? "Mobile Number is required":""}
                />
            </form>
            <DialogActions >
                <div style={{textAlign:"center",display:'block',width:'100%'}}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        title="single"
                        style={{marginRight:10}}
                        onClick={this.handleSubmit}
                        >
                            Save
                    </Button>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={this.handleClose}
                        >
                            Cancel
                    </Button>
                </div>
        </DialogActions>
        </DialogContent>
    </Dialog>
        </>
    )
}

export default contactinfo;
import React,{ Component } from 'react';
import contactinfo from './ContactInfo.jsx';
import { withStyles } from '@material-ui/core/styles';
import {authHeader} from '../../../../helper/auth-header';
import axios from 'axios';
import siteConfig from '../../../../config';

const apiUrl = siteConfig.apiUrl;
const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const styles = theme => ({
    skeleton:{
        marginTop: 20,
        marginRight:20,
        display:'inline-block'
    },
    heading:{
        fontSize: 16,
        fontWeight : 400,
        padding : 10,
        color : '#555',
        fontWeight:'bold'
    },
    addNew:{
        height : 80,
        width : 80,
        background : '#ccc',
        marginLeft : 20,
        borderRadius: 5,
        display : 'inline-block',
        position : 'relative',
        
        '&:hover':{
            boxShadow : '0px 1px 6px #000',
            transition : '0.2s'
        }
    },
    addIcon:{
        position: 'absolute',
        top : 28,
        left : 28
    },
    fab: {
        margin: theme.spacing(2),
      },
    contact_list:{
        fontSize : 14,
        width : '100%',
        textAlign:'left',
        marginBottom:0
    },
    ContactText:{
        verticalAlign:'super'
    },
    contact_div:{
        borderRadius:5,
        boxShadow : '0px 0px 9px #999',
        marginLeft : 20,
        display: 'inline-block',
        padding: 10
    },
    contact_title:{
        fontSize:20,
        marginBottom:20
    },
    contact_details:{
        fontSize:13,
        width:'100%',
        textAlign:'left',
        margin: '0',
        fontWeight:'500'
    },
    contact_email:{
        verticalAlign:'top',
        marginTop:3,
        marginLeft:5,
        display:'inline-block'
    },
    icon:{
        color:'#666',
        fontSize : 20
    },
    root : {
        display: 'inline-block',
        marginBottom : 20
    },
    vsuper :{
        verticalAlign : 'super'
    },
    blankdiv:{
        background : '#eaeaea',
        display : 'inline-block',
        borderRadius : 1
    }
})

class ContactInfo extends Component{
    constructor(props){
        super(props);

        this.state = {
            isLoading : false,
            openDialog : false,
            contact_type : '',
            supportType : [],
            name : '',
            email : '',
            mobile : '',
            submitted : false,
            action : ""
        }
        this.name = React.createRef('name');
        this.email = React.createRef('email');
        this.mobile = React.createRef('mobile');
    }
    componentDidMount(){
        this.getOptionsList()
    }
    getOptionsList = () => {
        const requestOptions = {
            method : "Get",
            headers : authHeader()
        }
        return axios(apiUrl+'/get_contact_type_list',requestOptions)
        .then(data=>{
            // console.log(data);
            this.setState({
                supportType : data.data.data.result
            })
        }).catch(error=>{
            console.log(error);
        })
    }

    addContact=(e)=>{
        // console.log(e.currentTarget)
        let id = e.currentTarget.getAttribute('id');
        let action = e.currentTarget.getAttribute('actions');
        this.setState({
            openDialog : true,
            contact_type : id,
            action : action
        })
    }
    handleClose = () => {
        this.setState({
            openDialog : false,
            submitted : false,
            name : '',
            email : '',
            mobile : ''
        })
    }
    handleChange = (e) => {
        this.setState({
            contact_type : e.target.value
        })
    }
    InputhandleChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    handleSubmit = (e) => {
        this.setState({
            submitted : true
        },()=>{
            if(this.state.submitted && this.state.name && this.state.mobile && this.state.email && regex.test(this.state.email) && this.state.mobile.length === 10){
                const requestOptions = {
                    method : "Post",
                    headers : authHeader(),
                    data : {
                        name : this.state.name,
                        mobile : this.state.mobile,
                        email : this.state.email,
                        action : this.state.action,
                        support_type_id : this.state.contact_type
                    }
                }
                return axios(apiUrl+'/vendor_contact_update',requestOptions)
                .then(data=>{
                    this.setState({
                        openDialog : false
                    },()=>{
                        // console.log(data);
                        this.getOptionsList()
                    })
                }).catch(error=>{
                    console.log(error)
                })
            }else{
                if(this.state.submitted && !this.state.name){
                    this.name.current.focus();
                }else if(this.state.submitted && (!this.state.mobile || this.state.mobile.length !== 10)){
                    this.mobile.current.focus();
                }else if(this.state.submitted && (!this.state.email || !regex.test(this.state.email))){
                    this.email.current.focus();
                }
            }
        })
    }
    handleUpdate=(e)=>{
        // console.log(e.currentTarget)
        let index = e.currentTarget.getAttribute('index');
        let id = e.currentTarget.getAttribute('id');
        let action = e.currentTarget.getAttribute('actions');
        let selectedType = this.state.supportType[index].list[0]
        this.setState({
            openDialog : true,
            name : selectedType.contact_person_name,
            email : selectedType.contact_person_email,
            mobile : selectedType.contact_person_phone,
            contact_type : id,
            action : action
        })
    }
    handleDelete = (e) => {
        // let index = e.currentTarget.getAttribute('index');
        let id = e.currentTarget.getAttribute('id');
        let action = e.currentTarget.getAttribute('actions');
        this.setState({
            contact_type : id,
            action : action
        },()=>{
            const requestOptions = {
                method : "Post",
                headers : authHeader(),
                data : {
                    action : this.state.action,
                    support_type_id : this.state.contact_type
                }
            }
            return axios(apiUrl+'/vendor_contact_update',requestOptions)
            .then(data=>{
                this.setState({
                    openDialog : false
                },()=>{
                    // console.log(data);
                    this.getOptionsList()
                })
            }).catch(error=>{
                console.log(error)
            })
        })
    }
    render(){
        return contactinfo.call(this,this.name,this.email,this.mobile);
    }
}

export default withStyles(styles)(ContactInfo);
import React from 'react';
import {Grid,Button,Paper,Fab,Tooltip,Collapse,Dialog,DialogContent,DialogTitle,DialogActions,DialogContentText,CircularProgress,Backdrop} from '@material-ui/core';
import {Add,Delete,Edit} from '@material-ui/icons';
import AddressForm from './address';

function step2(){
    return (
        <React.Fragment>
            {
                this.state.isLoading
                        ?
                    <div style={{padding:"40px 0",textAlign:'center'}}>
                        <CircularProgress/>
                    </div>
                        :
                        this.state.addressList.length>0
                        ?
            <React.Fragment>
                <h4 style={{fontSize:17,fontWeight:500}}>Warehouse Address List</h4>
                {
                    this.state.addressList.map((val,index)=>{
                        let start_time = val.working_hours.split(" ");
                        let s_time = new Date('2020-01-18T'+start_time[0]).toLocaleTimeString();
                        let e_time = new Date('2020-01-18T'+start_time[2]).toLocaleTimeString();
                        return  <Paper key={index} style={{padding:20,marginBottom:20}}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                        {
                                            this.state.open && this.state.id == index + 1
                                                            ?
                                                    <h4 style={{marginTop:0}}>Edit Address</h4>
                                                            :
                                            <React.Fragment>
                                                <ul style={{paddingLeft:0,margin:0,listStyleType:"none"}}>
                                                    <li style={{marginBottom:5}}>
                                                        <span style={{fontWeight:500,fontSize:14}}>Address : </span><p style={{margin:0,display:'inline',textAlign:'left',fontSize:14}}>{val.address}, {val.city}, {val.state}-{val.pincode}</p>
                                                    </li>
                                                    <li style={{marginBottom:5}}>
                                                        <span style={{fontWeight:500,fontSize:14}}>Working hours :</span><p style={{margin:0,display:'inline',textAlign:'left',fontSize:14}}> {s_time} - {e_time}</p>
                                                    </li>
                                                    <li style={{marginBottom:5}}>
                                                    <span style={{fontWeight:500,fontSize:14}}>Weekly Off :</span><p style={{margin:0,display:'inline',textAlign:'left',fontSize:14,textTransform:"capitalize"}}> {val.weekly_on_off}</p>
                                                    </li>
                                                </ul>
                                                
                                                
                                                
                                            </React.Fragment>
                                        }
                                        </Grid>
                                        <Grid item xs={6} style={{textAlign:"right"}}>
                                            {
                                                        this.state.open 
                                                                ?
                                                                null
                                                                :
                                                <React.Fragment>
                                                    <Tooltip title="Edit" placement="top" onClick={this.changeVisibility}>
                                                        <Fab color="primary" size="medium" style={{marginRight:10}} id={index + 1} address_id={val.address_id}
                                                        >
                                                            <Edit id={index + 1} />
                                                        </Fab>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top">
                                                        <Fab color="secondary" size="medium" onClick={this.openDialog} address_id = {val.address_id}>
                                                            <Delete />
                                                        </Fab>
                                                    </Tooltip>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Collapse in={this.state.open && this.state.id == index + 1}>
                                        <AddressForm
                                            addressList = {this.state.addressList} 
                                            submitWareHouseDetails={this.submitWareHouseDetails} 
                                            submitted={this.state.submitted} 
                                            address={this.state.address} 
                                            pincode={this.state.pincode} 
                                            city={this.state.city}
                                            State={this.state.State}
                                            handleChange={this.handleChange}
                                            handleStartChange={this.handleStartChange}
                                            handleEndChange={this.handleEndChange}
                                            start_time={this.state.start_time}
                                            end_time={this.state.end_time}
                                            options={this.options}
                                            selectChange={this.selectChange}
                                            selectedOptions={this.state.selectedOptions}
                                            addressRef={this.addressRef}
                                            pincodeRef={this.pincodeRef}
                                            cityRef={this.cityRef}
                                            StateRef={this.StateRef}
                                            previous={this.props.previous}
                                            next={this.props.next}
                                            openDialog={this.openDialog}
                                            closecollapse={this.closecollapse}
                                            index = {index+1}
                                            showSame={false}
                                            same={this.sameAddress}
                                            checked = {this.state.checked}
                                            weeklychange={this.weeklychange}
                                            weeklyOff={this.state.weeklyOff}
                                            handleWeeklyOff={this.handleWeeklyOff}
                                            inputChange={this.inputChange}
                                            delete={true}
                                            />
                                    </Collapse>
                                </Paper>
                    })
                }
                <Paper style={{padding:20}}>
                    <h3 style={{marginTop:0,display:'inline-block',width:"50%"}}>Add New Address</h3>
                    {
                        this.state.open
                            ?
                            null
                            :
                        <div style={{textAlign:'right',display:"inline-block",width:'50%'}}>
                            <Tooltip title="add" placement="top">
                                <Fab color="primary" onClick={this.changeVisibility} id="0" address_id="0">
                                    <Add />
                                </Fab>
                            </Tooltip>
                        </div>
                    }
                    <Collapse in={this.state.open && this.state.id === '0'}>
                    <AddressForm 
                        addressList = {this.state.addressList}
                        submitWareHouseDetails={this.submitWareHouseDetails} 
                        submitted={this.state.submitted} 
                        address={this.state.address} 
                        pincode={this.state.pincode} 
                        city={this.state.city}
                        State={this.state.State}
                        handleChange={this.handleChange}
                        handleStartChange={this.handleStartChange}
                        handleEndChange={this.handleEndChange}
                        start_time={this.state.start_time}
                        end_time={this.state.end_time}
                        options={this.options}
                        selectChange={this.selectChange}
                        selectedOptions={this.state.selectedOptions}
                        addressRef={this.addressRef}
                        pincodeRef={this.pincodeRef}
                        cityRef={this.cityRef}
                        StateRef={this.StateRef}
                        previous={this.props.previous}
                        next={this.props.next}
                        openDialog={this.openDialog}
                        closecollapse={this.closecollapse}
                        showSame={true}
                        same={this.sameAddress}
                        checked = {this.state.checked}
                        weeklychange={this.weeklychange}
                        weeklyOff={this.state.weeklyOff}
                        handleWeeklyOff={this.handleWeeklyOff}
                        inputChange={this.inputChange}
                        delete={false}
                        />
                    </Collapse>
                </Paper>
                <div style={{padding:"30px 0",textAlign:'center'}}>
                    <Button color="primary" variant="contained" style={{marginRight:5}} onClick={this.props.previous}>Back</Button>
                    <Button color="primary" variant="contained" onClick={this.props.next}>Next</Button>
                </div>
            </React.Fragment>
                        :
                        <AddressForm
                        addressList = {this.state.addressList} 
                        submitWareHouseDetails={this.submitWareHouseDetails} 
                        submitted={this.state.submitted} 
                        address={this.state.address} 
                        pincode={this.state.pincode} 
                        city={this.state.city}
                        State={this.state.State}
                        handleChange={this.handleChange}
                        handleStartChange={this.handleStartChange}
                        handleEndChange={this.handleEndChange}
                        start_time={this.state.start_time}
                        end_time={this.state.end_time}
                        options={this.options}
                        selectChange={this.selectChange}
                        selectedOptions={this.state.selectedOptions}
                        addressRef={this.addressRef}
                        pincodeRef={this.pincodeRef}
                        cityRef={this.cityRef}
                        StateRef={this.StateRef}
                        weeklyRef={this.WeeklyOffRef}
                        previous={this.props.previous}
                        next={this.props.next}
                        openDialog={this.openDialog}
                        closecollapse={this.closecollapse}
                        showSame={true}
                        same={this.sameAddress}
                        checked = {this.state.checked}
                        weeklychange={this.weeklychange}
                        weeklyOff={this.state.weeklyOff}
                        handleWeeklyOff={this.handleWeeklyOff}
                        inputChange={this.inputChange}
                        delete={false}
                        />
            }
                <Dialog
                    open={this.state.dialog}
                    onClose={this.closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Delete Address"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{width:"100%"}}>
                        Are you Sure you want to Delete this address.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.closeDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.deleteAddress} color="primary">
                        Delete
                    </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop open={this.state.backdropOpen} style={{zIndex:9999}}>
                <CircularProgress color="inherit" />
                </Backdrop>
        </React.Fragment>
    )
}
export default step2;